.m16
{
    h2 
    {
        font-family: $tiempos-headline-medium;
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: $fbgdark;
        margin-bottom: 34px;
        &.h22 
        {
            width: 100%;
            margin: 0;
            padding: 0;
            font-size: 24px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: $fbgdark;
            @media screen and (max-width: $mobile-breakpoint) {
                font-size: 20px;
                 font-weight: 500;
             }
        }
        @media screen and (max-width: $mobile-breakpoint) {
           text-align: center;
           font-size: 20px;
            font-weight: 500;
            margin: 0;
            padding: 0;
        }
    }
    a {
        &.buttonlinkw{
            width: 200px;
            height: 45px;
            line-height: 44px;
            border-radius: 22.5px;
            background-color: $white;
            border: 1px solid $dark-blue;

            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: $dark-blue;
            text-align: center;
            position: absolute;
            @media screen and (max-width: $mobile-breakpoint) {
                position: relative;
                padding: 15px 50px;
            }
          }
        &.adescriptiondiv //
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: $fbgdark;
            display: table-row;
            @media screen and (max-width: $mobile-breakpoint) {
                font-size: 13px;
                text-align: center;
            }

        }

        &.ainfodiv //
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: $grey;
            display: table-row;
            @media screen and (max-width: $mobile-breakpoint) {
                font-size: 14px;
                text-align: center;
            }
        }

        &.pclass2 { 
            min-height: 93px;
            margin-right: 0px;
            margin-left: 0%;
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.40;
            letter-spacing: normal;
            color: $fbgdark;
            display: inline;
            @media screen and (max-width: $mobile-breakpoint) {
                display: none;
            }
        }
        &.h22 {
            font-family: $WorkSans;
            font-size: 22px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.2;
            letter-spacing: normal;
            color: $fbgdark;
            margin-right: 0px;
            display: inline;
            @media screen and (max-width: $mobile-breakpoint) {
                min-height: 126px;  
                font-size: 20px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.3;
            }
        }
        &.h44 {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $dark-blue;
            display: inline;
            @media screen and (max-width: $mobile-breakpoint) {
                padding:0;
                margin-top: 1px;
                margin-bottom: 30px;
                margin-left: 0px;
                margin-right: 0px;
            }
        }
        &.paragraph {
            font-family: $WorkSans;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            display: inline;
            color: $grey;
            @media screen and (max-width: $mobile-breakpoint) {
                font-size: 15px;
                margin-left: 0px;
                margin-right: 0px;
            }
        }
        &.withimagea
        {
            font-family: $WorkSans;
            font-size: 24px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: $fbgdark;
            display: inline;
            @media screen and (max-width: $mobile-breakpoint) {
                min-height: 60px;
                max-height: 60px;
                font-size: 20px;
                margin-left: 0px;
                margin-right: 0px;
                margin-bottom: 0px;
            } 
        }
        &.withoutimagea {
            margin-left: 15px;
            margin-right: 15px;
            height: 95px;
            font-family: $WorkSans;
            font-size: 24px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: $fbgdark;
            display: flex;   
            margin-bottom: 40px;
            @media screen and (max-width: $mobile-breakpoint) {
                min-height: 60px;
                max-height: 60px;
                font-size: 20px;
                margin-left: 0px;
                margin-right: 0px;
                margin-bottom: 0px;
            } 
        }
        &.fontstyle
        {
            font-family: $WorkSans;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.5;
            letter-spacing: normal;
            color: $onyx;
            border-bottom: 1px solid orangered;
        }
        &.nav-link
        {
            padding-left: 0;
            line-height: 44px;
            display: inline;
            margin-right: 75px;
            padding: 0;
        }
        &.linkfont
        {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: $black;
        }
        &.linkfont2
        {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: $fbgdark;
        }
        &.accordionmenu
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $greyb;
            &:first-child
            {
                color: $dark-blue;
            }
        }
        &.arcodionlink
        {
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.11;
            letter-spacing: normal;
            color: $black;
        }
        &.linkinsideacc
        {
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            color: $dark-blue;
            margin-left: 6px;
            cursor: pointer;
        }
        &.linksubmenu
        {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $onyx;
            display: inline;
            border-bottom:  1px solid $onyx;
        }
    }
    a:hover 
    {
        outline: none;
        text-decoration: none;
    }
    a:focus
    {
        outline: none;
        text-decoration: none;
    }
    ////////////////
    .coldivposition
    {
       .positiondiv
       {
           width: 100%;
           height: 20px;
           margin-bottom: 16px;
           @media screen and (max-width: $mobile-breakpoint) {
                display: none;
            }
       }
       
       .titlediv
       {
        width: 100%;
        min-height: 95px;
        margin-bottom: 13px;
        @media screen and (max-width: $mobile-breakpoint) {
            width:255px;
             max-height: 90px;
             margin-left: auto;
             margin-right: auto;
             text-align: center;
        }
       }

       .descriptiondiv
       {
        width: 100%;
        min-height: 65px;
        margin-bottom: 15px;
        @media screen and (max-width: $mobile-breakpoint) {
            width:255px;
             max-height: 65px;
             margin-left: auto;
             margin-right: auto;
             text-align: center;
             margin-bottom: 5px;
        }
       }

       .infodiv
       {
            width: 100%;
            min-height: 35px;
            margin-bottom: 16px;
            @media screen and (max-width: $mobile-breakpoint) {
                width:255px;
                height: 40px;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }
       }

       .buttonbiv
       {
            width: 100%;
            min-height: 45px;
            margin-bottom: 0px;
            @media screen and (max-width: $mobile-breakpoint) {
                text-align: center;
                margin-bottom: 10px;
            }
       }
    }
    .col-md-4.coldivposition {
        border-right: 1px solid $brdgrey;
        &:last-child
        {
            border-right: none;
            @media screen and (max-width: $mobile-breakpoint) {
                border: none;
            }
        }
        @media screen and (max-width: $mobile-breakpoint) {
            border-right: none;
        }
    }
    .publications_slider 
    {
        margin-top: 15px;
        margin-bottom: 100px;
        @media screen and (max-width: $mobile-breakpoint) {
            margin-top: 40px;
            padding-bottom: 45px;
            border-bottom: 1px solid $brdgrey;
            margin-bottom: 40px;
        }
    }
    .slick-dots {
        display: flex;
        justify-content: center;
        
        margin: 0;
        padding: 30px 0;
        
        list-style-type: none;
        
            li {
                margin: 0 0.25rem;
            }
        
            button {
                display: block;
                width: 0.5rem;
                height: 0.5rem;
                padding: 0;
                
                border: none;
                border-radius: 100%;
                background-color: $brdgrey;
                text-indent: -9999px;
            }
        
            li.slick-active button {
                background-color: $bluedark;
            }
        
        }
}