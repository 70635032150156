// New Font Variables
@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,500,600,700');

@font-face {
  font-family: 'TiemposHeadline';
  src: url('/assets/fonts/TiemposHeadlineWeb-Medium.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/TiemposHeadlineWeb-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/TiemposHeadlineWeb-Medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/TiemposHeadlineWeb-Medium.woff') format('woff'); /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'TiemposText';
  src: url('/assets/fonts/TiemposHeadlineWeb-Regular.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/TiemposHeadlineWeb-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/TiemposHeadlineWeb-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/TiemposHeadlineWeb-Regular.woff') format('woff'); /* Pretty Modern Browsers */
}

$tiempos-headline-medium: 'TiemposHeadline', sans-serif;
$tiempos-headline-regular: 'TiemposText', sans-serif;
$tiempos-headline: 'TiemposHeadline', sans-serif;
$tiempos-text: 'TiemposText', sans-serif;
$roboto-slab: 'Roboto Slab', serif;
$WorkSans: 'Work Sans', 'sans-serif';
$TiemposHeadline: 'Roboto Slab', serif;
$TiemposText: 'Roboto Slab', serif;

$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;

//Color Variables and Color Classes
$orange: #e67800;
$orangeo: #ff6b35;
$orgvo: #ed4c22;
$dark: #101010;
$grey-dark: #cccccc;
$grey-medium: #e4e4e4;
$grey-light: #f6f6f6;
$white: #ffffff;
//New colors variables
$othcol4: #444444;
$istblack: #1d2114;
$onyx: #333333;
$istgreen: #394a13;
$ltgray: #999999;
$mossgreen: #575f49;
$cellgreen: #577512;
$alabaster: #ebede6;
$it-orange: #ff6c2d;
$dark-blue: #235789;
$grey: #9b9b9b;
$fbgdark: #222222;
$brdgrey: #dddddd;
$black: #000000;
$greyb: #4a4a4a;
$whtn: #ededed;
$othercol: #e4e5e7;
$greyvo: #cacece;
$bluedark: #192830;
$othcol: #383838;
//
.orange {
  color: $orange;
}

.dark {
  color: $dark;
}

.grey-dark {
  color: $grey-dark;
}

.grey-medium {
  color: $grey-medium;
}

.grey-light {
  color: $grey-light;
}

.white {
  color: white;
}

//orgvo
.othcol4 {
  color: $othcol4;
}

.orgvo {
  color: $orgvo;
}

.orangeo {
  color: $orangeo;
}

.othcol {
  color: $othcol;
}

.bluedark {
  color: $bluedark;
}

.greyvo {
  color: $greyvo;
}

.istblack {
  color: $istblack;
}

.onyx {
  color: $onyx;
}

.istgreen {
  color: $istgreen;
}

.ltgray {
  color: $ltgray;
}

.mossgreen {
  color: $mossgreen;
}

.cellgreen {
  color: $cellgreen;
}

.alabaster {
  color: $alabaster;
}

.it-orange {
  color: $it-orange;
}

.dark-blue {
  color: $dark-blue;
}

.grey {
  color: $grey;
}

.fbgdark {
  color: $fbgdark;
}

.brdgrey {
  color: $brdgrey;
}

.black {
  color: $black;
}

.greyb {
  color: $greyb;
}

.whtn {
  color: $whtn;
}

.othercol {
  color: $othercol;
}


html {
  font-family: $WorkSans;
}
body {
  margin: 0;
  color: #333;
}

h1 {
  text-align: left;
  font-family: $tiempos-headline-medium;
  font-weight: $medium;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 1px;
  color: $dark;
  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 24px;
    line-height: 31px;
    text-align: left;
  }
}

h2 {
  font-family: $tiempos-headline-regular;
  font-weight: $medium;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.8px;
  text-align: left;
  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 20px;
    line-height: 26px;
    text-align: left;
  }
}

h3 {
  font-family: $WorkSans;
  font-weight: $medium;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-align: left;
  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 16px;
    line-height: 22px;
    text-align: left;
  }
}

h4 {
  font-family: $WorkSans;
  font-weight: $bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: $dark-blue;
  text-align: left;
  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }
}

p {
  font-family: $WorkSans;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.3px;
  color: $onyx;
  text-align: left;
  &.p1 {
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 15px;
      line-height: 22px;
      text-align: left;
    }
  }
  &.p2 {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 13px;
      line-height: 18px;
      text-align: left;
    }
  }
  &.p3 {
    font-family: $WorkSans;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 13px;
      line-height: 16px;
      text-align: left;
    }
  }
  &.p1bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 15px;
      line-height: 22px;
      text-align: left;
    }
  }
  &.p2bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 13px;
      line-height: 18px;
      text-align: left;
    }
  }
  &.p3bold {
    font-family: $WorkSans;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 13px;
      line-height: 16px;
      font-weight: 600;
      text-align: left;
    }
  }
  &.ptest {
    font-family: $tiempos-headline-medium;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    text-align: left;
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 13px;
      line-height: 16px;
      font-weight: normal;
      text-align: left;
    }
  }
  &.ptext12 {
    font-size: 12px;
    line-height: 1.5;
    color: #222222;
  }
  &.quote {
    font-family: $tiempos-headline-regular;
    font-size: 18px;
    line-height: 2;
    color: #000000;
  }
  &.foterfont {
    font-family: $tiempos-headline-medium;
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    color: #ffffff;
    text-align: left;
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 13px;
      line-height: 16px;
      font-weight: normal;
      text-align: left;
    }
  }
  &.testleft {
    text-align: left;
  }

}

/*a {
	font-family: $WorkSans;
	font-weight: $medium;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.3px;
	color: $dark;
	border-bottom: 2px solid $orange;
	&.large {
		font-size: 25px;
		line-height: 40px;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 20px;
		}
	}
	&.medium {
		font-size: 16px;
		line-height: 30px;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 14px;
		}
	}
	&.small {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 1.5px;
		border: none;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 12px;
		}
	}
	&.downloadlink {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 1.5px;
		border: none;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 12px;
		}
	}

	&:hover {
		text-decoration: none;
		color: inherit;
	}

}
.btn {
	font-family: $WorkSans;
	font-weight: $medium;
	font-size: 14px;
	letter-spacing: 1.3px;
	color: $dark;
	text-transform: uppercase;
	@media screen and (max-width: $mobile-breakpoint) {
		font-size: 12px;
		letter-spacing: 1.3px;
	}

}
*/

ul {
  &.darklist {
    font-family: $WorkSans;
    font-weight: $light;
    font-size: 16px;
    line-height: 22px;
    background-color: #000;
    color: #e4e4e4;

    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 16px;
      line-height: 22px;
      margin-top: -2px;
      position: relative;
    }
  }
  li {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
}

li {
  &.textlist {
    background-color: #000000;
    color: blue;
  }
}

a {
  &.testlink {
    border-bottom: 1px solid $alabaster;
    color: red;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 20px;
      text-align: left;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  &.darkline {
    text-align: left;
    @media screen and (max-width: $screen-md-min) {
      border-bottom: 1px solid #444444;
      font-size: 16px;
      line-height: 22px;
      text-align: left;
      margin-left: 15px;
      margin-right: 15px;
      :last-of-type {
        border: none;
      }
    }
  }
  .typegen {
    &:hover {
      cursor: pointer;
    }

    .slick-disabled {
      opacity: 0.4 !important;
    }
  }
}

.video-wrapper {
  position: relative;
}

.video-wrapper > video {
  width: 100%;
  vertical-align: middle;
}

.video-wrapper > video.has-media-controls-hidden::-webkit-media-controls {
  display: none;
}

.video-overlay-play-button {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10px calc(50% - 50px);
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  opacity: 0.95;
  cursor: pointer;
  background-image: linear-gradient(transparent, #000);
  transition: opacity 150ms;
}

.video-overlay-play-button:hover {
  opacity: 1;
}

.video-overlay-play-button.is-hidden {
  display: none;
}

.main-content {
  padding-top: 44px;
  padding-bottom: 120px;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
select:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: #235789;
  box-shadow: none;
  outline: 0 none;
}

.mobile-only {
  @media screen and (min-width: 768px) {
    display: none!important;
  }
}

.desktop-only {
  @media screen and (max-width: 767px) {
    display: none!important;
  }
}

.button-link-simple {
  line-height: 45px;
  border-radius: 22.5px;
  background-color: $white;
  border: 1px solid $dark-blue;
  font-family: $WorkSans;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: $dark-blue;
  text-align: center;
  padding: 0 46px;
  @media screen and (max-width: $mobile-breakpoint) {
    position: relative;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .accordion-mobile {
    border: 0 !important;

    .collapse-mobile {
      display: block !important;
    }
    a {
      text-decoration: none;
    }
  }
  .accordion-mobile > div > div > .panel-title {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .accordion-mobile {
    border-top: 0 !important;
  }

  .linkdiv.mobile-only {
    margin-left: auto;
    margin-right: auto;
    a {
      text-decoration: none;
      p {
        font-size: 15px;
        font-weight: 600;
        color: #ff6b35;
        font-family: $WorkSans;
        text-transform: uppercase;
      }
    }
  }
}

.ellip {
  display: block;
  height: 100%;
}

.ellip-line {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  max-width: 100%;
}

.ellip,
.ellip-line {
  position: relative;
  overflow: hidden;
}