.m14x {
  h2 {
    margin-bottom: 33px;
  }
  ul {
    padding-right: 15px;
  }

  ol li {
    list-style-type: decimal-leading-zero;
    color: orangered;
  }
  a {
    &.fontstyle {
      font-family: $WorkSans;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.5;
      letter-spacing: normal;
      color: $onyx;
      border-bottom: 1px solid orangered;
    }
    &.nav-link {
      padding-left: 0;
      line-height: 44px;
      display: inline;
      margin-right: 45px;
      padding: 0;
    }
    &.linkfont {
      font-family: $TiemposText;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $black;
    }
    &.linkfont2 {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $fbgdark;
    }
    &.accordionmenu {
      font-family: $WorkSans;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $greyb;
      &:first-child {
        color: $dark-blue;
      }
    }
    &.arcodionlink {
      font-family: $tiempos-headline-medium;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.11;
      letter-spacing: normal;
      color: $black;
    }
    &.arcodionlinkchb {
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.11;
      letter-spacing: normal;
      color: $black;
      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 15px;
      }

    }
    &.linkinsideacc {
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: $dark-blue;
      margin-left: 6px;
      cursor: pointer;
    }

  }
  a:hover {
    outline: none;
    text-decoration: none;
  }
  a:focus {
    outline: none;
    text-decoration: none;
  }
  img {
    width: 100%;
    height: auto;
  }
  .col-md-3.d-flex.justify-content-end {
    padding: 0;
  }
  ul li {
    &.nav-item {
      line-height: 44px;
      text-align: left;
      border-bottom: 1px solid $brdgrey;
    }
    &.nav-item:active {
      line-height: 44px;
      text-align: left;
      border-bottom: 1px solid $brdgrey;
    }
    &.lit {
      padding-left: 12px;
    }
  }
  li.dropdown:hover > .dropdown-menu {
    display: block;
  }
  a.nav-link.linkfont2 {
    //padding-left: 15px;
    margin: 0;
  }
  a.nav-link.linkfont2:hover {
    font-weight: 600;
  }
  ul {
    .collapse.list-unstyled {
      padding: 0;
      border-top: 1px solid $brdgrey;
    }
  }
  .triangle {
    content: "";
    width: 5px;
    height: 5px;
    border-right: 1px solid $fbgdark;
    border-bottom: 1px solid $fbgdark;
    transform: rotate(315deg);
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
  .divaccordionspace {
    margin-top: 30px;
    margin-bottom: 19px
  }
  .divborder {
    border-top: 1px solid $brdgrey;
    margin: 0px;
    padding: 0px;
  }
  .divborderbottom {
    border-bottom: 1px solid $brdgrey;
    margin-bottom: 10px;
  }
  .divacc {
    padding-top: 40px;
  }
  ul.nav.flex-column {
    padding-right: 0;
  }
  .backtp {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $dark;
    margin: 0 auto;
    width: 100%;
    cursor: pointer;
    text-align: center;
  }
  .arrow {
    content: "";
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 10px;
    height: 10px;
    border-right: 2px solid $fbgdark;
    border-bottom: 2px solid $fbgdark;
    transform: rotate(225deg);
    cursor: pointer;
    margin-bottom: 55px;
  }

  .nav-link {

    &.expand {
      &:after {

        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 17px;
        border-right: 1px solid $fbgdark;
        border-bottom: 1px solid $fbgdark;
        transform: rotate(45deg);
        top: 62px;
      }
    }
    &.expand[aria-expanded="true"] {
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 17px;
        border-right: 1px solid #222;
        border-bottom: 1px solid #222;
        transform: rotate(225deg);
        top: 69px;
      }
    }
  }

  .nav-item {
    &.show {
      .nav-link.linkfont.expand {
        &:after {

          content: "";
          width: 10px;
          height: 10px;
          position: absolute;
          right: 2px;
          border-right: 1px solid $fbgdark;
          border-bottom: 1px solid $fbgdark;
          transform: rotate(225deg);
          top: 62px;
        }
      }
    }
  }
  ul.nav.flex-column.divborder {
    margin-right: 0px;
  }
  a.accordionmenu:hover {
    color: $dark-blue;
  }

  .panel-body {
    padding: 29px 0 37px 54px;
    @media screen and (max-width: $mobile-breakpoint) {
      padding: 20px 0px;
    }
    p {
      margin-bottom: 11px;
    }
  }

  .panel-group .panel + .panel {
    margin-top: 0;
    border-top: 0;
  }

  .panel-group .panel {
    border-radius: 0;
  }

  .panel-default > .panel-heading {
    color: $onyx;
    background-color: $white;
    border-color: $othercol;
    padding: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .panel-default > .panel-heading a {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    @media screen and (max-width: $mobile-breakpoint) {
      padding-left: 0px;
    }
  }

  .panel-default > .panel-heading a:after {
    content: "";
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: transform .25s linear;
    -webkit-transition: -webkit-transform .25s linear;
  }

  .panel-default > .panel-heading a[aria-expanded="true"] {
    border-bottom: 1px solid $brdgrey;
    border-bottom-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 50px;
  }

  .panel-default > .panel-heading a[aria-expanded="true"]:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    right: 30px;
    border-right: 1px solid $fbgdark;
    border-bottom: 1px solid $fbgdark;
    transform: rotate(225deg);
    top: 20px;
    @media screen and (max-width: $mobile-breakpoint) {
      right: 10px;
    }
  }

  .panel-default > .panel-heading a[aria-expanded="false"]:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    right: 30px;
    border-right: 1px solid $fbgdark;
    border-bottom: 1px solid $fbgdark;
    transform: rotate(45deg);
    top: 19px;
    @media screen and (max-width: $mobile-breakpoint) {
      right: 10px;
    }
  }

  .panel-group {
    border: 1px solid $brdgrey;
    margin-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    @media screen and (max-width: $mobile-breakpoint) {
      border: none;
      border-radius: 0;
      border-top: 1px solid $brdgrey;

      margin-bottom: 0;
      &:first-child {
        margin-top: 30px;
      }
      &:last-child {
        border-bottom: 1px solid $brdgrey;
      }
    }
  }
  .col-md-12.accdiv {
    @media screen and (max-width: $mobile-breakpoint) {
      padding: 0;
    }
  }

  h4 {
    &.panel-title {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.11;
      letter-spacing: normal;
      color: $black;
    }
  }
  input#searchid {
    //url('../assets/img/svg/icon-search.svg');
    width: 100%;
    background-repeat: no-repeat;
    text-indent: 20px;
    background-position: right 30px center;
    padding: 0;
    border: none;
    background-color: $grey-light;
    line-height: 44px;
  }
  input[type=text]:focus {
    background-image: none;
  }
  input[type=text]:active {
    background-image: none;
  }
  input[type=text]:hover {
    background-image: none;
  }
  .accordion {
    overflow-y: scroll;
    height: 100px;
    margin-left: 21px;
  }
  ::-webkit-scrollbar {
    width: 12px;
    background-color: $grey-light;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset red;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: $dark-blue;
  }
  .regular-checkbox {
    -webkit-appearance: none;
    background-color: $grey-light;
    border: 1px solid $greyvo;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
  }
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-top: 15px;
    text-align: center;
    border: 5px solid blue;
    color: blue;
    background-color: white;
  }
  //
  .checkbox-custom {
    opacity: 1;
    background-color: $grey-light;
  }
  //
  .rectanglemission {
    margin-top: 50px;
    width: 194px;
    border-radius: 3px;
    background-color: $othercol;
    padding-bottom: 20px;
    @media screen and (max-width: $mobile-breakpoint) {
      margin-top: 50px;
      width: 100%;
      margin-bottom: 50px;
    }
  }
  ul.no_bullet {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  li.leaf {
    background: url('/assets/img/icons/arrow_back.svg') no-repeat left top;
    min-height: 30px;
    padding-left: 50px;
    padding-top: 3px;
    padding-right: 10px;
  }
  .col-md-12.d-flex.justify-content-end {
    padding: 0;
    margin-bottom: 50px;
  }
  .panel-body.checkbox {
    padding: 0;
  }

  .panel-group.checkbox {
    @media screen and (max-width: $mobile-breakpoint) {
      border: 1px solid $brdgrey;
      margin-bottom: 30px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .firstmenu {
    @media screen and (max-width: $mobile-breakpoint) {
      display: none !important;
    }
  }
  a {
    color: #333333;
    text-decoration: underline;
    margin-bottom: 10px;
    &:hover {
      color: #333333;
    }
  }
  @media screen and (max-width: 767px) {
    .mobile-showall {
      border: 0;
      .panel-collapse {
        display: block !important;
      }
      .arcodionlink {
        border: 0 !important;
        &:after {
          display: none !important;
        }
      }
    }
  }
  .dropdown-filter-search {



    .noselect {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .dropdown-container, .instructions {
      width: 200px;
      margin: 20px auto 0;
      font-size: 14px;
      font-family: sans-serif;
      overflow: auto;
    }

    .instructions {
      width: 100%;
      text-align: center;
    }

    .dropdown-button {
      float: left;
      width: 100%;
      cursor: pointer;
      box-sizing: border-box;
      border-radius: 5px;
      border: solid 1px #dddddd;
      background-color: #ffffff;
      line-height: 50px;
      padding: 0 20px;
      position: relative;
      max-width: 326px;
      &:after {
        content: "";
        display: inline-block;
        font-family: Glyphicons Halflings;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: transform .25s linear;
        -webkit-transition: -webkit-transform .25s linear;
        width: 10px;
        height: 10px;
        position: absolute;
        right: 30px;
        border-right: 1px solid #222;
        border-bottom: 1px solid #222;
        transform: rotate(45deg);
        top: 19px;
      }

      .dropdown-label, .dropdown-quantity {
        float: left;
      }

      .dropdown-quantity {
        margin-left: 4px;
      }

      .fa-filter {
        float: right;
      }
    }

    .dropdown-list {
      float: left;
      border: 1px solid lightgray;
      border-top: none;
      box-sizing: border-box;
      position: absolute;
      z-index: 999999999;
      background: #fff;
      top: 93px;
      max-width: 326px;
      width: -webkit-calc(100% - 30px);
      width: -moz-calc(100% - 30px);
      width: calc(100% - 30px);
      input[type="search"] {
        line-height: 40px;
        width: 100%;
        border: 0;
        background-color: #f4f4f4;
      }

      ul {
        list-style-type: none!important;
        margin: 10px 0;
        max-height: 138px;
        overflow-y: auto;
        padding: 10px 14px 0px 20px;
        input[type="checkbox"] {
          position: relative;
          top: 4px;
          right: 4px;
        }
        label {
          font-family: $WorkSans;
          font-size: 13px;
          line-height: 1.38;
          color: #222222;
        }
      }
    }
    input[type="checkbox"] {
      margin-top: 0;
    }
  }
  input[type="search"]::placeholder {
    color: #999999;
    font-family: $WorkSans;
    font-size: 12px;
    padding-left: 20px;
  }
}