.m02
{
    .publications_slider {
        padding-top: 30px;
        padding-bottom: 40px;
        border-bottom: 1px solid;
        border-top: 1px solid;
        border-color: $brdgrey;
        margin-bottom: 20px;
        @media screen and (max-width: $mobile-breakpoint) {
            padding-bottom: 30px;
        }

    }
    /////////////////////////////////////////// 
    h2 {
        font-family: $WorkSans;
        font-size: 22px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: $fbgdark;
        margin-right: 0px;
        margin-bottom: 16px;
        @media screen and (max-width: $mobile-breakpoint) {
            min-height: 126px;  
        }
    }
    p {
        margin-right: 0%;
        margin-left: 0%;
        &.link
        {
            font-family: $WorkSans;
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: $orangeo;
            right: 0%;
            position: relative;
            margin-top: 0px;
            @media screen and (max-width: $mobile-breakpoint) {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0px;
                margin-bottom: 10px;
            }
        }
        
    }
    //////////////////////////////// 
    .upertext
    {
        position: relative;
        width: 100%;
        background-color: white;
        text-align: left;
        margin-right: 15px;
        height: 76px;
        border-top: 2px solid black;
        @media screen and (max-width: $mobile-breakpoint) {
            
        }
    }
    .firstrowarticle
    {
        //padding:  50px 0;
        border-top: 1px solid;
        border-color: $brdgrey;
        width: 100%;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        h4 {
            font-size: 12px;
            &:first-of-type{
                margin-right: 0;
                margin-left: 0;
                margin-bottom: 1px;
            }
            &:last-of-type {
                margin-right: 0;
                margin-left: 0;
                margin-bottom: 0;
                font-weight: 500;
            }
        }
        h2 {
            font-size: 20px;
            margin: 0px;
            &.divwithoutimg {
                font-size: 18px;
                margin: 0px;
                line-height: 1.1;
            }
        }
        
    }
    
    h1 {
        &.headerone {
            font-family: $tiempos-headline-medium;
            margin-top: 19px;
            margin-bottom: 19px;
        }
        @media screen and (max-width: $mobile-breakpoint) {
            font-size: 24px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $fbgdark;
        }
    }
    
    .linkdiv
    {
        width: 100%;
        background-color: white;
        text-align: right;
        margin-left: 15px;
        margin-right: 15px;
        height: 100px;
        @media screen and (max-width: $mobile-breakpoint) {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 70px;
        }
    }
    a:hover {
        text-decoration: none;
        }

        .type2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0px;
        top:18px;
        background-size:cover;
        transform: rotate(180deg);
        @media screen and (max-width: $mobile-breakpoint) {
            display: none;
        }
    }
    .type3 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 67px;
        top:18px;
        background-size:cover;
        @media screen and (max-width: $mobile-breakpoint) {
            display: none;
        }
    }

    .type2 {
        img {
            &:hover {
                cursor: pointer;
                opacity: 0.4;
            }
        }
    }
    .type3 {
        img {
            &:hover {
                cursor: pointer;
                opacity: 0.4;
            }
        }
    } 
    .typegen {
        &:hover {
            cursor: pointer;
        }

        .slick-disabled {
            opacity: 0.4 !important;
        }
    }
    img {
        &.imagearrow {
            width: 38px;
            height: 38px;
            border-radius: 50%;
        }
    }
    /////////////////////////////////// 
    .classborder {
        border-right: 1px solid  $brdgrey;
        @media screen and (max-width: $mobile-breakpoint) {
            border-right: 1px solid  $brdgrey;
        }
    }  
    .classborderwot {
        border-right: 1px solid  $brdgrey;
        @media screen and (max-width: $mobile-breakpoint) {
            border-right: none;
            min-height: 0px;
            //margin-: 15px;
        }
        @media screen and (max-width: $screen-sm-min) {
            border-right: none;
             min-height: 0;
            //margin-: 15px;
        }
    } 
    .classborderwo {
        border-right: none;
        @media screen and (max-width: $mobile-breakpoint) {
            padding-bottom: 9px;
            padding-right: 1px;
            margin-right: 14px;
            padding-left: 1px;
            margin-left: 14px;
            border-bottom: 1px solid  $brdgrey;
            margin-bottom: 0px;
            border-right: none;
            min-height: 0px;
            //margin-: 15px;
        }
        @media screen and (max-width: $screen-sm-min) {
            border-right: none;
            min-height: 0;
            min-height: 205px;
            //margin-: 15px;
        }
    }
    .bordertest 
    {
        height: 1px;
        width: 100%;
        background-color: $brdgrey;
        margin-top: 14.5px;
        margin-bottom: 14.5px;        
    }  
    img {
        @media screen and (max-width: $screen-sm-min) {
            margin-bottom: 10px;
        }
    }
    a:hover {
        text-decoration: none;
        border: none;
        cursor: pointer;
        opacity: 0.5;
        outline: none;
    }
    a:focus {
        text-decoration: none;
        border: none;
        outline: none;
    }
    a {
        &.h44 {
            &:first-of-type{
                font-family: $WorkSans;
                font-size: 12px;
                font-weight: 600;
                color: $dark-blue;
                margin-right: 0;
                margin-left: 0;
                display: inline;
            }
        }
        &.pclass2 { 
            min-height: 93px;
            margin-right: 0px;
            margin-left: 0%;
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.40;
            letter-spacing: normal;
            color: $fbgdark;
            display: inline;
            text-decoration: none;
            border: none;
            outline: none;
            @media screen and (max-width: $mobile-breakpoint) {
                display: none;
            }
        }
        &.h22 {
            font-family: $tiempos-headline-medium;
            font-size: 22px;
            font-weight: 500;
            line-height: 1.3;
            color: $fbgdark;
            margin-right: 0;
            display: inline;
            @media screen and (max-width: $mobile-breakpoint) {
                font-size: 20px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.2;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                /*! autoprefixer: off */
                -webkit-box-orient: vertical;
                height: 97px;
            }
        }
    }
   
    .hidediv
    {
        @media screen and (max-width: $mobile-breakpoint) {
        display: none; 
        }
    }

    .slick-slider {
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
      }
      .slick-list.draggable {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    .coldiv{
        width: 255px;
        .div-title {
            min-height: 78px;
            max-height: 88px;
            overflow: hidden;
            @media screen and (max-width: 767px) {
                margin-bottom: 15px;
            }
        }
        .div-text {
            height: 61px;
            overflow: hidden;
            @media screen and (max-width: $mobile-breakpoint) {
                display: none; 
            }
        }
        .div-link {
            position: absolute;
            bottom: 0;
            @media screen and (max-width: $mobile-breakpoint) {
                margin-top: 50px;
            }
            @media screen and (max-width: 767px) {
                margin-top: 0;
            }
            h4 {
                font-weight: 500;
            }
        }
        .div-link2 
        {
            margin-top: 20px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical; 
            @media screen and (max-width: $mobile-breakpoint) {
                bottom: 0;
                margin-top: 7px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical; 
            }
        }
        @media screen and (max-width: $mobile-breakpoint) {
            width: 100%;
        }
    }
    h4 {
        font-weight: 500;
    }
    @media screen and (min-width: $mobile-breakpoint+1) {
        .m2-slider .col-6 > .row > div {
            min-height: 235px;
        }
    }
}