.m22x {
  .iframe {
    width: 100%;
    height: 242px;
    margin-bottom: 50px;
  }
  .linklist
  {
    font-family: $WorkSans;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: $fbgdark;
    display: inline-block;
  }
  .buttonlinkk2{
    min-width: 120px;
    height: 30px;
    background-color: $dark-blue;
    border: $dark-blue;
    border-radius: 3px;
    font-family: $WorkSans;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: $white;
    margin-right: 20px;
    text-align: center;
    font-size: 14px;
    line-height: 30px;
    @media screen and (max-width: $mobile-breakpoint) {
      margin-bottom: 20px;
    }
  }
  .links-bottom {
    border-top: 1px solid #ddd;
    padding: 20px 0;

  }
}