.breadcrumbs {
  .border-bottom {
    padding-top: 30px;
    padding-bottom: 19px;
  }
  a {
      font-family: $WorkSans;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      &:first-child {
        color: #235789;
      }
  }
  .triangle {
    content: "";
    width: 5px;
    height: 5px;
    border-right: 1px solid #222;
    border-bottom: 1px solid #222;
    transform: rotate(315deg);
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
  }
  a.accordionmenu:hover {
    color: #235789;
  }
  .divaccordionspace {
    padding-left: 0;
  }
  @media screen and (max-width: $mobile-breakpoint) {
    display: none;
  }
}