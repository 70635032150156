
// standard download button override
.wp-block-file__button {
    background: $orange!important;
}

// image black image stretch fix: #9209
.wp-block-image {
    img {
        height: auto;
    }
}