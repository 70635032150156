.sidebar06x {
  max-width: 210px;
  margin-left: auto;
  margin-top: 50px;
  border-radius: 3px;
  padding: 16px 18px;
  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: 20px;
    width: 100%;
    margin-bottom: 100px;
    max-width: 100%;
    margin-left: 0;
  }
  p {
    font-family: $tiempos-headline-medium;
    line-height: 1.25;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #333333;
    padding-bottom: 15px;
    margin-bottom: 30px;
  }
  .divimg
  {
    width: 20px;
    height: 28px;
    margin: 0 8px;
    display: inline-block;
    @media screen and (max-width: $mobile-breakpoint) {
      width: 20px;
      height: 20px;
      margin: 0 8px;
      display: inline-block;
    }
  }
}