.m09
{
    h1 
    {
        font-family: $tiempos-headline-medium;
        font-size: 36px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: justify;
        color: $onyx;
        margin-bottom: 9px;
    }
    h2 
    {
        font-family: $tiempos-headline-medium;
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: $fbgdark;
        margin-bottom: 34px;
    }
    .divaccordionspace
    {
        margin-top: 30px;
        margin-bottom: 19px
    }
    a.accordionmenu:hover
    {
        color: $dark-blue;
    }

    .divacc
    {
        padding-top: 40px;
    }
    p {
        font-family: $WorkSans;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.75;
        letter-spacing: normal;
        color: $onyx;
        padding-bottom: 51px;
        margin-bottom: 0px;
        &:first-child
        {
            margin-bottom: 30px;
            padding-bottom: 0px;
        }
        &.ptext
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $istblack;
        }
        &.ptext12
        {
            font-family: $WorkSans;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: $fbgdark;
        }
        &.parccodion
        {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            color: $onyx;
            margin-left: 55px;
            padding: 0;
            margin-bottom: 18px;
            &:first-child
            {
                margin-left: 55px;
                margin-top: 28px;
            }
            &:last-child
            {
                margin-bottom: 30px;
            }
        }
        &.psubmenu
        {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: $black;
            padding-bottom: 20px;
            margin-bottom: 0;
            margin-left: 20px;
            margin-top: 20px;
            margin-right: 20px;
            text-align: center;
            border-bottom: 1px solid $brdgrey;
        }
        &.pdepartment {
            max-width: 164px;
            height: 100%;
            text-align: center;
            vertical-align: middle;
            margin: 0;
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $dark-blue;
        }
        &.pname {
            max-width: 220px;
            height: 100%;
            text-align: center;
            vertical-align: middle;
            margin: 0;
            font-family: $WorkSans;
            font-size: 24px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $othcol;
        }
        &.pgroup {
            max-width: 191px;
            height: 100%;
            text-align: center;
            vertical-align: middle;
            margin: 0;
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $grey;
        }
        &.pspecialization {
            max-width: 220px;
            height: 100%;
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $othcol;
            margin-bottom: auto;
        }
        &.pinfocontact
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $onyx;
            margin-bottom: 0;
            text-align: center;
            line-height: 22px;
        }
    }
    ul 
    {
        padding-right: 15px;
    }
    
    ol li 
    {
        list-style-type:decimal-leading-zero;
        color: orangered;
    }
    a {
        &.fontstyle
        {
            font-family: $WorkSans;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.5;
            letter-spacing: normal;
            color: $onyx;
            border-bottom: 1px solid orangered;
        }
        &.nav-link
        {
            padding-left: 0;
            line-height: 44px;
            display: inline;
            margin-right: 45px;
            padding: 0;
        }
        &.linkfont
        {
            font-family: $TiemposText;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: $black;
        }
        &.linkfont2
        {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: $fbgdark;
        }
        &.accordionmenu
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $greyb;
            &:first-child
            {
                color: $dark-blue;
            }
        }
        &.arcodionlink
        {
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.11;
            letter-spacing: normal;
            color: $black;
        }
        &.linkinsideacc
        {
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            color: $dark-blue;
            margin-left: 6px;
            cursor: pointer;
        }
        &.linksubmenu
        {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $onyx;
            display: inline;
            border-bottom:  1px solid $onyx;
        }
        &.link
        {
            font-family: $WorkSans;
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: $orangeo;
            right: 0%;
            position: relative;
            margin-top: 20px;
        }
    }
    a:hover 
    {
        outline: none;
        text-decoration: none;
    }
    a:focus
    {
        outline: none;
        text-decoration: none;
    }
    img {
        width: 100%;
        height: auto;
    }
    .nav-link {
    
        &.expand {
          &:after {
            
                    content: "";
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    right: 17px;
                    border-right: 1px solid $fbgdark;
                    border-bottom: 1px solid $fbgdark;
                    transform: rotate(45deg);
                    top: 62px;
            }
        }
        &.expand[aria-expanded="true"] {
            &:after {
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                right: 17px;
                border-right: 1px solid #222;
                border-bottom: 1px solid #222;
                transform: rotate(225deg);
                top: 69px;
            }
        }
      }
    
      .nav-item {
        &.show {
          .nav-link.linkfont.expand {
            &:after {
                
                        content: "";
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        right: 2px;
                        border-right: 1px solid $fbgdark;
                        border-bottom: 1px solid $fbgdark;
                        transform: rotate(225deg);
                        top: 62px;
            }
          }
        }
      }
      ul.nav.flex-column.divborder {
        margin-right: 0px;
        padding: 0;
    }
    
    .divborder
    {
        border-top: 1px solid $brdgrey;
        margin: 0px;
        padding: 0px;
        @media screen and (max-width: $mobile-breakpoint) {
            border-top: none;
        }
    }
    .divborderbottom
    {
        border-bottom: 1px solid $brdgrey;
        margin-bottom: 10px;
    }
    .divacc
    {
        padding-top: 40px;
    }
    
    ul li {
        &.nav-item
        {
            line-height: 44px;
            text-align: left;
            border-bottom: 1px solid $brdgrey; 
        }
        &.nav-item:active
        {
            line-height: 44px;
            text-align: left;
            border-bottom: 1px solid $brdgrey; 
        }
        &.lit
        {
            padding-left: 12px;
        }
    }
    li.dropdown:hover > .dropdown-menu {
        display: block;
    }
    a.nav-link.linkfont2 {
        //padding-left: 15px;
        margin: 0;
    }
    a.nav-link.linkfont2:hover {
        font-weight: 600;
    }
    ul 
    {
        .collapse.list-unstyled
        {
            padding: 0;
            border-top: 1px solid $brdgrey;
        }
    }
    .triangle
    {
        content: "";
        width: 5px;
        height: 5px;
        border-right: 1px solid $fbgdark;
        border-bottom: 1px solid $fbgdark;
        transform: rotate(315deg);
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 10px;
        margin-right: 10px;
    }
    .department{
        width: 100%;
        min-height: 37px;
        display: flex;
        justify-content: center;
        top:0px;
        text-align: center;
        vertical-align: middle;
        line-height: 90px;
        @media screen and (max-width: $mobile-breakpoint) {
            display: none;
        }
    }

    .profilepicture {
        width: 100%;
        min-height: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 57px;
        @media screen and (max-width: $mobile-breakpoint) {
            margin-top: 20px;
        }
    }

    .name{
        width: 100%;
        min-height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 199px;
        margin-top: 20px;
        margin-bottom: 20px;
        @media screen and (max-width: $mobile-breakpoint) {
            margin-top: 17px;
            margin-bottom: 12px;
        }
    }

    .specialization {
        width: 100%;
        min-height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 260px;
        margin-bottom: 5px;
        @media screen and (max-width: $mobile-breakpoint) {
            margin-bottom: 30px;
        }
    }
    
    a:hover {
        text-decoration: none;
     }

    .role {
        width: 100%;
        min-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 235px;
        margin-top: 8px;
        margin-bottom: 20px;
    }
    img {
        &.imageround{
            width: 125px;
            height: 125px;
            border-radius: 50%;
            object-fit: cover;
        }
      }
      .borderclass {
        //border-left: 1px solid  $brdgrey;
            @media screen and (max-width: $mobile-breakpoint) {
                border-right: none;
            }
        }
    .col-md-4.slick-slider
    {
        border-left: 1px solid  $white;
        border-right: 1px solid  $brdgrey;
        &:first-child
        {
            padding-left: 0;
        }
        @media screen and (max-width: $mobile-breakpoint) {
            border-right: none;
            border-bottom: 1px solid $brdgrey;
        }
        
    }
     .row.divspaces
     {
         padding-bottom: 22px;
         padding-top: 30px;
         border-bottom: 1px solid $brdgrey;
         &:last-child
         {
            border-bottom: none
         }
         
         @media screen and (max-width: $mobile-breakpoint) {
            border-bottom: none;
        }
         
     }
     .col-md-4.slick-slider.withoutborder
     {
        border-right: 1px solid  $white;
        @media screen and (max-width: $mobile-breakpoint) {
            border-right: none;
        }
     }
     .publications_slider {
        
        padding-top: 40px;
        padding-bottom: 150px;
    } 
    .row.divaccordionspace {
        @media screen and (max-width: $mobile-breakpoint) {
            display: none;
        }
    }
    .row.divspaces {
        @media screen and (max-width: $mobile-breakpoint) {
           padding: 0;
        }
    }
    .d-flex {
        @media screen and (max-width: $mobile-breakpoint) {
        display: block!important;
        }
    }
}