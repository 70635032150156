// css for single-job.php
.m31 {
  h1 {
    margin-bottom: 18px;
  }
  h2 {
    margin-bottom: 35px;
  }
  h3 {
    margin-bottom: 20px;
  }
  h4 {
    color: #1d2114;
  }
  .fontstyle {
    font-family: $tiempos-headline-regular;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.5;
    letter-spacing: normal;
    color: $onyx;
    border-bottom: 1px solid orangered;
    text-decoration: none;
  }
  ul {
    /* https://www.w3schools.com/howto/howto_css_bullet_color.asp */
    list-style: none; /* Remove default bullets */
    padding-left: 20px;
    & li::before {
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: $orangeo; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
      margin-top: 10px;
    }
  }
  .div-button {
    width: 182px;
    /* margin-left: 40px; */
    z-index: 999;
    display: flex;
    margin-bottom: 30px;
  }
  .orange-button {
    width: 180px;
    height: 45px;
    line-height: 44px;
    border-radius: 22.5px;
    background-color: $orangeo;
    /* border: #ff6b35; */
    font-family: $WorkSans;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: $white!important;
    text-align: center;
    text-decoration: none!important;
    &:hover {
      opacity: 0.9;
      text-decoration: underline!important;
    }
  }
  .white-button {
    width: 180px;
    height: 45px;
    line-height: 44px;
    border-radius: 22.5px;
    background-color: $white;
    border-style: solid;
    /* border: #ff6b35; */
    border-width: thin;
    font-family: $WorkSans;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: $orangeo!important;
    text-align: center;
    text-decoration: none!important;
    &:hover {
      opacity: 0.9;
      text-decoration: underline!important;
    }
  }
  .overpay {
    font-size: 12px;
  }
  .benefit {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 0px;
    padding-right: 0px;
    & img {
      width: 45px;
      margin-right: 15px;
    }
  }
  .modal_background {
    display: none;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-x: hidden;
  }
  .modal_box {
    background-color: #fff;
    margin: 10% auto;
    width: 30%;
    padding-top: 15px;
    padding-bottom: 25px;
    border-radius: 5px;
  }
  .close_button {
    color: #ccc;
    float: right;
    font-size: 30px;
    margin-right: 10px;
  }
  .close_button:hover, .close_button:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  .share_icon_row {
    width: 80%;
    margin: auto;
    display:flex;
    justify-content: space-around;
    & img {
      height: 40px;
    }
  }
  .share_header {
    margin-left: 20px;
    padding-top: 8px;
  }
  @media screen and (max-width: $mobile-breakpoint) {
    .modal_box {
      width: 82%;
      margin: 40% auto;
    }
    .button-col {
      display: flex;
      justify-content: center;
    }
  }
}
@media screen and (max-width: $mobile-breakpoint) {
  .main-content {
    padding-bottom: 0px;
  }
  .share-symbols {
    display: flex;
    justify-content: space-around;
  }
}