.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  a:hover, a:focus {
    color: #f1f1f1;
  }
  .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    @media screen and (max-width: 767px) {
      top: 5px;
      right: 20px;
      font-weight: 300;
      font-size: 29px;
      color: #333333;
      padding: 0;
      z-index: 99999999;
    }
  }
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

.m-overlay {
  h1 {
    margin-top: 100px;
    margin-bottom: 56px;
    font-size: 36px;
    font-family: $tiempos-headline;
  }
  h2 {
    font-family: $tiempos-text;
  }
  .searchnews {
    margin-bottom: 34px;
    width: 100%;
    height: 50px;
    border: 1px solid $brdgrey;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-position: right 30px center;
    background-repeat: no-repeat;
    padding-left: 20px;
    padding-right: 40px;
    @media screen and (max-width: 767px) {
      background-position: right 10px center;
    }
  }
  .m26x {
    @media screen and (max-width: 767px) {
      .classborder:nth-child(even) {
        border-right: 0;
      }
    }
    @media screen and (max-width: 992px) {
      .coldiv .div-text {
        display: -webkit-box;
        margin: 0!important;
      }
      a.pclass2 {
        display: block;
      }
      .coldiv .div-title {
        margin-top: 15px;
        margin-bottom: 5px;
        height: 140px;
      }
      .coldiv .div-link {
        margin-top: 10px;
      }
    }
  }
  .person-box {
    border-right: 1px solid #dddddd;
    @media screen and (max-width: 767px) {
      border-right: 0;
      border-bottom: 1px solid #ddd;
    }
    &:last-child {
      border: none;
    }
  }
  .show-all {
    border-radius: 22.5px;
    border: solid 1px #235789;
    font-size: 14px;
    font-weight: 600;
    color: #235789;
    padding: 15px 62px;
    margin: 0 auto;
    max-width: 200px;
    &:hover {
      text-decoration: none;
    }
  }
  .desktop-only {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .pop-search {
    h2 {
      margin-top: 120px;
      margin-bottom: 65px;
      @media screen and (max-width: 767px) {
        margin-top: 50px;
      }
    }
    a {
      color: #999999;
      display: block;
      margin-bottom: 35px;
      &:hover {
        text-decoration: none;
      }
    }
  }
}