.m20x {
  p {
    font-family: $WorkSans;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: $onyx;
    padding-bottom: 51px;
    margin-bottom: 0px;
    &:first-child {
      margin-bottom: 30px;
      padding-bottom: 0px;
    }
    &.ptext {
      font-family: $WorkSans;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $istblack;
    }
    &.ptext12 {
      font-family: $WorkSans;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $fbgdark;
    }
    &.parccodion {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: $onyx;
      margin-left: 55px;
      padding: 0;
      margin-bottom: 18px;
      &:first-child {
        margin-left: 55px;
        margin-top: 28px;
      }
      &:last-child {
        margin-bottom: 30px;
      }
    }
    &.psubmenu {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $black;
      padding-bottom: 20px;
      margin-bottom: 0;
      margin-left: 20px;
      margin-top: 20px;
      margin-right: 20px;
      text-align: center;
      border-bottom: 1px solid $brdgrey;
    }
    &.pdepartment {
      max-width: 164px;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      margin: 0;
      font-family: $WorkSans;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $dark-blue;
    }
    &.pname {
      max-width: 220px;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      margin: 0;
      font-family: $tiempos-headline-medium;
      font-size: 24px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $othcol;
    }
    &.pgroup {
      max-width: 191px;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      margin: 0;
      font-family: $WorkSans;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $grey;
    }
    &.pspecialization {
      max-width: 220px;
      height: 100%;
      font-family: $WorkSans;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $othcol;
      margin-bottom: auto;
    }
    &.pinfocontact {
      font-family: $WorkSans;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $onyx;
      margin-bottom: 0;
      text-align: center;
      line-height: 22px;
    }
  }
  /////////
  .department {
    width: 100%;
    min-height: 37px;
    display: flex;
    justify-content: center;
    top: 0px;
    text-align: center;
    vertical-align: middle;
    line-height: 90px;
    @media screen and (max-width: $mobile-breakpoint) {
      display: none;
    }
  }

  .profilepicture {
    width: 100%;
    min-height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 57px;
    @media screen and (max-width: $mobile-breakpoint) {
      margin-top: 20px;
    }
  }

  .name {
    width: 100%;
    min-height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 199px;
    margin-top: 20px;
    margin-bottom: 20px;
    @media screen and (max-width: $mobile-breakpoint) {
      margin-top: 17px;
      margin-bottom: 12px;
    }
  }

  .specialization {
    width: 100%;
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 260px;
    margin-bottom: 5px;
    @media screen and (max-width: $mobile-breakpoint) {
      margin-bottom: 30px;
    }
  }

  a:hover {
    text-decoration: none;
  }

  .role {
    width: 100%;
    min-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 235px;
    margin-top: 8px;
    margin-bottom: 20px;
  }
  img {
    &.imageround {
      width: 125px;
      height: 125px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .borderclass {
    //border-left: 1px solid  $brdgrey;
    @media screen and (max-width: $mobile-breakpoint) {
      border-right: none;
    }
  }
  .col-md-4.person-box {
    border-left: 1px solid $white;
    border-right: 1px solid $brdgrey;
    &:nth-child(3)  {
      border-right: 0;
    }
    &:first-child {
      padding-left: 0;
    }
    @media screen and (max-width: $mobile-breakpoint) {
      border-right: none;
      border-bottom: 1px solid $brdgrey;
    }
    &.last-person {
      border-bottom: 0;
    }

  }
  .row.divspaces {
    padding-bottom: 22px;
    padding-top: 30px;
    border-bottom: 1px solid $brdgrey;
    &:last-child {
      border-bottom: none
    }

    @media screen and (max-width: $mobile-breakpoint) {
      border-bottom: none;
    }

  }
  .col-md-4.person-box.withoutborder {
    border-right: 1px solid $white;
    @media screen and (max-width: $mobile-breakpoint) {
      border-right: none;
    }
  }
  .publications_slider {
    padding-top: 40px;
  }
  .row.divaccordionspace {
    @media screen and (max-width: $mobile-breakpoint) {
      display: none;
    }
  }
  .row.divspaces {
    @media screen and (max-width: $mobile-breakpoint) {
      padding: 0;
    }
  }
  .d-flex {
    @media screen and (max-width: $mobile-breakpoint) {
      display: block !important;
    }
  }
  hr {
    @media screen and (max-width: $mobile-breakpoint) {
      display: none;
    }
  }
}