footer {
  background-color: $fbgdark;
  .mobilehide {
    @media screen and (max-width: $mobile-breakpoint) {
      display: none;
    }
  }

  .center-mobile {
    @media screen and (max-width: $mobile-breakpoint) {
      text-align: left;
    }
    @media screen and (max-width: $screen-md-min) {
      text-align: center;
    }
  }

  #email {
    border-radius: 0;
    height: 20%;
    width: 10px;
    text-align: left;
  }
  .email2 {
    border-radius: 0;
    text-align: left;
    //max-width: 200px;
    width: 488px;
    //max-height: 25px;
    height: 53px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: $fbgdark;
    border-color: $white;
    border: 1px solid $white;
    text-align: left;
    @media screen and (max-width: $mobile-breakpoint) {
      height: 50px;
      font-family: $WorkSans;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      background-color: $fbgdark;
      border: 1px solid $white;
      text-align: left;
      width: 226px;
    }
    @media screen and (max-width: 321px) {
      height: 50px;
      font-family: $WorkSans;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      background-color: $fbgdark;
      border: 1px solid $white;
      text-align: left;
      width: 170px;
    }
  }
  .buttonemail {
    cursor: pointer;
    border-radius: 0;
    font-family: $WorkSans;
    color: $white;
    // width: 122px;
    width: 300px;
    height: 53px;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    // color: $fbgdark;
    color: $white;
    text-align: center;
    vertical-align: middle;
    // border: 1px solid $white;
    border: 1px solid $it-orange;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    // background-color: $white;
    background-color: $it-orange;
    @media screen and (max-width: $mobile-breakpoint) {
      border-radius: 0;
      // color: $white;
      // width: 119px;
      width: 265px;
      height: 50px;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      // color: $fbgdark;
      color: $white;
      text-align: center;
      vertical-align: middle;
      // background-color: $white;
      background-color: $it-orange;

      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    @media screen and (max-width: 321px) {
      border-radius: 0;
      // width: 115px;
      width: 265px;
      height: 50px;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      // color: $fbgdark;
      color: $white;
      text-align: center;
      vertical-align: middle;
      // background-color: $white;
      background-color: $it-orange;

      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &:hover {
      // background-color: #b3b6b7;
      background-color: #cc552b;
    }
  }
  .right {
    position: left;
  }
  @media (max-width: 991px) {
    .btn-group.my-btn-group-responsive > .btn {
      display: block;
      width: 100%;
    }

    /* making the border-radius correct */
    .btn-group.my-btn-group-responsive > .btn:first-child {
      border-radius: 6px 6px 0 0;
    }
    .btn-group.my-btn-group-responsive > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: 6px;
    }
    .btn-group.my-btn-group-responsive > .btn:last-child:not(:first-child) {
      border-radius: 0 0 6px 6px;
    }

    /* fixing margin */
    .btn-group.my-btn-group-responsive .btn + .btn {
      margin-left: 0;
    }

  }
  h3 {
    margin-bottom: 30px;
  }
  .level1 {
    width: 100%;
    padding-top: 56px;
    padding-bottom: 37px;
    border-bottom: 1px solid;
    border-color: $brdgrey;
    position: relative;
    @media screen and (max-width: $mobile-breakpoint) {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 30px;
      margin-bottom: 0;
      border: none;
    }
  }
  ul li {
    line-height: normal;
    &:first-of-type {
      margin-top: 28px;
      @media screen and (max-width: $mobile-breakpoint) {
        margin-top: 60px;
      }
    }
    margin-bottom: 20px;
  }
  ul.list-inline {
    display: inline-block;
  }
  .spacebetweenmenus {
    width: 100%;
    margin-top: 60px;
  }
  .elementstyle {
    float: right;
  }
  a:hover {
    color: #B3B6B7;
    text-decoration: none;
  }

  .form-control::-webkit-input-placeholder {
    color: $white;
    @media screen and (max-width: $mobile-breakpoint) {
      color: $white;
    }
  }
  /* WebKit, Blink, Edge */
  .form-control:-moz-placeholder {
    color: $white;
    @media screen and (max-width: $mobile-breakpoint) {
      color: $white;
    }
  }
  /* Mozilla Firefox 4 to 18 */
  .form-control::-moz-placeholder {
    color: $white;
    @media screen and (max-width: $mobile-breakpoint) {
      color: $white;
    }
  }
  /* Mozilla Firefox 19+ */
  .form-control:-ms-input-placeholder {
    color: $white;
    @media screen and (max-width: $mobile-breakpoint) {
      color: $white;
    }
  }
  /* Internet Explorer 10-11 */
  .form-control::-ms-input-placeholder {
    color: $white;
    @media screen and (max-width: $mobile-breakpoint) {
      color: $white;
    }
  }
  /* Microsoft Edge */

  h3 {
    margin-bottom: 30px;
  }
  .listone {
    font-family: $WorkSans;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white;
  }
  .listtwo {
    font-family: $WorkSans;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: $white;
  }
  .listheader {
    font-family: $WorkSans;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    color: $white;
  }
  input[type=email]:focus {
    outline: 2px solid $white;
    background-color: $fbgdark;
    color: $white; /* oranges! yey */

  }
  .flex {
    display: flex;
    position: absolute;
    text-align: right;
    vertical-align: bottom;
    bottom: 0px;
    right: 15px;
    @media screen and (max-width: $mobile-breakpoint) {
      left: 0%;
      vertical-align: bottom;
      position: absolute;
    }
  }
  .flex div {
    flex: 1;
    margin-left: 40px; /* need some space ?*/
    @media screen and (max-width: $mobile-breakpoint) {
      :first-of-type {
        margin-left: 15px;
        margin-bottom: 15px;
      }
      margin-left: 0px;
    }
  }
  input.form-control.email2.white {
    color: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    @media screen and (max-width: $mobile-breakpoint) {
      border-radius: 0;
    }
  }
  img {
    height: 25px;
    width: 25px;
    vertical-align: bottom;
    @media screen and (max-width: $mobile-breakpoint) {
      text-align: center;
      vertical-align: middle;
      margin-bottom: 29px;
    }
  }
  img:hover {
    opacity: 0.4;
  }
  a {
    vertical-align: bottom;
  }
  .asimplediv {
    margin-top: 0px;
    @media screen and (max-width: $screen-md-min) {
      @media screen and (max-width: $mobile-breakpoint) {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid $brdgrey;
        border-bottom: 1px solid $brdgrey;
        height: 110px;
        margin-top: 54px;
        width: 335px;
      }
      margin-top: 0px;
    }
    @media screen and (max-width: 321px) {
      width: 300px;
    }
  }
  h4 {
    @media screen and (max-width: $mobile-breakpoint) {
      margin-bottom: 0px;
      line-height: 36px;
    }
  }
  p {
    &.p1 {
      font-family: $WorkSans;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: -0.3px;
      color: $white;
    }
  }
  .smalldiv {
    width: 45px;
    height: 45px;
  }

  a.nav-link.alabaster.FoterFont {
    line-height: 44px;
  }
  .fontformenudown {
    opacity: 0.6;
    font-family: $WorkSans;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #dddddd;
  }
  a.nav-link.fontformenudown {
    padding: 0;
  }
  ul {
    list-style: none;
    .list-group.g2 {
      margin-right: 75px;
    }
  }
  .bordermenu {
    width: 100%;
    height: 1px;
    background-color: #dddddd;
    opacity: 0.6;
  }
  h4.white {
    @media screen and (max-width: $mobile-breakpoint) {
      position: absolute;
      left: 15px;
      top: 98px;
      bottom: 42px;
    }
  }
  a.white.fb {
    @media screen and (max-width: $mobile-breakpoint) {
      position: absolute;
      top: 98px;
      bottom: 42px;
      left: 127px;
    }
  }
  a.white.yu {
    @media screen and (max-width: $mobile-breakpoint) {
      position: absolute;
      top: 98px;
      bottom: 42px;
      left: 180px;
    }
  }
  a.white.tw {
    @media screen and (max-width: $mobile-breakpoint) {
      position: absolute;
      top: 98px;
      bottom: 42px;
      left: 238px;
    }
  }
}