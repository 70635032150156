.m17
{
  h2 
  {
      font-family: $tiempos-headline-medium;
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $fbgdark;
      margin-bottom: 34px;
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 20px;
         font-weight: 500;
     }
  }
    a 
    {
        &.arcodionlink
        {
            font-family: $TiemposText;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.11;
            letter-spacing: normal;
            color: $black;
        }
    }
    p
    {
        &.parccodion
        {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            color: $onyx;
            margin-left: 55px;
            padding: 0;
            margin-bottom: 18px;
            &:first-child
            {
                padding-left: 55px;
                padding-top: 28px;
            }
            &:last-child
            {
                padding-bottom: 30px;
            }
        }
        &.pacrtext
        {
            font-family: $WorkSans;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.75;
            letter-spacing: normal;
            color: $onyx;
        }
        &.pacrtextb
        {
            font-family: $WorkSans;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            color: $onyx;
            font-weight: bold;
        }

    }

    /////////////////////
    .panel-group
    {
        &:last-child
        {
            margin-bottom: 110px;
        }
    }
      
      .panel-body {
        padding: 40px 54px;
      }
      
      .panel-group .panel+.panel {
        margin-top: 0;
        border-top: 0;
      }
      
      .panel-group .panel {
        border-radius: 0;
      }
      
      .panel-default>.panel-heading {
        color: $onyx;
        background-color: $white;
        border-color: $grey-medium;
        padding: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      .panel-default>.panel-heading a {
        display: block;
        padding: 10px 15px;
        text-decoration: none;
        @media screen and (max-width: $mobile-breakpoint) {
            padding-left: 0px;
        }
      }
      
      .panel-default>.panel-heading a:after {
        content: "";
        position: relative;
        top: 1px;
        display: inline-block;
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: transform .25s linear;
        -webkit-transition: -webkit-transform .25s linear;
      }
      
      .panel-default>.panel-heading a[aria-expanded="true"] {
        border-bottom: 1px solid $brdgrey;
        border-bottom-right-radius: 0px;
        border-bottom-right-radius: 0px;
        height: 50px;
      }
      
      .panel-default>.panel-heading a[aria-expanded="true"]:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 30px;
        border-right: 1px solid $fbgdark;
        border-bottom: 1px solid $fbgdark;
        transform: rotate(225deg);
        top: 20px;
        @media screen and (max-width: $mobile-breakpoint) {
            right: 5px;
        }
      }
      
      .panel-default>.panel-heading a[aria-expanded="false"]:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 30px;
        border-right: 1px solid $fbgdark;
        border-bottom: 1px solid $fbgdark;
        transform: rotate(45deg);
        top: 19px;
        @media screen and (max-width: $mobile-breakpoint) {
            right: 5px;
        }
      }

      .panel-group
      {
          border: 1px solid $brdgrey;
          margin-bottom: 10px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          position: relative;
          @media screen and (max-width: $mobile-breakpoint) {
                border:none;
                border-radius: 0;
                border-top: 1px solid $brdgrey;
                border-bottom: 1px solid $brdgrey;
                margin-bottom: 0;
                &:first-child {
                    margin-top: 30px;
                }
            }
      }
    .col-md-12.accdiv
    {
        @media screen and (max-width: $mobile-breakpoint) {
            padding: 0;
        }
    }
}