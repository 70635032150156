.m13 {
    h1 
    {
        font-family: $tiempos-headline-medium;
        font-size: 36px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: justify;
        color: #333333;
        margin-bottom: 9px;
    }
    h2 
    {
        font-family: $tiempos-headline-medium;
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: #222222;
        margin-bottom: 34px;
        &.h22 
        {
            width: 100%;
            margin: 0;
            padding: 0;
            font-size: 24px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: #222222;
        }
    }
    p {
        font-family: $WorkSans;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.75;
        letter-spacing: normal;
        color: #333333;
        padding-bottom: 51px;
        margin-bottom: 0px;
        &:first-child
        {
            margin-bottom: 30px;
            padding-bottom: 0px;
        }
        &.ptext
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1d2114;
        }
        &.ptext12
        {
            font-family: $WorkSans;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #222222;
        }
        &.parccodion
        {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            color: #333333;
            margin-left: 55px;
            padding: 0;
            margin-bottom: 18px;
            &:first-child
            {
                padding-left: 55px;
                padding-top: 28px;
            }
            &:last-child
            {
                padding-bottom: 30px;
            }
        }
        &.psubmenu
        {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: #000000;
            padding-bottom: 20px;
            margin-bottom: 0;
            margin-left: 20px;
            margin-top: 20px;
            margin-right: 20px;
            text-align: center;
            border-bottom: 1px solid #dddddd;
        }
        &.titlep
        {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.75;
            letter-spacing: normal;
            color: #333333;
            margin: 0;
            padding: 0;
        }
    }
    ul 
    {
        padding-right: 15px;
    }
    
    ol li 
    {
        list-style-type:decimal-leading-zero;
        color: orangered;
    }
    a {
        &.buttonlinkw{
            width: 200px;
            height: 45px;
            line-height: 44px;
            border-radius: 22.5px;
            background-color: $white;
            border: 1px solid #235789;

            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: #235789;
            text-align: center;
            position: absolute;
            @media screen and (max-width: $mobile-breakpoint) {
                left: 0px;
                bottom: 20px;
            }
          }
        &.adescriptiondiv //
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: #222222;
            display: table-row;
        }

        &.ainfodiv //
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: #9b9b9b;
            display: table-row;
        }

        &.pclass2 { 
            min-height: 93px;
            margin-right: 0px;
            margin-left: 0%;
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.40;
            letter-spacing: normal;
            color: #222222;
            display: inline;
            @media screen and (max-width: $mobile-breakpoint) {
                display: none;
            }
        }
        &.h22 {
            font-family: $WorkSans;
            font-size: 22px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.2;
            letter-spacing: normal;
            color: #222222;
            margin-right: 0px;
            display: inline;
            @media screen and (max-width: $mobile-breakpoint) {
                min-height: 126px;  
                font-size: 20px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.3;
            }
        }
        &.h44 {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #235789;
            display: inline;
            @media screen and (max-width: $mobile-breakpoint) {
                padding:0;
                margin-top: 1px;
                margin-bottom: 30px;
                margin-left: 0px;
                margin-right: 0px;
            }
        }
        &.paragraph {
            font-family: $WorkSans;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            display: inline;
            color: #9b9b9b;
            @media screen and (max-width: $mobile-breakpoint) {
                font-size: 15px;
                margin-left: 0px;
                margin-right: 0px;
            }
        }
        &.withimagea
        {
            font-family: $WorkSans;
            font-size: 24px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: #222222;
            display: inline;
            @media screen and (max-width: $mobile-breakpoint) {
                min-height: 60px;
                max-height: 60px;
                font-size: 20px;
                margin-left: 0px;
                margin-right: 0px;
                margin-bottom: 0px;
            } 
        }
        &.withoutimagea {
            margin-left: 15px;
            margin-right: 15px;
            height: 95px;
            font-family: $WorkSans;
            font-size: 24px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: #222222;
            display: flex;   
            margin-bottom: 40px;
            @media screen and (max-width: $mobile-breakpoint) {
                min-height: 60px;
                max-height: 60px;
                font-size: 20px;
                margin-left: 0px;
                margin-right: 0px;
                margin-bottom: 0px;
            } 
        }
        &.fontstyle
        {
            font-family: $TiemposText;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.5;
            letter-spacing: normal;
            color: #333333;
            border-bottom: 1px solid orangered;
        }
        &.nav-link
        {
            padding-left: 0;
            line-height: 44px;
            display: inline;
            margin-right: 75px;
            padding: 0;
        }
        &.linkfont
        {
            font-family: $TiemposText;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: #000000;
        }
        &.linkfont2
        {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: #222222;
        }
        &.accordionmenu
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #4a4a4a;
            &:first-child
            {
                color: #235789;
            }
        }
        &.arcodionlink
        {
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.11;
            letter-spacing: normal;
            color: #000000;
        }
        &.linkinsideacc
        {
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            color: #235789;
            margin-left: 6px;
            cursor: pointer;
        }
        &.linksubmenu
        {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #333333;
            display: inline;
            border-bottom:  1px solid #333333;
        }
    }
    a:hover 
    {
        outline: none;
        text-decoration: none;
    }
    a:focus
    {
        outline: none;
        text-decoration: none;
    }
    img {
        width: 100%;
        height: auto;
    }
    .col-md-3.d-flex.justify-content-end
    {
        padding: 0;
    }
    ul li {
        &.nav-item
        {
            line-height: 44px;
            text-align: left;
            border-bottom: 1px solid #dddddd; 
        }
        &.nav-item:active
        {
            line-height: 44px;
            text-align: left;
            border-bottom: 1px solid #dddddd; 
        }
        &.lit
        {
            padding-left: 12px;
        }
    }
    li.dropdown:hover > .dropdown-menu {
        display: block;
    }
    a.nav-link.linkfont2 {
        //padding-left: 15px;
        margin: 0;
    }
    a.nav-link.linkfont2:hover {
        font-weight: 600;
    }
    ul 
    {
        .collapse.list-unstyled
        {
            padding: 0;
            border-top: 1px solid #dddddd;
        }
    }
    .triangle
    {
        content: "";
        width: 5px;
        height: 5px;
        border-right: 1px solid #222;
        border-bottom: 1px solid #222;
        transform: rotate(315deg);
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 10px;
        margin-right: 10px;
    }
    .divaccordionspace
    {
        margin-top: 30px;
        margin-bottom: 19px
    }
    .divborder
    {
        border-top: 1px solid #dddddd;
        margin: 0px;
        padding: 0px;
    }
    .divborderbottom
    {
        border-bottom: 1px solid #dddddd;
        margin-bottom: 10px;
    }
    .divacc
    {
        padding-top: 40px;
    }
    ul.nav.flex-column {
        padding-right: 0;
    }
    .backtp{
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1d2114;
        margin: 0 auto;
        width: 100px;
        cursor: pointer;
        text-align: center;
    }
    .arrow 
    {
        content: "";
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        width: 10px;
        height: 10px;
        border-right: 2px solid #222;
        border-bottom: 2px solid #222;
        transform: rotate(225deg);
        cursor: pointer;
        margin-bottom: 55px;
    }

    .nav-link {
    
        &.expand {
          &:after {
            
                    content: "";
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    right: 17px;
                    border-right: 1px solid #222;
                    border-bottom: 1px solid #222;
                    transform: rotate(45deg);
                    top: 62px;
            }
        }
        &.expand[aria-expanded="true"] {
            &:after {
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                right: 17px;
                border-right: 1px solid #222;
                border-bottom: 1px solid #222;
                transform: rotate(225deg);
                top: 69px;
            }
        }
      }
    
      .nav-item {
        &.show {
          .nav-link.linkfont.expand {
            &:after {
                
                        content: "";
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        right: 2px;
                        border-right: 1px solid #222;
                        border-bottom: 1px solid #222;
                        transform: rotate(225deg);
                        top: 62px;
            }
          }
        }
      }
      ul.nav.flex-column.divborder {
        margin-right: 15px;
    }
    a.accordionmenu:hover
    {
        color: #235789;
    }
    ////////////////////////////
   
    .panel-group
    {
        &:last-child
        {
            margin-bottom: 110px;
        }
    }
      
      .panel-body {
        padding: 0;
      }
      
      .panel-group .panel+.panel {
        margin-top: 0;
        border-top: 0;
      }
      
      .panel-group .panel {
        border-radius: 0;
      }
      
      .panel-default>.panel-heading {
        color: #333;
        background-color: #fff;
        border-color: #e4e5e7;
        padding: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      .panel-default>.panel-heading a {
        display: block;
        padding: 10px 15px;
        text-decoration: none;
      }
      
      .panel-default>.panel-heading a:after {
        content: "";
        position: relative;
        top: 1px;
        display: inline-block;
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: transform .25s linear;
        -webkit-transition: -webkit-transform .25s linear;
      }
      
      .panel-default>.panel-heading a[aria-expanded="true"] {
        border-bottom: 1px solid #dddddd;
        border-bottom-right-radius: 0px;
        border-bottom-right-radius: 0px;
        height: 50px;
      }
      
      .panel-default>.panel-heading a[aria-expanded="true"]:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 30px;
        border-right: 1px solid #222;
        border-bottom: 1px solid #222;
        transform: rotate(225deg);
        top: 20px;
      }
      
      .panel-default>.panel-heading a[aria-expanded="false"]:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 30px;
        border-right: 1px solid #222;
        border-bottom: 1px solid #222;
        transform: rotate(45deg);
        top: 19px;
      }

      .panel-group
      {
          border: 1px solid #dddddd;
          margin-bottom: 10px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          border-bottom-right-radius: 5px;
          position: relative;
      }

      h4 
      {
          &.panel-title
          {
            margin-top: 5px;
            margin-bottom: 5px;
             font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.11;
            letter-spacing: normal;
            color: #000000;
          }
      }
      input#searchid {
        //url('../assets/img/svg/icon-search.svg');
        width: 100%;
        background-repeat: no-repeat;
        text-indent: 20px;
        background-position: right 30px center;
        padding: 0;
        border: none;
        background-color: #f4f4f4;
        line-height: 44px;
    }
    input[type=text]:focus{
        background-image: none;
      }
      input[type=text]:active{
        background-image: none;
      }
      input[type=text]:hover{
        background-image: none;
      }
      .accordion
      {
        overflow-y:scroll; 
        height: 100px;
        margin-left: 21px;
      }
      ::-webkit-scrollbar {
        width: 12px;
        background-color: #f4f4f4;
    }
     
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset red;
    }
     
    ::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: #235789; 
    }
    .regular-checkbox {
        -webkit-appearance: none;
        background-color: #fafafa;
        border: 1px solid #cacece;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
        padding: 9px;
        border-radius: 3px;
        display: inline-block;
        position: relative;
    }
    input[type="checkbox"] {
        width: 20px;
        height: 20px;
        margin-top: 15px;
        text-align: center;
        border: 5px solid blue;
        color: blue;
        background-color: white;
    }
    //
    .checkbox-custom {
        opacity: 1;
        background-color: #fafafa;   
    }
    //
    .rectanglemission {
        width: 194px;
        height: 165px;
        border-radius: 3px;
        background-color: #ededed;
      }
      ul.no_bullet {
        list-style-type: none;
        padding: 0;
        margin: 0;
        }
      li.leaf {
        background: url('/assets/img/icons/arrow_back.svg') no-repeat left center ;
        height: 30px;
        padding-left: 50px;
        padding-top: 3px;
        padding-right: 10px;
        }
        ////////////////
        .rectanglemod {
            padding: 10px;
            max-width: 350px;
            border: 1px solid;
            border-color: #dddddd;
            position: relative;
            border-top: 3px solid black;
            margin-top: 15px;
            margin-bottom: 15px;
            @media screen and (max-width: $mobile-breakpoint) {
                max-width: 100%;
                padding: 0;
                margin: 0;
                border: none;
                border-bottom: 1px solid;
                border-color: #dddddd;
            }
          }  
        .rectanglerwo
        {
            width: 24px;
            height: 24px;
            position: absolute;
            border: none ;
        
            z-index: 999;
            right: -1px;
            bottom: -1px;
            @media screen and (max-width: $mobile-breakpoint) {
                display:none;
                visibility: hidden;
            }
        }
        .digonalline 
        {
            width:24px
        }
        .digonalline
        {
            background:linear-gradient(-45deg,#ffffff 49%,#dddddd 52%,#ffffff 51%);
        }
        .coldiv
        {
            width: 320px;
            .div-title
            {
                margin-left: 5px;
                margin-right: 5px;
                margin-bottom: 5px;
                min-height: 95px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical; 
                @media screen and (max-width: $mobile-breakpoint) {
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-bottom: 0px;
                    min-height: 60px;
                    margin-top: 6px;
                }
            }
            .div-text {
                margin-left: 5px;
                margin-right: 5px;
                margin-bottom: 5px;
                height: 45px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;  
                /*text-overflow: ellipsis;
                overflow: hidden; 
                width: 240px; 
                height: 45px;
                white-space: nowrap;*/
                @media screen and (max-width: $mobile-breakpoint) {
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-bottom: 0px;
                    height: 20px;
                    margin-top: 10px;
                }
            }
            .div-link 
            {
                margin-left: 5px;
                margin-right: 5px;
                margin-top: 0px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical; 
                @media screen and (max-width: $mobile-breakpoint) {
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-bottom: 20px;
                    margin-top: 2px;
                } 
            }
            .div-titlet
            {
                margin-left: 15px;
                margin-right: 15px;
                margin-bottom: 0px;
                height: 95px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical; 
                @media screen and (max-width: $mobile-breakpoint) {
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-bottom: 0px;
                    height: 60px;
                    margin-top: 6px;
                }
            }
            .div-textt {
                margin-left: 15px;
                margin-right: 15px;
                margin-bottom: 5px;
                height: 45px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical; 
                @media screen and (max-width: $mobile-breakpoint) {
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-bottom: 0px;
                    height: 20px;
                } 
            }
            .div-linkt
            {
                margin-left: 15px;
                margin-right: 15px;
                margin-top: 10px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical; 
                @media screen and (max-width: $mobile-breakpoint) {
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-bottom: 20px;
                    margin-top: 2px;
                } 
            }
            @media screen and (max-width: $mobile-breakpoint) {
                width: 100%;
            }
    }
     h6
    {
        &.firstclass
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #333333;
        }
        &.secondclass
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #333333;
        }
    }
    .classborder {
        border-right: 1px solid  #dddddd;
        @media screen and (max-width: $mobile-breakpoint) {
            border-right: 1px solid  #dddddd;
        }
    }  
    .classborderwot {
        border-right: 1px solid  #dddddd;
        @media screen and (max-width: $mobile-breakpoint) {
            border-right: none;
            min-height: 0px;
            //margin-: 15px;
        }
        @media screen and (max-width: $screen-sm-min) {
            border-right: none;
             min-height: 0;
            //margin-: 15px;
        }
    } 
    .classborderwo {
        border-right: none;
        @media screen and (max-width: $mobile-breakpoint) {
            padding-bottom: 9px;
            padding-right: 1px;
            margin-right: 14px;
            padding-left: 1px;
            margin-left: 14px;
            border-bottom: 1px solid  #dddddd;
            margin-bottom: 0px;
            border-right: none;
            min-height: 0px;
            //margin-: 15px;
        }
        @media screen and (max-width: $screen-sm-min) {
            border-right: none;
            min-height: 0;
            //margin-: 15px;
        }
    }
    .coldivposition
    {
       .positiondiv
       {
           width: 100%;
           height: 20px;
           margin-bottom: 16px;
       }
       
       .titlediv
       {
        width: 100%;
        min-height: 95px;
        margin-bottom: 13px;
       }

       .descriptiondiv
       {
        width: 100%;
        min-height: 65px;
        margin-bottom: 15px;
       }

       .infodiv
       {
        width: 100%;
        min-height: 35px;
        margin-bottom: 16px;
       }

       .buttonbiv
       {
        width: 100%;
        min-height: 45px;
        margin-bottom: 0px;
       }
    }
    .col-md-4.coldivposition {
        border-right: 1px solid #dddddd;
        &:last-child
        {
            border-right: none;
        }
    }
    .col-md-12.r
    {
        margin-bottom: 100px;
    }
    .col-md-12.borderbc {
        border-bottom: 1px solid;
    }
}