.back-to-top {
  position: fixed;
  bottom: 2em;
  right: -10px;
  text-decoration: none;
  color: #000000;
  font-size: 12px;
  display: none;
  width: 174px;
  height: 71px;
  border-radius: 8px;
  padding-right: 55px;
  text-align: center;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 99999999;
  @media screen and (max-width: 767px) {
    display: none;
    width: 50px;
    height: 40px;
  }
  .backtp {
    font-family: $tiempos-headline-regular;
    font-size: 16px;
    color: #1d2114;
    cursor: pointer;
    text-align: center;
    margin-top: -10px;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  img {
    cursor: pointer;
  }
}
