.m08
{
    h1 
    {
        font-family: $tiempos-headline-medium;
        font-size: 36px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: justify;
        color: $onyx;
        margin-bottom: 30px;
    }
    h2 
    {
        font-family: $tiempos-headline-medium;
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: $fbgdark;
        margin-bottom: 34px;
    }
    p {
        font-family: $WorkSans;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.75;
        letter-spacing: normal;
        color: $onyx;
        padding-bottom: 51px;
        margin-bottom: 0px;
        &:first-child
        {
            margin-bottom: 30px;
            padding-bottom: 0px;
        }
        &.ptext
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $istblack;
        }
        &.ptext12
        {
            font-family: $WorkSans;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: $fbgdark;
        }
        &.parccodion
        {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            color: $onyx;
            margin-left: 55px;
            padding: 0;
            margin-bottom: 18px;
            &:first-child
            {
                margin-left: 55px;
                margin-top: 28px;
            }
            &:last-child
            {
                margin-bottom: 30px;
            }
        }
        &.psubmenu
        {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: $black;
            padding-bottom: 20px;
            margin-bottom: 0;
            margin-left: 20px;
            margin-top: 20px;
            margin-right: 20px;
            text-align: center;
            border-bottom: 1px solid $brdgrey;
        }
        &.placeholder
        {
            margin-left: 20px;
        }
    }
    ul 
    {
        padding-right: 15px;
    }
    
    ol li 
    {
        list-style-type:decimal-leading-zero;
        color: orangered;
    }
    a {
        &.fontstyle
        {
            font-family: $WorkSans;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.5;
            letter-spacing: normal;
            color: $onyx;
            border-bottom: 1px solid orangered;
        }
        &.nav-link
        {
            padding-left: 0;
            line-height: 44px;
            display: inline;
            margin-right: 45px;
            padding: 0;
        }
        &.linkfont
        {
            font-family: $TiemposText;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: $black;
        }
        &.linkfont2
        {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: $fbgdark;
        }
        &.accordionmenu
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $greyb;
            &:first-child
            {
                color: $dark-blue;
            }
        }
        &.arcodionlink
        {
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.11;
            letter-spacing: normal;
            color: $black;
        }
        &.linkinsideacc
        {
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            color: $dark-blue;
            margin-left: 6px;
            cursor: pointer;
        }
        &.linksubmenu
        {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $onyx;
            display: inline;
            border-bottom:  1px solid $onyx;
        }
        &.h44 {
            &:first-of-type{
                font-family: $WorkSans;
                font-size: 12px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $dark-blue;
                margin-right: 0%;
                margin-left: 0%;
                display: inline;
                @media screen and (max-width: $mobile-breakpoint) {
                    //bottom: 5px;
                }
            }
            
        }
        &.pclass2 { 
            min-height: 93px;
            margin-right: 0px;
            margin-left: 0%;
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.40;
            letter-spacing: normal;
            color: $fbgdark;
            display: inline;
            @media screen and (max-width: $mobile-breakpoint) {
                display: none;
            }
        }
        &.h22 {
            font-family: $tiempos-headline-medium;
            font-size: 22px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.2;
            letter-spacing: normal;
            color: $fbgdark;
            margin-right: 0px;
            display: inline;
            @media screen and (max-width: $mobile-breakpoint) {
                min-height: 126px;  
                font-size: 20px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.3;
            }
        }
    }
    a:hover 
    {
        outline: none;
        text-decoration: none;
    }
    a:focus
    {
        outline: none;
        text-decoration: none;
    }
    img {
        width: 100%;
        height: 117px;
        &.classimg
        {
            vertical-align: middle;
        }
    }
    .col-md-3.d-flex.justify-content-end
    {
        padding: 0;
    }
    ul li {
        &.nav-item
        {
            line-height: 44px;
            text-align: left;
            border-bottom: 1px solid $brdgrey; 
        }
        &.nav-item:active
        {
            line-height: 44px;
            text-align: left;
            border-bottom: 1px solid $brdgrey; 
        }
        &.lit
        {
            padding-left: 12px;
        }
    }
    li.dropdown:hover > .dropdown-menu {
        display: block;
    }
    a.nav-link.linkfont2 {
        //padding-left: 15px;
        margin: 0;
    }
    a.nav-link.linkfont2:hover {
        font-weight: 600;
    }
    ul 
    {
        .collapse.list-unstyled
        {
            padding: 0;
            border-top: 1px solid $brdgrey;
        }
    }
    .triangle
    {
        content: "";
        width: 5px;
        height: 5px;
        border-right: 1px solid $fbgdark;
        border-bottom: 1px solid $fbgdark;
        transform: rotate(315deg);
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 10px;
        margin-right: 10px;
    }
    .divaccordionspace
    {
        margin-top: 30px;
        margin-bottom: 19px
    }
    .divborder
    {
        border-top: 1px solid $brdgrey;
        margin: 0px;
        padding: 0px;
    }
    .divborderbottom
    {
        border-bottom: 1px solid $brdgrey;
        margin-bottom: 10px;
    }
    .divacc
    {
        padding-top: 40px;
    }
    ul.nav.flex-column {
        padding-right: 0;
    }

    .backtp{
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $istblack;
        margin: 0 auto;
        width: 100%;
        cursor: pointer;
        text-align: center;
    }
    .arrow 
    {
        content: "";
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        width: 10px;
        height: 10px;
        border-right: 2px solid $fbgdark;
        border-bottom: 2px solid $fbgdark;
        transform: rotate(225deg);
        cursor: pointer;
        margin-bottom: 55px;
    }

    .nav-link {
    
        &.expand {
          &:after {
            
                    content: "";
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    right: 17px;
                    border-right: 1px solid $fbgdark;
                    border-bottom: 1px solid $fbgdark;
                    transform: rotate(45deg);
                    top: 16px;
            }
        }
        &.expand[aria-expanded="true"] {
            &:after {
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                right: 17px;
                border-right: 1px solid #222;
                border-bottom: 1px solid #222;
                transform: rotate(225deg);
                top: 20px;
            }
        }
      }
    
      .nav-item {
        &.show {
          .nav-link.linkfont.expand {
            &:after {
                
                        content: "";
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        right: 2px;
                        border-right: 1px solid $fbgdark;
                        border-bottom: 1px solid $fbgdark;
                        transform: rotate(225deg);
                        top: 16px;
            }
          }
        }
      }
      ul.nav.flex-column.divborder {
        margin-right: 15px;
    }
    a.accordionmenu:hover
    {
        color: $dark-blue;
    }
    ////////////////////////

    .coldiv{
        width: 255px;
        .div-title
        {
            margin-top: 16px;
            margin-bottom: 20px;
            height: 93px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical; 
            width: 100%;
            @media screen and (max-width: $mobile-breakpoint) {
                height: 126px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical; 
            }
        }
        .div-text {
            height: 117px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical; 
            margin-bottom: 25px; 
            @media screen and (max-width: $mobile-breakpoint) {
                display: none; 

            }
        }
        .div-link 
        {
            margin-top: 28px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical; 
            @media screen and (max-width: $mobile-breakpoint) {
                bottom: 0;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical; 
                margin-top: 50px;
            }
        }
        .div-link2 
        {
            margin-top: 20px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical; 
            @media screen and (max-width: $mobile-breakpoint) {
                bottom: 0;
                margin-top: 7px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical; 
            }
        }
        @media screen and (max-width: $mobile-breakpoint) {
            width: 100%;
        }
    }
    ////
    .classborder {
        border-right: 1px solid  $brdgrey;
        @media screen and (max-width: $mobile-breakpoint) {
            border-right: 1px solid  $brdgrey;
        }
    }  
    .classborderwot {
        border-right: 1px solid  $brdgrey;
        @media screen and (max-width: $mobile-breakpoint) {
            border-right: none;
            min-height: 0px;
            //margin-: 15px;
        }
        @media screen and (max-width: $screen-sm-min) {
            border-right: none;
             min-height: 0;
            //margin-: 15px;
        }
    } 
    .classborderwo {
        border-right: none;
        @media screen and (max-width: $mobile-breakpoint) {
            padding-bottom: 9px;
            padding-right: 1px;
            margin-right: 14px;
            padding-left: 1px;
            margin-left: 14px;
            border-bottom: 1px solid  $brdgrey;
            margin-bottom: 0px;
            border-right: none;
            min-height: 0px;
            //margin-: 15px;
        }
        @media screen and (max-width: $screen-sm-min) {
            border-right: none;
            min-height: 0;
            //margin-: 15px;
        }
    }
    .imagediv
    {
        width: 100%;
        height: 117px;
    }
    .col-lg-12.order-lg-1.coldiv {
        padding-right: 0;
    }
    .col-lg-12.order-lg-2 {
        padding-right: 0;
    }
    .divspaces
    {
        margin-top:24px;
        margin-bottom: 24px;
        margin-left: 15px;
        height: 1px;
        width: 100%;
        background-color: $brdgrey;
        @media screen and (max-width: $mobile-breakpoint) {
            display: none;
        }
    }
    .divspaces2
    {
        display: none;
        
        @media screen and (max-width: $mobile-breakpoint) {
            margin-top:24px;
            margin-bottom: 24px;
            margin-left: 15px;
            height: 1px;
            width: 100%;
            background-color: $brdgrey;
            display: block;
        }
    }
    h4 
    {
        margin: 0;
    }
    .publications_slider {
        padding-top: 30px;
        padding-bottom: 40px;
        margin-bottom: 20px;
        @media screen and (max-width: $mobile-breakpoint) {
            padding-bottom: 30px;
        }

    }
    .pagination {
        display: inline-block;
        border: 1px solid $brdgrey;
        position: absolute;
        right: 0;
       
      }
      
      .pagination a {
        color: black;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        
      }
      
      .pagination a.active {
        background-color: $brdgrey;
        border: 1px solid $brdgrey;
        cursor: default;
      }
      
      .pagination a:hover:not(.active) {
          background-color: $white;
        }
      
      .pagination a:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      
      .pagination a:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      .divpagenation
      {
          width:100%;
          margin-left: 15px;
          margin-right: 15px;
          margin-top: 50px;
          margin-bottom: 100px;
          position: relative;
          @media screen and (max-width: $mobile-breakpoint) {
            display: none;
          }

      }
      a
      {
          &.arrowpg
          {
            color: $dark-blue;
            font-size: 28px;
            padding-top: 0;
            padding-bottom: 0;
          }
      }
      .searchnews
      {
        width: 100%;
        height: 50px;
        border: 1px solid $brdgrey;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        background-position: right 30px center;
        background-repeat: no-repeat;
        color: $brdgrey;
        padding-left: 20px;
      }
      .daterange
      {
          margin-top: 30px;
            width: 100%;
            height: 50px;
            border: 1px solid $brdgrey;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background-position: left 16px center;
            background-repeat: no-repeat;
            color: $brdgrey;
            padding-left: 70px;
      }

      .form-control::-webkit-input-placeholder { 
        margin-left: 20px;
        padding-left: 20px;
        @media screen and (max-width: $mobile-breakpoint) {
          color: $white; 
        }
      }
      .checkboxclass
      {
          width: 30px;
          height: 30px;
          border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background: $white;
            border: 1px solid $dark-blue;
      }
      //
      .styled-checkbox {
        position: absolute; // take it out of document flow
        opacity: 0; // hide it
        border: 1px solid $dark-blue;
      
        & + label {
          position: relative;
          cursor: pointer;
          padding: 0;
        }
      
        // Box.
        & + label:before {
          content: '';
          margin-right: 10px;
          display: inline-block;
          vertical-align: text-top;
          width: 30px;
          height: 30px;
          border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          background: white;
          border: 1px solid $dark-blue;
        }
      
        
      
        // Box checked
        &:checked + label:before {
          background:$white;
        }
        
        // Disabled state label.
        &:disabled + label {
          color: $white;
          cursor: auto;
        }
      
        // Disabled box.
        &:disabled + label:before {
          box-shadow: none;
          background: $brdgrey;
        }
      
        // Checkmark. Could be replaced with an image
        &:checked + label:after {
          content: '\2713';;
          position: absolute;
          left: 10px;
          top: 2px;
          color: $dark-blue;
        }
      }
      label {
            margin-top: 40px;
            margin-bottom: 17px;
        }
      //
    .smalldatediv
    {
        height: 40px;
        width: 40px;
        background-color: red;
    }
    .bigdatediv
    {
        height: 40px;
        width: 100%;
        background-color: aqua;
    }
    .row.divaccordionspace {
        @media screen and (max-width: $mobile-breakpoint) {
            display: none;
          }
    }
    .styled-checkbox:after {
        content: "\2713";
        position: absolute;
        left: 9px;
        top: 0px;
        color: $dark-blue;
        font-size: 20px;
    }
    .styled-checkbox:checked+label:after {
        content: "\2713";
        position: absolute;
        left: 9px;
        top: 0px;
        color: $dark-blue;
        font-size: 20px;
    }
}