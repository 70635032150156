.sidebar01x {
  max-width: 210px;
  margin-left: auto;
  border-top: 1px solid #ddd;
  @media screen and (max-width: $mobile-breakpoint) {
    display: none;
  }
  ul {
    li {
      padding: 20px 0;
      border-bottom: 1px solid $brdgrey;
      position: relative;
      a {
        font-family: $tiempos-headline-regular;
        font-size: 16px;
        font-weight: $regular;
        letter-spacing: normal;
        color: #000000;
        padding: 0;
        &:hover {
          font-weight: 600;
        }
        &.active {
          font-weight: 600;
        }
        &.expand {
          &:after {
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            right: 17px;
            top: 24px;
            border-right: 1px solid $fbgdark;
            border-bottom: 1px solid $fbgdark;
            transform: rotate(45deg);
          }
        }
        &.expand[aria-expanded="true"] {
          &:after {
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            right: 17px;
            border-right: 1px solid #222;
            border-bottom: 1px solid #222;
            transform: rotate(225deg);
            top: 27px;
          }
        }
      }
      ul {
        border-top: 1px solid #ddd;
        li {
          border: 0;
          padding: 7px 0;
          a {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: $regular;
            line-height: 1.29;
            color: #222222;
            margin-left: 10px;
          }
          &:first-of-type {
            a {
              margin-top: 20px;
            }
          }
          &:last-of-type {
            a {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
  a:hover {
    outline: none;
    text-decoration: none;
  }
  a:focus {
    outline: none;
    text-decoration: none;
  }
}

.sidebar01x-dropdown {
  margin-bottom: 30px;
  border-top: 1px solid #ddd;
  position: relative;
  @media screen and (min-width: 768px) {
    display: none;
  }
  ul {
    li {
      line-height: 60px;
      border-bottom: 1px solid $brdgrey;
      position: relative;
      a {
        font-family: $tiempos-headline-regular;
        font-size: 16px;
        font-weight: $regular;
        letter-spacing: normal;
        color: #000000;
        padding: 0;
        &:hover {
          font-weight: 600;
        }
        &.active {
          font-weight: 600;
        }
        &.expand {
          &:after {
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            right: 17px;
            top: 24px;
            border-right: 1px solid $fbgdark;
            border-bottom: 1px solid $fbgdark;
            transform: rotate(45deg);
          }
        }
        &.expand[aria-expanded="true"] {
          &:after {
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            right: 17px;
            border-right: 1px solid #222;
            border-bottom: 1px solid #222;
            transform: rotate(225deg);
            top: 27px;
          }
        }
      }
      ul {
        border-top: 1px solid #ddd;
        li {
          border: 0;
          padding: 7px 0;
          a {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: $regular;
            line-height: 1.29;
            color: #222222;
            margin-left: 10px;
          }
          &:first-of-type {
            a {
              margin-top: 20px;
            }
          }
          &:last-of-type {
            a {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
  a:hover {
    outline: none;
    text-decoration: none;
  }
  a:focus {
    outline: none;
    text-decoration: none;
  }
  // select {
  //   height: 50px;
  //   width: 100%;
  //   background: transparent;
  //   padding: 0 15px;
  //   font-family: $tiempos-text;
  //   font-size: 16px;
  //   font-weight: 500;
  //   border-radius: 3px;
  //   border-color: #dddddd;
  //   option {
  //     border-color: #dddddd;
  //     font-family: $WorkSans;
  //     padding: 15 10px;
  //     border-bottom: 1px solid;
  //     line-height: 50px;
  //   }
  // }
  // .triangle-down {
  //   position: absolute;
  //   background: url(../img/icons/arrow-down.svg);
  //   width: 30px;
  //   height: 30px;
  //   right: 25px;
  //   top: 6px;
  // }
}