.m03 {
    .rectanglemod {
        padding: 10px;
        max-width: 350px;
        border: 1px solid;
        border-color: $brdgrey;
        position: relative;
        border-top: 3px solid $black;
        margin-top: 15px;
        margin-bottom: 15px;
        @media screen and (max-width: $mobile-breakpoint) {
            max-width: 100%;
            padding: 0;
            margin: 0;
            border: none;
            border-bottom: 1px solid;
            border-color: $brdgrey;
            height: 100%;
        }
        img {

        }
      }  
    .rectanglerwo
    {
        width: 24px;
        height: 24px;
        position: absolute;
        border: none ;
    
        z-index: 999;
        right: -1px;
        bottom: -1px;
        @media screen and (max-width: $mobile-breakpoint) {
            display:none;
            visibility: hidden;
        }
    }
    .publications_slider {
        padding: 40px 0;
        border-bottom: 1px solid;
        border-top: 1px solid;
        border-color: $brdgrey;
            @media screen and (max-width: $mobile-breakpoint) {
                padding-top:0;
                padding:0;
            }
    }
    .digonalline 
    {
        width:24px
    }
    .digonalline
    {
        background:linear-gradient(-45deg,$white 49%,$brdgrey 52%,$white 51%);
    }
    p {
        margin-left: 15px;
        margin-right: 15px;
        &.link
        {
            font-family: $WorkSans;
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: $orangeo;
            right: 0%;
            position: relative;
            margin-top: 18px;
            margin-right: 0;
            @media screen and (max-width: $mobile-breakpoint) {
                padding:0;
                margin-top: 0;
                margin-bottom: 10px;
                margin-left: 0;
                margin-right: 0;
            }
        }
        @media screen and (max-width: $mobile-breakpoint) {
            padding:0;
            margin-top: 18px;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;
        }
        
    }
    h2 {
        font-family: $tiempos-headline-medium;
        &:first-of-type{
            font-weight: 500;
            margin-top: 11px;
            margin-bottom: 10px;
            margin-left: 15px;
            margin-right: 15px;
            @media screen and (max-width: $mobile-breakpoint) {
                padding:0;
                margin-top: 26px;
                margin-bottom: 14px;
                margin-left: 0;
                margin-right: 0;
                min-height: 26px;
                max-height: 26px;
                font-size: 15px;
            }
        }
        &:last-of-type {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 15px;
            margin-right: 15px;
            @media screen and (max-width: $mobile-breakpoint) {
                padding:0;
                margin-top: 30px;
                margin-bottom: 7px;
                margin-left: 0;
                margin-right: 0;
                font-size: 20px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.3;
                letter-spacing: normal;
                color: $fbgdark;
            }
        }
        &.withimage
        {
            max-height: 167px;
            min-height: 113px;
            @media screen and (max-width: $screen-xl-min) {
                @media screen and (max-width: $screen-sm-min) {
                    @media screen and (max-width: $mobile-breakpoint) {
                            min-height: 60px;
                            max-height: 61px;
                        }
                        min-height: 144px;
                        max-height: 60px;
                    }
                    min-height: 146px;
                    max-height: 60px;
            }
        }
        &.withoutimage {
            min-height: 96px;
            max-height: 96px;
            @media screen and (max-width: $screen-xl-min) {
                @media screen and (max-width: $screen-sm-min) {
                    @media screen and (max-width: $mobile-breakpoint) {
                            min-height: 60px;
                            max-height: 60px;
                            }
                            min-height: 77px;
                            max-height: 60px;
                    }
                    min-height: 95px;
                    max-height: 60px; 
                }
            }
    }
    h4 {
        margin-bottom: 0; 
        margin-left: 15px;
        margin-right: 15px;
        @media screen and (max-width: $mobile-breakpoint) {
            padding:0;
            margin-top: 0;
            margin-bottom: 30px;
            margin-left: 0;
            margin-right: 0;
        }
    }
    .imageclass {
        max-width: 320px;
        min-height: 219.4px;
        background-size:cover;
        margin-top: 19px;
        margin-bottom: 15.6px;
        margin-left: 15px;
        margin-right: 15px;
        @media screen and (max-width: $mobile-breakpoint) {
            max-width: 100%;
            min-height: 154px;
            margin-top: 8px;
            margin-bottom: 0;
            margin-left: 1px;
            margin-right: 1px;
            padding: 0;
        }
        img {
            margin: 0 auto;
        }
    }

    .upertext
    {
        position: relative;
        width: 100%;
        background-color: white;
        text-align: left;
        margin-right: 15px;
        height: 76px;
        border-top: 2px solid black;
        @media screen and (max-width: $mobile-breakpoint) {
            
        }
    }
    h1 {
        &.headerone {
            font-family: $tiempos-headline-medium;
            margin-top: 19px;
            margin-bottom: 19px;
        }
        @media screen and (max-width: $mobile-breakpoint) {
            font-size: 24px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $fbgdark;
        }
    }
    .divsliderarrows {
        position: absolute;
        right: 0;
        top:18px;
        background-color: red;
        @media screen and (max-width: $mobile-breakpoint) {
            display: none;
        }
    }
    .divsliderarrows img {
        margin: 5px 5px 0px;
        width: 35px;
        height: 35px;
        opacity: 0.4;
        filter: alpha(opacity=40); /* For IE8 and earlier */
    }
    .divsliderarrows img:hover {
        opacity: 0.7;
        filter: alpha(opacity=70); /* For IE8 and earlier */
        }
    .linkdiv
    {
        width: 100%;
        background-color: white;
        text-align: right;
        margin-left: 15px;
        margin-right: 15px;
        height: 95px;
        @media screen and (max-width: $mobile-breakpoint) {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 70px;
        }
    }
    a:hover {
        text-decoration: none;
     }

     .type2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        top:18px;
        background-size:cover;
        transform: rotate(180deg);
        @media screen and (max-width: $mobile-breakpoint) {
            display: none;
        }
    }
    .type3 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 67px;
        top:18px;
        background-size:cover;
        @media screen and (max-width: $mobile-breakpoint) {
            display: none;
        }
    }

    .type2 {
        img {
            &:hover {
                cursor: pointer;
                opacity: 0.4;
            }
        }
    }
    .type3 {
        img {
            &:hover {
                cursor: pointer;
                opacity: 0.4;
            }
        }
    }
    .typegen {
        &:hover {
            cursor: pointer;
        }

        .slick-disabled {
            opacity: 0.4 !important;
        }
    }
    img {
        &.imagearrow {
            width: 38px;
            height: 38px;
            border-radius: 50%;
        }
      }
      h6 {
        position: absolute;
        right: 0;
        margin-right: 10px;
        margin-bottom: 0;
        bottom: 5px;
        padding-right: 8px;
        font-size: 13px;
        line-height: 1.38;
        color: $fbgdark;
        font-family: $WorkSans;
        @media screen and (max-width: $screen-xl-min) {
            font-size: 13px;
            margin-bottom: 7px;
            margin-top: 30px;
        }
      }
    a {
        &.h44 {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $dark-blue;
            display: inline;
            @media screen and (max-width: $mobile-breakpoint) {
                padding:0;
                margin-top: 1px;
                margin-bottom: 30px;
                margin-left: 0;
                margin-right: 0;
            }
        }
        &.paragraph {
            font-family: $WorkSans;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            display: inline;
            color: $grey;
            @media screen and (max-width: $mobile-breakpoint) {
                font-size: 15px;
                margin-left: 0;
                margin-right: 0;
            }
        }
        &.withimagea
        {
            font-family: $tiempos-headline-medium;
            font-size: 24px;
            line-height: 1.29;
            color: $fbgdark;
            display: inline;
            overflow: hidden;
            @media screen and (max-width: $mobile-breakpoint) {
                min-height: 60px;
                max-height: 60px;
                font-size: 20px;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 0;
            } 
        }
        &.withoutimagea {
            margin-left: 15px;
            margin-right: 15px;
            height: 95px;
            font-family: $tiempos-headline-medium;
            font-size: 24px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: $fbgdark;
            display: flex;   
            margin-bottom: 40px;
            @media screen and (max-width: $mobile-breakpoint) {
                min-height: 60px;
                max-height: 60px;
                font-size: 20px;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 0;
            } 
        }
    }
    a:hover {
        text-decoration: none;
        border: none;
        cursor: pointer;
        opacity: 0.5;
        outline: none;
    }
    a:focus {
        text-decoration: none;
        border: none;
        outline: none;
    }
    .hidediv
    {
        @media screen and (max-width: $mobile-breakpoint) {
            display: none; 
        }
    }
    .slick-slide {
	
        @media screen and (max-width: $mobile-breakpoint) {
            display:none!important;
        }
    
        &.slick-active {
            @media screen and (max-width: $mobile-breakpoint) {
            display:block!important;
            }
        }
    }

    .slick-slider {
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
      }
      .slick-list.draggable {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      .coldiv{
        width: 320px;
        .div-title
        {
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 39px;
            height: 65px;
            overflow: hidden;
            @media screen and (max-width: $mobile-breakpoint) {
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 0;
                height: 55px;
                margin-top: 6px;
            }
        }
        .div-text {
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 10px;
            height: 45px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;  
            @media screen and (max-width: $mobile-breakpoint) {
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 0;
                height: 20px;
                margin-top: 10px;
            }
        }
        .div-link 
        {
            margin-left: 15px;
            margin-right: 15px;
            margin-top: 20px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical; 
            @media screen and (max-width: $mobile-breakpoint) {
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 20px;
                margin-top: 2px;
            } 
        }
        .div-titlet
        {
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 33px;
            height: 62px;
            overflow: hidden;
            @media screen and (max-width: $mobile-breakpoint) {
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 0;
                height: 50px;
                margin-top: 6px;
            }
        }
        .div-textt {
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 5px;
            height: 45px;
            overflow: hidden;
            @media screen and (max-width: $mobile-breakpoint) {
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 0;
                height: 20px;
            } 
        }
        .div-linkt
        {
            margin-left: 15px;
            margin-right: 15px;
            margin-top: 10px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical; 
            @media screen and (max-width: $mobile-breakpoint) {
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 20px;
                margin-top: 2px;
            } 
        }
        @media screen and (max-width: $mobile-breakpoint) {
            width: 100%;
            margin-bottom: -1px;
        }
    }
}