.m04x {
  h1 {
    margin-bottom: 18px;
  }
  h2 {
    margin-bottom: 35px;
  }
  h4 {
    color: #1d2114;
  }
  .date-year {
    font-family: Work Sans, "sans-serif";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9b9b9b;
    padding: 0;
    margin: 0;
  }
  ol {
    margin-left: -15px;
    margin-bottom: 64px;
    li {
      list-style-type: decimal-leading-zero;
      color: orangered;
    }
  }
  .fontstyle {
    font-family: $tiempos-headline-regular;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.5;
    letter-spacing: normal;
    color: $onyx;
    border-bottom: 1px solid orangered;
    text-decoration: none;
  }
  .before-title {
    font-family: $WorkSans;
    font-size: 14px;
    font-weight: 600;
    color: #235789;
    text-transform: uppercase;
    margin: 0 0 8px 0;
    line-height: 16px;
  }
  a {
    color: #333333;
    text-decoration: underline;
    &:hover {
      color: #333333;
    }
  }
  #map {
    height: 547px;
    &.main-map {
      height: 744px;
    }
    .gm-style-iw {
      // height: 240px;
      width: 230px;
      padding: 0;
      border-radius: 0;
      .gm-style-iw-d {
        // height: 240px;
        width: 230px;
        padding: 0;
        border-radius: 0;
        overflow: hidden!important;
        .map-text-info {
          padding: 12px 5px 10px 10px;
          h3 {
            font-family: $tiempos-text;
            font-weight: $regular;
          }
          p {
            font-family: $WorkSans;
            font-size: 10px;
            line-height: 10px;
          }
          a {
            border-radius: 15px;
            background-color: #ff6b35;
            padding: 8px 20px;
            font-family: $WorkSans;
            font-weight: 500;
            font-size: 12px;
            text-decoration: none;
            color: $white;
          }
        }
      }
    }
    .gm-style .gm-style-iw-t::before, .gm-style .gm-style-iw-t::after {
      display: none;
    }
  }
}