.m24x {
  .inputcass {
    width: 100%;
    height: 50px;
    padding: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid $brdgrey;
  }

  input[type=text]:focus {
    outline: none;
  }
  .divbotton {
    margin-top: 55px;
    margin-bottom: 66px;
    text-align: right;
    @media screen and (max-width: $mobile-breakpoint) {
      text-align: center;

    }
  }
  .bottomdiv {
    border-bottom: 1px solid $brdgrey;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0px;
    margin-right: 0px;
  }
  .buttonlinkk {
    background-color: #ff6b35;
    border: #ff6b35;
    border-radius: 22.5px;
    font-family: Work Sans,"sans-serif";
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    padding: 15px 20px;
    @media screen and (max-width: $mobile-breakpoint) {
      left: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
  form {
    p {
      margin: 16px 0;
    }
  }

}