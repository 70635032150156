.m29x {
  h2 {
    font-family: $tiempos-headline-medium;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: $fbgdark;

    &.h22 {
      width: 100%;
      margin: 0;
      padding: 0;
      font-size: 24px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $fbgdark;
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 20px;
        margin-left: 15px;
        padding: 0;
        width: 50%;
      }
    }
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }
  h6 {
    &.firstclass {
      font-family: $WorkSans;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $onyx;
      @media screen and (max-width: $mobile-breakpoint) {
        margin: 0;
        position: absolute;
        right: 15px;
        top: 20px;
      }
    }
    &.secondclass {
      font-family: $WorkSans;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $onyx;
    }
  }
  a {
    &.arrowpg {
      color: $dark-blue;
      font-size: 18px;
      padding-top: 0;
      padding-bottom: 0;
    }
    &.h44 {
      font-family: $WorkSans;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $dark-blue;
      display: inline;
      text-decoration: none;
      @media screen and (max-width: $mobile-breakpoint) {
        padding: 0;
        margin-top: 1px;
        margin-bottom: 30px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    &.paragraph {
      font-family: $WorkSans;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      display: inline;
      color: $grey;
      text-decoration: none;
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 15px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    &.withimagea {
      font-family: $tiempos-headline-medium;
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $fbgdark;
      display: inline;
      text-decoration: none;
      @media screen and (max-width: $mobile-breakpoint) {
        min-height: 60px;
        max-height: 60px;
        font-size: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
      }
    }
    &.withoutimagea {
      margin-left: 15px;
      margin-right: 15px;
      height: 95px;
      font-family: $WorkSans;
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $fbgdark;
      display: flex;
      margin-bottom: 40px;
      @media screen and (max-width: $mobile-breakpoint) {
        min-height: 60px;
        max-height: 60px;
        font-size: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
      }
    }
    &.fontstyle {
      font-family: $WorkSans;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.5;
      letter-spacing: normal;
      color: $onyx;
      border-bottom: 1px solid orangered;
    }
    &.nav-link {
      padding-left: 0;
      line-height: 44px;
      display: inline;
      margin-right: 45px;
      padding: 0;
    }
    &.linkfont {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $black;
    }
    &.linkfont2 {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $fbgdark;
    }
    &.accordionmenu {
      font-family: $WorkSans;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $greyb;
      &:first-child {
        color: $dark-blue;
      }
    }
    &.arcodionlink {
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.11;
      letter-spacing: normal;
      color: $black;
    }
    &.linkinsideacc {
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: $dark-blue;
      margin-left: 6px;
      cursor: pointer;
    }
    &.linksubmenu {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $onyx;
      display: inline;
      border-bottom: 1px solid $onyx;
    }
  }
  a:hover {
    outline: none;
    text-decoration: none;
  }
  a:focus {
    outline: none;
    text-decoration: none;
  }
  //////////////////////
  .rectanglemod {
    padding: 10px;
    max-width: 350px;
    border: 1px solid;
    border-color: $brdgrey;
    position: relative;
    border-top: 3px solid black;
    margin-top: 15px;
    margin-bottom: 15px;
    @media screen and (max-width: $mobile-breakpoint) {
      max-width: 100%;
      padding: 15px;
      margin: 0;
      border: none;
      border: 1px solid $brdgrey;
      border-top: 3px solid black;
      margin-bottom: 20px;
    }
  }
  .rectanglerwo {
    width: 24px;
    height: 24px;
    position: absolute;
    border: none;

    z-index: 999;
    right: -1px;
    bottom: -1px;
    @media screen and (max-width: $mobile-breakpoint) {
    }
  }
  .digonalline {
    width: 24px
  }
  .digonalline {
    background: linear-gradient(-45deg, $white 49%, $brdgrey 52%, $white 51%);
  }
  .coldiv {
    width: 320px;
    .div-title {
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 5px;
      min-height: 95px;
      max-height: 95px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        min-height: 60px;
        margin-top: 6px;
        padding: 0;
      }
    }
    .div-text {
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 5px;
      height: 45px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      /*text-overflow: ellipsis;
      overflow: hidden;
      width: 240px;
       height: 45px;
      white-space: nowrap;*/
      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 20px;
        margin-top: 10px;
        padding: 0;
      }
    }
    .div-link {
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 0px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-top: 2px;
        padding: 0;
      }
    }
    .div-titlet {
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 0px;
      height: 95px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 60px;
        margin-top: 6px;
      }
    }
    .div-textt {
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 5px;
      height: 45px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 20px;
      }
    }
    .div-linkt {
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 10px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
        margin-top: 2px;
      }
    }
    @media screen and (max-width: $mobile-breakpoint) {
      width: 100%;
    }
  }
  /////////////////
  .imageclass {
    max-width: 230px;
    height: 117px;
    background-size: cover;
    margin-top: 19px;
    margin-bottom: 15.6px;
    margin-left: 0px;
    margin-right: 0px;
    @media screen and (max-width: $mobile-breakpoint) {
      max-width: 313px;
      max-height: 140px;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      background-size: cover;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
  .publications_slider {
    padding-bottom: 55px;
    padding-top: 20px;
    border-bottom: 1px solid;
    border-color: $brdgrey;
    width: 100%;
    margin-bottom: 50px;
    @media screen and (max-width: $mobile-breakpoint) {
      padding-top: 0;
      padding-bottom: 0px;
      border-bottom: 1px solid $brdgrey;
    }
  }
}