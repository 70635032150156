/* Module 01 - Module Name */
.m01 {
  font-size: 12px;
  &.sub-header {
    .slider {
      height: 350px;
      .slick-slide {
        height: 350px;
      }
    }
    p.ptext {
      text-align: left;
    }
    h1.textsize40one {
      text-align: left;
    }
  }

  .sliderContainer {
    position: relative;
    margin-top: 40px;
    @media screen and (max-width: $mobile-breakpoint) {
      margin-top: 0px;
    }
  }
  .container.mb-5 {
    @media screen and (max-width: $mobile-breakpoint) {
      padding: 0px;
    }
  }

  .slider {
    width: 100%;
    margin: 0;
    height: 465px;
    position: relative;
    @media screen and (max-width: $mobile-breakpoint) {
      height: 350px;
    }
  }

  .slick-slide {
    background: #3a8999;
    color: white;
    font-size: 30px;
    text-align: center;
    height: 465px;
    @media screen and (max-width: $mobile-breakpoint) {
      height: 350px;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    color: black;
  }

  .slick-dots {
    bottom: -30px;
  }

  .progressBarContainer {
    position: relative;
    top: 10px;
    width: 170px;
    float: right;
  }

  .progressBarContainer div {
    display: block;
    width: 25%;
    padding: 0;
    cursor: pointer;
    float: left;
    color: white;
  }

  .progressBarContainer div:last-child {
    margin-right: 0;
  }

  .progressBarContainer div span.progressBar {
    width: 90%;
    height: 4px;
    background-color: rgba(0, 0, 0, 0.4);
    display: block;
  }

  .progressBarContainer div span.progressBar .inProgress {
    background-color: $orgvo;
    width: 0%;
    height: 4px;
  }
  .inProgress.on {
    width: 100% !important;
  }

  .sliderButtons {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 1;
  }

  .sliderButtons img {
    margin: 5px 5px 0px;
    width: 35px;
    height: 35px;
    opacity: 0.4;
    filter: alpha(opacity=40); /* For IE8 and earlier */
  }

  .sliderButtons img:hover {
    opacity: 0.7;
    filter: alpha(opacity=70); /* For IE8 and earlier */
  }

  .bgdiv1 {
    background-size: cover;
  }
  .bgdiv2 {
    //background-image: url('../img/slide2.jpg');
    background-size: cover;
  }
  .bgdiv3 {
    //background-image: url('../img/slide3.jpg');
    background-size: cover;
  }
  .bgdiv4 {
    //background-image: url('../img/slide4.jpg');
    background-size: cover;
  }
  .divoverimage {
    width: 490px;
    height: 138px;
    position: absolute;
    z-index: 999;
    left: 10px;
  }

  .buttonlink {
    width: 180px;
    height: 45px;
    border-radius: 22.5px;
    background-color: $orangeo;
    border: $orangeo;
    position: relative;
    cursor: pointer;

  }
  .texttittle {
    width: 490px;
    margin-left: 40px;
    z-index: 999;
    @media screen and (max-width: $mobile-breakpoint) {
      width: 345px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  .texttittleone {
    width: 490px;
    z-index: 999;
    @media screen and (max-width: $mobile-breakpoint) {
      width: 100%;
    }
  }
  .divbotton {
    width: 182px;
    margin-left: 40px;
    z-index: 999;
    display: flex;
    margin-bottom: 30px;
    @media screen and (max-width: $mobile-breakpoint) {
      width: 100%;
      height: 45px;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 40px;
      align-items: left;
      justify-content: left;

    }
  }
  .divbottonone {
    z-index: 999;
    display: flex;
    @media screen and (max-width: $mobile-breakpoint) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      justify-content: center;
    }
  }
  .textbody {
    width: 313px;
    @media screen and (max-width: $mobile-breakpoint) {
      width: 100%;
    }
  }
  .texttittle img:hover {
    opacity: 0.7;
    filter: alpha(opacity=70); /* For IE8 and earlier */
  }
  h1 {
    &.textsize40 {
      font-family: $tiempos-headline-medium;
      font-size: 40px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $white;
      margin-bottom: 0px;
      z-index: 999;
      margin-bottom: 25px;
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 30px;
        font-weight: 500;
        line-height: 1.17;
      }
    }
    &.textsize40one {
      font-family: $tiempos-headline-medium;
      font-size: 40px;
      font-weight: 500;
      color: $white;
      z-index: 999;
      line-height: 1;
      margin-bottom: 15px;
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 30px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        letter-spacing: normal;
        color: $white;
        text-align: center;
      }
    }
  }
  h4 {
    font-family: $WorkSans;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: $white
  }
  button.navbar-toggler {

    @media screen and (max-width: $mobile-breakpoint) {
      border: none;
    }

  }
  .navbar-toggler {
    @media screen and (max-width: $mobile-breakpoint) {
      border: none;
    }
  }
  @media screen and (max-width: $mobile-breakpoint) {
    button.navbar-toggler {
      border: none;
    }
  }
  .progressBarContainer {
    @media screen and (max-width: $mobile-breakpoint) {
      float: initial;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .sliderButtons {
    img {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .type2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 30px;
    top: 30px;
    background-size: cover;
    transform: rotate(180deg);
    @media screen and (max-width: $mobile-breakpoint) {
      display: none;
    }
  }
  .type3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 85px;
    top: 30px;
    background-size: cover;
    @media screen and (max-width: $mobile-breakpoint) {
      display: none;
    }
  }

  .type2 {
    img {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .type3 {
    img {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .type2:hover {
    opacity: 0.4;
  }
  .type3:hover {
    opacity: 0.4;
  }
  img {
    &.imagearrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      opacity: 0.4;
    }
  }
  .publications_slider {
    padding-bottom: 80px;
    padding-top: 0px;
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-color: $brdgrey;
  }
  a:hover {
    text-decoration: none;
    border: none;
    color: $white;
    background-color: #cc552b!important;
    cursor: pointer;
  }

  a {
    &.buttonlinkk {
      width: 180px;
      height: 45px;
      line-height: 44px;
      border-radius: 22.5px;
      background-color: $orangeo;
      border: $orangeo;

      font-family: $WorkSans;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: $white;
      @media screen and (max-width: $mobile-breakpoint) {
        left: 0px;
        bottom: 20px;
      }
    }
    &.buttonlinkkone {
      width: 180px;
      height: 45px;
      line-height: 44px;
      border-radius: 22.5px;
      background-color: $orangeo;
      border: $orangeo;

      font-family: $WorkSans;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: $white;
      @media screen and (max-width: $mobile-breakpoint) {
        width: 220px;
        height: 45px;
        margin-bottom: 25px;
      }
    }
  }
  .sliderContainer.slider.single-item.g2 {
    height: 500px;
  }
  p {
    &.ptext {
      font-family: $tiempos-headline-regular;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.11;
      letter-spacing: normal;
      color: $white;
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: $white;
        font-family: $WorkSans;
        text-align: center;
      }
    }
  }

  .slick-slider {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  .slick-list.draggable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .linkmenu {
    margin-top: 15px;
    margin-bottom: 120px;
    width: 1000px;
    height: 100px;
  }
  .align-bottom {
    position: absolute;
    bottom: 0;
    padding: 0 40px 40px;
    width: 100%;
    @media screen and (max-width: $mobile-breakpoint) {
      padding: 0 15px 15px;
    }
  }

}
