.pag {
  @media screen and (max-width: $mobile-breakpoint) {
    display: none;
  }
  .pagination {
    height: 40px;
    display: inline-block;
    border: 1px solid $brdgrey;
    float: right;
    a {
      font-family: $WorkSans;
      color: black;
      float: left;
      text-decoration: none;
      width: 34px;
      line-height: 38px;
      border: 0!important;
      text-align: center;
      &.active {
        background-color: $brdgrey;
        border: 1px solid $brdgrey;
        cursor: default;
      }
      &:hover:not(.active) {
        background-color: $white;
      }
      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
  .divpagenation {
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 50px;
    position: relative;
  }
  a {
    &.arrowpg {
      color: $dark-blue;
      font-size: 28px;
      width: 40px;
      line-height: 35px;
    }
  }
}
.show-more-button {
  @media screen and (min-width: $mobile-breakpoint+1) {
    display: none;
  }
  text-align: center;
}