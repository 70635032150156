.m23x {
  .table {

    padding: 0;
  }
  table {

    padding: 0;
  }
  .table td, .table th {
    padding-left: 0;
  }
  .thr {
    font-family: $TiemposText;
    border-bottom: 2px solid $istblack;
    border-top: none;
  }
  .tspace {
    padding: 0 !important;
    margin: 0 !important;
    vertical-align: middle;
    @media screen and (max-width: $mobile-breakpoint) {
      width: 100%;
    }
    p {
      padding: 0;
      margin: 0;
    }
  }
  table.table {
    margin-bottom: 70px;
  }
  .pdatetimetext {
    font-family: $WorkSans;
    font-size: 16px;
    color: #999999;
  }
  @media screen and (max-width: $mobile-breakpoint) {
    thead {
      display: none;
    }
  }
  table {
    @media screen and (max-width: $mobile-breakpoint) {
      border-top: 2px solid $istblack;
    }
  }
  @media screen and (max-width: $mobile-breakpoint) {
    tbody {
      tr {
        border-bottom: 1px solid #ddd;
        th {
          padding-bottom: 0;
        }
        td {
          &:nth-child(2) {
            padding-top: 0;
          }
          &:nth-child(3) {
            padding-bottom: 0;
          }
          &:nth-child(4) {
            padding-top: 0;
          }
        }
        th, td {
          width: 100%;
          display: block;
          border: 0;
        }
      }
    }
  }
}