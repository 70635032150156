/*!
 * Bootstrap v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 *
 * IST Austria
 * Author - Ilir Hoxha
 *
 */
@import url("https://fonts.googleapis.com/css?family=Work+Sans:400,500,600,700");
/* line 19, src/assets/scss/bootstrap/_reboot.scss */
*,
*::before,
*::after {
  box-sizing: border-box; }

/* line 30, src/assets/scss/bootstrap/_reboot.scss */
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

/* line 48, src/assets/scss/bootstrap/_reboot.scss */
[tabindex="-1"]:focus {
  outline: 0 !important; }

/* line 58, src/assets/scss/bootstrap/_reboot.scss */
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

/* line 74, src/assets/scss/bootstrap/_reboot.scss */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

/* line 83, src/assets/scss/bootstrap/_reboot.scss */
p {
  margin-top: 0;
  margin-bottom: 1rem; }

/* line 96, src/assets/scss/bootstrap/_reboot.scss */
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

/* line 105, src/assets/scss/bootstrap/_reboot.scss */
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

/* line 111, src/assets/scss/bootstrap/_reboot.scss */
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

/* line 118, src/assets/scss/bootstrap/_reboot.scss */
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

/* line 125, src/assets/scss/bootstrap/_reboot.scss */
dt {
  font-weight: 700; }

/* line 129, src/assets/scss/bootstrap/_reboot.scss */
dd {
  margin-bottom: .5rem;
  margin-left: 0; }

/* line 134, src/assets/scss/bootstrap/_reboot.scss */
blockquote {
  margin: 0 0 1rem; }

/* line 138, src/assets/scss/bootstrap/_reboot.scss */
b,
strong {
  font-weight: bolder; }

/* line 143, src/assets/scss/bootstrap/_reboot.scss */
small {
  font-size: 80%; }

/* line 152, src/assets/scss/bootstrap/_reboot.scss */
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

/* line 160, src/assets/scss/bootstrap/_reboot.scss */
sub {
  bottom: -.25em; }

/* line 161, src/assets/scss/bootstrap/_reboot.scss */
sup {
  top: -.5em; }

/* line 168, src/assets/scss/bootstrap/_reboot.scss */
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

/* line 185, src/assets/scss/bootstrap/_reboot.scss */
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  /* line 194, src/assets/scss/bootstrap/_reboot.scss */
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

/* line 204, src/assets/scss/bootstrap/_reboot.scss */
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

/* line 212, src/assets/scss/bootstrap/_reboot.scss */
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

/* line 226, src/assets/scss/bootstrap/_reboot.scss */
figure {
  margin: 0 0 1rem; }

/* line 236, src/assets/scss/bootstrap/_reboot.scss */
img {
  vertical-align: middle;
  border-style: none; }

/* line 241, src/assets/scss/bootstrap/_reboot.scss */
svg {
  overflow: hidden;
  vertical-align: middle; }

/* line 253, src/assets/scss/bootstrap/_reboot.scss */
table {
  border-collapse: collapse; }

/* line 257, src/assets/scss/bootstrap/_reboot.scss */
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

/* line 265, src/assets/scss/bootstrap/_reboot.scss */
th {
  text-align: inherit; }

/* line 276, src/assets/scss/bootstrap/_reboot.scss */
label {
  display: inline-block;
  margin-bottom: 0.5rem; }

/* line 285, src/assets/scss/bootstrap/_reboot.scss */
button {
  border-radius: 0; }

/* line 293, src/assets/scss/bootstrap/_reboot.scss */
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

/* line 298, src/assets/scss/bootstrap/_reboot.scss */
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/* line 309, src/assets/scss/bootstrap/_reboot.scss */
button,
input {
  overflow: visible; }

/* line 314, src/assets/scss/bootstrap/_reboot.scss */
button,
select {
  text-transform: none; }

/* line 322, src/assets/scss/bootstrap/_reboot.scss */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/* line 330, src/assets/scss/bootstrap/_reboot.scss */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

/* line 338, src/assets/scss/bootstrap/_reboot.scss */
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

/* line 345, src/assets/scss/bootstrap/_reboot.scss */
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

/* line 357, src/assets/scss/bootstrap/_reboot.scss */
textarea {
  overflow: auto;
  resize: vertical; }

/* line 363, src/assets/scss/bootstrap/_reboot.scss */
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

/* line 378, src/assets/scss/bootstrap/_reboot.scss */
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

/* line 390, src/assets/scss/bootstrap/_reboot.scss */
progress {
  vertical-align: baseline; }

/* line 395, src/assets/scss/bootstrap/_reboot.scss */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/* line 400, src/assets/scss/bootstrap/_reboot.scss */
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

/* line 413, src/assets/scss/bootstrap/_reboot.scss */
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/* line 422, src/assets/scss/bootstrap/_reboot.scss */
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

/* line 431, src/assets/scss/bootstrap/_reboot.scss */
output {
  display: inline-block; }

/* line 435, src/assets/scss/bootstrap/_reboot.scss */
summary {
  display: list-item;
  cursor: pointer; }

/* line 440, src/assets/scss/bootstrap/_reboot.scss */
template {
  display: none; }

/* line 446, src/assets/scss/bootstrap/_reboot.scss */
[hidden] {
  display: none !important; }

/* line 7, src/assets/scss/bootstrap/_type.scss */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

/* line 16, src/assets/scss/bootstrap/_type.scss */
h1, .h1 {
  font-size: 2.5rem; }

/* line 17, src/assets/scss/bootstrap/_type.scss */
h2, .h2 {
  font-size: 2rem; }

/* line 18, src/assets/scss/bootstrap/_type.scss */
h3, .h3 {
  font-size: 1.75rem; }

/* line 19, src/assets/scss/bootstrap/_type.scss */
h4, .h4 {
  font-size: 1.5rem; }

/* line 20, src/assets/scss/bootstrap/_type.scss */
h5, .h5 {
  font-size: 1.25rem; }

/* line 21, src/assets/scss/bootstrap/_type.scss */
h6, .h6 {
  font-size: 1rem; }

/* line 23, src/assets/scss/bootstrap/_type.scss */
.lead {
  font-size: 1.25rem;
  font-weight: 300; }

/* line 29, src/assets/scss/bootstrap/_type.scss */
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

/* line 34, src/assets/scss/bootstrap/_type.scss */
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

/* line 39, src/assets/scss/bootstrap/_type.scss */
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

/* line 44, src/assets/scss/bootstrap/_type.scss */
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

/* line 55, src/assets/scss/bootstrap/_type.scss */
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

/* line 67, src/assets/scss/bootstrap/_type.scss */
small,
.small {
  font-size: 80%;
  font-weight: 400; }

/* line 73, src/assets/scss/bootstrap/_type.scss */
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

/* line 84, src/assets/scss/bootstrap/_type.scss */
.list-unstyled {
  padding-left: 0;
  list-style: none; }

/* line 89, src/assets/scss/bootstrap/_type.scss */
.list-inline {
  padding-left: 0;
  list-style: none; }

/* line 92, src/assets/scss/bootstrap/_type.scss */
.list-inline-item {
  display: inline-block; }
  /* line 95, src/assets/scss/bootstrap/_type.scss */
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

/* line 106, src/assets/scss/bootstrap/_type.scss */
.initialism {
  font-size: 90%;
  text-transform: uppercase; }

/* line 112, src/assets/scss/bootstrap/_type.scss */
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

/* line 117, src/assets/scss/bootstrap/_type.scss */
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  /* line 122, src/assets/scss/bootstrap/_type.scss */
  .blockquote-footer::before {
    content: "\2014\00A0"; }

/* line 8, src/assets/scss/bootstrap/_images.scss */
.img-fluid {
  max-width: 100%;
  height: auto; }

/* line 14, src/assets/scss/bootstrap/_images.scss */
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

/* line 29, src/assets/scss/bootstrap/_images.scss */
.figure {
  display: inline-block; }

/* line 34, src/assets/scss/bootstrap/_images.scss */
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

/* line 39, src/assets/scss/bootstrap/_images.scss */
.figure-caption {
  font-size: 90%;
  color: #6c757d; }

/* line 2, src/assets/scss/bootstrap/_code.scss */
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  /* line 8, src/assets/scss/bootstrap/_code.scss */
  a > code {
    color: inherit; }

/* line 14, src/assets/scss/bootstrap/_code.scss */
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  /* line 22, src/assets/scss/bootstrap/_code.scss */
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

/* line 31, src/assets/scss/bootstrap/_code.scss */
pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  /* line 37, src/assets/scss/bootstrap/_code.scss */
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

/* line 45, src/assets/scss/bootstrap/_code.scss */
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

/* line 6, src/assets/scss/bootstrap/_grid.scss */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    /* line 6, src/assets/scss/bootstrap/_grid.scss */
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    /* line 6, src/assets/scss/bootstrap/_grid.scss */
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    /* line 6, src/assets/scss/bootstrap/_grid.scss */
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    /* line 6, src/assets/scss/bootstrap/_grid.scss */
    .container {
      max-width: 1140px; } }

/* line 18, src/assets/scss/bootstrap/_grid.scss */
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

/* line 28, src/assets/scss/bootstrap/_grid.scss */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

/* line 34, src/assets/scss/bootstrap/_grid.scss */
.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  /* line 38, src/assets/scss/bootstrap/_grid.scss */
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

/* line 8, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

/* line 31, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

/* line 36, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

/* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

/* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

/* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

/* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

/* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

/* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

/* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

/* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

/* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

/* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

/* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

/* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

/* line 48, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.order-first {
  order: -1; }

/* line 50, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.order-last {
  order: 13; }

/* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.order-0 {
  order: 0; }

/* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.order-1 {
  order: 1; }

/* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.order-2 {
  order: 2; }

/* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.order-3 {
  order: 3; }

/* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.order-4 {
  order: 4; }

/* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.order-5 {
  order: 5; }

/* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.order-6 {
  order: 6; }

/* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.order-7 {
  order: 7; }

/* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.order-8 {
  order: 8; }

/* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.order-9 {
  order: 9; }

/* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.order-10 {
  order: 10; }

/* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.order-11 {
  order: 11; }

/* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.order-12 {
  order: 12; }

/* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.offset-1 {
  margin-left: 8.33333%; }

/* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.offset-2 {
  margin-left: 16.66667%; }

/* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.offset-3 {
  margin-left: 25%; }

/* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.offset-4 {
  margin-left: 33.33333%; }

/* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.offset-5 {
  margin-left: 41.66667%; }

/* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.offset-6 {
  margin-left: 50%; }

/* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.offset-7 {
  margin-left: 58.33333%; }

/* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.offset-8 {
  margin-left: 66.66667%; }

/* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.offset-9 {
  margin-left: 75%; }

/* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.offset-10 {
  margin-left: 83.33333%; }

/* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  /* line 31, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 36, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 48, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-sm-first {
    order: -1; }
  /* line 50, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-sm-last {
    order: 13; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-sm-0 {
    order: 0; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-sm-1 {
    order: 1; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-sm-2 {
    order: 2; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-sm-3 {
    order: 3; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-sm-4 {
    order: 4; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-sm-5 {
    order: 5; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-sm-6 {
    order: 6; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-sm-7 {
    order: 7; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-sm-8 {
    order: 8; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-sm-9 {
    order: 9; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-sm-10 {
    order: 10; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-sm-11 {
    order: 11; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-sm-12 {
    order: 12; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-0 {
    margin-left: 0; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-1 {
    margin-left: 8.33333%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-2 {
    margin-left: 16.66667%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-3 {
    margin-left: 25%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-4 {
    margin-left: 33.33333%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-5 {
    margin-left: 41.66667%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-6 {
    margin-left: 50%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-7 {
    margin-left: 58.33333%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-8 {
    margin-left: 66.66667%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-9 {
    margin-left: 75%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-10 {
    margin-left: 83.33333%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  /* line 31, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 36, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 48, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-md-first {
    order: -1; }
  /* line 50, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-md-last {
    order: 13; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-md-0 {
    order: 0; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-md-1 {
    order: 1; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-md-2 {
    order: 2; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-md-3 {
    order: 3; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-md-4 {
    order: 4; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-md-5 {
    order: 5; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-md-6 {
    order: 6; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-md-7 {
    order: 7; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-md-8 {
    order: 8; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-md-9 {
    order: 9; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-md-10 {
    order: 10; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-md-11 {
    order: 11; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-md-12 {
    order: 12; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-md-0 {
    margin-left: 0; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-md-1 {
    margin-left: 8.33333%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-md-2 {
    margin-left: 16.66667%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-md-3 {
    margin-left: 25%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-md-4 {
    margin-left: 33.33333%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-md-5 {
    margin-left: 41.66667%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-md-6 {
    margin-left: 50%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-md-7 {
    margin-left: 58.33333%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-md-8 {
    margin-left: 66.66667%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-md-9 {
    margin-left: 75%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-md-10 {
    margin-left: 83.33333%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  /* line 31, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 36, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 48, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-lg-first {
    order: -1; }
  /* line 50, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-lg-last {
    order: 13; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-lg-0 {
    order: 0; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-lg-1 {
    order: 1; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-lg-2 {
    order: 2; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-lg-3 {
    order: 3; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-lg-4 {
    order: 4; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-lg-5 {
    order: 5; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-lg-6 {
    order: 6; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-lg-7 {
    order: 7; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-lg-8 {
    order: 8; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-lg-9 {
    order: 9; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-lg-10 {
    order: 10; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-lg-11 {
    order: 11; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-lg-12 {
    order: 12; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-0 {
    margin-left: 0; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-1 {
    margin-left: 8.33333%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-2 {
    margin-left: 16.66667%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-3 {
    margin-left: 25%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-4 {
    margin-left: 33.33333%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-5 {
    margin-left: 41.66667%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-6 {
    margin-left: 50%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-7 {
    margin-left: 58.33333%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-8 {
    margin-left: 66.66667%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-9 {
    margin-left: 75%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-10 {
    margin-left: 83.33333%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  /* line 31, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 36, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 43, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 48, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-xl-first {
    order: -1; }
  /* line 50, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-xl-last {
    order: 13; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-xl-0 {
    order: 0; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-xl-1 {
    order: 1; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-xl-2 {
    order: 2; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-xl-3 {
    order: 3; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-xl-4 {
    order: 4; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-xl-5 {
    order: 5; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-xl-6 {
    order: 6; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-xl-7 {
    order: 7; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-xl-8 {
    order: 8; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-xl-9 {
    order: 9; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-xl-10 {
    order: 10; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-xl-11 {
    order: 11; }
  /* line 53, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .order-xl-12 {
    order: 12; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-0 {
    margin-left: 0; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-1 {
    margin-left: 8.33333%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-2 {
    margin-left: 16.66667%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-3 {
    margin-left: 25%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-4 {
    margin-left: 33.33333%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-5 {
    margin-left: 41.66667%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-6 {
    margin-left: 50%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-7 {
    margin-left: 58.33333%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-8 {
    margin-left: 66.66667%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-9 {
    margin-left: 75%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-10 {
    margin-left: 83.33333%; }
  /* line 59, src/assets/scss/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-11 {
    margin-left: 91.66667%; } }

/* line 5, src/assets/scss/bootstrap/_tables.scss */
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  /* line 10, src/assets/scss/bootstrap/_tables.scss */
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  /* line 17, src/assets/scss/bootstrap/_tables.scss */
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  /* line 22, src/assets/scss/bootstrap/_tables.scss */
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  /* line 26, src/assets/scss/bootstrap/_tables.scss */
  .table .table {
    background-color: #fff; }

/* line 37, src/assets/scss/bootstrap/_tables.scss */
.table-sm th,
.table-sm td {
  padding: 0.3rem; }

/* line 48, src/assets/scss/bootstrap/_tables.scss */
.table-bordered {
  border: 1px solid #dee2e6; }
  /* line 51, src/assets/scss/bootstrap/_tables.scss */
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  /* line 57, src/assets/scss/bootstrap/_tables.scss */
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

/* line 65, src/assets/scss/bootstrap/_tables.scss */
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

/* line 78, src/assets/scss/bootstrap/_tables.scss */
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

/* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

/* line 7, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

/* line 14, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

/* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  /* line 32, src/assets/scss/bootstrap/mixins/_table-row.scss */
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

/* line 7, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

/* line 14, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

/* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  /* line 32, src/assets/scss/bootstrap/mixins/_table-row.scss */
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

/* line 7, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

/* line 14, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

/* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  /* line 32, src/assets/scss/bootstrap/mixins/_table-row.scss */
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

/* line 7, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

/* line 14, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

/* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
.table-hover .table-info:hover {
  background-color: #abdde5; }
  /* line 32, src/assets/scss/bootstrap/mixins/_table-row.scss */
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

/* line 7, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

/* line 14, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

/* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  /* line 32, src/assets/scss/bootstrap/mixins/_table-row.scss */
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

/* line 7, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

/* line 14, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

/* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  /* line 32, src/assets/scss/bootstrap/mixins/_table-row.scss */
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

/* line 7, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

/* line 14, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

/* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
.table-hover .table-light:hover {
  background-color: #ececf6; }
  /* line 32, src/assets/scss/bootstrap/mixins/_table-row.scss */
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

/* line 7, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

/* line 14, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

/* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  /* line 32, src/assets/scss/bootstrap/mixins/_table-row.scss */
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

/* line 7, src/assets/scss/bootstrap/mixins/_table-row.scss */
.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

/* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  /* line 32, src/assets/scss/bootstrap/mixins/_table-row.scss */
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

/* line 116, src/assets/scss/bootstrap/_tables.scss */
.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

/* line 124, src/assets/scss/bootstrap/_tables.scss */
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

/* line 132, src/assets/scss/bootstrap/_tables.scss */
.table-dark {
  color: #fff;
  background-color: #212529; }
  /* line 136, src/assets/scss/bootstrap/_tables.scss */
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  /* line 142, src/assets/scss/bootstrap/_tables.scss */
  .table-dark.table-bordered {
    border: 0; }
  /* line 147, src/assets/scss/bootstrap/_tables.scss */
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  /* line 172, src/assets/scss/bootstrap/_tables.scss */
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    /* line 181, src/assets/scss/bootstrap/_tables.scss */
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  /* line 172, src/assets/scss/bootstrap/_tables.scss */
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    /* line 181, src/assets/scss/bootstrap/_tables.scss */
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  /* line 172, src/assets/scss/bootstrap/_tables.scss */
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    /* line 181, src/assets/scss/bootstrap/_tables.scss */
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  /* line 172, src/assets/scss/bootstrap/_tables.scss */
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    /* line 181, src/assets/scss/bootstrap/_tables.scss */
    .table-responsive-xl > .table-bordered {
      border: 0; } }

/* line 172, src/assets/scss/bootstrap/_tables.scss */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  /* line 181, src/assets/scss/bootstrap/_tables.scss */
  .table-responsive > .table-bordered {
    border: 0; }

/* line 7, src/assets/scss/bootstrap/_forms.scss */
.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    /* line 7, src/assets/scss/bootstrap/_forms.scss */
    .form-control {
      transition: none; } }
  /* line 33, src/assets/scss/bootstrap/_forms.scss */
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  /* line 14, src/assets/scss/bootstrap/mixins/_forms.scss */
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  /* line 42, src/assets/scss/bootstrap/_forms.scss */
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  /* line 53, src/assets/scss/bootstrap/_forms.scss */
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

/* line 62, src/assets/scss/bootstrap/_forms.scss */
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

/* line 74, src/assets/scss/bootstrap/_forms.scss */
.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

/* line 87, src/assets/scss/bootstrap/_forms.scss */
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

/* line 95, src/assets/scss/bootstrap/_forms.scss */
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

/* line 102, src/assets/scss/bootstrap/_forms.scss */
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

/* line 115, src/assets/scss/bootstrap/_forms.scss */
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  /* line 127, src/assets/scss/bootstrap/_forms.scss */
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

/* line 142, src/assets/scss/bootstrap/_forms.scss */
.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

/* line 150, src/assets/scss/bootstrap/_forms.scss */
.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

/* line 160, src/assets/scss/bootstrap/_forms.scss */
select.form-control[size], select.form-control[multiple] {
  height: auto; }

/* line 167, src/assets/scss/bootstrap/_forms.scss */
textarea.form-control {
  height: auto; }

/* line 176, src/assets/scss/bootstrap/_forms.scss */
.form-group {
  margin-bottom: 1rem; }

/* line 180, src/assets/scss/bootstrap/_forms.scss */
.form-text {
  display: block;
  margin-top: 0.25rem; }

/* line 190, src/assets/scss/bootstrap/_forms.scss */
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  /* line 196, src/assets/scss/bootstrap/_forms.scss */
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

/* line 208, src/assets/scss/bootstrap/_forms.scss */
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

/* line 214, src/assets/scss/bootstrap/_forms.scss */
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  /* line 219, src/assets/scss/bootstrap/_forms.scss */
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

/* line 224, src/assets/scss/bootstrap/_forms.scss */
.form-check-label {
  margin-bottom: 0; }

/* line 228, src/assets/scss/bootstrap/_forms.scss */
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  /* line 235, src/assets/scss/bootstrap/_forms.scss */
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

/* line 30, src/assets/scss/bootstrap/mixins/_forms.scss */
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

/* line 38, src/assets/scss/bootstrap/mixins/_forms.scss */
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

/* line 54, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); }
  /* line 71, src/assets/scss/bootstrap/mixins/_forms.scss */
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  /* line 76, src/assets/scss/bootstrap/mixins/_forms.scss */
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

/* line 85, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4); }

/* line 95, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") no-repeat center right 1.75rem/1.125rem 1.125rem; }
  /* line 105, src/assets/scss/bootstrap/mixins/_forms.scss */
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  /* line 110, src/assets/scss/bootstrap/mixins/_forms.scss */
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

/* line 121, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

/* line 131, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

/* line 135, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

/* line 145, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  /* line 148, src/assets/scss/bootstrap/mixins/_forms.scss */
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

/* line 153, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

/* line 159, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

/* line 166, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

/* line 170, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

/* line 181, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

/* line 185, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

/* line 191, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

/* line 30, src/assets/scss/bootstrap/mixins/_forms.scss */
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

/* line 38, src/assets/scss/bootstrap/mixins/_forms.scss */
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

/* line 54, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"); }
  /* line 71, src/assets/scss/bootstrap/mixins/_forms.scss */
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  /* line 76, src/assets/scss/bootstrap/mixins/_forms.scss */
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

/* line 85, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4); }

/* line 95, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") no-repeat center right 1.75rem/1.125rem 1.125rem; }
  /* line 105, src/assets/scss/bootstrap/mixins/_forms.scss */
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  /* line 110, src/assets/scss/bootstrap/mixins/_forms.scss */
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

/* line 121, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

/* line 131, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

/* line 135, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

/* line 145, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  /* line 148, src/assets/scss/bootstrap/mixins/_forms.scss */
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

/* line 153, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

/* line 159, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

/* line 166, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

/* line 170, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

/* line 181, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

/* line 185, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

/* line 191, src/assets/scss/bootstrap/mixins/_forms.scss */
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

/* line 263, src/assets/scss/bootstrap/_forms.scss */
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  /* line 271, src/assets/scss/bootstrap/_forms.scss */
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    /* line 277, src/assets/scss/bootstrap/_forms.scss */
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    /* line 285, src/assets/scss/bootstrap/_forms.scss */
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    /* line 294, src/assets/scss/bootstrap/_forms.scss */
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    /* line 301, src/assets/scss/bootstrap/_forms.scss */
    .form-inline .form-control-plaintext {
      display: inline-block; }
    /* line 305, src/assets/scss/bootstrap/_forms.scss */
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    /* line 312, src/assets/scss/bootstrap/_forms.scss */
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    /* line 319, src/assets/scss/bootstrap/_forms.scss */
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    /* line 326, src/assets/scss/bootstrap/_forms.scss */
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    /* line 330, src/assets/scss/bootstrap/_forms.scss */
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

/* line 7, src/assets/scss/bootstrap/_buttons.scss */
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    /* line 7, src/assets/scss/bootstrap/_buttons.scss */
    .btn {
      transition: none; } }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  /* line 24, src/assets/scss/bootstrap/_buttons.scss */
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  /* line 31, src/assets/scss/bootstrap/_buttons.scss */
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  /* line 38, src/assets/scss/bootstrap/_buttons.scss */
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

/* line 53, src/assets/scss/bootstrap/_buttons.scss */
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

/* line 64, src/assets/scss/bootstrap/_buttons.scss */
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  /* line 18, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  /* line 29, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  /* line 40, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    /* line 50, src/assets/scss/bootstrap/mixins/_buttons.scss */
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

/* line 64, src/assets/scss/bootstrap/_buttons.scss */
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  /* line 18, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  /* line 29, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  /* line 40, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    /* line 50, src/assets/scss/bootstrap/mixins/_buttons.scss */
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

/* line 64, src/assets/scss/bootstrap/_buttons.scss */
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  /* line 18, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  /* line 29, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  /* line 40, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    /* line 50, src/assets/scss/bootstrap/mixins/_buttons.scss */
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

/* line 64, src/assets/scss/bootstrap/_buttons.scss */
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  /* line 18, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  /* line 29, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  /* line 40, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    /* line 50, src/assets/scss/bootstrap/mixins/_buttons.scss */
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

/* line 64, src/assets/scss/bootstrap/_buttons.scss */
.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  /* line 18, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  /* line 29, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  /* line 40, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    /* line 50, src/assets/scss/bootstrap/mixins/_buttons.scss */
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

/* line 64, src/assets/scss/bootstrap/_buttons.scss */
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  /* line 18, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  /* line 29, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  /* line 40, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    /* line 50, src/assets/scss/bootstrap/mixins/_buttons.scss */
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

/* line 64, src/assets/scss/bootstrap/_buttons.scss */
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  /* line 18, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  /* line 29, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  /* line 40, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    /* line 50, src/assets/scss/bootstrap/mixins/_buttons.scss */
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

/* line 64, src/assets/scss/bootstrap/_buttons.scss */
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  /* line 18, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  /* line 29, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  /* line 40, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    /* line 50, src/assets/scss/bootstrap/mixins/_buttons.scss */
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

/* line 70, src/assets/scss/bootstrap/_buttons.scss */
.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  /* line 71, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  /* line 76, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  /* line 82, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    /* line 89, src/assets/scss/bootstrap/mixins/_buttons.scss */
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

/* line 70, src/assets/scss/bootstrap/_buttons.scss */
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  /* line 71, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  /* line 76, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  /* line 82, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    /* line 89, src/assets/scss/bootstrap/mixins/_buttons.scss */
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

/* line 70, src/assets/scss/bootstrap/_buttons.scss */
.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  /* line 71, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  /* line 76, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  /* line 82, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    /* line 89, src/assets/scss/bootstrap/mixins/_buttons.scss */
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

/* line 70, src/assets/scss/bootstrap/_buttons.scss */
.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  /* line 71, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  /* line 76, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  /* line 82, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    /* line 89, src/assets/scss/bootstrap/mixins/_buttons.scss */
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

/* line 70, src/assets/scss/bootstrap/_buttons.scss */
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  /* line 71, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  /* line 76, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  /* line 82, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    /* line 89, src/assets/scss/bootstrap/mixins/_buttons.scss */
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

/* line 70, src/assets/scss/bootstrap/_buttons.scss */
.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  /* line 71, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  /* line 76, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  /* line 82, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    /* line 89, src/assets/scss/bootstrap/mixins/_buttons.scss */
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

/* line 70, src/assets/scss/bootstrap/_buttons.scss */
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  /* line 71, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  /* line 76, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  /* line 82, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    /* line 89, src/assets/scss/bootstrap/mixins/_buttons.scss */
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

/* line 70, src/assets/scss/bootstrap/_buttons.scss */
.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  /* line 71, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  /* line 76, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  /* line 82, src/assets/scss/bootstrap/mixins/_buttons.scss */
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    /* line 89, src/assets/scss/bootstrap/mixins/_buttons.scss */
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

/* line 81, src/assets/scss/bootstrap/_buttons.scss */
.btn-link {
  font-weight: 400;
  color: #007bff; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  /* line 90, src/assets/scss/bootstrap/_buttons.scss */
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  /* line 96, src/assets/scss/bootstrap/_buttons.scss */
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

/* line 110, src/assets/scss/bootstrap/_buttons.scss */
.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

/* line 114, src/assets/scss/bootstrap/_buttons.scss */
.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

/* line 123, src/assets/scss/bootstrap/_buttons.scss */
.btn-block {
  display: block;
  width: 100%; }
  /* line 128, src/assets/scss/bootstrap/_buttons.scss */
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

/* line 137, src/assets/scss/bootstrap/_buttons.scss */
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

/* line 3, src/assets/scss/bootstrap/_transitions.scss */
.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    /* line 3, src/assets/scss/bootstrap/_transitions.scss */
    .fade {
      transition: none; } }
  /* line 6, src/assets/scss/bootstrap/_transitions.scss */
  .fade:not(.show) {
    opacity: 0; }

/* line 12, src/assets/scss/bootstrap/_transitions.scss */
.collapse:not(.show) {
  display: none; }

/* line 17, src/assets/scss/bootstrap/_transitions.scss */
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    /* line 17, src/assets/scss/bootstrap/_transitions.scss */
    .collapsing {
      transition: none; } }

/* line 2, src/assets/scss/bootstrap/_dropdown.scss */
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

/* line 30, src/assets/scss/bootstrap/mixins/_caret.scss */
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

/* line 58, src/assets/scss/bootstrap/mixins/_caret.scss */
.dropdown-toggle:empty::after {
  margin-left: 0; }

/* line 15, src/assets/scss/bootstrap/_dropdown.scss */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

/* line 40, src/assets/scss/bootstrap/_dropdown.scss */
.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  /* line 40, src/assets/scss/bootstrap/_dropdown.scss */
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  /* line 40, src/assets/scss/bootstrap/_dropdown.scss */
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  /* line 40, src/assets/scss/bootstrap/_dropdown.scss */
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  /* line 40, src/assets/scss/bootstrap/_dropdown.scss */
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

/* line 51, src/assets/scss/bootstrap/_dropdown.scss */
.dropdown-menu-left {
  right: auto;
  left: 0; }

@media (min-width: 576px) {
  /* line 51, src/assets/scss/bootstrap/_dropdown.scss */
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; } }

@media (min-width: 768px) {
  /* line 51, src/assets/scss/bootstrap/_dropdown.scss */
  .dropdown-menu-md-left {
    right: auto;
    left: 0; } }

@media (min-width: 992px) {
  /* line 51, src/assets/scss/bootstrap/_dropdown.scss */
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; } }

@media (min-width: 1200px) {
  /* line 51, src/assets/scss/bootstrap/_dropdown.scss */
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; } }

/* line 61, src/assets/scss/bootstrap/_dropdown.scss */
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

/* line 30, src/assets/scss/bootstrap/mixins/_caret.scss */
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

/* line 58, src/assets/scss/bootstrap/mixins/_caret.scss */
.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

/* line 74, src/assets/scss/bootstrap/_dropdown.scss */
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

/* line 30, src/assets/scss/bootstrap/mixins/_caret.scss */
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

/* line 58, src/assets/scss/bootstrap/mixins/_caret.scss */
.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

/* line 84, src/assets/scss/bootstrap/_dropdown.scss */
.dropright .dropdown-toggle::after {
  vertical-align: 0; }

/* line 91, src/assets/scss/bootstrap/_dropdown.scss */
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

/* line 30, src/assets/scss/bootstrap/mixins/_caret.scss */
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

/* line 45, src/assets/scss/bootstrap/mixins/_caret.scss */
.dropleft .dropdown-toggle::after {
  display: none; }

/* line 49, src/assets/scss/bootstrap/mixins/_caret.scss */
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

/* line 58, src/assets/scss/bootstrap/mixins/_caret.scss */
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

/* line 101, src/assets/scss/bootstrap/_dropdown.scss */
.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

/* line 110, src/assets/scss/bootstrap/_dropdown.scss */
.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

/* line 120, src/assets/scss/bootstrap/_dropdown.scss */
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

/* line 127, src/assets/scss/bootstrap/_dropdown.scss */
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  /* line 139, src/assets/scss/bootstrap/_dropdown.scss */
  .dropdown-item:first-child {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  /* line 143, src/assets/scss/bootstrap/_dropdown.scss */
  .dropdown-item:last-child {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px); }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  /* line 153, src/assets/scss/bootstrap/_dropdown.scss */
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  /* line 160, src/assets/scss/bootstrap/_dropdown.scss */
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

/* line 172, src/assets/scss/bootstrap/_dropdown.scss */
.dropdown-menu.show {
  display: block; }

/* line 177, src/assets/scss/bootstrap/_dropdown.scss */
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

/* line 187, src/assets/scss/bootstrap/_dropdown.scss */
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

/* line 4, src/assets/scss/bootstrap/_button-group.scss */
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  /* line 10, src/assets/scss/bootstrap/_button-group.scss */
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    /* line 19, src/assets/scss/bootstrap/_button-group.scss */
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

/* line 28, src/assets/scss/bootstrap/_button-group.scss */
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  /* line 33, src/assets/scss/bootstrap/_button-group.scss */
  .btn-toolbar .input-group {
    width: auto; }

/* line 40, src/assets/scss/bootstrap/_button-group.scss */
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

/* line 46, src/assets/scss/bootstrap/_button-group.scss */
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

/* line 51, src/assets/scss/bootstrap/_button-group.scss */
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

/* line 69, src/assets/scss/bootstrap/_button-group.scss */
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  /* line 73, src/assets/scss/bootstrap/_button-group.scss */
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  /* line 79, src/assets/scss/bootstrap/_button-group.scss */
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

/* line 84, src/assets/scss/bootstrap/_button-group.scss */
.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

/* line 89, src/assets/scss/bootstrap/_button-group.scss */
.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

/* line 111, src/assets/scss/bootstrap/_button-group.scss */
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  /* line 116, src/assets/scss/bootstrap/_button-group.scss */
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  /* line 121, src/assets/scss/bootstrap/_button-group.scss */
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  /* line 127, src/assets/scss/bootstrap/_button-group.scss */
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  /* line 132, src/assets/scss/bootstrap/_button-group.scss */
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

/* line 152, src/assets/scss/bootstrap/_button-group.scss */
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  /* line 156, src/assets/scss/bootstrap/_button-group.scss */
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

/* line 7, src/assets/scss/bootstrap/_input-group.scss */
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  /* line 14, src/assets/scss/bootstrap/_input-group.scss */
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    /* line 25, src/assets/scss/bootstrap/_input-group.scss */
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  /* line 33, src/assets/scss/bootstrap/_input-group.scss */
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  /* line 40, src/assets/scss/bootstrap/_input-group.scss */
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  /* line 46, src/assets/scss/bootstrap/_input-group.scss */
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  /* line 47, src/assets/scss/bootstrap/_input-group.scss */
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  /* line 52, src/assets/scss/bootstrap/_input-group.scss */
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    /* line 56, src/assets/scss/bootstrap/_input-group.scss */
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    /* line 58, src/assets/scss/bootstrap/_input-group.scss */
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

/* line 69, src/assets/scss/bootstrap/_input-group.scss */
.input-group-prepend,
.input-group-append {
  display: flex; }
  /* line 76, src/assets/scss/bootstrap/_input-group.scss */
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    /* line 80, src/assets/scss/bootstrap/_input-group.scss */
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  /* line 85, src/assets/scss/bootstrap/_input-group.scss */
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

/* line 93, src/assets/scss/bootstrap/_input-group.scss */
.input-group-prepend {
  margin-right: -1px; }

/* line 94, src/assets/scss/bootstrap/_input-group.scss */
.input-group-append {
  margin-left: -1px; }

/* line 102, src/assets/scss/bootstrap/_input-group.scss */
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  /* line 118, src/assets/scss/bootstrap/_input-group.scss */
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

/* line 130, src/assets/scss/bootstrap/_input-group.scss */
.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(2.875rem + 2px); }

/* line 135, src/assets/scss/bootstrap/_input-group.scss */
.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

/* line 147, src/assets/scss/bootstrap/_input-group.scss */
.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.8125rem + 2px); }

/* line 152, src/assets/scss/bootstrap/_input-group.scss */
.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

/* line 164, src/assets/scss/bootstrap/_input-group.scss */
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

/* line 177, src/assets/scss/bootstrap/_input-group.scss */
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

/* line 186, src/assets/scss/bootstrap/_input-group.scss */
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

/* line 10, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

/* line 17, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

/* line 22, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  /* line 27, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  /* line 34, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  /* line 43, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  /* line 47, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  /* line 55, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    /* line 58, src/assets/scss/bootstrap/_custom-forms.scss */
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

/* line 69, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  /* line 75, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  /* line 90, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

/* line 110, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

/* line 115, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

/* line 121, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

/* line 126, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

/* line 132, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

/* line 135, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

/* line 146, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-radio .custom-control-label::before {
  border-radius: 50%; }

/* line 151, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

/* line 157, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

/* line 168, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-switch {
  padding-left: 2.25rem; }
  /* line 172, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  /* line 179, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media screen and (prefers-reduced-motion: reduce) {
      /* line 179, src/assets/scss/bootstrap/_custom-forms.scss */
      .custom-switch .custom-control-label::after {
        transition: none; } }
  /* line 191, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  /* line 198, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

/* line 211, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  /* line 231, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5); }
    /* line 240, src/assets/scss/bootstrap/_custom-forms.scss */
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  /* line 251, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  /* line 258, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  /* line 264, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-select::-ms-expand {
    opacity: 0; }

/* line 269, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

/* line 277, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

/* line 290, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

/* line 298, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  /* line 306, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  /* line 311, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  /* line 316, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  /* line 321, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

/* line 326, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  /* line 342, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

/* line 366, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  /* line 373, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-range:focus {
    outline: none; }
    /* line 378, src/assets/scss/bootstrap/_custom-forms.scss */
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    /* line 379, src/assets/scss/bootstrap/_custom-forms.scss */
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    /* line 380, src/assets/scss/bootstrap/_custom-forms.scss */
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  /* line 383, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-range::-moz-focus-outer {
    border: 0; }
  /* line 387, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      /* line 387, src/assets/scss/bootstrap/_custom-forms.scss */
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    /* line 398, src/assets/scss/bootstrap/_custom-forms.scss */
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  /* line 403, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  /* line 414, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      /* line 414, src/assets/scss/bootstrap/_custom-forms.scss */
      .custom-range::-moz-range-thumb {
        transition: none; } }
    /* line 424, src/assets/scss/bootstrap/_custom-forms.scss */
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  /* line 429, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  /* line 440, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      /* line 440, src/assets/scss/bootstrap/_custom-forms.scss */
      .custom-range::-ms-thumb {
        transition: none; } }
    /* line 453, src/assets/scss/bootstrap/_custom-forms.scss */
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  /* line 458, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  /* line 469, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  /* line 474, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  /* line 481, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  /* line 485, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  /* line 489, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  /* line 493, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  /* line 497, src/assets/scss/bootstrap/_custom-forms.scss */
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

/* line 503, src/assets/scss/bootstrap/_custom-forms.scss */
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    /* line 503, src/assets/scss/bootstrap/_custom-forms.scss */
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

/* line 6, src/assets/scss/bootstrap/_nav.scss */
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

/* line 14, src/assets/scss/bootstrap/_nav.scss */
.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  /* line 23, src/assets/scss/bootstrap/_nav.scss */
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

/* line 34, src/assets/scss/bootstrap/_nav.scss */
.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  /* line 37, src/assets/scss/bootstrap/_nav.scss */
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  /* line 41, src/assets/scss/bootstrap/_nav.scss */
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    /* line 49, src/assets/scss/bootstrap/_nav.scss */
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  /* line 56, src/assets/scss/bootstrap/_nav.scss */
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  /* line 63, src/assets/scss/bootstrap/_nav.scss */
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

/* line 77, src/assets/scss/bootstrap/_nav.scss */
.nav-pills .nav-link {
  border-radius: 0.25rem; }

/* line 81, src/assets/scss/bootstrap/_nav.scss */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

/* line 94, src/assets/scss/bootstrap/_nav.scss */
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

/* line 101, src/assets/scss/bootstrap/_nav.scss */
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

/* line 114, src/assets/scss/bootstrap/_nav.scss */
.tab-content > .tab-pane {
  display: none; }

/* line 117, src/assets/scss/bootstrap/_nav.scss */
.tab-content > .active {
  display: block; }

/* line 18, src/assets/scss/bootstrap/_navbar.scss */
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  /* line 28, src/assets/scss/bootstrap/_navbar.scss */
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

/* line 42, src/assets/scss/bootstrap/_navbar.scss */
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

/* line 61, src/assets/scss/bootstrap/_navbar.scss */
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  /* line 68, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  /* line 73, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

/* line 84, src/assets/scss/bootstrap/_navbar.scss */
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

/* line 99, src/assets/scss/bootstrap/_navbar.scss */
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

/* line 108, src/assets/scss/bootstrap/_navbar.scss */
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  /* line 121, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

/* line 128, src/assets/scss/bootstrap/_navbar.scss */
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  /* line 147, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  /* line 145, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    /* line 158, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      /* line 161, src/assets/scss/bootstrap/_navbar.scss */
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      /* line 165, src/assets/scss/bootstrap/_navbar.scss */
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    /* line 172, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    /* line 177, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    /* line 184, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  /* line 147, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  /* line 145, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    /* line 158, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      /* line 161, src/assets/scss/bootstrap/_navbar.scss */
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      /* line 165, src/assets/scss/bootstrap/_navbar.scss */
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    /* line 172, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    /* line 177, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    /* line 184, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  /* line 147, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  /* line 145, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    /* line 158, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      /* line 161, src/assets/scss/bootstrap/_navbar.scss */
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      /* line 165, src/assets/scss/bootstrap/_navbar.scss */
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    /* line 172, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    /* line 177, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    /* line 184, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  /* line 147, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  /* line 145, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    /* line 158, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      /* line 161, src/assets/scss/bootstrap/_navbar.scss */
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      /* line 165, src/assets/scss/bootstrap/_navbar.scss */
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    /* line 172, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    /* line 177, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    /* line 184, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

/* line 145, src/assets/scss/bootstrap/_navbar.scss */
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  /* line 147, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  /* line 158, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    /* line 161, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    /* line 165, src/assets/scss/bootstrap/_navbar.scss */
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  /* line 172, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  /* line 177, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  /* line 184, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-expand .navbar-toggler {
    display: none; }

/* line 199, src/assets/scss/bootstrap/_navbar.scss */
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

/* line 208, src/assets/scss/bootstrap/_navbar.scss */
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  /* line 215, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

/* line 220, src/assets/scss/bootstrap/_navbar.scss */
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

/* line 228, src/assets/scss/bootstrap/_navbar.scss */
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

/* line 233, src/assets/scss/bootstrap/_navbar.scss */
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

/* line 237, src/assets/scss/bootstrap/_navbar.scss */
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  /* line 239, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

/* line 251, src/assets/scss/bootstrap/_navbar.scss */
.navbar-dark .navbar-brand {
  color: #fff; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

/* line 260, src/assets/scss/bootstrap/_navbar.scss */
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  /* line 267, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

/* line 272, src/assets/scss/bootstrap/_navbar.scss */
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

/* line 280, src/assets/scss/bootstrap/_navbar.scss */
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

/* line 285, src/assets/scss/bootstrap/_navbar.scss */
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

/* line 289, src/assets/scss/bootstrap/_navbar.scss */
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  /* line 291, src/assets/scss/bootstrap/_navbar.scss */
  .navbar-dark .navbar-text a {
    color: #fff; }
    /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

/* line 5, src/assets/scss/bootstrap/_card.scss */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  /* line 16, src/assets/scss/bootstrap/_card.scss */
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  /* line 22, src/assets/scss/bootstrap/_card.scss */
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  /* line 28, src/assets/scss/bootstrap/_card.scss */
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

/* line 34, src/assets/scss/bootstrap/_card.scss */
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

/* line 41, src/assets/scss/bootstrap/_card.scss */
.card-title {
  margin-bottom: 0.75rem; }

/* line 45, src/assets/scss/bootstrap/_card.scss */
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

/* line 50, src/assets/scss/bootstrap/_card.scss */
.card-text:last-child {
  margin-bottom: 0; }

/* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
.card-link:hover {
  text-decoration: none; }

/* line 59, src/assets/scss/bootstrap/_card.scss */
.card-link + .card-link {
  margin-left: 1.25rem; }

/* line 68, src/assets/scss/bootstrap/_card.scss */
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  /* line 75, src/assets/scss/bootstrap/_card.scss */
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  /* line 80, src/assets/scss/bootstrap/_card.scss */
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

/* line 86, src/assets/scss/bootstrap/_card.scss */
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  /* line 91, src/assets/scss/bootstrap/_card.scss */
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

/* line 101, src/assets/scss/bootstrap/_card.scss */
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

/* line 108, src/assets/scss/bootstrap/_card.scss */
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

/* line 114, src/assets/scss/bootstrap/_card.scss */
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

/* line 123, src/assets/scss/bootstrap/_card.scss */
.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

/* line 129, src/assets/scss/bootstrap/_card.scss */
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

/* line 134, src/assets/scss/bootstrap/_card.scss */
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

/* line 142, src/assets/scss/bootstrap/_card.scss */
.card-deck {
  display: flex;
  flex-direction: column; }
  /* line 146, src/assets/scss/bootstrap/_card.scss */
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    /* line 142, src/assets/scss/bootstrap/_card.scss */
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      /* line 155, src/assets/scss/bootstrap/_card.scss */
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

/* line 172, src/assets/scss/bootstrap/_card.scss */
.card-group {
  display: flex;
  flex-direction: column; }
  /* line 178, src/assets/scss/bootstrap/_card.scss */
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    /* line 172, src/assets/scss/bootstrap/_card.scss */
    .card-group {
      flex-flow: row wrap; }
      /* line 186, src/assets/scss/bootstrap/_card.scss */
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        /* line 191, src/assets/scss/bootstrap/_card.scss */
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        /* line 198, src/assets/scss/bootstrap/_card.scss */
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          /* line 201, src/assets/scss/bootstrap/_card.scss */
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          /* line 205, src/assets/scss/bootstrap/_card.scss */
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        /* line 211, src/assets/scss/bootstrap/_card.scss */
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          /* line 214, src/assets/scss/bootstrap/_card.scss */
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          /* line 218, src/assets/scss/bootstrap/_card.scss */
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        /* line 224, src/assets/scss/bootstrap/_card.scss */
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          /* line 227, src/assets/scss/bootstrap/_card.scss */
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          /* line 231, src/assets/scss/bootstrap/_card.scss */
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        /* line 237, src/assets/scss/bootstrap/_card.scss */
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          /* line 240, src/assets/scss/bootstrap/_card.scss */
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

/* line 258, src/assets/scss/bootstrap/_card.scss */
.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  /* line 257, src/assets/scss/bootstrap/_card.scss */
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    /* line 268, src/assets/scss/bootstrap/_card.scss */
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

/* line 281, src/assets/scss/bootstrap/_card.scss */
.accordion .card {
  overflow: hidden; }
  /* line 285, src/assets/scss/bootstrap/_card.scss */
  .accordion .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  /* line 289, src/assets/scss/bootstrap/_card.scss */
  .accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  /* line 295, src/assets/scss/bootstrap/_card.scss */
  .accordion .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  /* line 301, src/assets/scss/bootstrap/_card.scss */
  .accordion .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  /* line 306, src/assets/scss/bootstrap/_card.scss */
  .accordion .card .card-header {
    margin-bottom: -1px; }

/* line 1, src/assets/scss/bootstrap/_breadcrumb.scss */
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

/* line 13, src/assets/scss/bootstrap/_breadcrumb.scss */
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  /* line 16, src/assets/scss/bootstrap/_breadcrumb.scss */
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

/* line 30, src/assets/scss/bootstrap/_breadcrumb.scss */
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

/* line 34, src/assets/scss/bootstrap/_breadcrumb.scss */
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

/* line 38, src/assets/scss/bootstrap/_breadcrumb.scss */
.breadcrumb-item.active {
  color: #6c757d; }

/* line 1, src/assets/scss/bootstrap/_pagination.scss */
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

/* line 7, src/assets/scss/bootstrap/_pagination.scss */
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  /* line 17, src/assets/scss/bootstrap/_pagination.scss */
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  /* line 25, src/assets/scss/bootstrap/_pagination.scss */
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  /* line 32, src/assets/scss/bootstrap/_pagination.scss */
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

/* line 39, src/assets/scss/bootstrap/_pagination.scss */
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

/* line 45, src/assets/scss/bootstrap/_pagination.scss */
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

/* line 50, src/assets/scss/bootstrap/_pagination.scss */
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

/* line 57, src/assets/scss/bootstrap/_pagination.scss */
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

/* line 4, src/assets/scss/bootstrap/mixins/_pagination.scss */
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

/* line 12, src/assets/scss/bootstrap/mixins/_pagination.scss */
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

/* line 17, src/assets/scss/bootstrap/mixins/_pagination.scss */
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

/* line 4, src/assets/scss/bootstrap/mixins/_pagination.scss */
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

/* line 12, src/assets/scss/bootstrap/mixins/_pagination.scss */
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

/* line 17, src/assets/scss/bootstrap/mixins/_pagination.scss */
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

/* line 6, src/assets/scss/bootstrap/_badge.scss */
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  /* line 24, src/assets/scss/bootstrap/_badge.scss */
  .badge:empty {
    display: none; }

/* line 30, src/assets/scss/bootstrap/_badge.scss */
.btn .badge {
  position: relative;
  top: -1px; }

/* line 39, src/assets/scss/bootstrap/_badge.scss */
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

/* line 50, src/assets/scss/bootstrap/_badge.scss */
.badge-primary {
  color: #fff;
  background-color: #007bff; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }

/* line 50, src/assets/scss/bootstrap/_badge.scss */
.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }

/* line 50, src/assets/scss/bootstrap/_badge.scss */
.badge-success {
  color: #fff;
  background-color: #28a745; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }

/* line 50, src/assets/scss/bootstrap/_badge.scss */
.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }

/* line 50, src/assets/scss/bootstrap/_badge.scss */
.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }

/* line 50, src/assets/scss/bootstrap/_badge.scss */
.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }

/* line 50, src/assets/scss/bootstrap/_badge.scss */
.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }

/* line 50, src/assets/scss/bootstrap/_badge.scss */
.badge-dark {
  color: #fff;
  background-color: #343a40; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }

/* line 1, src/assets/scss/bootstrap/_jumbotron.scss */
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    /* line 1, src/assets/scss/bootstrap/_jumbotron.scss */
    .jumbotron {
      padding: 4rem 2rem; } }

/* line 12, src/assets/scss/bootstrap/_jumbotron.scss */
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

/* line 5, src/assets/scss/bootstrap/_alert.scss */
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

/* line 14, src/assets/scss/bootstrap/_alert.scss */
.alert-heading {
  color: inherit; }

/* line 20, src/assets/scss/bootstrap/_alert.scss */
.alert-link {
  font-weight: 700; }

/* line 29, src/assets/scss/bootstrap/_alert.scss */
.alert-dismissible {
  padding-right: 4rem; }
  /* line 33, src/assets/scss/bootstrap/_alert.scss */
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

/* line 48, src/assets/scss/bootstrap/_alert.scss */
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  /* line 6, src/assets/scss/bootstrap/mixins/_alert.scss */
  .alert-primary hr {
    border-top-color: #9fcdff; }
  /* line 10, src/assets/scss/bootstrap/mixins/_alert.scss */
  .alert-primary .alert-link {
    color: #002752; }

/* line 48, src/assets/scss/bootstrap/_alert.scss */
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  /* line 6, src/assets/scss/bootstrap/mixins/_alert.scss */
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  /* line 10, src/assets/scss/bootstrap/mixins/_alert.scss */
  .alert-secondary .alert-link {
    color: #202326; }

/* line 48, src/assets/scss/bootstrap/_alert.scss */
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  /* line 6, src/assets/scss/bootstrap/mixins/_alert.scss */
  .alert-success hr {
    border-top-color: #b1dfbb; }
  /* line 10, src/assets/scss/bootstrap/mixins/_alert.scss */
  .alert-success .alert-link {
    color: #0b2e13; }

/* line 48, src/assets/scss/bootstrap/_alert.scss */
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  /* line 6, src/assets/scss/bootstrap/mixins/_alert.scss */
  .alert-info hr {
    border-top-color: #abdde5; }
  /* line 10, src/assets/scss/bootstrap/mixins/_alert.scss */
  .alert-info .alert-link {
    color: #062c33; }

/* line 48, src/assets/scss/bootstrap/_alert.scss */
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  /* line 6, src/assets/scss/bootstrap/mixins/_alert.scss */
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  /* line 10, src/assets/scss/bootstrap/mixins/_alert.scss */
  .alert-warning .alert-link {
    color: #533f03; }

/* line 48, src/assets/scss/bootstrap/_alert.scss */
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  /* line 6, src/assets/scss/bootstrap/mixins/_alert.scss */
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  /* line 10, src/assets/scss/bootstrap/mixins/_alert.scss */
  .alert-danger .alert-link {
    color: #491217; }

/* line 48, src/assets/scss/bootstrap/_alert.scss */
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  /* line 6, src/assets/scss/bootstrap/mixins/_alert.scss */
  .alert-light hr {
    border-top-color: #ececf6; }
  /* line 10, src/assets/scss/bootstrap/mixins/_alert.scss */
  .alert-light .alert-link {
    color: #686868; }

/* line 48, src/assets/scss/bootstrap/_alert.scss */
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  /* line 6, src/assets/scss/bootstrap/mixins/_alert.scss */
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  /* line 10, src/assets/scss/bootstrap/mixins/_alert.scss */
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

/* line 6, src/assets/scss/bootstrap/_progress.scss */
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

/* line 16, src/assets/scss/bootstrap/_progress.scss */
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    /* line 16, src/assets/scss/bootstrap/_progress.scss */
    .progress-bar {
      transition: none; } }

/* line 27, src/assets/scss/bootstrap/_progress.scss */
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

/* line 32, src/assets/scss/bootstrap/_progress.scss */
.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

/* line 1, src/assets/scss/bootstrap/_media.scss */
.media {
  display: flex;
  align-items: flex-start; }

/* line 6, src/assets/scss/bootstrap/_media.scss */
.media-body {
  flex: 1; }

/* line 5, src/assets/scss/bootstrap/_list-group.scss */
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

/* line 20, src/assets/scss/bootstrap/_list-group.scss */
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  /* line 32, src/assets/scss/bootstrap/_list-group.scss */
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

/* line 43, src/assets/scss/bootstrap/_list-group.scss */
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  /* line 52, src/assets/scss/bootstrap/_list-group.scss */
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  /* line 56, src/assets/scss/bootstrap/_list-group.scss */
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  /* line 66, src/assets/scss/bootstrap/_list-group.scss */
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  /* line 74, src/assets/scss/bootstrap/_list-group.scss */
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

/* line 89, src/assets/scss/bootstrap/_list-group.scss */
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  /* line 94, src/assets/scss/bootstrap/_list-group.scss */
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

/* line 100, src/assets/scss/bootstrap/_list-group.scss */
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

/* line 106, src/assets/scss/bootstrap/_list-group.scss */
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

/* line 4, src/assets/scss/bootstrap/mixins/_list-group.scss */
.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  /* line 14, src/assets/scss/bootstrap/mixins/_list-group.scss */
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

/* line 4, src/assets/scss/bootstrap/mixins/_list-group.scss */
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  /* line 14, src/assets/scss/bootstrap/mixins/_list-group.scss */
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

/* line 4, src/assets/scss/bootstrap/mixins/_list-group.scss */
.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  /* line 14, src/assets/scss/bootstrap/mixins/_list-group.scss */
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

/* line 4, src/assets/scss/bootstrap/mixins/_list-group.scss */
.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  /* line 14, src/assets/scss/bootstrap/mixins/_list-group.scss */
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

/* line 4, src/assets/scss/bootstrap/mixins/_list-group.scss */
.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  /* line 14, src/assets/scss/bootstrap/mixins/_list-group.scss */
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

/* line 4, src/assets/scss/bootstrap/mixins/_list-group.scss */
.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  /* line 14, src/assets/scss/bootstrap/mixins/_list-group.scss */
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

/* line 4, src/assets/scss/bootstrap/mixins/_list-group.scss */
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  /* line 14, src/assets/scss/bootstrap/mixins/_list-group.scss */
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

/* line 4, src/assets/scss/bootstrap/mixins/_list-group.scss */
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  /* line 14, src/assets/scss/bootstrap/mixins/_list-group.scss */
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

/* line 1, src/assets/scss/bootstrap/_close.scss */
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  /* line 13, src/assets/scss/bootstrap/mixins/_hover.scss */
  .close:hover {
    color: #000;
    text-decoration: none; }
  /* line 16, src/assets/scss/bootstrap/_close.scss */
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }
    /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      opacity: .75; }

/* line 32, src/assets/scss/bootstrap/_close.scss */
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

/* line 42, src/assets/scss/bootstrap/_close.scss */
a.close.disabled {
  pointer-events: none; }

/* line 1, src/assets/scss/bootstrap/_toasts.scss */
.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0; }
  /* line 13, src/assets/scss/bootstrap/_toasts.scss */
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  /* line 17, src/assets/scss/bootstrap/_toasts.scss */
  .toast.showing {
    opacity: 1; }
  /* line 21, src/assets/scss/bootstrap/_toasts.scss */
  .toast.show {
    display: block;
    opacity: 1; }
  /* line 26, src/assets/scss/bootstrap/_toasts.scss */
  .toast.hide {
    display: none; }

/* line 31, src/assets/scss/bootstrap/_toasts.scss */
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

/* line 41, src/assets/scss/bootstrap/_toasts.scss */
.toast-body {
  padding: 0.75rem; }

/* line 7, src/assets/scss/bootstrap/_modal.scss */
.modal-open {
  overflow: hidden; }
  /* line 11, src/assets/scss/bootstrap/_modal.scss */
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

/* line 18, src/assets/scss/bootstrap/_modal.scss */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

/* line 36, src/assets/scss/bootstrap/_modal.scss */
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  /* line 44, src/assets/scss/bootstrap/_modal.scss */
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media screen and (prefers-reduced-motion: reduce) {
      /* line 44, src/assets/scss/bootstrap/_modal.scss */
      .modal.fade .modal-dialog {
        transition: none; } }
  /* line 48, src/assets/scss/bootstrap/_modal.scss */
  .modal.show .modal-dialog {
    transform: none; }

/* line 53, src/assets/scss/bootstrap/_modal.scss */
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }
  /* line 59, src/assets/scss/bootstrap/_modal.scss */
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: ""; }

/* line 67, src/assets/scss/bootstrap/_modal.scss */
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

/* line 84, src/assets/scss/bootstrap/_modal.scss */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  /* line 94, src/assets/scss/bootstrap/_modal.scss */
  .modal-backdrop.fade {
    opacity: 0; }
  /* line 95, src/assets/scss/bootstrap/_modal.scss */
  .modal-backdrop.show {
    opacity: 0.5; }

/* line 100, src/assets/scss/bootstrap/_modal.scss */
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  /* line 108, src/assets/scss/bootstrap/_modal.scss */
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

/* line 116, src/assets/scss/bootstrap/_modal.scss */
.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

/* line 123, src/assets/scss/bootstrap/_modal.scss */
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

/* line 132, src/assets/scss/bootstrap/_modal.scss */
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  /* line 141, src/assets/scss/bootstrap/_modal.scss */
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  /* line 142, src/assets/scss/bootstrap/_modal.scss */
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

/* line 146, src/assets/scss/bootstrap/_modal.scss */
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  /* line 157, src/assets/scss/bootstrap/_modal.scss */
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  /* line 162, src/assets/scss/bootstrap/_modal.scss */
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
    /* line 165, src/assets/scss/bootstrap/_modal.scss */
    .modal-dialog-centered::before {
      height: calc(100vh - (1.75rem * 2)); }
  /* line 174, src/assets/scss/bootstrap/_modal.scss */
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  /* line 178, src/assets/scss/bootstrap/_modal.scss */
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  /* line 185, src/assets/scss/bootstrap/_modal.scss */
  .modal-xl {
    max-width: 1140px; } }

/* line 2, src/assets/scss/bootstrap/_tooltip.scss */
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  /* line 15, src/assets/scss/bootstrap/_tooltip.scss */
  .tooltip.show {
    opacity: 0.9; }
  /* line 17, src/assets/scss/bootstrap/_tooltip.scss */
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    /* line 23, src/assets/scss/bootstrap/_tooltip.scss */
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

/* line 32, src/assets/scss/bootstrap/_tooltip.scss */
.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  /* line 35, src/assets/scss/bootstrap/_tooltip.scss */
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    /* line 38, src/assets/scss/bootstrap/_tooltip.scss */
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

/* line 46, src/assets/scss/bootstrap/_tooltip.scss */
.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  /* line 49, src/assets/scss/bootstrap/_tooltip.scss */
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    /* line 54, src/assets/scss/bootstrap/_tooltip.scss */
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

/* line 62, src/assets/scss/bootstrap/_tooltip.scss */
.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  /* line 65, src/assets/scss/bootstrap/_tooltip.scss */
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    /* line 68, src/assets/scss/bootstrap/_tooltip.scss */
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

/* line 76, src/assets/scss/bootstrap/_tooltip.scss */
.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  /* line 79, src/assets/scss/bootstrap/_tooltip.scss */
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    /* line 84, src/assets/scss/bootstrap/_tooltip.scss */
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

/* line 108, src/assets/scss/bootstrap/_tooltip.scss */
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

/* line 1, src/assets/scss/bootstrap/_popover.scss */
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  /* line 20, src/assets/scss/bootstrap/_popover.scss */
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    /* line 27, src/assets/scss/bootstrap/_popover.scss */
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

/* line 38, src/assets/scss/bootstrap/_popover.scss */
.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  /* line 41, src/assets/scss/bootstrap/_popover.scss */
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  /* line 45, src/assets/scss/bootstrap/_popover.scss */
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  /* line 50, src/assets/scss/bootstrap/_popover.scss */
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  /* line 55, src/assets/scss/bootstrap/_popover.scss */
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

/* line 61, src/assets/scss/bootstrap/_popover.scss */
.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  /* line 64, src/assets/scss/bootstrap/_popover.scss */
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  /* line 71, src/assets/scss/bootstrap/_popover.scss */
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  /* line 76, src/assets/scss/bootstrap/_popover.scss */
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  /* line 81, src/assets/scss/bootstrap/_popover.scss */
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

/* line 87, src/assets/scss/bootstrap/_popover.scss */
.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  /* line 90, src/assets/scss/bootstrap/_popover.scss */
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  /* line 94, src/assets/scss/bootstrap/_popover.scss */
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  /* line 99, src/assets/scss/bootstrap/_popover.scss */
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  /* line 104, src/assets/scss/bootstrap/_popover.scss */
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  /* line 110, src/assets/scss/bootstrap/_popover.scss */
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

/* line 122, src/assets/scss/bootstrap/_popover.scss */
.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  /* line 125, src/assets/scss/bootstrap/_popover.scss */
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  /* line 132, src/assets/scss/bootstrap/_popover.scss */
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  /* line 137, src/assets/scss/bootstrap/_popover.scss */
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  /* line 142, src/assets/scss/bootstrap/_popover.scss */
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

/* line 165, src/assets/scss/bootstrap/_popover.scss */
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  /* line 175, src/assets/scss/bootstrap/_popover.scss */
  .popover-header:empty {
    display: none; }

/* line 180, src/assets/scss/bootstrap/_popover.scss */
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

/* line 14, src/assets/scss/bootstrap/_carousel.scss */
.carousel {
  position: relative; }

/* line 18, src/assets/scss/bootstrap/_carousel.scss */
.carousel.pointer-event {
  touch-action: pan-y; }

/* line 22, src/assets/scss/bootstrap/_carousel.scss */
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  /* line 2, src/assets/scss/bootstrap/mixins/_clearfix.scss */
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

/* line 29, src/assets/scss/bootstrap/_carousel.scss */
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    /* line 29, src/assets/scss/bootstrap/_carousel.scss */
    .carousel-item {
      transition: none; } }

/* line 39, src/assets/scss/bootstrap/_carousel.scss */
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* line 45, src/assets/scss/bootstrap/_carousel.scss */
.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

/* line 50, src/assets/scss/bootstrap/_carousel.scss */
.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

/* line 61, src/assets/scss/bootstrap/_carousel.scss */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

/* line 67, src/assets/scss/bootstrap/_carousel.scss */
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

/* line 74, src/assets/scss/bootstrap/_carousel.scss */
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media screen and (prefers-reduced-motion: reduce) {
    /* line 74, src/assets/scss/bootstrap/_carousel.scss */
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

/* line 87, src/assets/scss/bootstrap/_carousel.scss */
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    /* line 87, src/assets/scss/bootstrap/_carousel.scss */
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  /* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

/* line 111, src/assets/scss/bootstrap/_carousel.scss */
.carousel-control-prev {
  left: 0; }

/* line 117, src/assets/scss/bootstrap/_carousel.scss */
.carousel-control-next {
  right: 0; }

/* line 125, src/assets/scss/bootstrap/_carousel.scss */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

/* line 133, src/assets/scss/bootstrap/_carousel.scss */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

/* line 136, src/assets/scss/bootstrap/_carousel.scss */
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

/* line 146, src/assets/scss/bootstrap/_carousel.scss */
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  /* line 160, src/assets/scss/bootstrap/_carousel.scss */
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media screen and (prefers-reduced-motion: reduce) {
      /* line 160, src/assets/scss/bootstrap/_carousel.scss */
      .carousel-indicators li {
        transition: none; } }
  /* line 178, src/assets/scss/bootstrap/_carousel.scss */
  .carousel-indicators .active {
    opacity: 1; }

/* line 188, src/assets/scss/bootstrap/_carousel.scss */
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

/* line 9, src/assets/scss/bootstrap/_spinners.scss */
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

/* line 20, src/assets/scss/bootstrap/_spinners.scss */
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

/* line 39, src/assets/scss/bootstrap/_spinners.scss */
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

/* line 50, src/assets/scss/bootstrap/_spinners.scss */
.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

/* line 3, src/assets/scss/bootstrap/utilities/_align.scss */
.align-baseline {
  vertical-align: baseline !important; }

/* line 4, src/assets/scss/bootstrap/utilities/_align.scss */
.align-top {
  vertical-align: top !important; }

/* line 5, src/assets/scss/bootstrap/utilities/_align.scss */
.align-middle {
  vertical-align: middle !important; }

/* line 6, src/assets/scss/bootstrap/utilities/_align.scss */
.align-bottom {
  vertical-align: bottom !important; }

/* line 7, src/assets/scss/bootstrap/utilities/_align.scss */
.align-text-bottom {
  vertical-align: text-bottom !important; }

/* line 8, src/assets/scss/bootstrap/utilities/_align.scss */
.align-text-top {
  vertical-align: text-top !important; }

/* line 6, src/assets/scss/bootstrap/mixins/_background-variant.scss */
.bg-primary {
  background-color: #007bff !important; }

/* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

/* line 6, src/assets/scss/bootstrap/mixins/_background-variant.scss */
.bg-secondary {
  background-color: #6c757d !important; }

/* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

/* line 6, src/assets/scss/bootstrap/mixins/_background-variant.scss */
.bg-success {
  background-color: #28a745 !important; }

/* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

/* line 6, src/assets/scss/bootstrap/mixins/_background-variant.scss */
.bg-info {
  background-color: #17a2b8 !important; }

/* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

/* line 6, src/assets/scss/bootstrap/mixins/_background-variant.scss */
.bg-warning {
  background-color: #ffc107 !important; }

/* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

/* line 6, src/assets/scss/bootstrap/mixins/_background-variant.scss */
.bg-danger {
  background-color: #dc3545 !important; }

/* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

/* line 6, src/assets/scss/bootstrap/mixins/_background-variant.scss */
.bg-light {
  background-color: #f8f9fa !important; }

/* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

/* line 6, src/assets/scss/bootstrap/mixins/_background-variant.scss */
.bg-dark {
  background-color: #343a40 !important; }

/* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

/* line 13, src/assets/scss/bootstrap/utilities/_background.scss */
.bg-white {
  background-color: #fff !important; }

/* line 17, src/assets/scss/bootstrap/utilities/_background.scss */
.bg-transparent {
  background-color: transparent !important; }

/* line 7, src/assets/scss/bootstrap/utilities/_borders.scss */
.border {
  border: 1px solid #dee2e6 !important; }

/* line 8, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-top {
  border-top: 1px solid #dee2e6 !important; }

/* line 9, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-right {
  border-right: 1px solid #dee2e6 !important; }

/* line 10, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

/* line 11, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-left {
  border-left: 1px solid #dee2e6 !important; }

/* line 13, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-0 {
  border: 0 !important; }

/* line 14, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-top-0 {
  border-top: 0 !important; }

/* line 15, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-right-0 {
  border-right: 0 !important; }

/* line 16, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-bottom-0 {
  border-bottom: 0 !important; }

/* line 17, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-left-0 {
  border-left: 0 !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-primary {
  border-color: #007bff !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-secondary {
  border-color: #6c757d !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-success {
  border-color: #28a745 !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-info {
  border-color: #17a2b8 !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-warning {
  border-color: #ffc107 !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-danger {
  border-color: #dc3545 !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-light {
  border-color: #f8f9fa !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-dark {
  border-color: #343a40 !important; }

/* line 25, src/assets/scss/bootstrap/utilities/_borders.scss */
.border-white {
  border-color: #fff !important; }

/* line 33, src/assets/scss/bootstrap/utilities/_borders.scss */
.rounded {
  border-radius: 0.25rem !important; }

/* line 36, src/assets/scss/bootstrap/utilities/_borders.scss */
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

/* line 40, src/assets/scss/bootstrap/utilities/_borders.scss */
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

/* line 44, src/assets/scss/bootstrap/utilities/_borders.scss */
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

/* line 48, src/assets/scss/bootstrap/utilities/_borders.scss */
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

/* line 53, src/assets/scss/bootstrap/utilities/_borders.scss */
.rounded-circle {
  border-radius: 50% !important; }

/* line 57, src/assets/scss/bootstrap/utilities/_borders.scss */
.rounded-pill {
  border-radius: 50rem !important; }

/* line 61, src/assets/scss/bootstrap/utilities/_borders.scss */
.rounded-0 {
  border-radius: 0 !important; }

/* line 2, src/assets/scss/bootstrap/mixins/_clearfix.scss */
.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

/* line 11, src/assets/scss/bootstrap/utilities/_display.scss */
.d-none {
  display: none !important; }

/* line 12, src/assets/scss/bootstrap/utilities/_display.scss */
.d-inline {
  display: inline !important; }

/* line 13, src/assets/scss/bootstrap/utilities/_display.scss */
.d-inline-block {
  display: inline-block !important; }

/* line 14, src/assets/scss/bootstrap/utilities/_display.scss */
.d-block {
  display: block !important; }

/* line 15, src/assets/scss/bootstrap/utilities/_display.scss */
.d-table {
  display: table !important; }

/* line 16, src/assets/scss/bootstrap/utilities/_display.scss */
.d-table-row {
  display: table-row !important; }

/* line 17, src/assets/scss/bootstrap/utilities/_display.scss */
.d-table-cell {
  display: table-cell !important; }

/* line 18, src/assets/scss/bootstrap/utilities/_display.scss */
.d-flex {
  display: flex !important; }

/* line 19, src/assets/scss/bootstrap/utilities/_display.scss */
.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  /* line 11, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-sm-none {
    display: none !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-sm-inline {
    display: inline !important; }
  /* line 13, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-sm-inline-block {
    display: inline-block !important; }
  /* line 14, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-sm-block {
    display: block !important; }
  /* line 15, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-sm-table {
    display: table !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-sm-table-row {
    display: table-row !important; }
  /* line 17, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-sm-table-cell {
    display: table-cell !important; }
  /* line 18, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-sm-flex {
    display: flex !important; }
  /* line 19, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  /* line 11, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-md-none {
    display: none !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-md-inline {
    display: inline !important; }
  /* line 13, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-md-inline-block {
    display: inline-block !important; }
  /* line 14, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-md-block {
    display: block !important; }
  /* line 15, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-md-table {
    display: table !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-md-table-row {
    display: table-row !important; }
  /* line 17, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-md-table-cell {
    display: table-cell !important; }
  /* line 18, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-md-flex {
    display: flex !important; }
  /* line 19, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  /* line 11, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-lg-none {
    display: none !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-lg-inline {
    display: inline !important; }
  /* line 13, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-lg-inline-block {
    display: inline-block !important; }
  /* line 14, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-lg-block {
    display: block !important; }
  /* line 15, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-lg-table {
    display: table !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-lg-table-row {
    display: table-row !important; }
  /* line 17, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-lg-table-cell {
    display: table-cell !important; }
  /* line 18, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-lg-flex {
    display: flex !important; }
  /* line 19, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  /* line 11, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-xl-none {
    display: none !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-xl-inline {
    display: inline !important; }
  /* line 13, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-xl-inline-block {
    display: inline-block !important; }
  /* line 14, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-xl-block {
    display: block !important; }
  /* line 15, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-xl-table {
    display: table !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-xl-table-row {
    display: table-row !important; }
  /* line 17, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-xl-table-cell {
    display: table-cell !important; }
  /* line 18, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-xl-flex {
    display: flex !important; }
  /* line 19, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  /* line 29, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-print-none {
    display: none !important; }
  /* line 30, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-print-inline {
    display: inline !important; }
  /* line 31, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-print-inline-block {
    display: inline-block !important; }
  /* line 32, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-print-block {
    display: block !important; }
  /* line 33, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-print-table {
    display: table !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-print-table-row {
    display: table-row !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-print-table-cell {
    display: table-cell !important; }
  /* line 36, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-print-flex {
    display: flex !important; }
  /* line 37, src/assets/scss/bootstrap/utilities/_display.scss */
  .d-print-inline-flex {
    display: inline-flex !important; } }

/* line 3, src/assets/scss/bootstrap/utilities/_embed.scss */
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  /* line 10, src/assets/scss/bootstrap/utilities/_embed.scss */
  .embed-responsive::before {
    display: block;
    content: ""; }
  /* line 15, src/assets/scss/bootstrap/utilities/_embed.scss */
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

/* line 35, src/assets/scss/bootstrap/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

/* line 35, src/assets/scss/bootstrap/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%; }

/* line 35, src/assets/scss/bootstrap/utilities/_embed.scss */
.embed-responsive-3by4::before {
  padding-top: 133.33333%; }

/* line 35, src/assets/scss/bootstrap/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%; }

/* line 11, src/assets/scss/bootstrap/utilities/_flex.scss */
.flex-row {
  flex-direction: row !important; }

/* line 12, src/assets/scss/bootstrap/utilities/_flex.scss */
.flex-column {
  flex-direction: column !important; }

/* line 13, src/assets/scss/bootstrap/utilities/_flex.scss */
.flex-row-reverse {
  flex-direction: row-reverse !important; }

/* line 14, src/assets/scss/bootstrap/utilities/_flex.scss */
.flex-column-reverse {
  flex-direction: column-reverse !important; }

/* line 16, src/assets/scss/bootstrap/utilities/_flex.scss */
.flex-wrap {
  flex-wrap: wrap !important; }

/* line 17, src/assets/scss/bootstrap/utilities/_flex.scss */
.flex-nowrap {
  flex-wrap: nowrap !important; }

/* line 18, src/assets/scss/bootstrap/utilities/_flex.scss */
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

/* line 19, src/assets/scss/bootstrap/utilities/_flex.scss */
.flex-fill {
  flex: 1 1 auto !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_flex.scss */
.flex-grow-0 {
  flex-grow: 0 !important; }

/* line 21, src/assets/scss/bootstrap/utilities/_flex.scss */
.flex-grow-1 {
  flex-grow: 1 !important; }

/* line 22, src/assets/scss/bootstrap/utilities/_flex.scss */
.flex-shrink-0 {
  flex-shrink: 0 !important; }

/* line 23, src/assets/scss/bootstrap/utilities/_flex.scss */
.flex-shrink-1 {
  flex-shrink: 1 !important; }

/* line 25, src/assets/scss/bootstrap/utilities/_flex.scss */
.justify-content-start {
  justify-content: flex-start !important; }

/* line 26, src/assets/scss/bootstrap/utilities/_flex.scss */
.justify-content-end {
  justify-content: flex-end !important; }

/* line 27, src/assets/scss/bootstrap/utilities/_flex.scss */
.justify-content-center {
  justify-content: center !important; }

/* line 28, src/assets/scss/bootstrap/utilities/_flex.scss */
.justify-content-between {
  justify-content: space-between !important; }

/* line 29, src/assets/scss/bootstrap/utilities/_flex.scss */
.justify-content-around {
  justify-content: space-around !important; }

/* line 31, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-items-start {
  align-items: flex-start !important; }

/* line 32, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-items-end {
  align-items: flex-end !important; }

/* line 33, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-items-center {
  align-items: center !important; }

/* line 34, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-items-baseline {
  align-items: baseline !important; }

/* line 35, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-items-stretch {
  align-items: stretch !important; }

/* line 37, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-content-start {
  align-content: flex-start !important; }

/* line 38, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-content-end {
  align-content: flex-end !important; }

/* line 39, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-content-center {
  align-content: center !important; }

/* line 40, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-content-between {
  align-content: space-between !important; }

/* line 41, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-content-around {
  align-content: space-around !important; }

/* line 42, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-content-stretch {
  align-content: stretch !important; }

/* line 44, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-self-auto {
  align-self: auto !important; }

/* line 45, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-self-start {
  align-self: flex-start !important; }

/* line 46, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-self-end {
  align-self: flex-end !important; }

/* line 47, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-self-center {
  align-self: center !important; }

/* line 48, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-self-baseline {
  align-self: baseline !important; }

/* line 49, src/assets/scss/bootstrap/utilities/_flex.scss */
.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  /* line 11, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-sm-row {
    flex-direction: row !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-sm-column {
    flex-direction: column !important; }
  /* line 13, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  /* line 17, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  /* line 26, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  /* line 27, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-sm-center {
    justify-content: center !important; }
  /* line 28, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-sm-between {
    justify-content: space-between !important; }
  /* line 29, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-sm-around {
    justify-content: space-around !important; }
  /* line 31, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-sm-start {
    align-items: flex-start !important; }
  /* line 32, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-sm-end {
    align-items: flex-end !important; }
  /* line 33, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-sm-center {
    align-items: center !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-sm-baseline {
    align-items: baseline !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-sm-stretch {
    align-items: stretch !important; }
  /* line 37, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-sm-start {
    align-content: flex-start !important; }
  /* line 38, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-sm-end {
    align-content: flex-end !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-sm-center {
    align-content: center !important; }
  /* line 40, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-sm-between {
    align-content: space-between !important; }
  /* line 41, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-sm-around {
    align-content: space-around !important; }
  /* line 42, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-sm-stretch {
    align-content: stretch !important; }
  /* line 44, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-sm-auto {
    align-self: auto !important; }
  /* line 45, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-sm-start {
    align-self: flex-start !important; }
  /* line 46, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-sm-end {
    align-self: flex-end !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-sm-center {
    align-self: center !important; }
  /* line 48, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-sm-baseline {
    align-self: baseline !important; }
  /* line 49, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  /* line 11, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-md-row {
    flex-direction: row !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-md-column {
    flex-direction: column !important; }
  /* line 13, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  /* line 17, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-md-fill {
    flex: 1 1 auto !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-md-start {
    justify-content: flex-start !important; }
  /* line 26, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-md-end {
    justify-content: flex-end !important; }
  /* line 27, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-md-center {
    justify-content: center !important; }
  /* line 28, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-md-between {
    justify-content: space-between !important; }
  /* line 29, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-md-around {
    justify-content: space-around !important; }
  /* line 31, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-md-start {
    align-items: flex-start !important; }
  /* line 32, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-md-end {
    align-items: flex-end !important; }
  /* line 33, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-md-center {
    align-items: center !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-md-baseline {
    align-items: baseline !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-md-stretch {
    align-items: stretch !important; }
  /* line 37, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-md-start {
    align-content: flex-start !important; }
  /* line 38, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-md-end {
    align-content: flex-end !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-md-center {
    align-content: center !important; }
  /* line 40, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-md-between {
    align-content: space-between !important; }
  /* line 41, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-md-around {
    align-content: space-around !important; }
  /* line 42, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-md-stretch {
    align-content: stretch !important; }
  /* line 44, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-md-auto {
    align-self: auto !important; }
  /* line 45, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-md-start {
    align-self: flex-start !important; }
  /* line 46, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-md-end {
    align-self: flex-end !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-md-center {
    align-self: center !important; }
  /* line 48, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-md-baseline {
    align-self: baseline !important; }
  /* line 49, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  /* line 11, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-lg-row {
    flex-direction: row !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-lg-column {
    flex-direction: column !important; }
  /* line 13, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  /* line 17, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  /* line 26, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  /* line 27, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-lg-center {
    justify-content: center !important; }
  /* line 28, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-lg-between {
    justify-content: space-between !important; }
  /* line 29, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-lg-around {
    justify-content: space-around !important; }
  /* line 31, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-lg-start {
    align-items: flex-start !important; }
  /* line 32, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-lg-end {
    align-items: flex-end !important; }
  /* line 33, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-lg-center {
    align-items: center !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-lg-baseline {
    align-items: baseline !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-lg-stretch {
    align-items: stretch !important; }
  /* line 37, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-lg-start {
    align-content: flex-start !important; }
  /* line 38, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-lg-end {
    align-content: flex-end !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-lg-center {
    align-content: center !important; }
  /* line 40, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-lg-between {
    align-content: space-between !important; }
  /* line 41, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-lg-around {
    align-content: space-around !important; }
  /* line 42, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-lg-stretch {
    align-content: stretch !important; }
  /* line 44, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-lg-auto {
    align-self: auto !important; }
  /* line 45, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-lg-start {
    align-self: flex-start !important; }
  /* line 46, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-lg-end {
    align-self: flex-end !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-lg-center {
    align-self: center !important; }
  /* line 48, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-lg-baseline {
    align-self: baseline !important; }
  /* line 49, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  /* line 11, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-xl-row {
    flex-direction: row !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-xl-column {
    flex-direction: column !important; }
  /* line 13, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  /* line 17, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, src/assets/scss/bootstrap/utilities/_flex.scss */
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  /* line 26, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  /* line 27, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-xl-center {
    justify-content: center !important; }
  /* line 28, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-xl-between {
    justify-content: space-between !important; }
  /* line 29, src/assets/scss/bootstrap/utilities/_flex.scss */
  .justify-content-xl-around {
    justify-content: space-around !important; }
  /* line 31, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-xl-start {
    align-items: flex-start !important; }
  /* line 32, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-xl-end {
    align-items: flex-end !important; }
  /* line 33, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-xl-center {
    align-items: center !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-xl-baseline {
    align-items: baseline !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-items-xl-stretch {
    align-items: stretch !important; }
  /* line 37, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-xl-start {
    align-content: flex-start !important; }
  /* line 38, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-xl-end {
    align-content: flex-end !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-xl-center {
    align-content: center !important; }
  /* line 40, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-xl-between {
    align-content: space-between !important; }
  /* line 41, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-xl-around {
    align-content: space-around !important; }
  /* line 42, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-content-xl-stretch {
    align-content: stretch !important; }
  /* line 44, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-xl-auto {
    align-self: auto !important; }
  /* line 45, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-xl-start {
    align-self: flex-start !important; }
  /* line 46, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-xl-end {
    align-self: flex-end !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-xl-center {
    align-self: center !important; }
  /* line 48, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-xl-baseline {
    align-self: baseline !important; }
  /* line 49, src/assets/scss/bootstrap/utilities/_flex.scss */
  .align-self-xl-stretch {
    align-self: stretch !important; } }

/* line 5, src/assets/scss/bootstrap/utilities/_float.scss */
.float-left {
  float: left !important; }

/* line 6, src/assets/scss/bootstrap/utilities/_float.scss */
.float-right {
  float: right !important; }

/* line 7, src/assets/scss/bootstrap/utilities/_float.scss */
.float-none {
  float: none !important; }

@media (min-width: 576px) {
  /* line 5, src/assets/scss/bootstrap/utilities/_float.scss */
  .float-sm-left {
    float: left !important; }
  /* line 6, src/assets/scss/bootstrap/utilities/_float.scss */
  .float-sm-right {
    float: right !important; }
  /* line 7, src/assets/scss/bootstrap/utilities/_float.scss */
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  /* line 5, src/assets/scss/bootstrap/utilities/_float.scss */
  .float-md-left {
    float: left !important; }
  /* line 6, src/assets/scss/bootstrap/utilities/_float.scss */
  .float-md-right {
    float: right !important; }
  /* line 7, src/assets/scss/bootstrap/utilities/_float.scss */
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  /* line 5, src/assets/scss/bootstrap/utilities/_float.scss */
  .float-lg-left {
    float: left !important; }
  /* line 6, src/assets/scss/bootstrap/utilities/_float.scss */
  .float-lg-right {
    float: right !important; }
  /* line 7, src/assets/scss/bootstrap/utilities/_float.scss */
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  /* line 5, src/assets/scss/bootstrap/utilities/_float.scss */
  .float-xl-left {
    float: left !important; }
  /* line 6, src/assets/scss/bootstrap/utilities/_float.scss */
  .float-xl-right {
    float: right !important; }
  /* line 7, src/assets/scss/bootstrap/utilities/_float.scss */
  .float-xl-none {
    float: none !important; } }

/* line 4, src/assets/scss/bootstrap/utilities/_overflow.scss */
.overflow-auto {
  overflow: auto !important; }

/* line 4, src/assets/scss/bootstrap/utilities/_overflow.scss */
.overflow-hidden {
  overflow: hidden !important; }

/* line 5, src/assets/scss/bootstrap/utilities/_position.scss */
.position-static {
  position: static !important; }

/* line 5, src/assets/scss/bootstrap/utilities/_position.scss */
.position-relative {
  position: relative !important; }

/* line 5, src/assets/scss/bootstrap/utilities/_position.scss */
.position-absolute {
  position: absolute !important; }

/* line 5, src/assets/scss/bootstrap/utilities/_position.scss */
.position-fixed {
  position: fixed !important; }

/* line 5, src/assets/scss/bootstrap/utilities/_position.scss */
.position-sticky {
  position: sticky !important; }

/* line 10, src/assets/scss/bootstrap/utilities/_position.scss */
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

/* line 18, src/assets/scss/bootstrap/utilities/_position.scss */
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  /* line 26, src/assets/scss/bootstrap/utilities/_position.scss */
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

/* line 5, src/assets/scss/bootstrap/utilities/_screenreaders.scss */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

/* line 24, src/assets/scss/bootstrap/mixins/_screen-reader.scss */
.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

/* line 3, src/assets/scss/bootstrap/utilities/_shadows.scss */
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

/* line 4, src/assets/scss/bootstrap/utilities/_shadows.scss */
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

/* line 5, src/assets/scss/bootstrap/utilities/_shadows.scss */
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

/* line 6, src/assets/scss/bootstrap/utilities/_shadows.scss */
.shadow-none {
  box-shadow: none !important; }

/* line 7, src/assets/scss/bootstrap/utilities/_sizing.scss */
.w-25 {
  width: 25% !important; }

/* line 7, src/assets/scss/bootstrap/utilities/_sizing.scss */
.w-50 {
  width: 50% !important; }

/* line 7, src/assets/scss/bootstrap/utilities/_sizing.scss */
.w-75 {
  width: 75% !important; }

/* line 7, src/assets/scss/bootstrap/utilities/_sizing.scss */
.w-100 {
  width: 100% !important; }

/* line 7, src/assets/scss/bootstrap/utilities/_sizing.scss */
.w-auto {
  width: auto !important; }

/* line 7, src/assets/scss/bootstrap/utilities/_sizing.scss */
.h-25 {
  height: 25% !important; }

/* line 7, src/assets/scss/bootstrap/utilities/_sizing.scss */
.h-50 {
  height: 50% !important; }

/* line 7, src/assets/scss/bootstrap/utilities/_sizing.scss */
.h-75 {
  height: 75% !important; }

/* line 7, src/assets/scss/bootstrap/utilities/_sizing.scss */
.h-100 {
  height: 100% !important; }

/* line 7, src/assets/scss/bootstrap/utilities/_sizing.scss */
.h-auto {
  height: auto !important; }

/* line 11, src/assets/scss/bootstrap/utilities/_sizing.scss */
.mw-100 {
  max-width: 100% !important; }

/* line 12, src/assets/scss/bootstrap/utilities/_sizing.scss */
.mh-100 {
  max-height: 100% !important; }

/* line 16, src/assets/scss/bootstrap/utilities/_sizing.scss */
.min-vw-100 {
  min-width: 100vw !important; }

/* line 17, src/assets/scss/bootstrap/utilities/_sizing.scss */
.min-vh-100 {
  min-height: 100vh !important; }

/* line 19, src/assets/scss/bootstrap/utilities/_sizing.scss */
.vw-100 {
  width: 100vw !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_sizing.scss */
.vh-100 {
  height: 100vh !important; }

/* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
.m-0 {
  margin: 0 !important; }

/* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mt-0,
.my-0 {
  margin-top: 0 !important; }

/* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mr-0,
.mx-0 {
  margin-right: 0 !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

/* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
.ml-0,
.mx-0 {
  margin-left: 0 !important; }

/* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
.m-1 {
  margin: 0.25rem !important; }

/* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

/* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

/* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

/* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
.m-2 {
  margin: 0.5rem !important; }

/* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

/* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

/* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

/* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
.m-3 {
  margin: 1rem !important; }

/* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mt-3,
.my-3 {
  margin-top: 1rem !important; }

/* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

/* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

/* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
.m-4 {
  margin: 1.5rem !important; }

/* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

/* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

/* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

/* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
.m-5 {
  margin: 3rem !important; }

/* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mt-5,
.my-5 {
  margin-top: 3rem !important; }

/* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

/* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

/* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
.p-0 {
  padding: 0 !important; }

/* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pt-0,
.py-0 {
  padding-top: 0 !important; }

/* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pr-0,
.px-0 {
  padding-right: 0 !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

/* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pl-0,
.px-0 {
  padding-left: 0 !important; }

/* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
.p-1 {
  padding: 0.25rem !important; }

/* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

/* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

/* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

/* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
.p-2 {
  padding: 0.5rem !important; }

/* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

/* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

/* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

/* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
.p-3 {
  padding: 1rem !important; }

/* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pt-3,
.py-3 {
  padding-top: 1rem !important; }

/* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pr-3,
.px-3 {
  padding-right: 1rem !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

/* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pl-3,
.px-3 {
  padding-left: 1rem !important; }

/* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
.p-4 {
  padding: 1.5rem !important; }

/* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

/* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

/* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

/* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
.p-5 {
  padding: 3rem !important; }

/* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pt-5,
.py-5 {
  padding-top: 3rem !important; }

/* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pr-5,
.px-5 {
  padding-right: 3rem !important; }

/* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

/* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
.pl-5,
.px-5 {
  padding-left: 3rem !important; }

/* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
.m-n1 {
  margin: -0.25rem !important; }

/* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

/* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

/* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

/* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

/* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
.m-n2 {
  margin: -0.5rem !important; }

/* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

/* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

/* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

/* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

/* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
.m-n3 {
  margin: -1rem !important; }

/* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

/* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

/* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

/* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

/* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
.m-n4 {
  margin: -1.5rem !important; }

/* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

/* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

/* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

/* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

/* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
.m-n5 {
  margin: -3rem !important; }

/* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

/* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

/* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

/* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

/* line 55, src/assets/scss/bootstrap/utilities/_spacing.scss */
.m-auto {
  margin: auto !important; }

/* line 56, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mt-auto,
.my-auto {
  margin-top: auto !important; }

/* line 60, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mr-auto,
.mx-auto {
  margin-right: auto !important; }

/* line 64, src/assets/scss/bootstrap/utilities/_spacing.scss */
.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

/* line 68, src/assets/scss/bootstrap/utilities/_spacing.scss */
.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-sm-0 {
    margin: 0 !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-sm-1 {
    margin: 0.25rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-sm-2 {
    margin: 0.5rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-sm-3 {
    margin: 1rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-sm-4 {
    margin: 1.5rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-sm-5 {
    margin: 3rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-sm-0 {
    padding: 0 !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-sm-1 {
    padding: 0.25rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-sm-2 {
    padding: 0.5rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-sm-3 {
    padding: 1rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-sm-4 {
    padding: 1.5rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-sm-5 {
    padding: 3rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-sm-n1 {
    margin: -0.25rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-sm-n2 {
    margin: -0.5rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-sm-n3 {
    margin: -1rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-sm-n4 {
    margin: -1.5rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-sm-n5 {
    margin: -3rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  /* line 55, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-sm-auto {
    margin: auto !important; }
  /* line 56, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  /* line 60, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  /* line 64, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  /* line 68, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-md-0 {
    margin: 0 !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-md-1 {
    margin: 0.25rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-md-2 {
    margin: 0.5rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-md-3 {
    margin: 1rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-md-4 {
    margin: 1.5rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-md-5 {
    margin: 3rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-md-0 {
    padding: 0 !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-md-1 {
    padding: 0.25rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-md-2 {
    padding: 0.5rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-md-3 {
    padding: 1rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-md-4 {
    padding: 1.5rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-md-5 {
    padding: 3rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-md-n1 {
    margin: -0.25rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-md-n2 {
    margin: -0.5rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-md-n3 {
    margin: -1rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-md-n4 {
    margin: -1.5rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-md-n5 {
    margin: -3rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  /* line 55, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-md-auto {
    margin: auto !important; }
  /* line 56, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  /* line 60, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  /* line 64, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  /* line 68, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-lg-0 {
    margin: 0 !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-lg-1 {
    margin: 0.25rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-lg-2 {
    margin: 0.5rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-lg-3 {
    margin: 1rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-lg-4 {
    margin: 1.5rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-lg-5 {
    margin: 3rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-lg-0 {
    padding: 0 !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-lg-1 {
    padding: 0.25rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-lg-2 {
    padding: 0.5rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-lg-3 {
    padding: 1rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-lg-4 {
    padding: 1.5rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-lg-5 {
    padding: 3rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-lg-n1 {
    margin: -0.25rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-lg-n2 {
    margin: -0.5rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-lg-n3 {
    margin: -1rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-lg-n4 {
    margin: -1.5rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-lg-n5 {
    margin: -3rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  /* line 55, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-lg-auto {
    margin: auto !important; }
  /* line 56, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  /* line 60, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  /* line 64, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  /* line 68, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-xl-0 {
    margin: 0 !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-xl-1 {
    margin: 0.25rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-xl-2 {
    margin: 0.5rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-xl-3 {
    margin: 1rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-xl-4 {
    margin: 1.5rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-xl-5 {
    margin: 3rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-xl-0 {
    padding: 0 !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-xl-1 {
    padding: 0.25rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-xl-2 {
    padding: 0.5rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-xl-3 {
    padding: 1rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-xl-4 {
    padding: 1.5rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  /* line 11, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .p-xl-5 {
    padding: 3rem !important; }
  /* line 12, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  /* line 16, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  /* line 20, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-xl-n1 {
    margin: -0.25rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-xl-n2 {
    margin: -0.5rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-xl-n3 {
    margin: -1rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-xl-n4 {
    margin: -1.5rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-xl-n5 {
    margin: -3rem !important; }
  /* line 35, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  /* line 39, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  /* line 43, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  /* line 55, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .m-xl-auto {
    margin: auto !important; }
  /* line 56, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  /* line 60, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  /* line 64, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  /* line 68, src/assets/scss/bootstrap/utilities/_spacing.scss */
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

/* line 7, src/assets/scss/bootstrap/utilities/_text.scss */
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

/* line 11, src/assets/scss/bootstrap/utilities/_text.scss */
.text-justify {
  text-align: justify !important; }

/* line 12, src/assets/scss/bootstrap/utilities/_text.scss */
.text-wrap {
  white-space: normal !important; }

/* line 13, src/assets/scss/bootstrap/utilities/_text.scss */
.text-nowrap {
  white-space: nowrap !important; }

/* line 14, src/assets/scss/bootstrap/utilities/_text.scss */
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* line 22, src/assets/scss/bootstrap/utilities/_text.scss */
.text-left {
  text-align: left !important; }

/* line 23, src/assets/scss/bootstrap/utilities/_text.scss */
.text-right {
  text-align: right !important; }

/* line 24, src/assets/scss/bootstrap/utilities/_text.scss */
.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  /* line 22, src/assets/scss/bootstrap/utilities/_text.scss */
  .text-sm-left {
    text-align: left !important; }
  /* line 23, src/assets/scss/bootstrap/utilities/_text.scss */
  .text-sm-right {
    text-align: right !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_text.scss */
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  /* line 22, src/assets/scss/bootstrap/utilities/_text.scss */
  .text-md-left {
    text-align: left !important; }
  /* line 23, src/assets/scss/bootstrap/utilities/_text.scss */
  .text-md-right {
    text-align: right !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_text.scss */
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  /* line 22, src/assets/scss/bootstrap/utilities/_text.scss */
  .text-lg-left {
    text-align: left !important; }
  /* line 23, src/assets/scss/bootstrap/utilities/_text.scss */
  .text-lg-right {
    text-align: right !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_text.scss */
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  /* line 22, src/assets/scss/bootstrap/utilities/_text.scss */
  .text-xl-left {
    text-align: left !important; }
  /* line 23, src/assets/scss/bootstrap/utilities/_text.scss */
  .text-xl-right {
    text-align: right !important; }
  /* line 24, src/assets/scss/bootstrap/utilities/_text.scss */
  .text-xl-center {
    text-align: center !important; } }

/* line 30, src/assets/scss/bootstrap/utilities/_text.scss */
.text-lowercase {
  text-transform: lowercase !important; }

/* line 31, src/assets/scss/bootstrap/utilities/_text.scss */
.text-uppercase {
  text-transform: uppercase !important; }

/* line 32, src/assets/scss/bootstrap/utilities/_text.scss */
.text-capitalize {
  text-transform: capitalize !important; }

/* line 36, src/assets/scss/bootstrap/utilities/_text.scss */
.font-weight-light {
  font-weight: 300 !important; }

/* line 37, src/assets/scss/bootstrap/utilities/_text.scss */
.font-weight-lighter {
  font-weight: lighter !important; }

/* line 38, src/assets/scss/bootstrap/utilities/_text.scss */
.font-weight-normal {
  font-weight: 400 !important; }

/* line 39, src/assets/scss/bootstrap/utilities/_text.scss */
.font-weight-bold {
  font-weight: 700 !important; }

/* line 40, src/assets/scss/bootstrap/utilities/_text.scss */
.font-weight-bolder {
  font-weight: bolder !important; }

/* line 41, src/assets/scss/bootstrap/utilities/_text.scss */
.font-italic {
  font-style: italic !important; }

/* line 45, src/assets/scss/bootstrap/utilities/_text.scss */
.text-white {
  color: #fff !important; }

/* line 6, src/assets/scss/bootstrap/mixins/_text-emphasis.scss */
.text-primary {
  color: #007bff !important; }

/* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

/* line 6, src/assets/scss/bootstrap/mixins/_text-emphasis.scss */
.text-secondary {
  color: #6c757d !important; }

/* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

/* line 6, src/assets/scss/bootstrap/mixins/_text-emphasis.scss */
.text-success {
  color: #28a745 !important; }

/* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

/* line 6, src/assets/scss/bootstrap/mixins/_text-emphasis.scss */
.text-info {
  color: #17a2b8 !important; }

/* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

/* line 6, src/assets/scss/bootstrap/mixins/_text-emphasis.scss */
.text-warning {
  color: #ffc107 !important; }

/* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

/* line 6, src/assets/scss/bootstrap/mixins/_text-emphasis.scss */
.text-danger {
  color: #dc3545 !important; }

/* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

/* line 6, src/assets/scss/bootstrap/mixins/_text-emphasis.scss */
.text-light {
  color: #f8f9fa !important; }

/* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

/* line 6, src/assets/scss/bootstrap/mixins/_text-emphasis.scss */
.text-dark {
  color: #343a40 !important; }

/* line 17, src/assets/scss/bootstrap/mixins/_hover.scss */
a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

/* line 51, src/assets/scss/bootstrap/utilities/_text.scss */
.text-body {
  color: #212529 !important; }

/* line 52, src/assets/scss/bootstrap/utilities/_text.scss */
.text-muted {
  color: #6c757d !important; }

/* line 54, src/assets/scss/bootstrap/utilities/_text.scss */
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

/* line 55, src/assets/scss/bootstrap/utilities/_text.scss */
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

/* line 59, src/assets/scss/bootstrap/utilities/_text.scss */
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

/* line 63, src/assets/scss/bootstrap/utilities/_text.scss */
.text-decoration-none {
  text-decoration: none !important; }

/* line 67, src/assets/scss/bootstrap/utilities/_text.scss */
.text-reset {
  color: inherit !important; }

/* line 5, src/assets/scss/bootstrap/utilities/_visibility.scss */
.visible {
  visibility: visible !important; }

/* line 9, src/assets/scss/bootstrap/utilities/_visibility.scss */
.invisible {
  visibility: hidden !important; }

@media print {
  /* line 13, src/assets/scss/bootstrap/_print.scss */
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  /* line 24, src/assets/scss/bootstrap/_print.scss */
  a:not(.btn) {
    text-decoration: underline; }
  /* line 34, src/assets/scss/bootstrap/_print.scss */
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  /* line 49, src/assets/scss/bootstrap/_print.scss */
  pre {
    white-space: pre-wrap !important; }
  /* line 52, src/assets/scss/bootstrap/_print.scss */
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  /* line 63, src/assets/scss/bootstrap/_print.scss */
  thead {
    display: table-header-group; }
  /* line 67, src/assets/scss/bootstrap/_print.scss */
  tr,
  img {
    page-break-inside: avoid; }
  /* line 72, src/assets/scss/bootstrap/_print.scss */
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  /* line 79, src/assets/scss/bootstrap/_print.scss */
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  /* line 92, src/assets/scss/bootstrap/_print.scss */
  body {
    min-width: 992px !important; }
  /* line 95, src/assets/scss/bootstrap/_print.scss */
  .container {
    min-width: 992px !important; }
  /* line 100, src/assets/scss/bootstrap/_print.scss */
  .navbar {
    display: none; }
  /* line 103, src/assets/scss/bootstrap/_print.scss */
  .badge {
    border: 1px solid #000; }
  /* line 107, src/assets/scss/bootstrap/_print.scss */
  .table {
    border-collapse: collapse !important; }
    /* line 110, src/assets/scss/bootstrap/_print.scss */
    .table td,
    .table th {
      background-color: #fff !important; }
  /* line 117, src/assets/scss/bootstrap/_print.scss */
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  /* line 123, src/assets/scss/bootstrap/_print.scss */
  .table-dark {
    color: inherit; }
    /* line 126, src/assets/scss/bootstrap/_print.scss */
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  /* line 134, src/assets/scss/bootstrap/_print.scss */
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

@font-face {
  font-family: 'TiemposHeadline';
  src: url("/assets/fonts/TiemposHeadlineWeb-Medium.eot");
  /* IE9 Compat Modes */
  src: url("/assets/fonts/TiemposHeadlineWeb-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/TiemposHeadlineWeb-Medium.woff2") format("woff2"), url("/assets/fonts/TiemposHeadlineWeb-Medium.woff") format("woff");
  /* Pretty Modern Browsers */ }

@font-face {
  font-family: 'TiemposText';
  src: url("/assets/fonts/TiemposHeadlineWeb-Regular.eot");
  /* IE9 Compat Modes */
  src: url("/assets/fonts/TiemposHeadlineWeb-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/TiemposHeadlineWeb-Regular.woff2") format("woff2"), url("/assets/fonts/TiemposHeadlineWeb-Regular.woff") format("woff");
  /* Pretty Modern Browsers */ }

/* line 65, src/assets/scss/general/_styleguide.scss */
.orange {
  color: #e67800; }

/* line 69, src/assets/scss/general/_styleguide.scss */
.dark {
  color: #101010; }

/* line 73, src/assets/scss/general/_styleguide.scss */
.grey-dark {
  color: #cccccc; }

/* line 77, src/assets/scss/general/_styleguide.scss */
.grey-medium {
  color: #e4e4e4; }

/* line 81, src/assets/scss/general/_styleguide.scss */
.grey-light {
  color: #f6f6f6; }

/* line 85, src/assets/scss/general/_styleguide.scss */
.white {
  color: white; }

/* line 90, src/assets/scss/general/_styleguide.scss */
.othcol4 {
  color: #444444; }

/* line 94, src/assets/scss/general/_styleguide.scss */
.orgvo {
  color: #ed4c22; }

/* line 98, src/assets/scss/general/_styleguide.scss */
.orangeo {
  color: #ff6b35; }

/* line 102, src/assets/scss/general/_styleguide.scss */
.othcol {
  color: #383838; }

/* line 106, src/assets/scss/general/_styleguide.scss */
.bluedark {
  color: #192830; }

/* line 110, src/assets/scss/general/_styleguide.scss */
.greyvo {
  color: #cacece; }

/* line 114, src/assets/scss/general/_styleguide.scss */
.istblack {
  color: #1d2114; }

/* line 118, src/assets/scss/general/_styleguide.scss */
.onyx {
  color: #333333; }

/* line 122, src/assets/scss/general/_styleguide.scss */
.istgreen {
  color: #394a13; }

/* line 126, src/assets/scss/general/_styleguide.scss */
.ltgray {
  color: #999999; }

/* line 130, src/assets/scss/general/_styleguide.scss */
.mossgreen {
  color: #575f49; }

/* line 134, src/assets/scss/general/_styleguide.scss */
.cellgreen {
  color: #577512; }

/* line 138, src/assets/scss/general/_styleguide.scss */
.alabaster {
  color: #ebede6; }

/* line 142, src/assets/scss/general/_styleguide.scss */
.it-orange {
  color: #ff6c2d; }

/* line 146, src/assets/scss/general/_styleguide.scss */
.dark-blue {
  color: #235789; }

/* line 150, src/assets/scss/general/_styleguide.scss */
.grey {
  color: #9b9b9b; }

/* line 154, src/assets/scss/general/_styleguide.scss */
.fbgdark {
  color: #222222; }

/* line 158, src/assets/scss/general/_styleguide.scss */
.brdgrey {
  color: #dddddd; }

/* line 162, src/assets/scss/general/_styleguide.scss */
.black {
  color: #000000; }

/* line 166, src/assets/scss/general/_styleguide.scss */
.greyb {
  color: #4a4a4a; }

/* line 170, src/assets/scss/general/_styleguide.scss */
.whtn {
  color: #ededed; }

/* line 174, src/assets/scss/general/_styleguide.scss */
.othercol {
  color: #e4e5e7; }

/* line 179, src/assets/scss/general/_styleguide.scss */
html {
  font-family: "Work Sans", "sans-serif"; }

/* line 182, src/assets/scss/general/_styleguide.scss */
body {
  margin: 0;
  color: #333; }

/* line 187, src/assets/scss/general/_styleguide.scss */
h1 {
  text-align: left;
  font-family: "TiemposHeadline", sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 1px;
  color: #101010; }
  @media screen and (max-width: 992px) {
    /* line 187, src/assets/scss/general/_styleguide.scss */
    h1 {
      font-size: 24px;
      line-height: 31px;
      text-align: left; } }

/* line 202, src/assets/scss/general/_styleguide.scss */
h2 {
  font-family: "TiemposText", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.8px;
  text-align: left; }
  @media screen and (max-width: 992px) {
    /* line 202, src/assets/scss/general/_styleguide.scss */
    h2 {
      font-size: 20px;
      line-height: 26px;
      text-align: left; } }

/* line 216, src/assets/scss/general/_styleguide.scss */
h3 {
  font-family: "Work Sans", "sans-serif";
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-align: left; }
  @media screen and (max-width: 992px) {
    /* line 216, src/assets/scss/general/_styleguide.scss */
    h3 {
      font-size: 16px;
      line-height: 22px;
      text-align: left; } }

/* line 230, src/assets/scss/general/_styleguide.scss */
h4 {
  font-family: "Work Sans", "sans-serif";
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #235789;
  text-align: left; }
  @media screen and (max-width: 992px) {
    /* line 230, src/assets/scss/general/_styleguide.scss */
    h4 {
      font-size: 12px;
      line-height: 16px;
      text-align: left; } }

/* line 245, src/assets/scss/general/_styleguide.scss */
p {
  font-family: "Work Sans", "sans-serif";
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.3px;
  color: #333333;
  text-align: left; }
  /* line 253, src/assets/scss/general/_styleguide.scss */
  p.p1 {
    font-weight: normal;
    font-size: 16px;
    line-height: 28px; }
    @media screen and (max-width: 992px) {
      /* line 253, src/assets/scss/general/_styleguide.scss */
      p.p1 {
        font-size: 15px;
        line-height: 22px;
        text-align: left; } }
  /* line 263, src/assets/scss/general/_styleguide.scss */
  p.p2 {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: left; }
    @media screen and (max-width: 992px) {
      /* line 263, src/assets/scss/general/_styleguide.scss */
      p.p2 {
        font-size: 13px;
        line-height: 18px;
        text-align: left; } }
  /* line 274, src/assets/scss/general/_styleguide.scss */
  p.p3 {
    font-family: "Work Sans", "sans-serif";
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: left; }
    @media screen and (max-width: 992px) {
      /* line 274, src/assets/scss/general/_styleguide.scss */
      p.p3 {
        font-size: 13px;
        line-height: 16px;
        text-align: left; } }
  /* line 286, src/assets/scss/general/_styleguide.scss */
  p.p1bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-align: left; }
    @media screen and (max-width: 992px) {
      /* line 286, src/assets/scss/general/_styleguide.scss */
      p.p1bold {
        font-size: 15px;
        line-height: 22px;
        text-align: left; } }
  /* line 297, src/assets/scss/general/_styleguide.scss */
  p.p2bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: left; }
    @media screen and (max-width: 992px) {
      /* line 297, src/assets/scss/general/_styleguide.scss */
      p.p2bold {
        font-size: 13px;
        line-height: 18px;
        text-align: left; } }
  /* line 308, src/assets/scss/general/_styleguide.scss */
  p.p3bold {
    font-family: "Work Sans", "sans-serif";
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: left; }
    @media screen and (max-width: 992px) {
      /* line 308, src/assets/scss/general/_styleguide.scss */
      p.p3bold {
        font-size: 13px;
        line-height: 16px;
        font-weight: 600;
        text-align: left; } }
  /* line 321, src/assets/scss/general/_styleguide.scss */
  p.ptest {
    font-family: "TiemposHeadline", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    text-align: left; }
    @media screen and (max-width: 992px) {
      /* line 321, src/assets/scss/general/_styleguide.scss */
      p.ptest {
        font-size: 13px;
        line-height: 16px;
        font-weight: normal;
        text-align: left; } }
  /* line 335, src/assets/scss/general/_styleguide.scss */
  p.ptext12 {
    font-size: 12px;
    line-height: 1.5;
    color: #222222; }
  /* line 340, src/assets/scss/general/_styleguide.scss */
  p.quote {
    font-family: "TiemposText", sans-serif;
    font-size: 18px;
    line-height: 2;
    color: #000000; }
  /* line 346, src/assets/scss/general/_styleguide.scss */
  p.foterfont {
    font-family: "TiemposHeadline", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    color: #ffffff;
    text-align: left; }
    @media screen and (max-width: 992px) {
      /* line 346, src/assets/scss/general/_styleguide.scss */
      p.foterfont {
        font-size: 13px;
        line-height: 16px;
        font-weight: normal;
        text-align: left; } }
  /* line 360, src/assets/scss/general/_styleguide.scss */
  p.testleft {
    text-align: left; }

/*a {
	font-family: $WorkSans;
	font-weight: $medium;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.3px;
	color: $dark;
	border-bottom: 2px solid $orange;
	&.large {
		font-size: 25px;
		line-height: 40px;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 20px;
		}
	}
	&.medium {
		font-size: 16px;
		line-height: 30px;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 14px;
		}
	}
	&.small {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 1.5px;
		border: none;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 12px;
		}
	}
	&.downloadlink {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 1.5px;
		border: none;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 12px;
		}
	}

	&:hover {
		text-decoration: none;
		color: inherit;
	}

}
.btn {
	font-family: $WorkSans;
	font-weight: $medium;
	font-size: 14px;
	letter-spacing: 1.3px;
	color: $dark;
	text-transform: uppercase;
	@media screen and (max-width: $mobile-breakpoint) {
		font-size: 12px;
		letter-spacing: 1.3px;
	}

}
*/
/* line 429, src/assets/scss/general/_styleguide.scss */
ul.darklist {
  font-family: "Work Sans", "sans-serif";
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  background-color: #000;
  color: #e4e4e4; }
  @media screen and (max-width: 992px) {
    /* line 429, src/assets/scss/general/_styleguide.scss */
    ul.darklist {
      font-size: 16px;
      line-height: 22px;
      margin-top: -2px;
      position: relative; } }

/* line 444, src/assets/scss/general/_styleguide.scss */
ul li {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px; }

/* line 452, src/assets/scss/general/_styleguide.scss */
li.textlist {
  background-color: #000000;
  color: blue; }

/* line 459, src/assets/scss/general/_styleguide.scss */
a.testlink {
  border-bottom: 1px solid #ebede6;
  color: red;
  font-size: 16px;
  line-height: 22px;
  text-align: left; }
  @media screen and (max-width: 992px) {
    /* line 459, src/assets/scss/general/_styleguide.scss */
    a.testlink {
      font-size: 20px;
      text-align: left;
      margin-left: 15px;
      margin-right: 15px; } }

/* line 472, src/assets/scss/general/_styleguide.scss */
a.darkline {
  text-align: left; }
  @media screen and (max-width: 767px) {
    /* line 472, src/assets/scss/general/_styleguide.scss */
    a.darkline {
      border-bottom: 1px solid #444444;
      font-size: 16px;
      line-height: 22px;
      text-align: left;
      margin-left: 15px;
      margin-right: 15px; }
      /* line 481, src/assets/scss/general/_styleguide.scss */
      a.darkline :last-of-type {
        border: none; } }

/* line 487, src/assets/scss/general/_styleguide.scss */
a .typegen:hover {
  cursor: pointer; }

/* line 491, src/assets/scss/general/_styleguide.scss */
a .typegen .slick-disabled {
  opacity: 0.4 !important; }

/* line 497, src/assets/scss/general/_styleguide.scss */
.video-wrapper {
  position: relative; }

/* line 501, src/assets/scss/general/_styleguide.scss */
.video-wrapper > video {
  width: 100%;
  vertical-align: middle; }

/* line 506, src/assets/scss/general/_styleguide.scss */
.video-wrapper > video.has-media-controls-hidden::-webkit-media-controls {
  display: none; }

/* line 510, src/assets/scss/general/_styleguide.scss */
.video-overlay-play-button {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10px calc(50% - 50px);
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  opacity: 0.95;
  cursor: pointer;
  background-image: linear-gradient(transparent, #000);
  transition: opacity 150ms; }

/* line 525, src/assets/scss/general/_styleguide.scss */
.video-overlay-play-button:hover {
  opacity: 1; }

/* line 529, src/assets/scss/general/_styleguide.scss */
.video-overlay-play-button.is-hidden {
  display: none; }

/* line 533, src/assets/scss/general/_styleguide.scss */
.main-content {
  padding-top: 44px;
  padding-bottom: 120px; }

/* line 538, src/assets/scss/general/_styleguide.scss */
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
select:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: #235789;
  box-shadow: none;
  outline: 0 none; }

@media screen and (min-width: 768px) {
  /* line 560, src/assets/scss/general/_styleguide.scss */
  .mobile-only {
    display: none !important; } }

@media screen and (max-width: 767px) {
  /* line 566, src/assets/scss/general/_styleguide.scss */
  .desktop-only {
    display: none !important; } }

/* line 572, src/assets/scss/general/_styleguide.scss */
.button-link-simple {
  line-height: 45px;
  border-radius: 22.5px;
  background-color: #ffffff;
  border: 1px solid #235789;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #235789;
  text-align: center;
  padding: 0 46px; }
  @media screen and (max-width: 992px) {
    /* line 572, src/assets/scss/general/_styleguide.scss */
    .button-link-simple {
      position: relative;
      margin: 0 auto; } }

@media screen and (min-width: 768px) {
  /* line 593, src/assets/scss/general/_styleguide.scss */
  .accordion-mobile {
    border: 0 !important; }
    /* line 596, src/assets/scss/general/_styleguide.scss */
    .accordion-mobile .collapse-mobile {
      display: block !important; }
    /* line 599, src/assets/scss/general/_styleguide.scss */
    .accordion-mobile a {
      text-decoration: none; }
  /* line 603, src/assets/scss/general/_styleguide.scss */
  .accordion-mobile > div > div > .panel-title {
    display: none; } }

@media screen and (max-width: 767px) {
  /* line 609, src/assets/scss/general/_styleguide.scss */
  .accordion-mobile {
    border-top: 0 !important; }
  /* line 613, src/assets/scss/general/_styleguide.scss */
  .linkdiv.mobile-only {
    margin-left: auto;
    margin-right: auto; }
    /* line 616, src/assets/scss/general/_styleguide.scss */
    .linkdiv.mobile-only a {
      text-decoration: none; }
      /* line 618, src/assets/scss/general/_styleguide.scss */
      .linkdiv.mobile-only a p {
        font-size: 15px;
        font-weight: 600;
        color: #ff6b35;
        font-family: "Work Sans", "sans-serif";
        text-transform: uppercase; } }

/* line 629, src/assets/scss/general/_styleguide.scss */
.ellip {
  display: block;
  height: 100%; }

/* line 634, src/assets/scss/general/_styleguide.scss */
.ellip-line {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  max-width: 100%; }

/* line 642, src/assets/scss/general/_styleguide.scss */
.ellip,
.ellip-line {
  position: relative;
  overflow: hidden; }

/* line 2, src/assets/scss/general/_header.scss */
header .change-language {
  background-color: #ededed;
  vertical-align: middle; }
  /* line 5, src/assets/scss/general/_header.scss */
  header .change-language p {
    margin: 0;
    line-height: 50px;
    font-size: 14px;
    padding-left: 14px; }
    /* line 10, src/assets/scss/general/_header.scss */
    header .change-language p a {
      color: #333;
      font-weight: 700; }
  @media screen and (min-width: 768px) {
    /* line 2, src/assets/scss/general/_header.scss */
    header .change-language {
      display: none; } }

/* line 20, src/assets/scss/general/_header.scss */
header a.dropdown-item {
  border-bottom: 1px solid #dddddd !important;
  font-family: "TiemposText", sans-serif; }
  @media screen and (min-width: 768px) {
    /* line 20, src/assets/scss/general/_header.scss */
    header a.dropdown-item {
      min-width: 400px;
      line-height: 50px !important; } }

/* line 29, src/assets/scss/general/_header.scss */
header .divlogo {
  width: 300px;
  height: 150px;
  background-repeat: no-repeat;
  z-index: 999;
  position: absolute;
  /* @media screen and (max-width: $screen-xl-min) {
         width: 250px;
         height: 100px;
     }*/ }

/* line 40, src/assets/scss/general/_header.scss */
header .divspace {
  width: 100%;
  height: 120px;
  background-color: #ffffff; }

/* line 45, src/assets/scss/general/_header.scss */
header .divlogondy {
  width: 100px;
  height: 66px;
  background-repeat: no-repeat;
  z-index: 999; }

/* line 51, src/assets/scss/general/_header.scss */
header .navcolor {
  background-color: #ededed;
  border-top: 2px solid #ffffff; }

/* line 55, src/assets/scss/general/_header.scss */
header a {
  padding: 0px; }

/* line 58, src/assets/scss/general/_header.scss */
header nav.navbar.navbar-expand-md.navbar-light.sticky.top {
  padding: 0px; }

/* line 62, src/assets/scss/general/_header.scss */
header .listtwo {
  padding: 0;
  margin-top: 9px;
  margin-bottom: 5px;
  font-family: "Work Sans", "sans-serif";
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 5px;
  margin-top: 9px; }

/* line 80, src/assets/scss/general/_header.scss */
header li.nav-item.border-right.border-left {
  line-height: 30px; }

/* line 83, src/assets/scss/general/_header.scss */
header li.nav-item {
  line-height: 30px; }

@media screen and (max-width: 992px) {
  /* line 87, src/assets/scss/general/_header.scss */
  header p.ptest {
    font-family: "Work Sans", "sans-serif";
    font-size: 15px;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 0px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff; }
    /* line 100, src/assets/scss/general/_header.scss */
    header p.ptest :first-of-type {
      border-bottom: 1px solid #444444; }
    /* line 103, src/assets/scss/general/_header.scss */
    header p.ptest :last-of-type {
      border: none; } }

/* line 108, src/assets/scss/general/_header.scss */
header p.p13 {
  line-height: 44px;
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333; }
  @media screen and (max-width: 992px) {
    /* line 108, src/assets/scss/general/_header.scss */
    header p.p13 {
      font-family: "Work Sans", "sans-serif";
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #444444;
      margin-bottom: 19px;
      margin-top: 19px;
      margin-left: 0px;
      margin-right: 0px;
      width: 80%; }
      /* line 133, src/assets/scss/general/_header.scss */
      header p.p13 :first-of-type {
        margin-top: 15px;
        margin-bottom: 19px; }
      /* line 138, src/assets/scss/general/_header.scss */
      header p.p13 :last-of-type {
        margin-top: 19px;
        margin-bottom: 25px; } }

/* line 147, src/assets/scss/general/_header.scss */
header a.puppermenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 5px;
  margin-top: 9px;
  text-transform: uppercase; }

/* line 164, src/assets/scss/general/_header.scss */
header a:hover {
  color: #B3B6B7;
  text-decoration: none; }

/* line 169, src/assets/scss/general/_header.scss */
header a.nav-link.testlink {
  padding: 0px; }

/* line 172, src/assets/scss/general/_header.scss */
header a.nav-link.bottom {
  padding: 0px; }

/* line 175, src/assets/scss/general/_header.scss */
header .searchdiv {
  width: 25px;
  height: 25px;
  z-index: 999;
  right: 65px;
  top: 20px;
  position: absolute; }

/* line 183, src/assets/scss/general/_header.scss */
header .triangle {
  width: 12px;
  height: 12px;
  background-color: red;
  position: absolute;
  right: 15px;
  top: 0px; }

/* line 191, src/assets/scss/general/_header.scss */
header .iconmenu {
  position: relative; }

@media screen and (max-width: 992px) {
  /* line 194, src/assets/scss/general/_header.scss */
  header .bottom2 {
    margin-left: 15px; } }

/* line 199, src/assets/scss/general/_header.scss */
header nav .p1 {
  margin: 0;
  line-height: 44px; }
  @media screen and (max-width: 992px) {
    /* line 199, src/assets/scss/general/_header.scss */
    header nav .p1 {
      margin-top: 12px;
      margin-bottom: 12px;
      line-height: normal; } }

/* line 208, src/assets/scss/general/_header.scss */
header a.nav-link.bottom.bottom2 {
  padding: 0;
  font-family: "Work Sans", "sans-serif";
  border-bottom: 2px solid white;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #333333;
  line-height: 42px; }
  @media screen and (max-width: 992px) {
    /* line 208, src/assets/scss/general/_header.scss */
    header a.nav-link.bottom.bottom2 {
      margin-left: 15px;
      margin-right: 15px;
      border-bottom: 1px solid white; } }

/* line 228, src/assets/scss/general/_header.scss */
header a.nav-link.darkline.p1 {
  padding: 0;
  border-bottom: 2px solid white;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 42px;
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #333333;
  text-transform: uppercase; }
  @media screen and (max-width: 767px) {
    /* line 228, src/assets/scss/general/_header.scss */
    header a.nav-link.darkline.p1 {
      margin-left: 15px;
      margin-right: 15px; } }
  @media screen and (max-width: 767px) and (max-width: 992px) {
    /* line 228, src/assets/scss/general/_header.scss */
    header a.nav-link.darkline.p1 {
      margin-left: 15px;
      margin-right: 15px;
      border-bottom: 2px solid #444444; } }

/* line 254, src/assets/scss/general/_header.scss */
header .dropdown-menu {
  padding: 0px;
  margin: 0px;
  margin-top: 0;
  border: none;
  box-shadow: 0px 3px 8px #646464;
  border-radius: 0;
  border-top: 1px solid #dee2e6 !important; }
  @media screen and (max-width: 767px) {
    /* line 254, src/assets/scss/general/_header.scss */
    header .dropdown-menu {
      box-shadow: none;
      border-radius: none; } }
  /* line 266, src/assets/scss/general/_header.scss */
  header .dropdown-menu a {
    font-family: "TiemposText", sans-serif; }

/* line 270, src/assets/scss/general/_header.scss */
header a.dropdown-item:hover {
  background-color: #ff6b35;
  color: #ffffff;
  border: none;
  border-radius: 0; }

/* line 276, src/assets/scss/general/_header.scss */
header li.dropdown-item {
  padding: 0; }

/* line 279, src/assets/scss/general/_header.scss */
header li.dropdown-item :hover {
  background-color: #ff6b35;
  padding: 0;
  margin: 0;
  border-radius: none;
  border: none; }

/* line 286, src/assets/scss/general/_header.scss */
header ul.dropdown-menu.show {
  text-decoration: underline;
  text-decoration-color: #ff6b35;
  border: none; }

/* line 291, src/assets/scss/general/_header.scss */
header a.nav-link.darkline.p1:hover {
  border-bottom: 2px solid #ff6b35;
  border-radius: 0; }
  @media screen and (max-width: 992px) {
    /* line 291, src/assets/scss/general/_header.scss */
    header a.nav-link.darkline.p1:hover {
      border-bottom: 2px solid #ff6b35; } }
  @media screen and (max-width: 992px) and (max-width: 767px) {
    /* line 291, src/assets/scss/general/_header.scss */
    header a.nav-link.darkline.p1:hover {
      color: #444444;
      border-bottom: 2px solid #444444; } }

/* line 302, src/assets/scss/general/_header.scss */
header a.nav-link.bottom.bottom2:hover {
  border-bottom: 2px solid #ff6b35;
  border-radius: 0; }
  @media screen and (max-width: 992px) {
    /* line 302, src/assets/scss/general/_header.scss */
    header a.nav-link.bottom.bottom2:hover {
      color: #444444;
      border-bottom: 2px solid #ff6b35; } }
  @media screen and (max-width: 576px) {
    /* line 302, src/assets/scss/general/_header.scss */
    header a.nav-link.bottom.bottom2:hover {
      color: #444444;
      border-bottom: 2px solid #222222; } }

/* line 315, src/assets/scss/general/_header.scss */
header .primary-navigation ul li:hover a {
  background: #f39cbd;
  border-radius: 0; }

/* line 319, src/assets/scss/general/_header.scss */
header a.nav-link.darkline.p1:hover a {
  background: #f39cbd;
  border-radius: 0; }

/* line 324, src/assets/scss/general/_header.scss */
header .navbar-expand-md.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0; }

@media screen and (max-width: 992px) {
  /* line 329, src/assets/scss/general/_header.scss */
  header a.nav-link.testlink.ptest {
    color: white;
    font-family: "Work Sans", "sans-serif";
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    line-height: 44px;
    color: #ffffff;
    margin-left: 15px; } }

@media screen and (max-width: 992px) {
  /* line 344, src/assets/scss/general/_header.scss */
  header a.nav-link.testlink.ptest:hover {
    color: white;
    border-bottom: 1px solid #ffffff; } }

/* line 350, src/assets/scss/general/_header.scss */
header a.nav-link.testlink.ptest::after {
  color: #ffffff; }

@media screen and (max-width: 992px) {
  /* line 353, src/assets/scss/general/_header.scss */
  header button.navbar-toggler {
    border: none; } }

/* line 358, src/assets/scss/general/_header.scss */
header button.navbar-toggler:hover {
  border-radius: 0; }
  @media screen and (max-width: 992px) {
    /* line 358, src/assets/scss/general/_header.scss */
    header button.navbar-toggler:hover {
      border: none;
      border-radius: 0; } }

/* line 365, src/assets/scss/general/_header.scss */
header .navbar-toggle.collapsed {
  background-color: #000000 !important; }

@media screen and (max-width: 992px) {
  /* line 369, src/assets/scss/general/_header.scss */
  header .divinline {
    width: 200px;
    text-align: left;
    margin-bottom: 15px;
    margin-top: 16px; } }

@media screen and (max-width: 992px) {
  /* line 377, src/assets/scss/general/_header.scss */
  header .singlecharacter {
    display: inline-block;
    width: 10px;
    height: 33px;
    background-color: #485d30;
    color: white;
    text-align: center;
    vertical-align: middle;
    line-height: 31px;
    font-size: 16px; } }

@media screen and (max-width: 992px) {
  /* line 390, src/assets/scss/general/_header.scss */
  header .multiplesinglecharacter {
    display: inline-block;
    width: 57px;
    height: 33px;
    background-color: #485d30;
    color: white;
    text-align: center;
    vertical-align: middle;
    line-height: 31px;
    font-size: 16px; } }

@media (max-width: 992px) {
  /* line 405, src/assets/scss/general/_header.scss */
  header .navbar-toggler {
    border: none;
    outline: none; } }

@media screen and (max-width: 992px) {
  /* line 410, src/assets/scss/general/_header.scss */
  header .nav-link {
    position: relative; } }

@media screen and (max-width: 767px) and (max-width: 992px) {
  /* line 417, src/assets/scss/general/_header.scss */
  header .nav-link.expand:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    right: 2px;
    border-right: 1px solid #222222;
    border-bottom: 1px solid #222222;
    transform: rotate(45deg);
    top: 17px; } }

/* line 435, src/assets/scss/general/_header.scss */
header .nav-link.testlink {
  padding: 0px; }

@media screen and (max-width: 767px) and (max-width: 992px) {
  /* line 443, src/assets/scss/general/_header.scss */
  header .nav-item.show .nav-link.linkfont.expand:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    right: 2px;
    border-right: 1px solid #222222;
    border-bottom: 1px solid #222222;
    transform: rotate(225deg);
    top: 23px; } }

/* line 461, src/assets/scss/general/_header.scss */
header nav.navbar.navbar-expand-md.navbar-light.sticky.top {
  border-bottom: 1px solid #dee2e6 !important;
  border-top: 1px solid #dee2e6 !important; }
  @media screen and (max-width: 992px) {
    /* line 461, src/assets/scss/general/_header.scss */
    header nav.navbar.navbar-expand-md.navbar-light.sticky.top {
      border-bottom: 1px solid #dee2e6 !important; } }

/* line 470, src/assets/scss/general/_header.scss */
header img.mainlogo {
  margin-top: 10px;
  max-width: 190px;
  background: #fff; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    /* line 470, src/assets/scss/general/_header.scss */
    header img.mainlogo {
      width: auto;
      height: 99px; } }
  @media screen and (max-width: 767px) {
    /* line 470, src/assets/scss/general/_header.scss */
    header img.mainlogo {
      margin-top: 0px;
      width: auto;
      height: 100%; } }

@media screen and (min-width: 768px) {
  /* line 486, src/assets/scss/general/_header.scss */
  header li.dropdown:hover > .dropdown-menu {
    display: block; } }

@media screen and (max-width: 767px) {
  /* line 492, src/assets/scss/general/_header.scss */
  header li.dropdown > .dropdown-menu {
    padding: 0 30px; }
    /* line 494, src/assets/scss/general/_header.scss */
    header li.dropdown > .dropdown-menu a.dropdown-item {
      padding: 0;
      line-height: 50px; }
      /* line 497, src/assets/scss/general/_header.scss */
      header li.dropdown > .dropdown-menu a.dropdown-item:last-child {
        border-bottom: 0 !important; } }

/* line 503, src/assets/scss/general/_header.scss */
header .dropdown::after .dropdown-menu {
  display: none; }

@media screen and (max-width: 576px) {
  /* line 507, src/assets/scss/general/_header.scss */
  header .navbar-light .navbar-nav .nav-link:hover {
    color: white; } }

@media screen and (max-width: 992px) {
  /* line 512, src/assets/scss/general/_header.scss */
  header nav.navbar.navbar-expand-md.navbar-light.sticky.top {
    z-index: 9999; } }

/* line 518, src/assets/scss/general/_header.scss */
header nav.navbar.navbar-expand-md.navbar-light.sticky.top > .container {
  position: relative; }

@media screen and (min-width: 576px) {
  /* line 529, src/assets/scss/general/_header.scss */
  header li.nav-item:hover .nav-link {
    border-bottom: 2px solid #ff6b35; } }
  @media screen and (min-width: 576px) and (max-width: 576px) {
    /* line 529, src/assets/scss/general/_header.scss */
    header li.nav-item:hover .nav-link {
      border-bottom: 2px solid #222222; } }

@media screen and (min-width: 576px) {
  /* line 540, src/assets/scss/general/_header.scss */
  header .dropdown:hover .dropdown-menu {
    display: block; } }

/* line 545, src/assets/scss/general/_header.scss */
header .nav-item:link, header .nav-link:link {
  display: block; }

/* line 548, src/assets/scss/general/_header.scss */
header .nav-item:link, header .nav-link:active {
  display: block; }

/* line 1, src/assets/scss/general/_footer.scss */
footer {
  background-color: #222222;
  /* WebKit, Blink, Edge */
  /* Mozilla Firefox 4 to 18 */
  /* Mozilla Firefox 19+ */
  /* Internet Explorer 10-11 */
  /* Microsoft Edge */ }
  @media screen and (max-width: 992px) {
    /* line 3, src/assets/scss/general/_footer.scss */
    footer .mobilehide {
      display: none; } }
  @media screen and (max-width: 992px) {
    /* line 9, src/assets/scss/general/_footer.scss */
    footer .center-mobile {
      text-align: left; } }
  @media screen and (max-width: 767px) {
    /* line 9, src/assets/scss/general/_footer.scss */
    footer .center-mobile {
      text-align: center; } }
  /* line 18, src/assets/scss/general/_footer.scss */
  footer #email {
    border-radius: 0;
    height: 20%;
    width: 10px;
    text-align: left; }
  /* line 24, src/assets/scss/general/_footer.scss */
  footer .email2 {
    border-radius: 0;
    text-align: left;
    width: 488px;
    height: 53px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: #222222;
    border-color: #ffffff;
    border: 1px solid #ffffff;
    text-align: left; }
    @media screen and (max-width: 992px) {
      /* line 24, src/assets/scss/general/_footer.scss */
      footer .email2 {
        height: 50px;
        font-family: "Work Sans", "sans-serif";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        background-color: #222222;
        border: 1px solid #ffffff;
        text-align: left;
        width: 226px; } }
    @media screen and (max-width: 321px) {
      /* line 24, src/assets/scss/general/_footer.scss */
      footer .email2 {
        height: 50px;
        font-family: "Work Sans", "sans-serif";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        background-color: #222222;
        border: 1px solid #ffffff;
        text-align: left;
        width: 170px; } }
  /* line 70, src/assets/scss/general/_footer.scss */
  footer .buttonemail {
    cursor: pointer;
    border-radius: 0;
    font-family: "Work Sans", "sans-serif";
    color: #ffffff;
    width: 300px;
    height: 53px;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #ff6c2d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #ff6c2d; }
    @media screen and (max-width: 992px) {
      /* line 70, src/assets/scss/general/_footer.scss */
      footer .buttonemail {
        border-radius: 0;
        width: 265px;
        height: 50px;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        text-align: center;
        vertical-align: middle;
        background-color: #ff6c2d;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px; } }
    @media screen and (max-width: 321px) {
      /* line 70, src/assets/scss/general/_footer.scss */
      footer .buttonemail {
        border-radius: 0;
        width: 265px;
        height: 50px;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        text-align: center;
        vertical-align: middle;
        background-color: #ff6c2d;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px; } }
    /* line 138, src/assets/scss/general/_footer.scss */
    footer .buttonemail:hover {
      background-color: #cc552b; }
  /* line 143, src/assets/scss/general/_footer.scss */
  footer .right {
    position: left; }
  @media (max-width: 991px) {
    /* line 1, src/assets/scss/general/_footer.scss */
    footer {
      /* making the border-radius correct */
      /* fixing margin */ }
      /* line 147, src/assets/scss/general/_footer.scss */
      footer .btn-group.my-btn-group-responsive > .btn {
        display: block;
        width: 100%; }
      /* line 153, src/assets/scss/general/_footer.scss */
      footer .btn-group.my-btn-group-responsive > .btn:first-child {
        border-radius: 6px 6px 0 0; }
      /* line 156, src/assets/scss/general/_footer.scss */
      footer .btn-group.my-btn-group-responsive > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: 6px; }
      /* line 159, src/assets/scss/general/_footer.scss */
      footer .btn-group.my-btn-group-responsive > .btn:last-child:not(:first-child) {
        border-radius: 0 0 6px 6px; }
      /* line 164, src/assets/scss/general/_footer.scss */
      footer .btn-group.my-btn-group-responsive .btn + .btn {
        margin-left: 0; } }
  /* line 169, src/assets/scss/general/_footer.scss */
  footer h3 {
    margin-bottom: 30px; }
  /* line 172, src/assets/scss/general/_footer.scss */
  footer .level1 {
    width: 100%;
    padding-top: 56px;
    padding-bottom: 37px;
    border-bottom: 1px solid;
    border-color: #dddddd;
    position: relative; }
    @media screen and (max-width: 992px) {
      /* line 172, src/assets/scss/general/_footer.scss */
      footer .level1 {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 30px;
        margin-bottom: 0;
        border: none; } }
  /* line 187, src/assets/scss/general/_footer.scss */
  footer ul li {
    line-height: normal;
    margin-bottom: 20px; }
    /* line 189, src/assets/scss/general/_footer.scss */
    footer ul li:first-of-type {
      margin-top: 28px; }
      @media screen and (max-width: 992px) {
        /* line 189, src/assets/scss/general/_footer.scss */
        footer ul li:first-of-type {
          margin-top: 60px; } }
  /* line 197, src/assets/scss/general/_footer.scss */
  footer ul.list-inline {
    display: inline-block; }
  /* line 200, src/assets/scss/general/_footer.scss */
  footer .spacebetweenmenus {
    width: 100%;
    margin-top: 60px; }
  /* line 204, src/assets/scss/general/_footer.scss */
  footer .elementstyle {
    float: right; }
  /* line 207, src/assets/scss/general/_footer.scss */
  footer a:hover {
    color: #B3B6B7;
    text-decoration: none; }
  /* line 212, src/assets/scss/general/_footer.scss */
  footer .form-control::-webkit-input-placeholder {
    color: #ffffff; }
    @media screen and (max-width: 992px) {
      /* line 212, src/assets/scss/general/_footer.scss */
      footer .form-control::-webkit-input-placeholder {
        color: #ffffff; } }
  /* line 219, src/assets/scss/general/_footer.scss */
  footer .form-control:-moz-placeholder {
    color: #ffffff; }
    @media screen and (max-width: 992px) {
      /* line 219, src/assets/scss/general/_footer.scss */
      footer .form-control:-moz-placeholder {
        color: #ffffff; } }
  /* line 226, src/assets/scss/general/_footer.scss */
  footer .form-control::-moz-placeholder {
    color: #ffffff; }
    @media screen and (max-width: 992px) {
      /* line 226, src/assets/scss/general/_footer.scss */
      footer .form-control::-moz-placeholder {
        color: #ffffff; } }
  /* line 233, src/assets/scss/general/_footer.scss */
  footer .form-control:-ms-input-placeholder {
    color: #ffffff; }
    @media screen and (max-width: 992px) {
      /* line 233, src/assets/scss/general/_footer.scss */
      footer .form-control:-ms-input-placeholder {
        color: #ffffff; } }
  /* line 240, src/assets/scss/general/_footer.scss */
  footer .form-control::-ms-input-placeholder {
    color: #ffffff; }
    @media screen and (max-width: 992px) {
      /* line 240, src/assets/scss/general/_footer.scss */
      footer .form-control::-ms-input-placeholder {
        color: #ffffff; } }
  /* line 248, src/assets/scss/general/_footer.scss */
  footer h3 {
    margin-bottom: 30px; }
  /* line 251, src/assets/scss/general/_footer.scss */
  footer .listone {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff; }
  /* line 261, src/assets/scss/general/_footer.scss */
  footer .listtwo {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff; }
  /* line 270, src/assets/scss/general/_footer.scss */
  footer .listheader {
    font-family: "Work Sans", "sans-serif";
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    color: #ffffff; }
  /* line 277, src/assets/scss/general/_footer.scss */
  footer input[type=email]:focus {
    outline: 2px solid #ffffff;
    background-color: #222222;
    color: #ffffff;
    /* oranges! yey */ }
  /* line 283, src/assets/scss/general/_footer.scss */
  footer .flex {
    display: flex;
    position: absolute;
    text-align: right;
    vertical-align: bottom;
    bottom: 0px;
    right: 15px; }
    @media screen and (max-width: 992px) {
      /* line 283, src/assets/scss/general/_footer.scss */
      footer .flex {
        left: 0%;
        vertical-align: bottom;
        position: absolute; } }
  /* line 296, src/assets/scss/general/_footer.scss */
  footer .flex div {
    flex: 1;
    margin-left: 40px;
    /* need some space ?*/ }
    @media screen and (max-width: 992px) {
      /* line 296, src/assets/scss/general/_footer.scss */
      footer .flex div {
        margin-left: 0px; }
        /* line 300, src/assets/scss/general/_footer.scss */
        footer .flex div :first-of-type {
          margin-left: 15px;
          margin-bottom: 15px; } }
  /* line 307, src/assets/scss/general/_footer.scss */
  footer input.form-control.email2.white {
    color: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
    @media screen and (max-width: 992px) {
      /* line 307, src/assets/scss/general/_footer.scss */
      footer input.form-control.email2.white {
        border-radius: 0; } }
  /* line 315, src/assets/scss/general/_footer.scss */
  footer img {
    height: 25px;
    width: 25px;
    vertical-align: bottom; }
    @media screen and (max-width: 992px) {
      /* line 315, src/assets/scss/general/_footer.scss */
      footer img {
        text-align: center;
        vertical-align: middle;
        margin-bottom: 29px; } }
  /* line 325, src/assets/scss/general/_footer.scss */
  footer img:hover {
    opacity: 0.4; }
  /* line 328, src/assets/scss/general/_footer.scss */
  footer a {
    vertical-align: bottom; }
  /* line 331, src/assets/scss/general/_footer.scss */
  footer .asimplediv {
    margin-top: 0px; }
    @media screen and (max-width: 767px) {
      /* line 331, src/assets/scss/general/_footer.scss */
      footer .asimplediv {
        margin-top: 0px; } }
  @media screen and (max-width: 767px) and (max-width: 992px) {
    /* line 331, src/assets/scss/general/_footer.scss */
    footer .asimplediv {
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
      height: 110px;
      margin-top: 54px;
      width: 335px; } }
    @media screen and (max-width: 321px) {
      /* line 331, src/assets/scss/general/_footer.scss */
      footer .asimplediv {
        width: 300px; } }
  @media screen and (max-width: 992px) {
    /* line 350, src/assets/scss/general/_footer.scss */
    footer h4 {
      margin-bottom: 0px;
      line-height: 36px; } }
  /* line 357, src/assets/scss/general/_footer.scss */
  footer p.p1 {
    font-family: "Work Sans", "sans-serif";
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: -0.3px;
    color: #ffffff; }
  /* line 368, src/assets/scss/general/_footer.scss */
  footer .smalldiv {
    width: 45px;
    height: 45px; }
  /* line 373, src/assets/scss/general/_footer.scss */
  footer a.nav-link.alabaster.FoterFont {
    line-height: 44px; }
  /* line 376, src/assets/scss/general/_footer.scss */
  footer .fontformenudown {
    opacity: 0.6;
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #dddddd; }
  /* line 387, src/assets/scss/general/_footer.scss */
  footer a.nav-link.fontformenudown {
    padding: 0; }
  /* line 390, src/assets/scss/general/_footer.scss */
  footer ul {
    list-style: none; }
    /* line 392, src/assets/scss/general/_footer.scss */
    footer ul .list-group.g2 {
      margin-right: 75px; }
  /* line 396, src/assets/scss/general/_footer.scss */
  footer .bordermenu {
    width: 100%;
    height: 1px;
    background-color: #dddddd;
    opacity: 0.6; }
  @media screen and (max-width: 992px) {
    /* line 402, src/assets/scss/general/_footer.scss */
    footer h4.white {
      position: absolute;
      left: 15px;
      top: 98px;
      bottom: 42px; } }
  @media screen and (max-width: 992px) {
    /* line 410, src/assets/scss/general/_footer.scss */
    footer a.white.fb {
      position: absolute;
      top: 98px;
      bottom: 42px;
      left: 127px; } }
  @media screen and (max-width: 992px) {
    /* line 418, src/assets/scss/general/_footer.scss */
    footer a.white.yu {
      position: absolute;
      top: 98px;
      bottom: 42px;
      left: 180px; } }
  @media screen and (max-width: 992px) {
    /* line 426, src/assets/scss/general/_footer.scss */
    footer a.white.tw {
      position: absolute;
      top: 98px;
      bottom: 42px;
      left: 238px; } }

/*!
 * Primary color settings
 */
/* Slider */
/* line 3, src/assets/scss/slick/slick.scss */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

/* line 17, src/assets/scss/slick/slick.scss */
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  /* line 24, src/assets/scss/slick/slick.scss */
  .slick-list:focus {
    outline: none; }
  /* line 28, src/assets/scss/slick/slick.scss */
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

/* line 33, src/assets/scss/slick/slick.scss */
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* line 42, src/assets/scss/slick/slick.scss */
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  /* line 50, src/assets/scss/slick/slick.scss */
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  /* line 56, src/assets/scss/slick/slick.scss */
  .slick-track:after {
    clear: both; }
  /* line 60, src/assets/scss/slick/slick.scss */
  .slick-loading .slick-track {
    visibility: hidden; }

/* line 64, src/assets/scss/slick/slick.scss */
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  /* line 68, src/assets/scss/slick/slick.scss */
  [dir="rtl"] .slick-slide {
    float: right; }
  /* line 71, src/assets/scss/slick/slick.scss */
  .slick-slide img {
    display: block; }
  /* line 74, src/assets/scss/slick/slick.scss */
  .slick-slide.slick-loading img {
    display: none; }
  /* line 80, src/assets/scss/slick/slick.scss */
  .slick-slide.dragging img {
    pointer-events: none; }
  /* line 84, src/assets/scss/slick/slick.scss */
  .slick-initialized .slick-slide {
    display: block; }
  /* line 88, src/assets/scss/slick/slick.scss */
  .slick-loading .slick-slide {
    visibility: hidden; }
  /* line 92, src/assets/scss/slick/slick.scss */
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

/* line 98, src/assets/scss/slick/slick.scss */
.slick-arrow.slick-hidden {
  display: none; }

/* Module 01 - Module Name */
/* line 2, src/assets/scss/modules/_m01x.scss */
.m01 {
  font-size: 12px; }
  /* line 5, src/assets/scss/modules/_m01x.scss */
  .m01.sub-header .slider {
    height: 350px; }
    /* line 7, src/assets/scss/modules/_m01x.scss */
    .m01.sub-header .slider .slick-slide {
      height: 350px; }
  /* line 11, src/assets/scss/modules/_m01x.scss */
  .m01.sub-header p.ptext {
    text-align: left; }
  /* line 14, src/assets/scss/modules/_m01x.scss */
  .m01.sub-header h1.textsize40one {
    text-align: left; }
  /* line 19, src/assets/scss/modules/_m01x.scss */
  .m01 .sliderContainer {
    position: relative;
    margin-top: 40px; }
    @media screen and (max-width: 992px) {
      /* line 19, src/assets/scss/modules/_m01x.scss */
      .m01 .sliderContainer {
        margin-top: 0px; } }
  @media screen and (max-width: 992px) {
    /* line 26, src/assets/scss/modules/_m01x.scss */
    .m01 .container.mb-5 {
      padding: 0px; } }
  /* line 32, src/assets/scss/modules/_m01x.scss */
  .m01 .slider {
    width: 100%;
    margin: 0;
    height: 465px;
    position: relative; }
    @media screen and (max-width: 992px) {
      /* line 32, src/assets/scss/modules/_m01x.scss */
      .m01 .slider {
        height: 350px; } }
  /* line 42, src/assets/scss/modules/_m01x.scss */
  .m01 .slick-slide {
    background: #3a8999;
    color: white;
    font-size: 30px;
    text-align: center;
    height: 465px; }
    @media screen and (max-width: 992px) {
      /* line 42, src/assets/scss/modules/_m01x.scss */
      .m01 .slick-slide {
        height: 350px; } }
  /* line 53, src/assets/scss/modules/_m01x.scss */
  .m01 .slick-prev:before,
  .m01 .slick-next:before {
    color: black; }
  /* line 58, src/assets/scss/modules/_m01x.scss */
  .m01 .slick-dots {
    bottom: -30px; }
  /* line 62, src/assets/scss/modules/_m01x.scss */
  .m01 .progressBarContainer {
    position: relative;
    top: 10px;
    width: 170px;
    float: right; }
  /* line 69, src/assets/scss/modules/_m01x.scss */
  .m01 .progressBarContainer div {
    display: block;
    width: 25%;
    padding: 0;
    cursor: pointer;
    float: left;
    color: white; }
  /* line 78, src/assets/scss/modules/_m01x.scss */
  .m01 .progressBarContainer div:last-child {
    margin-right: 0; }
  /* line 82, src/assets/scss/modules/_m01x.scss */
  .m01 .progressBarContainer div span.progressBar {
    width: 90%;
    height: 4px;
    background-color: rgba(0, 0, 0, 0.4);
    display: block; }
  /* line 89, src/assets/scss/modules/_m01x.scss */
  .m01 .progressBarContainer div span.progressBar .inProgress {
    background-color: #ed4c22;
    width: 0%;
    height: 4px; }
  /* line 94, src/assets/scss/modules/_m01x.scss */
  .m01 .inProgress.on {
    width: 100% !important; }
  /* line 98, src/assets/scss/modules/_m01x.scss */
  .m01 .sliderButtons {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 1; }
  /* line 105, src/assets/scss/modules/_m01x.scss */
  .m01 .sliderButtons img {
    margin: 5px 5px 0px;
    width: 35px;
    height: 35px;
    opacity: 0.4;
    filter: alpha(opacity=40);
    /* For IE8 and earlier */ }
  /* line 113, src/assets/scss/modules/_m01x.scss */
  .m01 .sliderButtons img:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
    /* For IE8 and earlier */ }
  /* line 118, src/assets/scss/modules/_m01x.scss */
  .m01 .bgdiv1 {
    background-size: cover; }
  /* line 121, src/assets/scss/modules/_m01x.scss */
  .m01 .bgdiv2 {
    background-size: cover; }
  /* line 125, src/assets/scss/modules/_m01x.scss */
  .m01 .bgdiv3 {
    background-size: cover; }
  /* line 129, src/assets/scss/modules/_m01x.scss */
  .m01 .bgdiv4 {
    background-size: cover; }
  /* line 133, src/assets/scss/modules/_m01x.scss */
  .m01 .divoverimage {
    width: 490px;
    height: 138px;
    position: absolute;
    z-index: 999;
    left: 10px; }
  /* line 141, src/assets/scss/modules/_m01x.scss */
  .m01 .buttonlink {
    width: 180px;
    height: 45px;
    border-radius: 22.5px;
    background-color: #ff6b35;
    border: #ff6b35;
    position: relative;
    cursor: pointer; }
  /* line 151, src/assets/scss/modules/_m01x.scss */
  .m01 .texttittle {
    width: 490px;
    margin-left: 40px;
    z-index: 999; }
    @media screen and (max-width: 992px) {
      /* line 151, src/assets/scss/modules/_m01x.scss */
      .m01 .texttittle {
        width: 345px;
        margin-left: 15px;
        margin-right: 15px; } }
  /* line 161, src/assets/scss/modules/_m01x.scss */
  .m01 .texttittleone {
    width: 490px;
    z-index: 999; }
    @media screen and (max-width: 992px) {
      /* line 161, src/assets/scss/modules/_m01x.scss */
      .m01 .texttittleone {
        width: 100%; } }
  /* line 168, src/assets/scss/modules/_m01x.scss */
  .m01 .divbotton {
    width: 182px;
    margin-left: 40px;
    z-index: 999;
    display: flex;
    margin-bottom: 30px; }
    @media screen and (max-width: 992px) {
      /* line 168, src/assets/scss/modules/_m01x.scss */
      .m01 .divbotton {
        width: 100%;
        height: 45px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 40px;
        align-items: left;
        justify-content: left; } }
  /* line 185, src/assets/scss/modules/_m01x.scss */
  .m01 .divbottonone {
    z-index: 999;
    display: flex; }
    @media screen and (max-width: 992px) {
      /* line 185, src/assets/scss/modules/_m01x.scss */
      .m01 .divbottonone {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: center; } }
  /* line 196, src/assets/scss/modules/_m01x.scss */
  .m01 .textbody {
    width: 313px; }
    @media screen and (max-width: 992px) {
      /* line 196, src/assets/scss/modules/_m01x.scss */
      .m01 .textbody {
        width: 100%; } }
  /* line 202, src/assets/scss/modules/_m01x.scss */
  .m01 .texttittle img:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
    /* For IE8 and earlier */ }
  /* line 207, src/assets/scss/modules/_m01x.scss */
  .m01 h1.textsize40 {
    font-family: "TiemposHeadline", sans-serif;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin-bottom: 0px;
    z-index: 999;
    margin-bottom: 25px; }
    @media screen and (max-width: 992px) {
      /* line 207, src/assets/scss/modules/_m01x.scss */
      .m01 h1.textsize40 {
        font-size: 30px;
        font-weight: 500;
        line-height: 1.17; } }
  /* line 225, src/assets/scss/modules/_m01x.scss */
  .m01 h1.textsize40one {
    font-family: "TiemposHeadline", sans-serif;
    font-size: 40px;
    font-weight: 500;
    color: #fff;
    z-index: 999;
    line-height: 1;
    margin-bottom: 15px; }
    @media screen and (max-width: 992px) {
      /* line 225, src/assets/scss/modules/_m01x.scss */
      .m01 h1.textsize40one {
        font-size: 30px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        letter-spacing: normal;
        color: #fff;
        text-align: center; } }
  /* line 245, src/assets/scss/modules/_m01x.scss */
  .m01 h4 {
    font-family: "Work Sans", "sans-serif";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #fff; }
  @media screen and (max-width: 992px) {
    /* line 256, src/assets/scss/modules/_m01x.scss */
    .m01 button.navbar-toggler {
      border: none; } }
  @media screen and (max-width: 992px) {
    /* line 263, src/assets/scss/modules/_m01x.scss */
    .m01 .navbar-toggler {
      border: none; } }
  @media screen and (max-width: 992px) {
    /* line 269, src/assets/scss/modules/_m01x.scss */
    .m01 button.navbar-toggler {
      border: none; } }
  @media screen and (max-width: 992px) {
    /* line 273, src/assets/scss/modules/_m01x.scss */
    .m01 .progressBarContainer {
      float: initial;
      margin-left: auto;
      margin-right: auto; } }
  /* line 282, src/assets/scss/modules/_m01x.scss */
  .m01 .sliderButtons img:hover {
    cursor: pointer; }
  /* line 287, src/assets/scss/modules/_m01x.scss */
  .m01 .type2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 30px;
    top: 30px;
    background-size: cover;
    transform: rotate(180deg); }
    @media screen and (max-width: 992px) {
      /* line 287, src/assets/scss/modules/_m01x.scss */
      .m01 .type2 {
        display: none; } }
  /* line 302, src/assets/scss/modules/_m01x.scss */
  .m01 .type3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 85px;
    top: 30px;
    background-size: cover; }
    @media screen and (max-width: 992px) {
      /* line 302, src/assets/scss/modules/_m01x.scss */
      .m01 .type3 {
        display: none; } }
  /* line 319, src/assets/scss/modules/_m01x.scss */
  .m01 .type2 img:hover {
    cursor: pointer; }
  /* line 326, src/assets/scss/modules/_m01x.scss */
  .m01 .type3 img:hover {
    cursor: pointer; }
  /* line 331, src/assets/scss/modules/_m01x.scss */
  .m01 .type2:hover {
    opacity: 0.4; }
  /* line 334, src/assets/scss/modules/_m01x.scss */
  .m01 .type3:hover {
    opacity: 0.4; }
  /* line 338, src/assets/scss/modules/_m01x.scss */
  .m01 img.imagearrow {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    opacity: 0.4; }
  /* line 345, src/assets/scss/modules/_m01x.scss */
  .m01 .publications_slider {
    padding-bottom: 80px;
    padding-top: 0px;
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-color: #dddddd; }
  /* line 352, src/assets/scss/modules/_m01x.scss */
  .m01 a:hover {
    text-decoration: none;
    border: none;
    color: #fff;
    background-color: #cc552b !important;
    cursor: pointer; }
  /* line 361, src/assets/scss/modules/_m01x.scss */
  .m01 a.buttonlinkk {
    width: 180px;
    height: 45px;
    line-height: 44px;
    border-radius: 22.5px;
    background-color: #ff6b35;
    border: #ff6b35;
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #fff; }
    @media screen and (max-width: 992px) {
      /* line 361, src/assets/scss/modules/_m01x.scss */
      .m01 a.buttonlinkk {
        left: 0px;
        bottom: 20px; } }
  /* line 381, src/assets/scss/modules/_m01x.scss */
  .m01 a.buttonlinkkone {
    width: 180px;
    height: 45px;
    line-height: 44px;
    border-radius: 22.5px;
    background-color: #ff6b35;
    border: #ff6b35;
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #fff; }
    @media screen and (max-width: 992px) {
      /* line 381, src/assets/scss/modules/_m01x.scss */
      .m01 a.buttonlinkkone {
        width: 220px;
        height: 45px;
        margin-bottom: 25px; } }
  /* line 403, src/assets/scss/modules/_m01x.scss */
  .m01 .sliderContainer.slider.single-item.g2 {
    height: 500px; }
  /* line 407, src/assets/scss/modules/_m01x.scss */
  .m01 p.ptext {
    font-family: "TiemposText", sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.11;
    letter-spacing: normal;
    color: #fff; }
    @media screen and (max-width: 992px) {
      /* line 407, src/assets/scss/modules/_m01x.scss */
      .m01 p.ptext {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #fff;
        font-family: "Work Sans", "sans-serif";
        text-align: center; } }
  /* line 430, src/assets/scss/modules/_m01x.scss */
  .m01 .slick-slider {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text; }
  /* line 437, src/assets/scss/modules/_m01x.scss */
  .m01 .slick-list.draggable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  /* line 444, src/assets/scss/modules/_m01x.scss */
  .m01 .linkmenu {
    margin-top: 15px;
    margin-bottom: 120px;
    width: 1000px;
    height: 100px; }
  /* line 450, src/assets/scss/modules/_m01x.scss */
  .m01 .align-bottom {
    position: absolute;
    bottom: 0;
    padding: 0 40px 40px;
    width: 100%; }
    @media screen and (max-width: 992px) {
      /* line 450, src/assets/scss/modules/_m01x.scss */
      .m01 .align-bottom {
        padding: 0 15px 15px; } }

/* line 3, src/assets/scss/modules/_m02.scss */
.m02 .publications_slider {
  padding-top: 30px;
  padding-bottom: 40px;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: #dddddd;
  margin-bottom: 20px; }
  @media screen and (max-width: 992px) {
    /* line 3, src/assets/scss/modules/_m02.scss */
    .m02 .publications_slider {
      padding-bottom: 30px; } }

/* line 16, src/assets/scss/modules/_m02.scss */
.m02 h2 {
  font-family: "Work Sans", "sans-serif";
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #222222;
  margin-right: 0px;
  margin-bottom: 16px; }
  @media screen and (max-width: 992px) {
    /* line 16, src/assets/scss/modules/_m02.scss */
    .m02 h2 {
      min-height: 126px; } }

/* line 31, src/assets/scss/modules/_m02.scss */
.m02 p {
  margin-right: 0%;
  margin-left: 0%; }
  /* line 34, src/assets/scss/modules/_m02.scss */
  .m02 p.link {
    font-family: "Work Sans", "sans-serif";
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ff6b35;
    right: 0%;
    position: relative;
    margin-top: 0px; }
    @media screen and (max-width: 992px) {
      /* line 34, src/assets/scss/modules/_m02.scss */
      .m02 p.link {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        margin-bottom: 10px; } }

/* line 59, src/assets/scss/modules/_m02.scss */
.m02 .upertext {
  position: relative;
  width: 100%;
  background-color: white;
  text-align: left;
  margin-right: 15px;
  height: 76px;
  border-top: 2px solid black; }

/* line 72, src/assets/scss/modules/_m02.scss */
.m02 .firstrowarticle {
  border-top: 1px solid;
  border-color: #dddddd;
  width: 100%; }

@media screen and (max-width: 992px) {
  /* line 81, src/assets/scss/modules/_m02.scss */
  .m02 h4 {
    font-size: 12px; }
    /* line 83, src/assets/scss/modules/_m02.scss */
    .m02 h4:first-of-type {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 1px; }
    /* line 88, src/assets/scss/modules/_m02.scss */
    .m02 h4:last-of-type {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 0;
      font-weight: 500; }
  /* line 95, src/assets/scss/modules/_m02.scss */
  .m02 h2 {
    font-size: 20px;
    margin: 0px; }
    /* line 98, src/assets/scss/modules/_m02.scss */
    .m02 h2.divwithoutimg {
      font-size: 18px;
      margin: 0px;
      line-height: 1.1; } }

/* line 108, src/assets/scss/modules/_m02.scss */
.m02 h1.headerone {
  font-family: "TiemposHeadline", sans-serif;
  margin-top: 19px;
  margin-bottom: 19px; }

@media screen and (max-width: 992px) {
  /* line 107, src/assets/scss/modules/_m02.scss */
  .m02 h1 {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222222; } }

/* line 124, src/assets/scss/modules/_m02.scss */
.m02 .linkdiv {
  width: 100%;
  background-color: white;
  text-align: right;
  margin-left: 15px;
  margin-right: 15px;
  height: 100px; }
  @media screen and (max-width: 992px) {
    /* line 124, src/assets/scss/modules/_m02.scss */
    .m02 .linkdiv {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70px; } }

/* line 139, src/assets/scss/modules/_m02.scss */
.m02 a:hover {
  text-decoration: none; }

/* line 143, src/assets/scss/modules/_m02.scss */
.m02 .type2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  top: 18px;
  background-size: cover;
  transform: rotate(180deg); }
  @media screen and (max-width: 992px) {
    /* line 143, src/assets/scss/modules/_m02.scss */
    .m02 .type2 {
      display: none; } }

/* line 158, src/assets/scss/modules/_m02.scss */
.m02 .type3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 67px;
  top: 18px;
  background-size: cover; }
  @media screen and (max-width: 992px) {
    /* line 158, src/assets/scss/modules/_m02.scss */
    .m02 .type3 {
      display: none; } }

/* line 175, src/assets/scss/modules/_m02.scss */
.m02 .type2 img:hover {
  cursor: pointer;
  opacity: 0.4; }

/* line 183, src/assets/scss/modules/_m02.scss */
.m02 .type3 img:hover {
  cursor: pointer;
  opacity: 0.4; }

/* line 190, src/assets/scss/modules/_m02.scss */
.m02 .typegen:hover {
  cursor: pointer; }

/* line 194, src/assets/scss/modules/_m02.scss */
.m02 .typegen .slick-disabled {
  opacity: 0.4 !important; }

/* line 199, src/assets/scss/modules/_m02.scss */
.m02 img.imagearrow {
  width: 38px;
  height: 38px;
  border-radius: 50%; }

/* line 206, src/assets/scss/modules/_m02.scss */
.m02 .classborder {
  border-right: 1px solid #dddddd; }
  @media screen and (max-width: 992px) {
    /* line 206, src/assets/scss/modules/_m02.scss */
    .m02 .classborder {
      border-right: 1px solid #dddddd; } }

/* line 212, src/assets/scss/modules/_m02.scss */
.m02 .classborderwot {
  border-right: 1px solid #dddddd; }
  @media screen and (max-width: 992px) {
    /* line 212, src/assets/scss/modules/_m02.scss */
    .m02 .classborderwot {
      border-right: none;
      min-height: 0px; } }
  @media screen and (max-width: 576px) {
    /* line 212, src/assets/scss/modules/_m02.scss */
    .m02 .classborderwot {
      border-right: none;
      min-height: 0; } }

/* line 225, src/assets/scss/modules/_m02.scss */
.m02 .classborderwo {
  border-right: none; }
  @media screen and (max-width: 992px) {
    /* line 225, src/assets/scss/modules/_m02.scss */
    .m02 .classborderwo {
      padding-bottom: 9px;
      padding-right: 1px;
      margin-right: 14px;
      padding-left: 1px;
      margin-left: 14px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 0px;
      border-right: none;
      min-height: 0px; } }
  @media screen and (max-width: 576px) {
    /* line 225, src/assets/scss/modules/_m02.scss */
    .m02 .classborderwo {
      border-right: none;
      min-height: 0;
      min-height: 205px; } }

/* line 246, src/assets/scss/modules/_m02.scss */
.m02 .bordertest {
  height: 1px;
  width: 100%;
  background-color: #dddddd;
  margin-top: 14.5px;
  margin-bottom: 14.5px; }

@media screen and (max-width: 576px) {
  /* line 254, src/assets/scss/modules/_m02.scss */
  .m02 img {
    margin-bottom: 10px; } }

/* line 259, src/assets/scss/modules/_m02.scss */
.m02 a:hover {
  text-decoration: none;
  border: none;
  cursor: pointer;
  opacity: 0.5;
  outline: none; }

/* line 266, src/assets/scss/modules/_m02.scss */
.m02 a:focus {
  text-decoration: none;
  border: none;
  outline: none; }

/* line 273, src/assets/scss/modules/_m02.scss */
.m02 a.h44:first-of-type {
  font-family: "Work Sans", "sans-serif";
  font-size: 12px;
  font-weight: 600;
  color: #235789;
  margin-right: 0;
  margin-left: 0;
  display: inline; }

/* line 283, src/assets/scss/modules/_m02.scss */
.m02 a.pclass2 {
  min-height: 93px;
  margin-right: 0px;
  margin-left: 0%;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.40;
  letter-spacing: normal;
  color: #222222;
  display: inline;
  text-decoration: none;
  border: none;
  outline: none; }
  @media screen and (max-width: 992px) {
    /* line 283, src/assets/scss/modules/_m02.scss */
    .m02 a.pclass2 {
      display: none; } }

/* line 303, src/assets/scss/modules/_m02.scss */
.m02 a.h22 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.3;
  color: #222222;
  margin-right: 0;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 303, src/assets/scss/modules/_m02.scss */
    .m02 a.h22 {
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      /*! autoprefixer: off */
      -webkit-box-orient: vertical;
      height: 97px; } }

@media screen and (max-width: 992px) {
  /* line 328, src/assets/scss/modules/_m02.scss */
  .m02 .hidediv {
    display: none; } }

/* line 335, src/assets/scss/modules/_m02.scss */
.m02 .slick-slider {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text; }

/* line 342, src/assets/scss/modules/_m02.scss */
.m02 .slick-list.draggable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 349, src/assets/scss/modules/_m02.scss */
.m02 .coldiv {
  width: 255px; }
  /* line 351, src/assets/scss/modules/_m02.scss */
  .m02 .coldiv .div-title {
    min-height: 78px;
    max-height: 88px;
    overflow: hidden; }
    @media screen and (max-width: 767px) {
      /* line 351, src/assets/scss/modules/_m02.scss */
      .m02 .coldiv .div-title {
        margin-bottom: 15px; } }
  /* line 359, src/assets/scss/modules/_m02.scss */
  .m02 .coldiv .div-text {
    height: 61px;
    overflow: hidden; }
    @media screen and (max-width: 992px) {
      /* line 359, src/assets/scss/modules/_m02.scss */
      .m02 .coldiv .div-text {
        display: none; } }
  /* line 366, src/assets/scss/modules/_m02.scss */
  .m02 .coldiv .div-link {
    position: absolute;
    bottom: 0; }
    @media screen and (max-width: 992px) {
      /* line 366, src/assets/scss/modules/_m02.scss */
      .m02 .coldiv .div-link {
        margin-top: 50px; } }
    @media screen and (max-width: 767px) {
      /* line 366, src/assets/scss/modules/_m02.scss */
      .m02 .coldiv .div-link {
        margin-top: 0; } }
    /* line 375, src/assets/scss/modules/_m02.scss */
    .m02 .coldiv .div-link h4 {
      font-weight: 500; }
  /* line 379, src/assets/scss/modules/_m02.scss */
  .m02 .coldiv .div-link2 {
    margin-top: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 379, src/assets/scss/modules/_m02.scss */
      .m02 .coldiv .div-link2 {
        bottom: 0;
        margin-top: 7px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; } }
  @media screen and (max-width: 992px) {
    /* line 349, src/assets/scss/modules/_m02.scss */
    .m02 .coldiv {
      width: 100%; } }

/* line 398, src/assets/scss/modules/_m02.scss */
.m02 h4 {
  font-weight: 500; }

@media screen and (min-width: 993px) {
  /* line 402, src/assets/scss/modules/_m02.scss */
  .m02 .m2-slider .col-6 > .row > div {
    min-height: 235px; } }

/* line 2, src/assets/scss/modules/_m03.scss */
.m03 .rectanglemod {
  padding: 10px;
  max-width: 350px;
  border: 1px solid;
  border-color: #dddddd;
  position: relative;
  border-top: 3px solid #000;
  margin-top: 15px;
  margin-bottom: 15px; }
  @media screen and (max-width: 992px) {
    /* line 2, src/assets/scss/modules/_m03.scss */
    .m03 .rectanglemod {
      max-width: 100%;
      padding: 0;
      margin: 0;
      border: none;
      border-bottom: 1px solid;
      border-color: #dddddd;
      height: 100%; } }

/* line 24, src/assets/scss/modules/_m03.scss */
.m03 .rectanglerwo {
  width: 24px;
  height: 24px;
  position: absolute;
  border: none;
  z-index: 999;
  right: -1px;
  bottom: -1px; }
  @media screen and (max-width: 992px) {
    /* line 24, src/assets/scss/modules/_m03.scss */
    .m03 .rectanglerwo {
      display: none;
      visibility: hidden; } }

/* line 39, src/assets/scss/modules/_m03.scss */
.m03 .publications_slider {
  padding: 40px 0;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: #dddddd; }
  @media screen and (max-width: 992px) {
    /* line 39, src/assets/scss/modules/_m03.scss */
    .m03 .publications_slider {
      padding-top: 0;
      padding: 0; } }

/* line 49, src/assets/scss/modules/_m03.scss */
.m03 .digonalline {
  width: 24px; }

/* line 53, src/assets/scss/modules/_m03.scss */
.m03 .digonalline {
  background: linear-gradient(-45deg, #fff 49%, #dddddd 52%, #fff 51%); }

/* line 57, src/assets/scss/modules/_m03.scss */
.m03 p {
  margin-left: 15px;
  margin-right: 15px; }
  /* line 60, src/assets/scss/modules/_m03.scss */
  .m03 p.link {
    font-family: "Work Sans", "sans-serif";
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ff6b35;
    right: 0%;
    position: relative;
    margin-top: 18px;
    margin-right: 0; }
    @media screen and (max-width: 992px) {
      /* line 60, src/assets/scss/modules/_m03.scss */
      .m03 p.link {
        padding: 0;
        margin-top: 0;
        margin-bottom: 10px;
        margin-left: 0;
        margin-right: 0; } }
  @media screen and (max-width: 992px) {
    /* line 57, src/assets/scss/modules/_m03.scss */
    .m03 p {
      padding: 0;
      margin-top: 18px;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0; } }

/* line 92, src/assets/scss/modules/_m03.scss */
.m03 h2 {
  font-family: "TiemposHeadline", sans-serif; }
  /* line 94, src/assets/scss/modules/_m03.scss */
  .m03 h2:first-of-type {
    font-weight: 500;
    margin-top: 11px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px; }
    @media screen and (max-width: 992px) {
      /* line 94, src/assets/scss/modules/_m03.scss */
      .m03 h2:first-of-type {
        padding: 0;
        margin-top: 26px;
        margin-bottom: 14px;
        margin-left: 0;
        margin-right: 0;
        min-height: 26px;
        max-height: 26px;
        font-size: 15px; } }
  /* line 111, src/assets/scss/modules/_m03.scss */
  .m03 h2:last-of-type {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 15px;
    margin-right: 15px; }
    @media screen and (max-width: 992px) {
      /* line 111, src/assets/scss/modules/_m03.scss */
      .m03 h2:last-of-type {
        padding: 0;
        margin-top: 30px;
        margin-bottom: 7px;
        margin-left: 0;
        margin-right: 0;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.3;
        letter-spacing: normal;
        color: #222222; } }
  /* line 131, src/assets/scss/modules/_m03.scss */
  .m03 h2.withimage {
    max-height: 167px;
    min-height: 113px; }
    @media screen and (max-width: 1200px) {
      /* line 131, src/assets/scss/modules/_m03.scss */
      .m03 h2.withimage {
        min-height: 146px;
        max-height: 60px; } }
  @media screen and (max-width: 1200px) and (max-width: 576px) {
    /* line 131, src/assets/scss/modules/_m03.scss */
    .m03 h2.withimage {
      min-height: 144px;
      max-height: 60px; } }
  @media screen and (max-width: 1200px) and (max-width: 576px) and (max-width: 992px) {
    /* line 131, src/assets/scss/modules/_m03.scss */
    .m03 h2.withimage {
      min-height: 60px;
      max-height: 61px; } }
  /* line 148, src/assets/scss/modules/_m03.scss */
  .m03 h2.withoutimage {
    min-height: 96px;
    max-height: 96px; }
    @media screen and (max-width: 1200px) {
      /* line 148, src/assets/scss/modules/_m03.scss */
      .m03 h2.withoutimage {
        min-height: 95px;
        max-height: 60px; } }
  @media screen and (max-width: 1200px) and (max-width: 576px) {
    /* line 148, src/assets/scss/modules/_m03.scss */
    .m03 h2.withoutimage {
      min-height: 77px;
      max-height: 60px; } }
  @media screen and (max-width: 1200px) and (max-width: 576px) and (max-width: 992px) {
    /* line 148, src/assets/scss/modules/_m03.scss */
    .m03 h2.withoutimage {
      min-height: 60px;
      max-height: 60px; } }

/* line 165, src/assets/scss/modules/_m03.scss */
.m03 h4 {
  margin-bottom: 0;
  margin-left: 15px;
  margin-right: 15px; }
  @media screen and (max-width: 992px) {
    /* line 165, src/assets/scss/modules/_m03.scss */
    .m03 h4 {
      padding: 0;
      margin-top: 0;
      margin-bottom: 30px;
      margin-left: 0;
      margin-right: 0; } }

/* line 177, src/assets/scss/modules/_m03.scss */
.m03 .imageclass {
  max-width: 320px;
  min-height: 219.4px;
  background-size: cover;
  margin-top: 19px;
  margin-bottom: 15.6px;
  margin-left: 15px;
  margin-right: 15px; }
  @media screen and (max-width: 992px) {
    /* line 177, src/assets/scss/modules/_m03.scss */
    .m03 .imageclass {
      max-width: 100%;
      min-height: 154px;
      margin-top: 8px;
      margin-bottom: 0;
      margin-left: 1px;
      margin-right: 1px;
      padding: 0; } }
  /* line 194, src/assets/scss/modules/_m03.scss */
  .m03 .imageclass img {
    margin: 0 auto; }

/* line 199, src/assets/scss/modules/_m03.scss */
.m03 .upertext {
  position: relative;
  width: 100%;
  background-color: white;
  text-align: left;
  margin-right: 15px;
  height: 76px;
  border-top: 2px solid black; }

/* line 213, src/assets/scss/modules/_m03.scss */
.m03 h1.headerone {
  font-family: "TiemposHeadline", sans-serif;
  margin-top: 19px;
  margin-bottom: 19px; }

@media screen and (max-width: 992px) {
  /* line 212, src/assets/scss/modules/_m03.scss */
  .m03 h1 {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222222; } }

/* line 228, src/assets/scss/modules/_m03.scss */
.m03 .divsliderarrows {
  position: absolute;
  right: 0;
  top: 18px;
  background-color: red; }
  @media screen and (max-width: 992px) {
    /* line 228, src/assets/scss/modules/_m03.scss */
    .m03 .divsliderarrows {
      display: none; } }

/* line 237, src/assets/scss/modules/_m03.scss */
.m03 .divsliderarrows img {
  margin: 5px 5px 0px;
  width: 35px;
  height: 35px;
  opacity: 0.4;
  filter: alpha(opacity=40);
  /* For IE8 and earlier */ }

/* line 244, src/assets/scss/modules/_m03.scss */
.m03 .divsliderarrows img:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
  /* For IE8 and earlier */ }

/* line 248, src/assets/scss/modules/_m03.scss */
.m03 .linkdiv {
  width: 100%;
  background-color: white;
  text-align: right;
  margin-left: 15px;
  margin-right: 15px;
  height: 95px; }
  @media screen and (max-width: 992px) {
    /* line 248, src/assets/scss/modules/_m03.scss */
    .m03 .linkdiv {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70px; } }

/* line 263, src/assets/scss/modules/_m03.scss */
.m03 a:hover {
  text-decoration: none; }

/* line 267, src/assets/scss/modules/_m03.scss */
.m03 .type2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 18px;
  background-size: cover;
  transform: rotate(180deg); }
  @media screen and (max-width: 992px) {
    /* line 267, src/assets/scss/modules/_m03.scss */
    .m03 .type2 {
      display: none; } }

/* line 282, src/assets/scss/modules/_m03.scss */
.m03 .type3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 67px;
  top: 18px;
  background-size: cover; }
  @media screen and (max-width: 992px) {
    /* line 282, src/assets/scss/modules/_m03.scss */
    .m03 .type3 {
      display: none; } }

/* line 299, src/assets/scss/modules/_m03.scss */
.m03 .type2 img:hover {
  cursor: pointer;
  opacity: 0.4; }

/* line 307, src/assets/scss/modules/_m03.scss */
.m03 .type3 img:hover {
  cursor: pointer;
  opacity: 0.4; }

/* line 314, src/assets/scss/modules/_m03.scss */
.m03 .typegen:hover {
  cursor: pointer; }

/* line 318, src/assets/scss/modules/_m03.scss */
.m03 .typegen .slick-disabled {
  opacity: 0.4 !important; }

/* line 323, src/assets/scss/modules/_m03.scss */
.m03 img.imagearrow {
  width: 38px;
  height: 38px;
  border-radius: 50%; }

/* line 329, src/assets/scss/modules/_m03.scss */
.m03 h6 {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-bottom: 0;
  bottom: 5px;
  padding-right: 8px;
  font-size: 13px;
  line-height: 1.38;
  color: #222222;
  font-family: "Work Sans", "sans-serif"; }
  @media screen and (max-width: 1200px) {
    /* line 329, src/assets/scss/modules/_m03.scss */
    .m03 h6 {
      font-size: 13px;
      margin-bottom: 7px;
      margin-top: 30px; } }

/* line 347, src/assets/scss/modules/_m03.scss */
.m03 a.h44 {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #235789;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 347, src/assets/scss/modules/_m03.scss */
    .m03 a.h44 {
      padding: 0;
      margin-top: 1px;
      margin-bottom: 30px;
      margin-left: 0;
      margin-right: 0; } }

/* line 365, src/assets/scss/modules/_m03.scss */
.m03 a.paragraph {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: inline;
  color: #9b9b9b; }
  @media screen and (max-width: 992px) {
    /* line 365, src/assets/scss/modules/_m03.scss */
    .m03 a.paragraph {
      font-size: 15px;
      margin-left: 0;
      margin-right: 0; } }

/* line 381, src/assets/scss/modules/_m03.scss */
.m03 a.withimagea {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  line-height: 1.29;
  color: #222222;
  display: inline;
  overflow: hidden; }
  @media screen and (max-width: 992px) {
    /* line 381, src/assets/scss/modules/_m03.scss */
    .m03 a.withimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0; } }

/* line 398, src/assets/scss/modules/_m03.scss */
.m03 a.withoutimagea {
  margin-left: 15px;
  margin-right: 15px;
  height: 95px;
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  display: flex;
  margin-bottom: 40px; }
  @media screen and (max-width: 992px) {
    /* line 398, src/assets/scss/modules/_m03.scss */
    .m03 a.withoutimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0; } }

/* line 422, src/assets/scss/modules/_m03.scss */
.m03 a:hover {
  text-decoration: none;
  border: none;
  cursor: pointer;
  opacity: 0.5;
  outline: none; }

/* line 429, src/assets/scss/modules/_m03.scss */
.m03 a:focus {
  text-decoration: none;
  border: none;
  outline: none; }

@media screen and (max-width: 992px) {
  /* line 434, src/assets/scss/modules/_m03.scss */
  .m03 .hidediv {
    display: none; } }

@media screen and (max-width: 992px) {
  /* line 440, src/assets/scss/modules/_m03.scss */
  .m03 .slick-slide {
    display: none !important; } }

@media screen and (max-width: 992px) {
  /* line 446, src/assets/scss/modules/_m03.scss */
  .m03 .slick-slide.slick-active {
    display: block !important; } }

/* line 453, src/assets/scss/modules/_m03.scss */
.m03 .slick-slider {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text; }

/* line 460, src/assets/scss/modules/_m03.scss */
.m03 .slick-list.draggable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 468, src/assets/scss/modules/_m03.scss */
.m03 .coldiv {
  width: 320px; }
  /* line 470, src/assets/scss/modules/_m03.scss */
  .m03 .coldiv .div-title {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 39px;
    height: 65px;
    overflow: hidden; }
    @media screen and (max-width: 992px) {
      /* line 470, src/assets/scss/modules/_m03.scss */
      .m03 .coldiv .div-title {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        height: 55px;
        margin-top: 6px; } }
  /* line 485, src/assets/scss/modules/_m03.scss */
  .m03 .coldiv .div-text {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 485, src/assets/scss/modules/_m03.scss */
      .m03 .coldiv .div-text {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        height: 20px;
        margin-top: 10px; } }
  /* line 502, src/assets/scss/modules/_m03.scss */
  .m03 .coldiv .div-link {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 502, src/assets/scss/modules/_m03.scss */
      .m03 .coldiv .div-link {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        margin-top: 2px; } }
  /* line 518, src/assets/scss/modules/_m03.scss */
  .m03 .coldiv .div-titlet {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 33px;
    height: 62px;
    overflow: hidden; }
    @media screen and (max-width: 992px) {
      /* line 518, src/assets/scss/modules/_m03.scss */
      .m03 .coldiv .div-titlet {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        height: 50px;
        margin-top: 6px; } }
  /* line 533, src/assets/scss/modules/_m03.scss */
  .m03 .coldiv .div-textt {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 5px;
    height: 45px;
    overflow: hidden; }
    @media screen and (max-width: 992px) {
      /* line 533, src/assets/scss/modules/_m03.scss */
      .m03 .coldiv .div-textt {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        height: 20px; } }
  /* line 546, src/assets/scss/modules/_m03.scss */
  .m03 .coldiv .div-linkt {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 546, src/assets/scss/modules/_m03.scss */
      .m03 .coldiv .div-linkt {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        margin-top: 2px; } }
  @media screen and (max-width: 992px) {
    /* line 468, src/assets/scss/modules/_m03.scss */
    .m03 .coldiv {
      width: 100%;
      margin-bottom: -1px; } }

/* line 2, src/assets/scss/modules/_m04.scss */
.m04 h1 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #333333;
  margin-bottom: 9px; }

/* line 15, src/assets/scss/modules/_m04.scss */
.m04 h2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  margin-bottom: 34px; }

/* line 27, src/assets/scss/modules/_m04.scss */
.m04 p {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #333333;
  padding-bottom: 51px;
  margin-bottom: 0px; }
  /* line 38, src/assets/scss/modules/_m04.scss */
  .m04 p:first-child {
    margin-bottom: 30px;
    padding-bottom: 0px; }
  /* line 43, src/assets/scss/modules/_m04.scss */
  .m04 p.ptext {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d2114; }
  /* line 54, src/assets/scss/modules/_m04.scss */
  .m04 p.ptext12 {
    font-family: "Work Sans", "sans-serif";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #222222; }
  /* line 65, src/assets/scss/modules/_m04.scss */
  .m04 p.parccodion {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    margin-left: 55px;
    padding: 0;
    margin-bottom: 18px; }
    /* line 77, src/assets/scss/modules/_m04.scss */
    .m04 p.parccodion:first-child {
      padding-left: 55px;
      padding-top: 28px; }
    /* line 82, src/assets/scss/modules/_m04.scss */
    .m04 p.parccodion:last-child {
      padding-bottom: 30px; }
  /* line 87, src/assets/scss/modules/_m04.scss */
  .m04 p.psubmenu {
    font-family: "TiemposHeadline", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    text-align: left;
    border-bottom: 1px solid #dddddd; }

/* line 106, src/assets/scss/modules/_m04.scss */
.m04 ul {
  padding-right: 15px; }

/* line 111, src/assets/scss/modules/_m04.scss */
.m04 ol li {
  list-style-type: decimal-leading-zero;
  color: orangered; }

/* line 117, src/assets/scss/modules/_m04.scss */
.m04 a.fontstyle {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 129, src/assets/scss/modules/_m04.scss */
.m04 a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 45px;
  padding: 0; }

/* line 137, src/assets/scss/modules/_m04.scss */
.m04 a.linkfont {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000; }

/* line 148, src/assets/scss/modules/_m04.scss */
.m04 a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 158, src/assets/scss/modules/_m04.scss */
.m04 a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 168, src/assets/scss/modules/_m04.scss */
  .m04 a.accordionmenu:first-child {
    color: #235789; }

/* line 173, src/assets/scss/modules/_m04.scss */
.m04 a.arcodionlink {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 183, src/assets/scss/modules/_m04.scss */
.m04 a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 195, src/assets/scss/modules/_m04.scss */
.m04 a.linksubmenu {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline;
  border-bottom: 1px solid #333333; }

/* line 208, src/assets/scss/modules/_m04.scss */
.m04 a:hover {
  outline: none;
  text-decoration: none; }

/* line 213, src/assets/scss/modules/_m04.scss */
.m04 a:focus {
  outline: none;
  text-decoration: none; }

/* line 218, src/assets/scss/modules/_m04.scss */
.m04 img {
  width: 100%;
  height: auto; }

/* line 222, src/assets/scss/modules/_m04.scss */
.m04 .col-md-3.d-flex.justify-content-end {
  padding: 0; }

/* line 227, src/assets/scss/modules/_m04.scss */
.m04 ul li.nav-item {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 233, src/assets/scss/modules/_m04.scss */
.m04 ul li.nav-item:active {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 239, src/assets/scss/modules/_m04.scss */
.m04 ul li.lit {
  padding-left: 12px; }

/* line 244, src/assets/scss/modules/_m04.scss */
.m04 li.dropdown:hover > .dropdown-menu {
  display: block; }

/* line 247, src/assets/scss/modules/_m04.scss */
.m04 a.nav-link.linkfont2 {
  margin: 0; }

/* line 251, src/assets/scss/modules/_m04.scss */
.m04 a.nav-link.linkfont2:hover {
  font-weight: 600; }

/* line 256, src/assets/scss/modules/_m04.scss */
.m04 ul .collapse.list-unstyled {
  padding: 0;
  border-top: 1px solid #dddddd; }

/* line 262, src/assets/scss/modules/_m04.scss */
.m04 .triangle {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(315deg);
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px; }

/* line 275, src/assets/scss/modules/_m04.scss */
.m04 .divaccordionspace {
  margin-top: 30px;
  margin-bottom: 19px; }

/* line 280, src/assets/scss/modules/_m04.scss */
.m04 .divborder {
  border-top: 1px solid #dddddd;
  margin: 0px;
  padding: 0px; }

/* line 286, src/assets/scss/modules/_m04.scss */
.m04 .divborderbottom {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px; }

/* line 291, src/assets/scss/modules/_m04.scss */
.m04 .divacc {
  padding-top: 40px; }

/* line 295, src/assets/scss/modules/_m04.scss */
.m04 ul.nav.flex-column {
  padding-right: 0; }

/* line 298, src/assets/scss/modules/_m04.scss */
.m04 .backtp {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d2114;
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  text-align: center; }

/* line 311, src/assets/scss/modules/_m04.scss */
.m04 .arrow {
  content: "";
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 10px;
  height: 10px;
  border-right: 2px solid #222222;
  border-bottom: 2px solid #222222;
  transform: rotate(225deg);
  cursor: pointer;
  margin-bottom: 55px; }

/* line 329, src/assets/scss/modules/_m04.scss */
.m04 .nav-link.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 62px; }

/* line 343, src/assets/scss/modules/_m04.scss */
.m04 .nav-link.expand[aria-expanded="true"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
  transform: rotate(225deg);
  top: 69px; }

/* line 360, src/assets/scss/modules/_m04.scss */
.m04 .nav-item.show .nav-link.linkfont.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 2px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 62px; }

/* line 375, src/assets/scss/modules/_m04.scss */
.m04 ul.nav.flex-column.divborder {
  margin-right: 15px; }

/* line 378, src/assets/scss/modules/_m04.scss */
.m04 a.accordionmenu:hover {
  color: #235789; }

/* line 385, src/assets/scss/modules/_m04.scss */
.m04 .panel-body {
  padding: 0; }

/* line 389, src/assets/scss/modules/_m04.scss */
.m04 .panel-group .panel + .panel {
  margin-top: 0;
  border-top: 0; }

/* line 394, src/assets/scss/modules/_m04.scss */
.m04 .panel-group .panel {
  border-radius: 0; }

/* line 398, src/assets/scss/modules/_m04.scss */
.m04 .panel-default > .panel-heading {
  color: #333333;
  background-color: #fff;
  border-color: #e4e5e7;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 409, src/assets/scss/modules/_m04.scss */
.m04 .panel-default > .panel-heading a {
  display: block;
  padding: 10px 15px;
  text-decoration: none; }

/* line 415, src/assets/scss/modules/_m04.scss */
.m04 .panel-default > .panel-heading a:after {
  content: "";
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: transform .25s linear;
  -webkit-transition: -webkit-transform .25s linear; }

/* line 430, src/assets/scss/modules/_m04.scss */
.m04 .panel-default > .panel-heading a[aria-expanded="true"] {
  border-bottom: 1px solid #dddddd;
  border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 50px; }

/* line 437, src/assets/scss/modules/_m04.scss */
.m04 .panel-default > .panel-heading a[aria-expanded="true"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 30px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 20px; }

/* line 449, src/assets/scss/modules/_m04.scss */
.m04 .panel-default > .panel-heading a[aria-expanded="false"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 30px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 19px; }

/* line 461, src/assets/scss/modules/_m04.scss */
.m04 .panel-group {
  border: 1px solid #dddddd;
  margin-bottom: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative; }

/* line 474, src/assets/scss/modules/_m04.scss */
.m04 h4.panel-title {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 487, src/assets/scss/modules/_m04.scss */
.m04 input#searchid {
  width: 100%;
  background-repeat: no-repeat;
  text-indent: 20px;
  background-position: right 30px center;
  padding: 0;
  border: none;
  background-color: #d2d2d2;
  line-height: 44px; }

/* line 498, src/assets/scss/modules/_m04.scss */
.m04 input[type=text]:focus {
  background-image: none; }

/* line 501, src/assets/scss/modules/_m04.scss */
.m04 input[type=text]:active {
  background-image: none; }

/* line 504, src/assets/scss/modules/_m04.scss */
.m04 input[type=text]:hover {
  background-image: none; }

/* line 507, src/assets/scss/modules/_m04.scss */
.m04 .accordion {
  overflow-y: scroll;
  height: 100px;
  margin-left: 21px; }

/* line 513, src/assets/scss/modules/_m04.scss */
.m04 ::-webkit-scrollbar {
  width: 12px;
  background-color: #d2d2d2; }

/* line 518, src/assets/scss/modules/_m04.scss */
.m04 ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset red; }

/* line 522, src/assets/scss/modules/_m04.scss */
.m04 ::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #235789; }

/* line 526, src/assets/scss/modules/_m04.scss */
.m04 .regular-checkbox {
  -webkit-appearance: none;
  background-color: #d2d2d2;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative; }

/* line 536, src/assets/scss/modules/_m04.scss */
.m04 input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-top: 15px;
  text-align: center;
  border: 5px solid blue;
  color: blue;
  background-color: white; }

/* line 546, src/assets/scss/modules/_m04.scss */
.m04 .checkbox-custom {
  opacity: 1;
  background-color: #d2d2d2; }

/* line 551, src/assets/scss/modules/_m04.scss */
.m04 .rectanglemission {
  width: 194px;
  height: 165px;
  border-radius: 3px;
  background-color: #ededed; }
  @media screen and (max-width: 992px) {
    /* line 551, src/assets/scss/modules/_m04.scss */
    .m04 .rectanglemission {
      margin-top: 0px;
      width: 100%;
      margin: 15px; } }

/* line 562, src/assets/scss/modules/_m04.scss */
.m04 ul.no_bullet {
  list-style-type: none;
  padding: 0;
  margin: 0; }

/* line 567, src/assets/scss/modules/_m04.scss */
.m04 li.leaf {
  background: url("/assets/img/icons/arrow_back.svg") no-repeat left center;
  height: 30px;
  padding-left: 50px;
  padding-top: 3px;
  padding-right: 10px; }

/* line 2, src/assets/scss/modules/_m04x.scss */
.m04x h1 {
  margin-bottom: 18px; }

/* line 5, src/assets/scss/modules/_m04x.scss */
.m04x h2 {
  margin-bottom: 35px; }

/* line 8, src/assets/scss/modules/_m04x.scss */
.m04x h4 {
  color: #1d2114; }

/* line 11, src/assets/scss/modules/_m04x.scss */
.m04x .date-year {
  font-family: Work Sans, "sans-serif";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
  padding: 0;
  margin: 0; }

/* line 23, src/assets/scss/modules/_m04x.scss */
.m04x ol {
  margin-left: -15px;
  margin-bottom: 64px; }
  /* line 26, src/assets/scss/modules/_m04x.scss */
  .m04x ol li {
    list-style-type: decimal-leading-zero;
    color: orangered; }

/* line 31, src/assets/scss/modules/_m04x.scss */
.m04x .fontstyle {
  font-family: "TiemposText", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered;
  text-decoration: none; }

/* line 43, src/assets/scss/modules/_m04x.scss */
.m04x .before-title {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  color: #235789;
  text-transform: uppercase;
  margin: 0 0 8px 0;
  line-height: 16px; }

/* line 52, src/assets/scss/modules/_m04x.scss */
.m04x a {
  color: #333333;
  text-decoration: underline; }
  /* line 55, src/assets/scss/modules/_m04x.scss */
  .m04x a:hover {
    color: #333333; }

/* line 59, src/assets/scss/modules/_m04x.scss */
.m04x #map {
  height: 547px; }
  /* line 61, src/assets/scss/modules/_m04x.scss */
  .m04x #map.main-map {
    height: 744px; }
  /* line 64, src/assets/scss/modules/_m04x.scss */
  .m04x #map .gm-style-iw {
    width: 230px;
    padding: 0;
    border-radius: 0; }
    /* line 69, src/assets/scss/modules/_m04x.scss */
    .m04x #map .gm-style-iw .gm-style-iw-d {
      width: 230px;
      padding: 0;
      border-radius: 0;
      overflow: hidden !important; }
      /* line 75, src/assets/scss/modules/_m04x.scss */
      .m04x #map .gm-style-iw .gm-style-iw-d .map-text-info {
        padding: 12px 5px 10px 10px; }
        /* line 77, src/assets/scss/modules/_m04x.scss */
        .m04x #map .gm-style-iw .gm-style-iw-d .map-text-info h3 {
          font-family: "TiemposText", sans-serif;
          font-weight: 400; }
        /* line 81, src/assets/scss/modules/_m04x.scss */
        .m04x #map .gm-style-iw .gm-style-iw-d .map-text-info p {
          font-family: "Work Sans", "sans-serif";
          font-size: 10px;
          line-height: 10px; }
        /* line 86, src/assets/scss/modules/_m04x.scss */
        .m04x #map .gm-style-iw .gm-style-iw-d .map-text-info a {
          border-radius: 15px;
          background-color: #ff6b35;
          padding: 8px 20px;
          font-family: "Work Sans", "sans-serif";
          font-weight: 500;
          font-size: 12px;
          text-decoration: none;
          color: #fff; }
  /* line 99, src/assets/scss/modules/_m04x.scss */
  .m04x #map .gm-style .gm-style-iw-t::before, .m04x #map .gm-style .gm-style-iw-t::after {
    display: none; }

/* line 6, src/assets/scss/modules/_m05.scss */
.m05 p.link {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ff6b35;
  right: 0%;
  position: relative;
  margin-top: 20px; }

/* line 22, src/assets/scss/modules/_m05.scss */
.m05 p.pdepartment {
  max-width: 164px;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  margin: 0;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #235789; }

/* line 37, src/assets/scss/modules/_m05.scss */
.m05 p.pname {
  max-width: 220px;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  margin: 0;
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #383838; }

/* line 52, src/assets/scss/modules/_m05.scss */
.m05 p.pgroup {
  max-width: 191px;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  margin: 0;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #383838; }

/* line 67, src/assets/scss/modules/_m05.scss */
.m05 p.pspecialization {
  max-width: 220px;
  height: 100%;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #383838;
  margin-bottom: auto; }

/* line 83, src/assets/scss/modules/_m05.scss */
.m05 h1.headerone {
  font-family: "TiemposHeadline", sans-serif;
  margin-top: 19px;
  margin-bottom: 19px; }

@media screen and (max-width: 992px) {
  /* line 82, src/assets/scss/modules/_m05.scss */
  .m05 h1 {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222222; } }

/* line 99, src/assets/scss/modules/_m05.scss */
.m05 .publications_slider {
  padding: 50px 0;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: #dddddd; }
  @media screen and (max-width: 992px) {
    /* line 99, src/assets/scss/modules/_m05.scss */
    .m05 .publications_slider {
      padding: 15px 0; } }

/* line 109, src/assets/scss/modules/_m05.scss */
.m05 .divsliderarrows {
  position: absolute;
  right: 0;
  top: 18px;
  background-color: red; }
  @media screen and (max-width: 992px) {
    /* line 109, src/assets/scss/modules/_m05.scss */
    .m05 .divsliderarrows {
      display: none; } }

/* line 119, src/assets/scss/modules/_m05.scss */
.m05 .divsliderarrows img {
  margin: 5px 5px 0px;
  width: 35px;
  height: 35px;
  opacity: 0.4;
  filter: alpha(opacity=40);
  /* For IE8 and earlier */ }

/* line 127, src/assets/scss/modules/_m05.scss */
.m05 .divsliderarrows img:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
  /* For IE8 and earlier */ }

/* line 131, src/assets/scss/modules/_m05.scss */
.m05 .linkdiv {
  width: 100%;
  background-color: white;
  text-align: right;
  margin-left: 15px;
  margin-right: 15px;
  height: 100px; }
  @media screen and (max-width: 992px) {
    /* line 131, src/assets/scss/modules/_m05.scss */
    .m05 .linkdiv {
      display: flex;
      align-items: center;
      justify-content: center; } }

/* line 145, src/assets/scss/modules/_m05.scss */
.m05 .upertext {
  position: relative;
  width: 100%;
  background-color: white;
  text-align: left;
  margin-right: 15px;
  height: 76px;
  border-top: 2px solid black; }

/* line 161, src/assets/scss/modules/_m05.scss */
.m05 .profile {
  width: 100%;
  min-height: 316px;
  position: relative; }
  @media screen and (max-width: 992px) {
    /* line 161, src/assets/scss/modules/_m05.scss */
    .m05 .profile {
      border-bottom: 1px solid;
      border-color: #dddddd;
      margin-bottom: 15px; } }

/* line 172, src/assets/scss/modules/_m05.scss */
.m05 .department {
  width: 100%;
  min-height: 37px;
  display: flex;
  justify-content: center;
  top: 0px;
  text-align: center;
  vertical-align: middle;
  line-height: 90px; }

/* line 183, src/assets/scss/modules/_m05.scss */
.m05 .profilepicture {
  width: 100%;
  min-height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 57px; }

/* line 192, src/assets/scss/modules/_m05.scss */
.m05 .name {
  width: 100%;
  min-height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 199px;
  margin-top: 20px;
  margin-bottom: 20px; }

/* line 203, src/assets/scss/modules/_m05.scss */
.m05 .specialization {
  width: 100%;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 260px;
  margin-bottom: 5px; }

/* line 213, src/assets/scss/modules/_m05.scss */
.m05 a:hover {
  text-decoration: none; }

/* line 217, src/assets/scss/modules/_m05.scss */
.m05 .role {
  width: 100%;
  min-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 235px;
  margin-top: 8px;
  margin-bottom: 20px; }

/* line 228, src/assets/scss/modules/_m05.scss */
.m05 img.imageround {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover; }

/* line 234, src/assets/scss/modules/_m05.scss */
.m05 img.imagearrow {
  width: 38px;
  height: 38px;
  border-radius: 50%; }

@media screen and (max-width: 992px) {
  /* line 240, src/assets/scss/modules/_m05.scss */
  .m05 .borderclass {
    border-right: none; } }

/* line 247, src/assets/scss/modules/_m05.scss */
.m05 .type2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  top: 18px;
  background-size: cover;
  transform: rotate(180deg); }
  @media screen and (max-width: 992px) {
    /* line 247, src/assets/scss/modules/_m05.scss */
    .m05 .type2 {
      display: none; } }

/* line 262, src/assets/scss/modules/_m05.scss */
.m05 .type3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 67px;
  top: 18px;
  background-size: cover; }
  @media screen and (max-width: 992px) {
    /* line 262, src/assets/scss/modules/_m05.scss */
    .m05 .type3 {
      display: none; } }

/* line 279, src/assets/scss/modules/_m05.scss */
.m05 .type2 img:hover {
  cursor: pointer;
  opacity: 0.4; }

/* line 287, src/assets/scss/modules/_m05.scss */
.m05 .type3 img:hover {
  cursor: pointer;
  opacity: 0.4; }

/* line 294, src/assets/scss/modules/_m05.scss */
.m05 .typegen:hover {
  cursor: pointer; }

/* line 298, src/assets/scss/modules/_m05.scss */
.m05 .typegen .slick-disabled {
  opacity: 0.4 !important; }

/* line 302, src/assets/scss/modules/_m05.scss */
.m05 .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none; }
  /* line 311, src/assets/scss/modules/_m05.scss */
  .m05 .slick-dots li {
    margin: 0 0.25rem; }
  /* line 315, src/assets/scss/modules/_m05.scss */
  .m05 .slick-dots button {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #dddddd;
    text-indent: -9999px; }
  /* line 327, src/assets/scss/modules/_m05.scss */
  .m05 .slick-dots li.slick-active button {
    background-color: #192830; }

/* line 332, src/assets/scss/modules/_m05.scss */
.m05 .slick-slider {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text; }

/* line 339, src/assets/scss/modules/_m05.scss */
.m05 .slick-list.draggable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 346, src/assets/scss/modules/_m05.scss */
.m05 .col-md-3.slick-slider {
  border-right: 1px solid #fff;
  border-left: 1px solid #dddddd; }

/* line 351, src/assets/scss/modules/_m05.scss */
.m05 .col-md-3.slick-slider.slick-slide.slick-current.slick-active {
  border-left: 1px solid #fff; }

/* line 3, src/assets/scss/modules/_m06.scss */
.m06 h1 {
  font-family: "Work Sans", "sans-serif";
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #333333;
  margin-bottom: 9px; }

/* line 16, src/assets/scss/modules/_m06.scss */
.m06 h2 {
  font-family: "Work Sans", "sans-serif";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  margin-bottom: 34px; }
  /* line 27, src/assets/scss/modules/_m06.scss */
  .m06 h2.spaceheading {
    margin-top: 74px; }

/* line 32, src/assets/scss/modules/_m06.scss */
.m06 p {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #333333;
  padding-bottom: 51px;
  margin-bottom: 0px; }
  /* line 43, src/assets/scss/modules/_m06.scss */
  .m06 p:first-child {
    margin-bottom: 30px;
    padding-bottom: 0px; }
  /* line 48, src/assets/scss/modules/_m06.scss */
  .m06 p.ptext {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d2114; }
  /* line 59, src/assets/scss/modules/_m06.scss */
  .m06 p.ptext12 {
    font-family: "Work Sans", "sans-serif";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #222222; }
  /* line 70, src/assets/scss/modules/_m06.scss */
  .m06 p.parccodion {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    margin-left: 55px;
    padding: 0;
    margin-bottom: 18px; }
    /* line 82, src/assets/scss/modules/_m06.scss */
    .m06 p.parccodion:first-child {
      margin-left: 55px;
      margin-top: 28px; }
    /* line 87, src/assets/scss/modules/_m06.scss */
    .m06 p.parccodion:last-child {
      margin-bottom: 30px; }
  /* line 92, src/assets/scss/modules/_m06.scss */
  .m06 p.psubmenu {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    text-align: left;
    border-bottom: 1px solid #dddddd; }

/* line 110, src/assets/scss/modules/_m06.scss */
.m06 ul {
  padding-right: 15px; }

/* line 115, src/assets/scss/modules/_m06.scss */
.m06 ol li {
  list-style-type: decimal-leading-zero;
  color: orangered; }

/* line 121, src/assets/scss/modules/_m06.scss */
.m06 a.fontstyle {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 133, src/assets/scss/modules/_m06.scss */
.m06 a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 45px;
  padding: 0; }

/* line 141, src/assets/scss/modules/_m06.scss */
.m06 a.linkfont {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000; }

/* line 151, src/assets/scss/modules/_m06.scss */
.m06 a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 161, src/assets/scss/modules/_m06.scss */
.m06 a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 171, src/assets/scss/modules/_m06.scss */
  .m06 a.accordionmenu:first-child {
    color: #235789; }

/* line 176, src/assets/scss/modules/_m06.scss */
.m06 a.arcodionlink {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 186, src/assets/scss/modules/_m06.scss */
.m06 a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 198, src/assets/scss/modules/_m06.scss */
.m06 a.linksubmenu {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline;
  border-bottom: 1px solid #333333; }

/* line 211, src/assets/scss/modules/_m06.scss */
.m06 a:hover {
  outline: none;
  text-decoration: none; }

/* line 216, src/assets/scss/modules/_m06.scss */
.m06 a:focus {
  outline: none;
  text-decoration: none; }

/* line 221, src/assets/scss/modules/_m06.scss */
.m06 img {
  width: 100%;
  height: auto; }

/* line 225, src/assets/scss/modules/_m06.scss */
.m06 .col-md-12.d-flex.justify-content-end {
  padding: 0; }

/* line 230, src/assets/scss/modules/_m06.scss */
.m06 ul li.nav-item {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 236, src/assets/scss/modules/_m06.scss */
.m06 ul li.nav-item:active {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 242, src/assets/scss/modules/_m06.scss */
.m06 ul li.lit {
  padding-left: 12px; }

/* line 247, src/assets/scss/modules/_m06.scss */
.m06 li.dropdown:hover > .dropdown-menu {
  display: block; }

/* line 250, src/assets/scss/modules/_m06.scss */
.m06 a.nav-link.linkfont2 {
  margin: 0; }

/* line 254, src/assets/scss/modules/_m06.scss */
.m06 a.nav-link.linkfont2:hover {
  font-weight: 600; }

/* line 259, src/assets/scss/modules/_m06.scss */
.m06 ul .collapse.list-unstyled {
  padding: 0;
  border-top: 1px solid #dddddd; }

/* line 265, src/assets/scss/modules/_m06.scss */
.m06 .triangle {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(315deg);
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px; }

/* line 278, src/assets/scss/modules/_m06.scss */
.m06 .divaccordionspace {
  margin-top: 30px;
  margin-bottom: 19px; }

/* line 283, src/assets/scss/modules/_m06.scss */
.m06 .divborder {
  border-top: 1px solid #dddddd;
  margin: 0px;
  padding: 0px; }

/* line 289, src/assets/scss/modules/_m06.scss */
.m06 .divborderbottom {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px; }

/* line 294, src/assets/scss/modules/_m06.scss */
.m06 .divacc {
  padding-top: 40px; }

/* line 298, src/assets/scss/modules/_m06.scss */
.m06 ul.nav.flex-column {
  padding-right: 0; }

/* line 301, src/assets/scss/modules/_m06.scss */
.m06 .backtp {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d2114;
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  text-align: center; }

/* line 314, src/assets/scss/modules/_m06.scss */
.m06 .arrow {
  content: "";
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 10px;
  height: 10px;
  border-right: 2px solid #222222;
  border-bottom: 2px solid #222222;
  transform: rotate(225deg);
  cursor: pointer;
  margin-bottom: 55px; }

/* line 332, src/assets/scss/modules/_m06.scss */
.m06 .nav-link.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 107px; }

/* line 350, src/assets/scss/modules/_m06.scss */
.m06 .nav-item.show .nav-link.linkfont.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 2px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 107px; }

/* line 365, src/assets/scss/modules/_m06.scss */
.m06 ul.nav.flex-column.divborder {
  margin-right: 0px; }

/* line 368, src/assets/scss/modules/_m06.scss */
.m06 a.accordionmenu:hover {
  color: #235789; }

/* line 373, src/assets/scss/modules/_m06.scss */
.m06 .rectanglemission {
  width: 194px;
  height: 115px;
  border-radius: 3px;
  background-color: #ededed; }
  @media screen and (max-width: 992px) {
    /* line 373, src/assets/scss/modules/_m06.scss */
    .m06 .rectanglemission {
      width: 100%;
      margin: 15px; } }

/* line 383, src/assets/scss/modules/_m06.scss */
.m06 ul.no_bullet {
  list-style-type: none;
  padding: 0;
  margin: 0; }

/* line 388, src/assets/scss/modules/_m06.scss */
.m06 li.leaf {
  background: url("/assets/img/icons/arrow_back.svg") no-repeat left center;
  max-height: 70px;
  padding-left: 50px;
  padding-top: 0px;
  padding-right: 10px;
  margin-top: 10px; }

/* line 396, src/assets/scss/modules/_m06.scss */
.m06 .rectangdownload {
  width: 194px;
  height: 282px;
  border-radius: 3px;
  background-color: #fff;
  margin-top: 250px;
  margin-bottom: 20px;
  border: 1px solid #dddddd; }

/* line 405, src/assets/scss/modules/_m06.scss */
.m06 .imgdiv {
  width: 1100px;
  height: 480px; }

/* line 410, src/assets/scss/modules/_m06.scss */
.m06 img {
  width: 100%;
  height: auto; }

@media screen and (max-width: 992px) {
  /* line 2, src/assets/scss/modules/_m07.scss */
  .m07 .classcenter {
    display: flex;
    align-items: center;
    justify-content: center; } }

/* line 10, src/assets/scss/modules/_m07.scss */
.m07 .rectangle {
  min-height: 248px;
  position: relative;
  padding: 20px;
  border: 1px solid;
  border-color: #dddddd; }
  @media screen and (max-width: 992px) {
    /* line 10, src/assets/scss/modules/_m07.scss */
    .m07 .rectangle {
      min-height: 313px;
      max-width: 255px; } }

/* line 22, src/assets/scss/modules/_m07.scss */
.m07 .rectangle2 {
  width: 24px;
  height: 24px;
  position: absolute;
  border: 1px solid;
  z-index: 999;
  right: -1px;
  top: -1px;
  border-color: white white #dddddd #dddddd; }

/* line 34, src/assets/scss/modules/_m07.scss */
.m07 .publications_slider {
  padding-bottom: 0px;
  padding-top: 30px;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: #dddddd; }

/* line 43, src/assets/scss/modules/_m07.scss */
.m07 .one {
  width: 24px; }

/* line 47, src/assets/scss/modules/_m07.scss */
.m07 .one {
  background: linear-gradient(45deg, #fff 49%, #dddddd 50%, #fff 51%); }

/* line 52, src/assets/scss/modules/_m07.scss */
.m07 .bottomLine {
  border: 1px solid #9b9b9b;
  background-color: #9b9b9b; }

/* line 57, src/assets/scss/modules/_m07.scss */
.m07 .topLine {
  border: 1px solid #000;
  background-color: #000; }

/* line 63, src/assets/scss/modules/_m07.scss */
.m07 p.link {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ff6c2d;
  right: 0%;
  position: relative;
  margin-top: 20px; }

/* line 79, src/assets/scss/modules/_m07.scss */
.m07 .linkdiv {
  width: 100%;
  background-color: white;
  text-align: right;
  margin-left: 15px;
  margin-right: 15px;
  height: 120px; }
  @media screen and (max-width: 992px) {
    /* line 79, src/assets/scss/modules/_m07.scss */
    .m07 .linkdiv {
      display: grid;
      align-items: center;
      justify-content: center; } }

/* line 94, src/assets/scss/modules/_m07.scss */
.m07 .upertext {
  position: relative;
  width: 100%;
  background-color: white;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
  height: 75px;
  border-top: 2px solid black; }

/* line 105, src/assets/scss/modules/_m07.scss */
.m07 h1 {
  font-family: "TiemposHeadline", sans-serif;
  margin-bottom: 0px;
  margin-top: 18px;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #333333; }
  @media screen and (max-width: 992px) {
    /* line 105, src/assets/scss/modules/_m07.scss */
    .m07 h1 {
      font-size: 24px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #222222; } }

/* line 128, src/assets/scss/modules/_m07.scss */
.m07 p {
  margin-bottom: 18px; }
  /* line 131, src/assets/scss/modules/_m07.scss */
  .m07 p.p2 {
    margin-bottom: 20px; }
    /* line 134, src/assets/scss/modules/_m07.scss */
    .m07 p.p2:first-of-type {
      color: #235789;
      font-weight: 500; }
    /* line 139, src/assets/scss/modules/_m07.scss */
    .m07 p.p2:last-of-type {
      margin: 0; }
  /* line 144, src/assets/scss/modules/_m07.scss */
  .m07 p.backtotop {
    font-family: "Work Sans", "sans-serif";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d2114;
    text-align: center; }
    @media screen and (max-width: 992px) {
      /* line 144, src/assets/scss/modules/_m07.scss */
      .m07 p.backtotop {
        text-align: center;
        bottom: 5px; } }

/* line 162, src/assets/scss/modules/_m07.scss */
.m07 h2 {
  margin-bottom: 22px; }

/* line 166, src/assets/scss/modules/_m07.scss */
.m07 .divsliderarrows {
  position: absolute;
  right: 0;
  top: 18px;
  background-color: red; }
  @media screen and (max-width: 992px) {
    /* line 166, src/assets/scss/modules/_m07.scss */
    .m07 .divsliderarrows {
      display: none; } }

/* line 176, src/assets/scss/modules/_m07.scss */
.m07 .divsliderarrows img {
  margin: 5px 5px 0px;
  width: 35px;
  height: 35px;
  opacity: 0.4;
  filter: alpha(opacity=40);
  /* For IE8 and earlier */ }

/* line 184, src/assets/scss/modules/_m07.scss */
.m07 .divsliderarrows img:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
  /* For IE8 and earlier */ }

/* line 189, src/assets/scss/modules/_m07.scss */
.m07 a:hover {
  text-decoration: none; }

/* line 194, src/assets/scss/modules/_m07.scss */
.m07 img.imagearrow {
  width: 38px;
  height: 38px;
  border-radius: 50%; }

/* line 201, src/assets/scss/modules/_m07.scss */
.m07 .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 30px 0;
  list-style-type: none; }
  /* line 210, src/assets/scss/modules/_m07.scss */
  .m07 .slick-dots li {
    margin: 0 0.25rem; }
  /* line 214, src/assets/scss/modules/_m07.scss */
  .m07 .slick-dots button {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #dddddd;
    text-indent: -9999px; }
  /* line 226, src/assets/scss/modules/_m07.scss */
  .m07 .slick-dots li.slick-active button {
    background-color: #192830; }

/* line 233, src/assets/scss/modules/_m07.scss */
.m07 .type2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  top: 18px;
  background-size: cover;
  transform: rotate(180deg); }
  @media screen and (max-width: 992px) {
    /* line 233, src/assets/scss/modules/_m07.scss */
    .m07 .type2 {
      display: none; } }

/* line 249, src/assets/scss/modules/_m07.scss */
.m07 .type3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 67px;
  top: 18px;
  background-size: cover; }
  @media screen and (max-width: 992px) {
    /* line 249, src/assets/scss/modules/_m07.scss */
    .m07 .type3 {
      display: none; } }

/* line 266, src/assets/scss/modules/_m07.scss */
.m07 .type2 img:hover {
  cursor: pointer;
  opacity: 0.4; }

/* line 275, src/assets/scss/modules/_m07.scss */
.m07 .type3 img:hover {
  cursor: pointer;
  opacity: 0.4; }

/* line 283, src/assets/scss/modules/_m07.scss */
.m07 .typegen:hover {
  cursor: pointer; }

/* line 287, src/assets/scss/modules/_m07.scss */
.m07 .typegen .slick-disabled {
  opacity: 0.4 !important; }

/* line 293, src/assets/scss/modules/_m07.scss */
.m07 .toptriangle {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 2px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 17px;
  text-align: center; }

/* line 306, src/assets/scss/modules/_m07.scss */
.m07 .col-md-6.classcenter {
  padding-bottom: 30px;
  padding-left: 0;
  padding-right: 0;
  margin-left: 15px;
  margin-right: 15px; }
  @media screen and (max-width: 992px) {
    /* line 306, src/assets/scss/modules/_m07.scss */
    .m07 .col-md-6.classcenter {
      border-bottom: 1px solid #dddddd; } }

/* line 317, src/assets/scss/modules/_m07.scss */
.m07 .backtp {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d2114;
  margin: 0 auto;
  width: 100px;
  cursor: pointer;
  text-align: center; }

/* line 331, src/assets/scss/modules/_m07.scss */
.m07 .arrow {
  content: "";
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 10px;
  height: 10px;
  border-right: 2px solid #222222;
  border-bottom: 2px solid #222222;
  transform: rotate(225deg);
  cursor: pointer; }

/* line 344, src/assets/scss/modules/_m07.scss */
.m07 .slick-slider {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text; }

/* line 352, src/assets/scss/modules/_m07.scss */
.m07 .slick-list.draggable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 361, src/assets/scss/modules/_m07.scss */
.m07 a.linkh2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  margin-bottom: 22px;
  font-weight: 500;
  /*
      @media screen and (min-width: $mobile-breakpoint+1) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        */
  /*! autoprefixer: off */
  /*
        -webkit-box-orient: vertical;
        height: 65px;
      }
    */ }
  @media screen and (max-width: 992px) {
    /* line 361, src/assets/scss/modules/_m07.scss */
    .m07 a.linkh2 {
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3;
      letter-spacing: normal;
      color: #222222;
      /*
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        */
      /*! autoprefixer: off */
      /*
        -webkit-box-orient: vertical;
        height: 82px;
        */ } }

/* line 408, src/assets/scss/modules/_m07.scss */
.m07 a:hover {
  outline: none;
  text-decoration: none;
  border: none; }

/* line 414, src/assets/scss/modules/_m07.scss */
.m07 a:focus {
  outline: none;
  text-decoration: none;
  border: none; }

@media screen and (min-width: 992px) {
  /* line 421, src/assets/scss/modules/_m07.scss */
  .m07 p.paragraph {
    margin-top: 22px; } }

@media screen and (max-width: 992px) {
  /* line 421, src/assets/scss/modules/_m07.scss */
  .m07 p.paragraph {
    margin-top: 12px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222222; } }

/* line 3, src/assets/scss/modules/_m08.scss */
.m08 h1 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #333333;
  margin-bottom: 30px; }

/* line 16, src/assets/scss/modules/_m08.scss */
.m08 h2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  margin-bottom: 34px; }

/* line 28, src/assets/scss/modules/_m08.scss */
.m08 p {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #333333;
  padding-bottom: 51px;
  margin-bottom: 0px; }
  /* line 39, src/assets/scss/modules/_m08.scss */
  .m08 p:first-child {
    margin-bottom: 30px;
    padding-bottom: 0px; }
  /* line 44, src/assets/scss/modules/_m08.scss */
  .m08 p.ptext {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d2114; }
  /* line 55, src/assets/scss/modules/_m08.scss */
  .m08 p.ptext12 {
    font-family: "Work Sans", "sans-serif";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #222222; }
  /* line 66, src/assets/scss/modules/_m08.scss */
  .m08 p.parccodion {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    margin-left: 55px;
    padding: 0;
    margin-bottom: 18px; }
    /* line 78, src/assets/scss/modules/_m08.scss */
    .m08 p.parccodion:first-child {
      margin-left: 55px;
      margin-top: 28px; }
    /* line 83, src/assets/scss/modules/_m08.scss */
    .m08 p.parccodion:last-child {
      margin-bottom: 30px; }
  /* line 88, src/assets/scss/modules/_m08.scss */
  .m08 p.psubmenu {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    text-align: center;
    border-bottom: 1px solid #dddddd; }
  /* line 105, src/assets/scss/modules/_m08.scss */
  .m08 p.placeholder {
    margin-left: 20px; }

/* line 110, src/assets/scss/modules/_m08.scss */
.m08 ul {
  padding-right: 15px; }

/* line 115, src/assets/scss/modules/_m08.scss */
.m08 ol li {
  list-style-type: decimal-leading-zero;
  color: orangered; }

/* line 121, src/assets/scss/modules/_m08.scss */
.m08 a.fontstyle {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 133, src/assets/scss/modules/_m08.scss */
.m08 a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 45px;
  padding: 0; }

/* line 141, src/assets/scss/modules/_m08.scss */
.m08 a.linkfont {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000; }

/* line 152, src/assets/scss/modules/_m08.scss */
.m08 a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 162, src/assets/scss/modules/_m08.scss */
.m08 a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 172, src/assets/scss/modules/_m08.scss */
  .m08 a.accordionmenu:first-child {
    color: #235789; }

/* line 177, src/assets/scss/modules/_m08.scss */
.m08 a.arcodionlink {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 187, src/assets/scss/modules/_m08.scss */
.m08 a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 199, src/assets/scss/modules/_m08.scss */
.m08 a.linksubmenu {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline;
  border-bottom: 1px solid #333333; }

/* line 212, src/assets/scss/modules/_m08.scss */
.m08 a.h44:first-of-type {
  font-family: "Work Sans", "sans-serif";
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #235789;
  margin-right: 0%;
  margin-left: 0%;
  display: inline; }

/* line 230, src/assets/scss/modules/_m08.scss */
.m08 a.pclass2 {
  min-height: 93px;
  margin-right: 0px;
  margin-left: 0%;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.40;
  letter-spacing: normal;
  color: #222222;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 230, src/assets/scss/modules/_m08.scss */
    .m08 a.pclass2 {
      display: none; } }

/* line 247, src/assets/scss/modules/_m08.scss */
.m08 a.h22 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #222222;
  margin-right: 0px;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 247, src/assets/scss/modules/_m08.scss */
    .m08 a.h22 {
      min-height: 126px;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3; } }

/* line 268, src/assets/scss/modules/_m08.scss */
.m08 a:hover {
  outline: none;
  text-decoration: none; }

/* line 273, src/assets/scss/modules/_m08.scss */
.m08 a:focus {
  outline: none;
  text-decoration: none; }

/* line 278, src/assets/scss/modules/_m08.scss */
.m08 img {
  width: 100%;
  height: 117px; }
  /* line 281, src/assets/scss/modules/_m08.scss */
  .m08 img.classimg {
    vertical-align: middle; }

/* line 286, src/assets/scss/modules/_m08.scss */
.m08 .col-md-3.d-flex.justify-content-end {
  padding: 0; }

/* line 291, src/assets/scss/modules/_m08.scss */
.m08 ul li.nav-item {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 297, src/assets/scss/modules/_m08.scss */
.m08 ul li.nav-item:active {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 303, src/assets/scss/modules/_m08.scss */
.m08 ul li.lit {
  padding-left: 12px; }

/* line 308, src/assets/scss/modules/_m08.scss */
.m08 li.dropdown:hover > .dropdown-menu {
  display: block; }

/* line 311, src/assets/scss/modules/_m08.scss */
.m08 a.nav-link.linkfont2 {
  margin: 0; }

/* line 315, src/assets/scss/modules/_m08.scss */
.m08 a.nav-link.linkfont2:hover {
  font-weight: 600; }

/* line 320, src/assets/scss/modules/_m08.scss */
.m08 ul .collapse.list-unstyled {
  padding: 0;
  border-top: 1px solid #dddddd; }

/* line 326, src/assets/scss/modules/_m08.scss */
.m08 .triangle {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(315deg);
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px; }

/* line 339, src/assets/scss/modules/_m08.scss */
.m08 .divaccordionspace {
  margin-top: 30px;
  margin-bottom: 19px; }

/* line 344, src/assets/scss/modules/_m08.scss */
.m08 .divborder {
  border-top: 1px solid #dddddd;
  margin: 0px;
  padding: 0px; }

/* line 350, src/assets/scss/modules/_m08.scss */
.m08 .divborderbottom {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px; }

/* line 355, src/assets/scss/modules/_m08.scss */
.m08 .divacc {
  padding-top: 40px; }

/* line 359, src/assets/scss/modules/_m08.scss */
.m08 ul.nav.flex-column {
  padding-right: 0; }

/* line 363, src/assets/scss/modules/_m08.scss */
.m08 .backtp {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d2114;
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  text-align: center; }

/* line 376, src/assets/scss/modules/_m08.scss */
.m08 .arrow {
  content: "";
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 10px;
  height: 10px;
  border-right: 2px solid #222222;
  border-bottom: 2px solid #222222;
  transform: rotate(225deg);
  cursor: pointer;
  margin-bottom: 55px; }

/* line 394, src/assets/scss/modules/_m08.scss */
.m08 .nav-link.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 16px; }

/* line 408, src/assets/scss/modules/_m08.scss */
.m08 .nav-link.expand[aria-expanded="true"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
  transform: rotate(225deg);
  top: 20px; }

/* line 425, src/assets/scss/modules/_m08.scss */
.m08 .nav-item.show .nav-link.linkfont.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 2px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 16px; }

/* line 440, src/assets/scss/modules/_m08.scss */
.m08 ul.nav.flex-column.divborder {
  margin-right: 15px; }

/* line 443, src/assets/scss/modules/_m08.scss */
.m08 a.accordionmenu:hover {
  color: #235789; }

/* line 449, src/assets/scss/modules/_m08.scss */
.m08 .coldiv {
  width: 255px; }
  /* line 451, src/assets/scss/modules/_m08.scss */
  .m08 .coldiv .div-title {
    margin-top: 16px;
    margin-bottom: 20px;
    height: 93px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%; }
    @media screen and (max-width: 992px) {
      /* line 451, src/assets/scss/modules/_m08.scss */
      .m08 .coldiv .div-title {
        height: 126px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; } }
  /* line 468, src/assets/scss/modules/_m08.scss */
  .m08 .coldiv .div-text {
    height: 117px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 25px; }
    @media screen and (max-width: 992px) {
      /* line 468, src/assets/scss/modules/_m08.scss */
      .m08 .coldiv .div-text {
        display: none; } }
  /* line 480, src/assets/scss/modules/_m08.scss */
  .m08 .coldiv .div-link {
    margin-top: 28px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 480, src/assets/scss/modules/_m08.scss */
      .m08 .coldiv .div-link {
        bottom: 0;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-top: 50px; } }
  /* line 495, src/assets/scss/modules/_m08.scss */
  .m08 .coldiv .div-link2 {
    margin-top: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 495, src/assets/scss/modules/_m08.scss */
      .m08 .coldiv .div-link2 {
        bottom: 0;
        margin-top: 7px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; } }
  @media screen and (max-width: 992px) {
    /* line 449, src/assets/scss/modules/_m08.scss */
    .m08 .coldiv {
      width: 100%; } }

/* line 515, src/assets/scss/modules/_m08.scss */
.m08 .classborder {
  border-right: 1px solid #dddddd; }
  @media screen and (max-width: 992px) {
    /* line 515, src/assets/scss/modules/_m08.scss */
    .m08 .classborder {
      border-right: 1px solid #dddddd; } }

/* line 521, src/assets/scss/modules/_m08.scss */
.m08 .classborderwot {
  border-right: 1px solid #dddddd; }
  @media screen and (max-width: 992px) {
    /* line 521, src/assets/scss/modules/_m08.scss */
    .m08 .classborderwot {
      border-right: none;
      min-height: 0px; } }
  @media screen and (max-width: 576px) {
    /* line 521, src/assets/scss/modules/_m08.scss */
    .m08 .classborderwot {
      border-right: none;
      min-height: 0; } }

/* line 534, src/assets/scss/modules/_m08.scss */
.m08 .classborderwo {
  border-right: none; }
  @media screen and (max-width: 992px) {
    /* line 534, src/assets/scss/modules/_m08.scss */
    .m08 .classborderwo {
      padding-bottom: 9px;
      padding-right: 1px;
      margin-right: 14px;
      padding-left: 1px;
      margin-left: 14px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 0px;
      border-right: none;
      min-height: 0px; } }
  @media screen and (max-width: 576px) {
    /* line 534, src/assets/scss/modules/_m08.scss */
    .m08 .classborderwo {
      border-right: none;
      min-height: 0; } }

/* line 554, src/assets/scss/modules/_m08.scss */
.m08 .imagediv {
  width: 100%;
  height: 117px; }

/* line 559, src/assets/scss/modules/_m08.scss */
.m08 .col-lg-12.order-lg-1.coldiv {
  padding-right: 0; }

/* line 562, src/assets/scss/modules/_m08.scss */
.m08 .col-lg-12.order-lg-2 {
  padding-right: 0; }

/* line 565, src/assets/scss/modules/_m08.scss */
.m08 .divspaces {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 15px;
  height: 1px;
  width: 100%;
  background-color: #dddddd; }
  @media screen and (max-width: 992px) {
    /* line 565, src/assets/scss/modules/_m08.scss */
    .m08 .divspaces {
      display: none; } }

/* line 577, src/assets/scss/modules/_m08.scss */
.m08 .divspaces2 {
  display: none; }
  @media screen and (max-width: 992px) {
    /* line 577, src/assets/scss/modules/_m08.scss */
    .m08 .divspaces2 {
      margin-top: 24px;
      margin-bottom: 24px;
      margin-left: 15px;
      height: 1px;
      width: 100%;
      background-color: #dddddd;
      display: block; } }

/* line 591, src/assets/scss/modules/_m08.scss */
.m08 h4 {
  margin: 0; }

/* line 595, src/assets/scss/modules/_m08.scss */
.m08 .publications_slider {
  padding-top: 30px;
  padding-bottom: 40px;
  margin-bottom: 20px; }
  @media screen and (max-width: 992px) {
    /* line 595, src/assets/scss/modules/_m08.scss */
    .m08 .publications_slider {
      padding-bottom: 30px; } }

/* line 604, src/assets/scss/modules/_m08.scss */
.m08 .pagination {
  display: inline-block;
  border: 1px solid #dddddd;
  position: absolute;
  right: 0; }

/* line 612, src/assets/scss/modules/_m08.scss */
.m08 .pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none; }

/* line 620, src/assets/scss/modules/_m08.scss */
.m08 .pagination a.active {
  background-color: #dddddd;
  border: 1px solid #dddddd;
  cursor: default; }

/* line 626, src/assets/scss/modules/_m08.scss */
.m08 .pagination a:hover:not(.active) {
  background-color: #fff; }

/* line 630, src/assets/scss/modules/_m08.scss */
.m08 .pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

/* line 635, src/assets/scss/modules/_m08.scss */
.m08 .pagination a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

/* line 639, src/assets/scss/modules/_m08.scss */
.m08 .divpagenation {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 50px;
  margin-bottom: 100px;
  position: relative; }
  @media screen and (max-width: 992px) {
    /* line 639, src/assets/scss/modules/_m08.scss */
    .m08 .divpagenation {
      display: none; } }

/* line 654, src/assets/scss/modules/_m08.scss */
.m08 a.arrowpg {
  color: #235789;
  font-size: 28px;
  padding-top: 0;
  padding-bottom: 0; }

/* line 662, src/assets/scss/modules/_m08.scss */
.m08 .searchnews {
  width: 100%;
  height: 50px;
  border: 1px solid #dddddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-position: right 30px center;
  background-repeat: no-repeat;
  color: #dddddd;
  padding-left: 20px; }

/* line 676, src/assets/scss/modules/_m08.scss */
.m08 .daterange {
  margin-top: 30px;
  width: 100%;
  height: 50px;
  border: 1px solid #dddddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-position: left 16px center;
  background-repeat: no-repeat;
  color: #dddddd;
  padding-left: 70px; }

/* line 692, src/assets/scss/modules/_m08.scss */
.m08 .form-control::-webkit-input-placeholder {
  margin-left: 20px;
  padding-left: 20px; }
  @media screen and (max-width: 992px) {
    /* line 692, src/assets/scss/modules/_m08.scss */
    .m08 .form-control::-webkit-input-placeholder {
      color: #fff; } }

/* line 699, src/assets/scss/modules/_m08.scss */
.m08 .checkboxclass {
  width: 30px;
  height: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  border: 1px solid #235789; }

/* line 711, src/assets/scss/modules/_m08.scss */
.m08 .styled-checkbox {
  position: absolute;
  opacity: 0;
  border: 1px solid #235789; }
  /* line 716, src/assets/scss/modules/_m08.scss */
  .m08 .styled-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0; }
  /* line 723, src/assets/scss/modules/_m08.scss */
  .m08 .styled-checkbox + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 30px;
    height: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: white;
    border: 1px solid #235789; }
  /* line 741, src/assets/scss/modules/_m08.scss */
  .m08 .styled-checkbox:checked + label:before {
    background: #fff; }
  /* line 746, src/assets/scss/modules/_m08.scss */
  .m08 .styled-checkbox:disabled + label {
    color: #fff;
    cursor: auto; }
  /* line 752, src/assets/scss/modules/_m08.scss */
  .m08 .styled-checkbox:disabled + label:before {
    box-shadow: none;
    background: #dddddd; }
  /* line 758, src/assets/scss/modules/_m08.scss */
  .m08 .styled-checkbox:checked + label:after {
    content: '\2713';
    position: absolute;
    left: 10px;
    top: 2px;
    color: #235789; }

/* line 766, src/assets/scss/modules/_m08.scss */
.m08 label {
  margin-top: 40px;
  margin-bottom: 17px; }

/* line 771, src/assets/scss/modules/_m08.scss */
.m08 .smalldatediv {
  height: 40px;
  width: 40px;
  background-color: red; }

/* line 777, src/assets/scss/modules/_m08.scss */
.m08 .bigdatediv {
  height: 40px;
  width: 100%;
  background-color: aqua; }

@media screen and (max-width: 992px) {
  /* line 783, src/assets/scss/modules/_m08.scss */
  .m08 .row.divaccordionspace {
    display: none; } }

/* line 788, src/assets/scss/modules/_m08.scss */
.m08 .styled-checkbox:after {
  content: "\2713";
  position: absolute;
  left: 9px;
  top: 0px;
  color: #235789;
  font-size: 20px; }

/* line 796, src/assets/scss/modules/_m08.scss */
.m08 .styled-checkbox:checked + label:after {
  content: "\2713";
  position: absolute;
  left: 9px;
  top: 0px;
  color: #235789;
  font-size: 20px; }

/* line 3, src/assets/scss/modules/_m09.scss */
.m09 h1 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #333333;
  margin-bottom: 9px; }

/* line 16, src/assets/scss/modules/_m09.scss */
.m09 h2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  margin-bottom: 34px; }

/* line 28, src/assets/scss/modules/_m09.scss */
.m09 .divaccordionspace {
  margin-top: 30px;
  margin-bottom: 19px; }

/* line 33, src/assets/scss/modules/_m09.scss */
.m09 a.accordionmenu:hover {
  color: #235789; }

/* line 38, src/assets/scss/modules/_m09.scss */
.m09 .divacc {
  padding-top: 40px; }

/* line 42, src/assets/scss/modules/_m09.scss */
.m09 p {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #333333;
  padding-bottom: 51px;
  margin-bottom: 0px; }
  /* line 53, src/assets/scss/modules/_m09.scss */
  .m09 p:first-child {
    margin-bottom: 30px;
    padding-bottom: 0px; }
  /* line 58, src/assets/scss/modules/_m09.scss */
  .m09 p.ptext {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d2114; }
  /* line 69, src/assets/scss/modules/_m09.scss */
  .m09 p.ptext12 {
    font-family: "Work Sans", "sans-serif";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #222222; }
  /* line 80, src/assets/scss/modules/_m09.scss */
  .m09 p.parccodion {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    margin-left: 55px;
    padding: 0;
    margin-bottom: 18px; }
    /* line 92, src/assets/scss/modules/_m09.scss */
    .m09 p.parccodion:first-child {
      margin-left: 55px;
      margin-top: 28px; }
    /* line 97, src/assets/scss/modules/_m09.scss */
    .m09 p.parccodion:last-child {
      margin-bottom: 30px; }
  /* line 102, src/assets/scss/modules/_m09.scss */
  .m09 p.psubmenu {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    text-align: center;
    border-bottom: 1px solid #dddddd; }
  /* line 119, src/assets/scss/modules/_m09.scss */
  .m09 p.pdepartment {
    max-width: 164px;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    margin: 0;
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #235789; }
  /* line 134, src/assets/scss/modules/_m09.scss */
  .m09 p.pname {
    max-width: 220px;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    margin: 0;
    font-family: "Work Sans", "sans-serif";
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #383838; }
  /* line 149, src/assets/scss/modules/_m09.scss */
  .m09 p.pgroup {
    max-width: 191px;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    margin: 0;
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9b9b9b; }
  /* line 164, src/assets/scss/modules/_m09.scss */
  .m09 p.pspecialization {
    max-width: 220px;
    height: 100%;
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #383838;
    margin-bottom: auto; }
  /* line 178, src/assets/scss/modules/_m09.scss */
  .m09 p.pinfocontact {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin-bottom: 0;
    text-align: center;
    line-height: 22px; }

/* line 194, src/assets/scss/modules/_m09.scss */
.m09 ul {
  padding-right: 15px; }

/* line 199, src/assets/scss/modules/_m09.scss */
.m09 ol li {
  list-style-type: decimal-leading-zero;
  color: orangered; }

/* line 205, src/assets/scss/modules/_m09.scss */
.m09 a.fontstyle {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 217, src/assets/scss/modules/_m09.scss */
.m09 a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 45px;
  padding: 0; }

/* line 225, src/assets/scss/modules/_m09.scss */
.m09 a.linkfont {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000; }

/* line 236, src/assets/scss/modules/_m09.scss */
.m09 a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 246, src/assets/scss/modules/_m09.scss */
.m09 a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 256, src/assets/scss/modules/_m09.scss */
  .m09 a.accordionmenu:first-child {
    color: #235789; }

/* line 261, src/assets/scss/modules/_m09.scss */
.m09 a.arcodionlink {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 271, src/assets/scss/modules/_m09.scss */
.m09 a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 283, src/assets/scss/modules/_m09.scss */
.m09 a.linksubmenu {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline;
  border-bottom: 1px solid #333333; }

/* line 295, src/assets/scss/modules/_m09.scss */
.m09 a.link {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ff6b35;
  right: 0%;
  position: relative;
  margin-top: 20px; }

/* line 311, src/assets/scss/modules/_m09.scss */
.m09 a:hover {
  outline: none;
  text-decoration: none; }

/* line 316, src/assets/scss/modules/_m09.scss */
.m09 a:focus {
  outline: none;
  text-decoration: none; }

/* line 321, src/assets/scss/modules/_m09.scss */
.m09 img {
  width: 100%;
  height: auto; }

/* line 328, src/assets/scss/modules/_m09.scss */
.m09 .nav-link.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 62px; }

/* line 342, src/assets/scss/modules/_m09.scss */
.m09 .nav-link.expand[aria-expanded="true"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
  transform: rotate(225deg);
  top: 69px; }

/* line 359, src/assets/scss/modules/_m09.scss */
.m09 .nav-item.show .nav-link.linkfont.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 2px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 62px; }

/* line 374, src/assets/scss/modules/_m09.scss */
.m09 ul.nav.flex-column.divborder {
  margin-right: 0px;
  padding: 0; }

/* line 379, src/assets/scss/modules/_m09.scss */
.m09 .divborder {
  border-top: 1px solid #dddddd;
  margin: 0px;
  padding: 0px; }
  @media screen and (max-width: 992px) {
    /* line 379, src/assets/scss/modules/_m09.scss */
    .m09 .divborder {
      border-top: none; } }

/* line 388, src/assets/scss/modules/_m09.scss */
.m09 .divborderbottom {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px; }

/* line 393, src/assets/scss/modules/_m09.scss */
.m09 .divacc {
  padding-top: 40px; }

/* line 399, src/assets/scss/modules/_m09.scss */
.m09 ul li.nav-item {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 405, src/assets/scss/modules/_m09.scss */
.m09 ul li.nav-item:active {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 411, src/assets/scss/modules/_m09.scss */
.m09 ul li.lit {
  padding-left: 12px; }

/* line 416, src/assets/scss/modules/_m09.scss */
.m09 li.dropdown:hover > .dropdown-menu {
  display: block; }

/* line 419, src/assets/scss/modules/_m09.scss */
.m09 a.nav-link.linkfont2 {
  margin: 0; }

/* line 423, src/assets/scss/modules/_m09.scss */
.m09 a.nav-link.linkfont2:hover {
  font-weight: 600; }

/* line 428, src/assets/scss/modules/_m09.scss */
.m09 ul .collapse.list-unstyled {
  padding: 0;
  border-top: 1px solid #dddddd; }

/* line 434, src/assets/scss/modules/_m09.scss */
.m09 .triangle {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(315deg);
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px; }

/* line 447, src/assets/scss/modules/_m09.scss */
.m09 .department {
  width: 100%;
  min-height: 37px;
  display: flex;
  justify-content: center;
  top: 0px;
  text-align: center;
  vertical-align: middle;
  line-height: 90px; }
  @media screen and (max-width: 992px) {
    /* line 447, src/assets/scss/modules/_m09.scss */
    .m09 .department {
      display: none; } }

/* line 461, src/assets/scss/modules/_m09.scss */
.m09 .profilepicture {
  width: 100%;
  min-height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 57px; }
  @media screen and (max-width: 992px) {
    /* line 461, src/assets/scss/modules/_m09.scss */
    .m09 .profilepicture {
      margin-top: 20px; } }

/* line 473, src/assets/scss/modules/_m09.scss */
.m09 .name {
  width: 100%;
  min-height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 199px;
  margin-top: 20px;
  margin-bottom: 20px; }
  @media screen and (max-width: 992px) {
    /* line 473, src/assets/scss/modules/_m09.scss */
    .m09 .name {
      margin-top: 17px;
      margin-bottom: 12px; } }

/* line 488, src/assets/scss/modules/_m09.scss */
.m09 .specialization {
  width: 100%;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 260px;
  margin-bottom: 5px; }
  @media screen and (max-width: 992px) {
    /* line 488, src/assets/scss/modules/_m09.scss */
    .m09 .specialization {
      margin-bottom: 30px; } }

/* line 501, src/assets/scss/modules/_m09.scss */
.m09 a:hover {
  text-decoration: none; }

/* line 505, src/assets/scss/modules/_m09.scss */
.m09 .role {
  width: 100%;
  min-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 235px;
  margin-top: 8px;
  margin-bottom: 20px; }

/* line 516, src/assets/scss/modules/_m09.scss */
.m09 img.imageround {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover; }

@media screen and (max-width: 992px) {
  /* line 523, src/assets/scss/modules/_m09.scss */
  .m09 .borderclass {
    border-right: none; } }

/* line 529, src/assets/scss/modules/_m09.scss */
.m09 .col-md-4.slick-slider {
  border-left: 1px solid #fff;
  border-right: 1px solid #dddddd; }
  /* line 533, src/assets/scss/modules/_m09.scss */
  .m09 .col-md-4.slick-slider:first-child {
    padding-left: 0; }
  @media screen and (max-width: 992px) {
    /* line 529, src/assets/scss/modules/_m09.scss */
    .m09 .col-md-4.slick-slider {
      border-right: none;
      border-bottom: 1px solid #dddddd; } }

/* line 543, src/assets/scss/modules/_m09.scss */
.m09 .row.divspaces {
  padding-bottom: 22px;
  padding-top: 30px;
  border-bottom: 1px solid #dddddd; }
  /* line 548, src/assets/scss/modules/_m09.scss */
  .m09 .row.divspaces:last-child {
    border-bottom: none; }
  @media screen and (max-width: 992px) {
    /* line 543, src/assets/scss/modules/_m09.scss */
    .m09 .row.divspaces {
      border-bottom: none; } }

/* line 558, src/assets/scss/modules/_m09.scss */
.m09 .col-md-4.slick-slider.withoutborder {
  border-right: 1px solid #fff; }
  @media screen and (max-width: 992px) {
    /* line 558, src/assets/scss/modules/_m09.scss */
    .m09 .col-md-4.slick-slider.withoutborder {
      border-right: none; } }

/* line 565, src/assets/scss/modules/_m09.scss */
.m09 .publications_slider {
  padding-top: 40px;
  padding-bottom: 150px; }

@media screen and (max-width: 992px) {
  /* line 570, src/assets/scss/modules/_m09.scss */
  .m09 .row.divaccordionspace {
    display: none; } }

@media screen and (max-width: 992px) {
  /* line 575, src/assets/scss/modules/_m09.scss */
  .m09 .row.divspaces {
    padding: 0; } }

@media screen and (max-width: 992px) {
  /* line 580, src/assets/scss/modules/_m09.scss */
  .m09 .d-flex {
    display: block !important; } }

/* line 3, src/assets/scss/modules/_m10.scss */
.m10 h1 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #333333;
  margin-bottom: 9px; }
  @media screen and (max-width: 992px) {
    /* line 3, src/assets/scss/modules/_m10.scss */
    .m10 h1 {
      text-align: left;
      margin: 0;
      font-size: 30px; } }

/* line 21, src/assets/scss/modules/_m10.scss */
.m10 h2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }
  /* line 32, src/assets/scss/modules/_m10.scss */
  .m10 h2.h22 {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #222222;
    margin-left: 15px; }
    @media screen and (max-width: 992px) {
      /* line 32, src/assets/scss/modules/_m10.scss */
      .m10 h2.h22 {
        font-size: 20px;
        margin-left: 15px;
        padding: 0;
        width: 50%; } }
  @media screen and (max-width: 992px) {
    /* line 21, src/assets/scss/modules/_m10.scss */
    .m10 h2 {
      font-size: 20px;
      margin-bottom: 30px; } }

/* line 59, src/assets/scss/modules/_m10.scss */
.m10 h6.firstclass {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-left: 15px; }
  @media screen and (max-width: 992px) {
    /* line 59, src/assets/scss/modules/_m10.scss */
    .m10 h6.firstclass {
      margin: 0;
      position: absolute;
      right: 15px;
      top: 20px; } }

/* line 77, src/assets/scss/modules/_m10.scss */
.m10 h6.secondclass {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333; }

/* line 89, src/assets/scss/modules/_m10.scss */
.m10 p {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #333333;
  padding-bottom: 51px;
  margin-bottom: 0px; }
  /* line 100, src/assets/scss/modules/_m10.scss */
  .m10 p:first-child {
    margin-bottom: 30px;
    padding-bottom: 0px; }
  /* line 105, src/assets/scss/modules/_m10.scss */
  .m10 p.ptext {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d2114; }
  /* line 116, src/assets/scss/modules/_m10.scss */
  .m10 p.ptext12 {
    font-family: "Work Sans", "sans-serif";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #222222; }
  /* line 127, src/assets/scss/modules/_m10.scss */
  .m10 p.parccodion {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    margin-left: 55px;
    padding: 0;
    margin-bottom: 18px; }
    /* line 139, src/assets/scss/modules/_m10.scss */
    .m10 p.parccodion:first-child {
      padding-left: 55px;
      padding-top: 28px; }
    /* line 144, src/assets/scss/modules/_m10.scss */
    .m10 p.parccodion:last-child {
      padding-bottom: 30px; }
  /* line 149, src/assets/scss/modules/_m10.scss */
  .m10 p.psubmenu {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    text-align: center;
    border-bottom: 1px solid #dddddd; }

/* line 167, src/assets/scss/modules/_m10.scss */
.m10 ul {
  padding-right: 15px; }

/* line 172, src/assets/scss/modules/_m10.scss */
.m10 ol li {
  list-style-type: decimal-leading-zero;
  color: orangered; }

/* line 178, src/assets/scss/modules/_m10.scss */
.m10 a.arrowpg {
  color: #235789;
  font-size: 18px;
  padding-top: 0;
  padding-bottom: 0; }

/* line 185, src/assets/scss/modules/_m10.scss */
.m10 a.h44 {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #235789;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 185, src/assets/scss/modules/_m10.scss */
    .m10 a.h44 {
      padding: 0;
      margin-top: 1px;
      margin-bottom: 30px;
      margin-left: 0px;
      margin-right: 0px; } }

/* line 203, src/assets/scss/modules/_m10.scss */
.m10 a.paragraph {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: inline;
  color: #9b9b9b; }
  @media screen and (max-width: 992px) {
    /* line 203, src/assets/scss/modules/_m10.scss */
    .m10 a.paragraph {
      font-size: 15px;
      margin-left: 0px;
      margin-right: 0px; } }

/* line 219, src/assets/scss/modules/_m10.scss */
.m10 a.withimagea {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 219, src/assets/scss/modules/_m10.scss */
    .m10 a.withimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px; } }

/* line 239, src/assets/scss/modules/_m10.scss */
.m10 a.withoutimagea {
  margin-left: 15px;
  margin-right: 15px;
  height: 95px;
  font-family: "Work Sans", "sans-serif";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  display: flex;
  margin-bottom: 40px; }
  @media screen and (max-width: 992px) {
    /* line 239, src/assets/scss/modules/_m10.scss */
    .m10 a.withoutimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px; } }

/* line 262, src/assets/scss/modules/_m10.scss */
.m10 a.fontstyle {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 274, src/assets/scss/modules/_m10.scss */
.m10 a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 45px;
  padding: 0; }

/* line 282, src/assets/scss/modules/_m10.scss */
.m10 a.linkfont {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000; }

/* line 293, src/assets/scss/modules/_m10.scss */
.m10 a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 303, src/assets/scss/modules/_m10.scss */
.m10 a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 313, src/assets/scss/modules/_m10.scss */
  .m10 a.accordionmenu:first-child {
    color: #235789; }

/* line 318, src/assets/scss/modules/_m10.scss */
.m10 a.arcodionlink {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 328, src/assets/scss/modules/_m10.scss */
.m10 a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 340, src/assets/scss/modules/_m10.scss */
.m10 a.linksubmenu {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline;
  border-bottom: 1px solid #333333; }

/* line 353, src/assets/scss/modules/_m10.scss */
.m10 a:hover {
  outline: none;
  text-decoration: none; }

/* line 358, src/assets/scss/modules/_m10.scss */
.m10 a:focus {
  outline: none;
  text-decoration: none; }

/* line 363, src/assets/scss/modules/_m10.scss */
.m10 .col-md-3.d-flex.justify-content-end {
  padding: 0; }

/* line 368, src/assets/scss/modules/_m10.scss */
.m10 ul li.nav-item {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 374, src/assets/scss/modules/_m10.scss */
.m10 ul li.nav-item:active {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 380, src/assets/scss/modules/_m10.scss */
.m10 ul li.lit {
  padding-left: 12px; }

/* line 385, src/assets/scss/modules/_m10.scss */
.m10 li.dropdown:hover > .dropdown-menu {
  display: block; }

/* line 388, src/assets/scss/modules/_m10.scss */
.m10 a.nav-link.linkfont2 {
  margin: 0; }

/* line 392, src/assets/scss/modules/_m10.scss */
.m10 a.nav-link.linkfont2:hover {
  font-weight: 600; }

/* line 397, src/assets/scss/modules/_m10.scss */
.m10 ul .collapse.list-unstyled {
  padding: 0;
  border-top: 1px solid #dddddd; }

/* line 403, src/assets/scss/modules/_m10.scss */
.m10 .triangle {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(315deg);
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px; }

/* line 416, src/assets/scss/modules/_m10.scss */
.m10 .divaccordionspace {
  margin-top: 30px;
  margin-bottom: 19px; }

/* line 421, src/assets/scss/modules/_m10.scss */
.m10 .divborder {
  border-top: 1px solid #dddddd;
  margin: 0px;
  padding: 0px; }

/* line 427, src/assets/scss/modules/_m10.scss */
.m10 .divborderbottom {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px; }

/* line 432, src/assets/scss/modules/_m10.scss */
.m10 .divacc {
  padding-top: 40px; }

/* line 436, src/assets/scss/modules/_m10.scss */
.m10 ul.nav.flex-column {
  padding-right: 0; }

/* line 439, src/assets/scss/modules/_m10.scss */
.m10 .backtp {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d2114;
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  text-align: center; }

/* line 452, src/assets/scss/modules/_m10.scss */
.m10 .arrow {
  content: "";
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 10px;
  height: 10px;
  border-right: 2px solid #222222;
  border-bottom: 2px solid #222222;
  transform: rotate(225deg);
  cursor: pointer;
  margin-bottom: 55px; }

/* line 470, src/assets/scss/modules/_m10.scss */
.m10 .nav-link.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 16px; }

/* line 484, src/assets/scss/modules/_m10.scss */
.m10 .nav-link.expand[aria-expanded=true]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 22px; }

/* line 501, src/assets/scss/modules/_m10.scss */
.m10 .nav-item.show .nav-link.linkfont.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 2px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 16px; }

/* line 516, src/assets/scss/modules/_m10.scss */
.m10 ul.nav.flex-column.divborder {
  margin-right: 15px; }

/* line 519, src/assets/scss/modules/_m10.scss */
.m10 a.accordionmenu:hover {
  color: #235789; }

/* line 526, src/assets/scss/modules/_m10.scss */
.m10 .rectanglemod {
  padding: 10px;
  max-width: 350px;
  border: 1px solid;
  border-color: #dddddd;
  position: relative;
  border-top: 3px solid black;
  margin-top: 15px;
  margin-bottom: 15px; }
  @media screen and (max-width: 992px) {
    /* line 526, src/assets/scss/modules/_m10.scss */
    .m10 .rectanglemod {
      max-width: 100%;
      padding: 15px;
      margin: 0;
      border: none;
      border: 1px solid #dddddd;
      border-top: 3px solid black;
      margin-bottom: 20px; } }

/* line 545, src/assets/scss/modules/_m10.scss */
.m10 .rectanglerwo {
  width: 24px;
  height: 24px;
  position: absolute;
  border: none;
  z-index: 999;
  right: -1px;
  bottom: -1px; }

/* line 558, src/assets/scss/modules/_m10.scss */
.m10 .digonalline {
  width: 24px; }

/* line 562, src/assets/scss/modules/_m10.scss */
.m10 .digonalline {
  background: linear-gradient(-45deg, #fff 49%, #dddddd 52%, #fff 51%); }

/* line 566, src/assets/scss/modules/_m10.scss */
.m10 .publications_slider {
  padding-bottom: 55px;
  padding-top: 20px;
  border-bottom: 1px solid;
  border-color: #dddddd;
  width: 100%;
  margin-bottom: 50px; }
  @media screen and (max-width: 992px) {
    /* line 566, src/assets/scss/modules/_m10.scss */
    .m10 .publications_slider {
      padding-top: 0;
      padding-bottom: 0px;
      border-bottom: 1px solid #dddddd; } }

/* line 579, src/assets/scss/modules/_m10.scss */
.m10 .imageclass {
  max-width: 230px;
  height: 117px;
  background-size: cover;
  margin-top: 19px;
  margin-bottom: 15.6px;
  margin-left: 0px;
  margin-right: 0px; }
  @media screen and (max-width: 992px) {
    /* line 579, src/assets/scss/modules/_m10.scss */
    .m10 .imageclass {
      max-width: 313px;
      max-height: 140px;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      background-size: cover; } }

/* line 596, src/assets/scss/modules/_m10.scss */
.m10 img {
  width: 100%;
  height: auto; }

/* line 601, src/assets/scss/modules/_m10.scss */
.m10 .coldiv {
  width: 320px; }
  /* line 604, src/assets/scss/modules/_m10.scss */
  .m10 .coldiv .div-title {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    min-height: 95px;
    max-height: 95px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 604, src/assets/scss/modules/_m10.scss */
      .m10 .coldiv .div-title {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        min-height: 60px;
        margin-top: 6px;
        padding: 0; } }
  /* line 624, src/assets/scss/modules/_m10.scss */
  .m10 .coldiv .div-text {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    /*text-overflow: ellipsis;
            overflow: hidden; 
            width: 240px; 
             height: 45px;
            white-space: nowrap;*/ }
    @media screen and (max-width: 992px) {
      /* line 624, src/assets/scss/modules/_m10.scss */
      .m10 .coldiv .div-text {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 20px;
        margin-top: 10px;
        padding: 0; } }
  /* line 647, src/assets/scss/modules/_m10.scss */
  .m10 .coldiv .div-link {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 647, src/assets/scss/modules/_m10.scss */
      .m10 .coldiv .div-link {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-top: 2px;
        padding: 0; } }
  /* line 664, src/assets/scss/modules/_m10.scss */
  .m10 .coldiv .div-titlet {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
    height: 95px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 664, src/assets/scss/modules/_m10.scss */
      .m10 .coldiv .div-titlet {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 60px;
        margin-top: 6px; } }
  /* line 682, src/assets/scss/modules/_m10.scss */
  .m10 .coldiv .div-textt {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 5px;
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 682, src/assets/scss/modules/_m10.scss */
      .m10 .coldiv .div-textt {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 20px; } }
  /* line 698, src/assets/scss/modules/_m10.scss */
  .m10 .coldiv .div-linkt {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 698, src/assets/scss/modules/_m10.scss */
      .m10 .coldiv .div-linkt {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
        margin-top: 2px; } }
  @media screen and (max-width: 992px) {
    /* line 601, src/assets/scss/modules/_m10.scss */
    .m10 .coldiv {
      width: 100%; } }

/* line 718, src/assets/scss/modules/_m10.scss */
.m10 .type2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  position: absolute;
  right: 15px;
  top: 0px;
  background-size: cover;
  transform: rotate(180deg); }
  @media screen and (max-width: 992px) {
    /* line 718, src/assets/scss/modules/_m10.scss */
    .m10 .type2 {
      display: none; } }

/* line 733, src/assets/scss/modules/_m10.scss */
.m10 .type3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  position: absolute;
  right: 83px;
  top: 0px;
  background-size: cover; }
  @media screen and (max-width: 992px) {
    /* line 733, src/assets/scss/modules/_m10.scss */
    .m10 .type3 {
      display: none; } }

/* line 750, src/assets/scss/modules/_m10.scss */
.m10 .type2 img:hover {
  cursor: pointer;
  opacity: 0.4; }

/* line 758, src/assets/scss/modules/_m10.scss */
.m10 .type3 img:hover {
  cursor: pointer;
  opacity: 0.4; }

/* line 765, src/assets/scss/modules/_m10.scss */
.m10 .typegen:hover {
  cursor: pointer; }

/* line 769, src/assets/scss/modules/_m10.scss */
.m10 .typegen .slick-disabled {
  opacity: 0.4 !important; }

/* line 774, src/assets/scss/modules/_m10.scss */
.m10 img.imagearrow {
  width: 38px;
  height: 38px;
  border-radius: 50%; }

/* line 781, src/assets/scss/modules/_m10.scss */
.m10 .pagination {
  display: inline-block;
  border: 1px solid #dddddd;
  position: absolute;
  right: 0;
  bottom: 0; }

/* line 789, src/assets/scss/modules/_m10.scss */
.m10 .pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none; }

/* line 797, src/assets/scss/modules/_m10.scss */
.m10 .pagination a.active {
  background-color: #dddddd;
  border: 1px solid #dddddd;
  cursor: default; }

/* line 803, src/assets/scss/modules/_m10.scss */
.m10 .pagination a:hover:not(.active) {
  background-color: #fff; }

/* line 807, src/assets/scss/modules/_m10.scss */
.m10 .pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

/* line 812, src/assets/scss/modules/_m10.scss */
.m10 .pagination a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

/* line 816, src/assets/scss/modules/_m10.scss */
.m10 .divpagenation {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 50px;
  position: relative; }
  @media screen and (max-width: 992px) {
    /* line 816, src/assets/scss/modules/_m10.scss */
    .m10 .divpagenation {
      display: none; } }

/* line 827, src/assets/scss/modules/_m10.scss */
.m10 .publications_slider2 {
  padding-top: 30px;
  padding-bottom: 40px;
  margin-bottom: 20px; }
  @media screen and (max-width: 992px) {
    /* line 827, src/assets/scss/modules/_m10.scss */
    .m10 .publications_slider2 {
      padding-bottom: 30px; } }

/* line 836, src/assets/scss/modules/_m10.scss */
.m10 .searchnews {
  width: 100%;
  height: 50px;
  border: 1px solid #dddddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-position: right 30px center;
  background-repeat: no-repeat;
  color: #dddddd;
  padding-left: 20px;
  margin-top: 30px;
  margin-bottom: 30px; }

/* line 852, src/assets/scss/modules/_m10.scss */
.m10 .daterange {
  margin-top: 30px;
  width: 100%;
  height: 50px;
  border: 1px solid #dddddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-position: left 16px center;
  background-repeat: no-repeat;
  color: #dddddd;
  padding-left: 70px; }

/* line 868, src/assets/scss/modules/_m10.scss */
.m10 .form-control::-webkit-input-placeholder {
  margin-left: 20px;
  padding-left: 20px; }
  @media screen and (max-width: 992px) {
    /* line 868, src/assets/scss/modules/_m10.scss */
    .m10 .form-control::-webkit-input-placeholder {
      color: #fff; } }

/* line 875, src/assets/scss/modules/_m10.scss */
.m10 .checkboxclass {
  width: 30px;
  height: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  border: 1px solid #235789; }

/* line 887, src/assets/scss/modules/_m10.scss */
.m10 .styled-checkbox {
  position: absolute;
  opacity: 0;
  border: 1px solid #235789; }
  /* line 892, src/assets/scss/modules/_m10.scss */
  .m10 .styled-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0; }
  /* line 899, src/assets/scss/modules/_m10.scss */
  .m10 .styled-checkbox + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 30px;
    height: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: white;
    border: 1px solid #235789; }
  /* line 917, src/assets/scss/modules/_m10.scss */
  .m10 .styled-checkbox:checked + label:before {
    background: #fff; }
  /* line 922, src/assets/scss/modules/_m10.scss */
  .m10 .styled-checkbox:disabled + label {
    color: #b8b8b8;
    cursor: auto; }
  /* line 928, src/assets/scss/modules/_m10.scss */
  .m10 .styled-checkbox:disabled + label:before {
    box-shadow: none;
    background: #dddddd; }
  /* line 934, src/assets/scss/modules/_m10.scss */
  .m10 .styled-checkbox:checked + label:after {
    content: '\2713';
    position: absolute;
    left: 10px;
    top: 2px;
    color: #235789; }

/* line 942, src/assets/scss/modules/_m10.scss */
.m10 label {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  color: #333333; }

@media screen and (max-width: 992px) {
  /* line 948, src/assets/scss/modules/_m10.scss */
  .m10 .d-flex {
    padding: 15px;
    display: block !important; } }

/* line 954, src/assets/scss/modules/_m10.scss */
.m10 .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 30px 0;
  list-style-type: none; }
  /* line 963, src/assets/scss/modules/_m10.scss */
  .m10 .slick-dots li {
    margin: 0 0.25rem; }
  /* line 967, src/assets/scss/modules/_m10.scss */
  .m10 .slick-dots button {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #dddddd;
    text-indent: -9999px; }
  /* line 979, src/assets/scss/modules/_m10.scss */
  .m10 .slick-dots li.slick-active button {
    background-color: #192830; }

/* line 984, src/assets/scss/modules/_m10.scss */
.m10 .styled-checkbox:checked + label:after {
  content: "\2713";
  position: absolute;
  left: 9px;
  top: 3px;
  color: #235789;
  font-size: 20px; }

@media screen and (max-width: 992px) {
  /* line 992, src/assets/scss/modules/_m10.scss */
  .m10 ul.slick-dots {
    padding-top: 0; } }

/* line 998, src/assets/scss/modules/_m10.scss */
.m10 label {
  margin-right: 15px; }

@media screen and (max-width: 992px) {
  /* line 1002, src/assets/scss/modules/_m10.scss */
  .m10 .row.divaccordionspace {
    display: none; } }

/* line 2, src/assets/scss/modules/_m11.scss */
.m11 h1 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #333333;
  margin-bottom: 9px;
  white-space: pre-wrap; }

/* line 16, src/assets/scss/modules/_m11.scss */
.m11 h2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  margin-bottom: 34px; }

/* line 28, src/assets/scss/modules/_m11.scss */
.m11 p {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #333333;
  padding-bottom: 51px;
  margin-bottom: 0px; }
  /* line 39, src/assets/scss/modules/_m11.scss */
  .m11 p:first-child {
    margin-bottom: 30px;
    padding-bottom: 0px; }
  /* line 44, src/assets/scss/modules/_m11.scss */
  .m11 p.ptext {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d2114; }
  /* line 55, src/assets/scss/modules/_m11.scss */
  .m11 p.ptext12 {
    font-family: "Work Sans", "sans-serif";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #222222; }
  /* line 66, src/assets/scss/modules/_m11.scss */
  .m11 p.parccodion {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    margin-left: 55px;
    padding: 0;
    margin-bottom: 18px; }
    /* line 78, src/assets/scss/modules/_m11.scss */
    .m11 p.parccodion:first-child {
      margin-left: 55px;
      margin-top: 28px; }
    /* line 83, src/assets/scss/modules/_m11.scss */
    .m11 p.parccodion:last-child {
      margin-bottom: 30px; }
  /* line 88, src/assets/scss/modules/_m11.scss */
  .m11 p.psubmenu {
    font-family: "Roboto Slab", serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
    border-bottom: 1px solid #dddddd; }
  /* line 105, src/assets/scss/modules/_m11.scss */
  .m11 p.pdescription {
    font-family: "Roboto Slab", serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #333333; }
    @media screen and (max-width: 992px) {
      /* line 105, src/assets/scss/modules/_m11.scss */
      .m11 p.pdescription {
        font-size: 16px;
        margin: 0; } }
  /* line 121, src/assets/scss/modules/_m11.scss */
  .m11 p.pauthor {
    font-family: "TiemposHeadline", sans-serif;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000; }
    @media screen and (max-width: 992px) {
      /* line 121, src/assets/scss/modules/_m11.scss */
      .m11 p.pauthor {
        font-size: 22px;
        margin: 0; } }
  /* line 137, src/assets/scss/modules/_m11.scss */
  .m11 p.prole {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #9b9b9b; }
  /* line 149, src/assets/scss/modules/_m11.scss */
  .m11 p.pdatetimetext {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9b9b9b;
    margin: 0;
    padding: 0; }

/* line 163, src/assets/scss/modules/_m11.scss */
.m11 ul {
  padding-right: 15px; }

/* line 168, src/assets/scss/modules/_m11.scss */
.m11 ol li {
  list-style-type: decimal-leading-zero;
  color: orangered; }

/* line 174, src/assets/scss/modules/_m11.scss */
.m11 a.buttonlinkk2 {
  min-width: 120px;
  height: 30px;
  background-color: #235789;
  border: #235789;
  padding-top: 6px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 19px;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #fff;
  margin-left: 15px;
  margin-right: 5px;
  text-align: center; }
  @media screen and (max-width: 992px) {
    /* line 174, src/assets/scss/modules/_m11.scss */
    .m11 a.buttonlinkk2 {
      margin-bottom: 20px; } }

/* line 198, src/assets/scss/modules/_m11.scss */
.m11 a.linklist {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #222222;
  display: inline-block; }

/* line 210, src/assets/scss/modules/_m11.scss */
.m11 a.linklist.active {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #235789;
  display: inline-block; }

/* line 222, src/assets/scss/modules/_m11.scss */
.m11 a.fontstyle {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 234, src/assets/scss/modules/_m11.scss */
.m11 a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 45px;
  padding: 0; }

/* line 242, src/assets/scss/modules/_m11.scss */
.m11 a.linkfont {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000; }

/* line 252, src/assets/scss/modules/_m11.scss */
.m11 a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 262, src/assets/scss/modules/_m11.scss */
.m11 a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 272, src/assets/scss/modules/_m11.scss */
  .m11 a.accordionmenu:first-child {
    color: #235789; }

/* line 277, src/assets/scss/modules/_m11.scss */
.m11 a.arcodionlink {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 287, src/assets/scss/modules/_m11.scss */
.m11 a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 299, src/assets/scss/modules/_m11.scss */
.m11 a.linksubmenu {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline;
  border-bottom: 1px solid #333333; }

/* line 312, src/assets/scss/modules/_m11.scss */
.m11 a:hover {
  outline: none;
  text-decoration: none; }

/* line 317, src/assets/scss/modules/_m11.scss */
.m11 a:focus {
  outline: none;
  text-decoration: none; }

/* line 322, src/assets/scss/modules/_m11.scss */
.m11 img {
  width: 100%;
  height: auto; }

/* line 326, src/assets/scss/modules/_m11.scss */
.m11 .col-md-3.d-flex.justify-content-end {
  padding: 0; }

/* line 331, src/assets/scss/modules/_m11.scss */
.m11 ul li.nav-item {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 337, src/assets/scss/modules/_m11.scss */
.m11 ul li.nav-item:active {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 343, src/assets/scss/modules/_m11.scss */
.m11 ul li.lit {
  padding-left: 12px; }

/* line 348, src/assets/scss/modules/_m11.scss */
.m11 li.dropdown:hover > .dropdown-menu {
  display: block; }

/* line 351, src/assets/scss/modules/_m11.scss */
.m11 a.nav-link.linkfont2 {
  margin: 0; }

/* line 355, src/assets/scss/modules/_m11.scss */
.m11 a.nav-link.linkfont2:hover {
  font-weight: 600; }

/* line 360, src/assets/scss/modules/_m11.scss */
.m11 ul .collapse.list-unstyled {
  padding: 0;
  border-top: 1px solid #dddddd; }

/* line 366, src/assets/scss/modules/_m11.scss */
.m11 .triangle {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(315deg);
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px; }

/* line 379, src/assets/scss/modules/_m11.scss */
.m11 .divaccordionspace {
  margin-top: 30px;
  margin-bottom: 19px; }

/* line 384, src/assets/scss/modules/_m11.scss */
.m11 .divborder {
  border-top: 1px solid #dddddd;
  margin: 0px;
  padding: 0px; }

/* line 390, src/assets/scss/modules/_m11.scss */
.m11 .divborderbottom {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px; }

/* line 395, src/assets/scss/modules/_m11.scss */
.m11 .divacc {
  padding-top: 45px; }

/* line 399, src/assets/scss/modules/_m11.scss */
.m11 ul.nav.flex-column {
  padding-right: 0; }

/* line 402, src/assets/scss/modules/_m11.scss */
.m11 .backtp {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d2114;
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  text-align: center; }

/* line 415, src/assets/scss/modules/_m11.scss */
.m11 .arrow {
  content: "";
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 10px;
  height: 10px;
  border-right: 2px solid #222222;
  border-bottom: 2px solid #222222;
  transform: rotate(225deg);
  cursor: pointer;
  margin-bottom: 55px; }

/* line 433, src/assets/scss/modules/_m11.scss */
.m11 .nav-link.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 62px; }

/* line 451, src/assets/scss/modules/_m11.scss */
.m11 .nav-item.show .nav-link.linkfont.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 2px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 62px; }

/* line 466, src/assets/scss/modules/_m11.scss */
.m11 ul.nav.flex-column.divborder {
  margin-right: 15px; }

/* line 469, src/assets/scss/modules/_m11.scss */
.m11 a.accordionmenu:hover {
  color: #235789; }

/* line 474, src/assets/scss/modules/_m11.scss */
.m11 .rectanglemission {
  width: 194px;
  min-height: 165px;
  border-radius: 3px;
  background-color: #ededed;
  padding-bottom: 22px;
  margin-top: 0px;
  padding-top: 14px; }
  @media screen and (max-width: 992px) {
    /* line 474, src/assets/scss/modules/_m11.scss */
    .m11 .rectanglemission {
      margin-top: 0px;
      width: 100%; } }

/* line 487, src/assets/scss/modules/_m11.scss */
.m11 ul.no_bullet {
  list-style-type: none;
  padding: 0;
  margin: 0; }

/* line 492, src/assets/scss/modules/_m11.scss */
.m11 li.leaf {
  background: url("/assets/img/icons/arrow_back.svg") no-repeat left top;
  min-height: 30px;
  padding-left: 50px;
  padding-top: 3px;
  padding-right: 10px; }

/* line 499, src/assets/scss/modules/_m11.scss */
.m11 .col-md-12.d-flex.justify-content-end {
  padding: 0;
  margin-bottom: 50px; }

/* line 503, src/assets/scss/modules/_m11.scss */
.m11 .description {
  width: 635px;
  height: 144px;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 992px) {
    /* line 503, src/assets/scss/modules/_m11.scss */
    .m11 .description {
      width: 100%;
      min-height: 210px; } }

/* line 514, src/assets/scss/modules/_m11.scss */
.m11 .author {
  width: 220px;
  height: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 27px;
  margin-bottom: 12px; }

/* line 523, src/assets/scss/modules/_m11.scss */
.m11 .role {
  width: 190px;
  height: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 54px; }

/* line 531, src/assets/scss/modules/_m11.scss */
.m11 .col-md-12.divborderbottom {
  width: 100%;
  height: 30px; }

/* line 536, src/assets/scss/modules/_m11.scss */
.m11 .linkmenu {
  margin-top: 15px;
  margin-bottom: 10px;
  width: 100%; }
  @media screen and (max-width: 992px) {
    /* line 536, src/assets/scss/modules/_m11.scss */
    .m11 .linkmenu {
      margin-bottom: 20px; } }

@media screen and (max-width: 992px) {
  /* line 545, src/assets/scss/modules/_m11.scss */
  .m11 .row.divaccordionspace {
    display: none; } }

@media screen and (max-width: 992px) {
  /* line 550, src/assets/scss/modules/_m11.scss */
  .m11 .d-flex {
    display: block !important; } }

@media screen and (max-width: 992px) {
  /* line 555, src/assets/scss/modules/_m11.scss */
  .m11 .slider-nav {
    display: none; } }

/* line 561, src/assets/scss/modules/_m11.scss */
.m11 .iframe {
  width: 100%;
  height: 242px;
  margin-bottom: 50px; }
  @media screen and (max-width: 992px) {
    /* line 561, src/assets/scss/modules/_m11.scss */
    .m11 .iframe {
      height: 152px;
      margin-bottom: 50px; } }

/* line 3, src/assets/scss/modules/_m12.scss */
.m12 h1 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #333333;
  margin-bottom: 9px; }

/* line 16, src/assets/scss/modules/_m12.scss */
.m12 h2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  margin-bottom: 34px; }

/* line 28, src/assets/scss/modules/_m12.scss */
.m12 p {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #333333;
  padding-bottom: 51px;
  margin-bottom: 0px; }
  /* line 39, src/assets/scss/modules/_m12.scss */
  .m12 p:first-child {
    margin-bottom: 30px;
    padding-bottom: 0px; }
  /* line 44, src/assets/scss/modules/_m12.scss */
  .m12 p.ptextdeskription {
    font-family: "Work Sans", "sans-serif";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #333333;
    padding: 0;
    margin-left: 20px; }
  /* line 57, src/assets/scss/modules/_m12.scss */
  .m12 p.ptextdeskriptionz {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #333333;
    padding: 0;
    margin-left: 20px;
    margin-top: 10px; }
  /* line 71, src/assets/scss/modules/_m12.scss */
  .m12 p.ptext {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d2114; }
  /* line 82, src/assets/scss/modules/_m12.scss */
  .m12 p.ptext12 {
    font-family: "Work Sans", "sans-serif";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #222222; }
  /* line 93, src/assets/scss/modules/_m12.scss */
  .m12 p.parccodion {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    margin-left: 55px;
    padding: 0;
    margin-bottom: 18px; }
    /* line 105, src/assets/scss/modules/_m12.scss */
    .m12 p.parccodion:first-child {
      margin-left: 55px;
      margin-top: 28px; }
    /* line 110, src/assets/scss/modules/_m12.scss */
    .m12 p.parccodion:last-child {
      margin-bottom: 30px; }
  /* line 115, src/assets/scss/modules/_m12.scss */
  .m12 p.psubmenu {
    font-family: "Roboto Slab", serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    text-align: left;
    border-bottom: 1px solid #dddddd; }
  /* line 134, src/assets/scss/modules/_m12.scss */
  .m12 p.pinputtext {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #222222;
    margin-bottom: 10px;
    margin-top: 21px; }
  /* line 147, src/assets/scss/modules/_m12.scss */
  .m12 p.pdatetimetext {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9b9b9b;
    margin: 0;
    padding: 0; }
  /* line 160, src/assets/scss/modules/_m12.scss */
  .m12 p.pdspeaker {
    font-family: "Work Sans", "sans-serif";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    margin: 0;
    margin-bottom: 10px;
    padding: 0; }
  /* line 174, src/assets/scss/modules/_m12.scss */
  .m12 p.pdthema {
    font-family: "Work Sans", "sans-serif";
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    margin: 0;
    margin-bottom: 30px;
    padding: 0; }
  /* line 188, src/assets/scss/modules/_m12.scss */
  .m12 p.pdtime {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #999999;
    margin: 0;
    margin-bottom: 10px;
    padding: 0; }
  /* line 201, src/assets/scss/modules/_m12.scss */
  .m12 p.pdlocation {
    font-family: "Work Sans", "sans-serif";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    margin: 0;
    padding: 0; }

/* line 215, src/assets/scss/modules/_m12.scss */
.m12 ul {
  padding-right: 15px; }

/* line 220, src/assets/scss/modules/_m12.scss */
.m12 ol li {
  list-style-type: decimal-leading-zero;
  color: orangered; }

/* line 226, src/assets/scss/modules/_m12.scss */
.m12 a.buttonlinkk2 {
  min-width: 120px;
  height: 30px;
  background-color: #235789;
  border: #235789;
  padding-top: 6px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 19px;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #fff;
  margin-left: 15px;
  margin-right: 5px;
  text-align: center; }
  @media screen and (max-width: 992px) {
    /* line 226, src/assets/scss/modules/_m12.scss */
    .m12 a.buttonlinkk2 {
      margin-bottom: 20px;
      margin-left: 0px;
      margin-right: 20px; } }

/* line 253, src/assets/scss/modules/_m12.scss */
.m12 a .m12 a.fontstyle {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 266, src/assets/scss/modules/_m12.scss */
.m12 a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 45px;
  padding: 0; }

/* line 274, src/assets/scss/modules/_m12.scss */
.m12 a.linkfont {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000; }

/* line 284, src/assets/scss/modules/_m12.scss */
.m12 a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 294, src/assets/scss/modules/_m12.scss */
.m12 a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 304, src/assets/scss/modules/_m12.scss */
  .m12 a.accordionmenu:first-child {
    color: #235789; }

/* line 309, src/assets/scss/modules/_m12.scss */
.m12 a.arcodionlink {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 319, src/assets/scss/modules/_m12.scss */
.m12 a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 331, src/assets/scss/modules/_m12.scss */
.m12 a.linksubmenu {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline;
  border-bottom: 1px solid #333333; }

/* line 344, src/assets/scss/modules/_m12.scss */
.m12 a:hover {
  outline: none;
  text-decoration: none; }

/* line 349, src/assets/scss/modules/_m12.scss */
.m12 a:focus {
  outline: none;
  text-decoration: none; }

/* line 354, src/assets/scss/modules/_m12.scss */
.m12 img {
  width: 100%;
  height: auto; }

/* line 358, src/assets/scss/modules/_m12.scss */
.m12 .col-md-3.d-flex.justify-content-end {
  padding: 0; }

/* line 363, src/assets/scss/modules/_m12.scss */
.m12 ul li.nav-item {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 369, src/assets/scss/modules/_m12.scss */
.m12 ul li.nav-item:active {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 375, src/assets/scss/modules/_m12.scss */
.m12 ul li.lit {
  padding-left: 12px; }

/* line 380, src/assets/scss/modules/_m12.scss */
.m12 li.dropdown:hover > .dropdown-menu {
  display: block; }

/* line 383, src/assets/scss/modules/_m12.scss */
.m12 a.nav-link.linkfont2 {
  margin: 0; }

/* line 387, src/assets/scss/modules/_m12.scss */
.m12 a.nav-link.linkfont2:hover {
  font-weight: 600; }

/* line 392, src/assets/scss/modules/_m12.scss */
.m12 ul .collapse.list-unstyled {
  padding: 0;
  border-top: 1px solid #dddddd; }

/* line 398, src/assets/scss/modules/_m12.scss */
.m12 .triangle {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(315deg);
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px; }

/* line 411, src/assets/scss/modules/_m12.scss */
.m12 .divaccordionspace {
  margin-top: 30px;
  margin-bottom: 19px; }

/* line 416, src/assets/scss/modules/_m12.scss */
.m12 .divborder {
  border-top: 1px solid #dddddd;
  margin: 0px;
  padding: 0px; }

/* line 422, src/assets/scss/modules/_m12.scss */
.m12 .divborderbottom {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px; }

/* line 427, src/assets/scss/modules/_m12.scss */
.m12 .divacc {
  padding-top: 45px; }

/* line 431, src/assets/scss/modules/_m12.scss */
.m12 ul.nav.flex-column {
  padding-right: 0; }

/* line 434, src/assets/scss/modules/_m12.scss */
.m12 .backtp {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d2114;
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  text-align: center; }

/* line 447, src/assets/scss/modules/_m12.scss */
.m12 .arrow {
  content: "";
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 10px;
  height: 10px;
  border-right: 2px solid #222222;
  border-bottom: 2px solid #222222;
  transform: rotate(225deg);
  cursor: pointer;
  margin-bottom: 55px; }

/* line 465, src/assets/scss/modules/_m12.scss */
.m12 .nav-link.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 62px; }

/* line 483, src/assets/scss/modules/_m12.scss */
.m12 .nav-item.show .nav-link.linkfont.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 2px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 62px; }

/* line 498, src/assets/scss/modules/_m12.scss */
.m12 ul.nav.flex-column.divborder {
  margin-right: 0px; }

/* line 501, src/assets/scss/modules/_m12.scss */
.m12 a.accordionmenu:hover {
  color: #235789; }

/* line 511, src/assets/scss/modules/_m12.scss */
.m12 .linkmenu {
  margin-top: 15px;
  margin-bottom: 120px;
  width: 100%; }

/* line 517, src/assets/scss/modules/_m12.scss */
.m12 .col-md-12.d-flex.justify-content-end {
  padding: 0; }

/* line 520, src/assets/scss/modules/_m12.scss */
.m12 .rectanglemission {
  margin-top: 157px;
  width: 194px;
  border-radius: 3px;
  background-color: #ededed;
  padding-bottom: 20px; }
  @media screen and (max-width: 992px) {
    /* line 520, src/assets/scss/modules/_m12.scss */
    .m12 .rectanglemission {
      margin-top: 50px;
      width: 100%; } }

/* line 531, src/assets/scss/modules/_m12.scss */
.m12 ul.no_bullet {
  list-style-type: none;
  padding: 0;
  margin: 0; }

/* line 536, src/assets/scss/modules/_m12.scss */
.m12 li.leaf {
  background: url("/assets/img/icons/arrow_back.svg") no-repeat left center;
  max-height: 70px;
  padding-left: 50px;
  padding-top: 0px;
  padding-right: 10px;
  margin-top: 10px; }

/* line 544, src/assets/scss/modules/_m12.scss */
.m12 .rectangdownload {
  width: 194px;
  border-radius: 3px;
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #dddddd;
  padding-bottom: 25px; }
  @media screen and (max-width: 992px) {
    /* line 544, src/assets/scss/modules/_m12.scss */
    .m12 .rectangdownload {
      margin-top: 50px;
      width: 100%; } }

/* line 557, src/assets/scss/modules/_m12.scss */
.m12 .share {
  width: 194px; }
  @media screen and (max-width: 992px) {
    /* line 557, src/assets/scss/modules/_m12.scss */
    .m12 .share {
      border-bottom: 1px solid #dddddd;
      margin-top: 50px;
      width: 100%; } }

/* line 566, src/assets/scss/modules/_m12.scss */
.m12 .divimg {
  margin-top: 30px;
  width: 20px;
  height: 23px;
  background-color: black;
  margin-left: 20px; }
  /* line 573, src/assets/scss/modules/_m12.scss */
  .m12 .divimg:first-child {
    margin-left: 35px; }
  @media screen and (max-width: 992px) {
    /* line 566, src/assets/scss/modules/_m12.scss */
    .m12 .divimg {
      margin-bottom: 50px; } }

@media screen and (max-width: 992px) {
  /* line 581, src/assets/scss/modules/_m12.scss */
  .m12 .row.divaccordionspace {
    display: none; } }

@media screen and (max-width: 992px) {
  /* line 586, src/assets/scss/modules/_m12.scss */
  .m12 .d-flex {
    display: block !important; } }

@media screen and (max-width: 992px) {
  /* line 591, src/assets/scss/modules/_m12.scss */
  .m12 table {
    display: none; } }

/* line 597, src/assets/scss/modules/_m12.scss */
.m12 .programs {
  display: none; }
  @media screen and (max-width: 992px) {
    /* line 597, src/assets/scss/modules/_m12.scss */
    .m12 .programs {
      width: 100%;
      height: 140px;
      border-top: 3px solid #333333;
      padding-bottom: 20px;
      padding-top: 11px;
      display: block;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 20px; }
      /* line 609, src/assets/scss/modules/_m12.scss */
      .m12 .programs:last-child {
        margin-bottom: 60px; } }

/* line 2, src/assets/scss/modules/_m13.scss */
.m13 h1 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #333333;
  margin-bottom: 9px; }

/* line 15, src/assets/scss/modules/_m13.scss */
.m13 h2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  margin-bottom: 34px; }
  /* line 26, src/assets/scss/modules/_m13.scss */
  .m13 h2.h22 {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #222222; }

/* line 40, src/assets/scss/modules/_m13.scss */
.m13 p {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #333333;
  padding-bottom: 51px;
  margin-bottom: 0px; }
  /* line 51, src/assets/scss/modules/_m13.scss */
  .m13 p:first-child {
    margin-bottom: 30px;
    padding-bottom: 0px; }
  /* line 56, src/assets/scss/modules/_m13.scss */
  .m13 p.ptext {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d2114; }
  /* line 67, src/assets/scss/modules/_m13.scss */
  .m13 p.ptext12 {
    font-family: "Work Sans", "sans-serif";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #222222; }
  /* line 78, src/assets/scss/modules/_m13.scss */
  .m13 p.parccodion {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    margin-left: 55px;
    padding: 0;
    margin-bottom: 18px; }
    /* line 90, src/assets/scss/modules/_m13.scss */
    .m13 p.parccodion:first-child {
      padding-left: 55px;
      padding-top: 28px; }
    /* line 95, src/assets/scss/modules/_m13.scss */
    .m13 p.parccodion:last-child {
      padding-bottom: 30px; }
  /* line 100, src/assets/scss/modules/_m13.scss */
  .m13 p.psubmenu {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000000;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    text-align: center;
    border-bottom: 1px solid #dddddd; }
  /* line 117, src/assets/scss/modules/_m13.scss */
  .m13 p.titlep {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #333333;
    margin: 0;
    padding: 0; }

/* line 130, src/assets/scss/modules/_m13.scss */
.m13 ul {
  padding-right: 15px; }

/* line 135, src/assets/scss/modules/_m13.scss */
.m13 ol li {
  list-style-type: decimal-leading-zero;
  color: orangered; }

/* line 141, src/assets/scss/modules/_m13.scss */
.m13 a.buttonlinkw {
  width: 200px;
  height: 45px;
  line-height: 44px;
  border-radius: 22.5px;
  background-color: #fff;
  border: 1px solid #235789;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #235789;
  text-align: center;
  position: absolute; }
  @media screen and (max-width: 992px) {
    /* line 141, src/assets/scss/modules/_m13.scss */
    .m13 a.buttonlinkw {
      left: 0px;
      bottom: 20px; } }

/* line 163, src/assets/scss/modules/_m13.scss */
.m13 a.adescriptiondiv {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #222222;
  display: table-row; }

/* line 175, src/assets/scss/modules/_m13.scss */
.m13 a.ainfodiv {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #9b9b9b;
  display: table-row; }

/* line 187, src/assets/scss/modules/_m13.scss */
.m13 a.pclass2 {
  min-height: 93px;
  margin-right: 0px;
  margin-left: 0%;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.40;
  letter-spacing: normal;
  color: #222222;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 187, src/assets/scss/modules/_m13.scss */
    .m13 a.pclass2 {
      display: none; } }

/* line 204, src/assets/scss/modules/_m13.scss */
.m13 a.h22 {
  font-family: "Work Sans", "sans-serif";
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #222222;
  margin-right: 0px;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 204, src/assets/scss/modules/_m13.scss */
    .m13 a.h22 {
      min-height: 126px;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3; } }

/* line 224, src/assets/scss/modules/_m13.scss */
.m13 a.h44 {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #235789;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 224, src/assets/scss/modules/_m13.scss */
    .m13 a.h44 {
      padding: 0;
      margin-top: 1px;
      margin-bottom: 30px;
      margin-left: 0px;
      margin-right: 0px; } }

/* line 242, src/assets/scss/modules/_m13.scss */
.m13 a.paragraph {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: inline;
  color: #9b9b9b; }
  @media screen and (max-width: 992px) {
    /* line 242, src/assets/scss/modules/_m13.scss */
    .m13 a.paragraph {
      font-size: 15px;
      margin-left: 0px;
      margin-right: 0px; } }

/* line 258, src/assets/scss/modules/_m13.scss */
.m13 a.withimagea {
  font-family: "Work Sans", "sans-serif";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 258, src/assets/scss/modules/_m13.scss */
    .m13 a.withimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px; } }

/* line 278, src/assets/scss/modules/_m13.scss */
.m13 a.withoutimagea {
  margin-left: 15px;
  margin-right: 15px;
  height: 95px;
  font-family: "Work Sans", "sans-serif";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  display: flex;
  margin-bottom: 40px; }
  @media screen and (max-width: 992px) {
    /* line 278, src/assets/scss/modules/_m13.scss */
    .m13 a.withoutimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px; } }

/* line 301, src/assets/scss/modules/_m13.scss */
.m13 a.fontstyle {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 313, src/assets/scss/modules/_m13.scss */
.m13 a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 75px;
  padding: 0; }

/* line 321, src/assets/scss/modules/_m13.scss */
.m13 a.linkfont {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000000; }

/* line 332, src/assets/scss/modules/_m13.scss */
.m13 a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 342, src/assets/scss/modules/_m13.scss */
.m13 a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 352, src/assets/scss/modules/_m13.scss */
  .m13 a.accordionmenu:first-child {
    color: #235789; }

/* line 357, src/assets/scss/modules/_m13.scss */
.m13 a.arcodionlink {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000000; }

/* line 367, src/assets/scss/modules/_m13.scss */
.m13 a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 379, src/assets/scss/modules/_m13.scss */
.m13 a.linksubmenu {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline;
  border-bottom: 1px solid #333333; }

/* line 392, src/assets/scss/modules/_m13.scss */
.m13 a:hover {
  outline: none;
  text-decoration: none; }

/* line 397, src/assets/scss/modules/_m13.scss */
.m13 a:focus {
  outline: none;
  text-decoration: none; }

/* line 402, src/assets/scss/modules/_m13.scss */
.m13 img {
  width: 100%;
  height: auto; }

/* line 406, src/assets/scss/modules/_m13.scss */
.m13 .col-md-3.d-flex.justify-content-end {
  padding: 0; }

/* line 411, src/assets/scss/modules/_m13.scss */
.m13 ul li.nav-item {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 417, src/assets/scss/modules/_m13.scss */
.m13 ul li.nav-item:active {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 423, src/assets/scss/modules/_m13.scss */
.m13 ul li.lit {
  padding-left: 12px; }

/* line 428, src/assets/scss/modules/_m13.scss */
.m13 li.dropdown:hover > .dropdown-menu {
  display: block; }

/* line 431, src/assets/scss/modules/_m13.scss */
.m13 a.nav-link.linkfont2 {
  margin: 0; }

/* line 435, src/assets/scss/modules/_m13.scss */
.m13 a.nav-link.linkfont2:hover {
  font-weight: 600; }

/* line 440, src/assets/scss/modules/_m13.scss */
.m13 ul .collapse.list-unstyled {
  padding: 0;
  border-top: 1px solid #dddddd; }

/* line 446, src/assets/scss/modules/_m13.scss */
.m13 .triangle {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
  transform: rotate(315deg);
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px; }

/* line 459, src/assets/scss/modules/_m13.scss */
.m13 .divaccordionspace {
  margin-top: 30px;
  margin-bottom: 19px; }

/* line 464, src/assets/scss/modules/_m13.scss */
.m13 .divborder {
  border-top: 1px solid #dddddd;
  margin: 0px;
  padding: 0px; }

/* line 470, src/assets/scss/modules/_m13.scss */
.m13 .divborderbottom {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px; }

/* line 475, src/assets/scss/modules/_m13.scss */
.m13 .divacc {
  padding-top: 40px; }

/* line 479, src/assets/scss/modules/_m13.scss */
.m13 ul.nav.flex-column {
  padding-right: 0; }

/* line 482, src/assets/scss/modules/_m13.scss */
.m13 .backtp {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d2114;
  margin: 0 auto;
  width: 100px;
  cursor: pointer;
  text-align: center; }

/* line 495, src/assets/scss/modules/_m13.scss */
.m13 .arrow {
  content: "";
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 10px;
  height: 10px;
  border-right: 2px solid #222;
  border-bottom: 2px solid #222;
  transform: rotate(225deg);
  cursor: pointer;
  margin-bottom: 55px; }

/* line 513, src/assets/scss/modules/_m13.scss */
.m13 .nav-link.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
  transform: rotate(45deg);
  top: 62px; }

/* line 527, src/assets/scss/modules/_m13.scss */
.m13 .nav-link.expand[aria-expanded="true"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
  transform: rotate(225deg);
  top: 69px; }

/* line 544, src/assets/scss/modules/_m13.scss */
.m13 .nav-item.show .nav-link.linkfont.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 2px;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
  transform: rotate(225deg);
  top: 62px; }

/* line 559, src/assets/scss/modules/_m13.scss */
.m13 ul.nav.flex-column.divborder {
  margin-right: 15px; }

/* line 562, src/assets/scss/modules/_m13.scss */
.m13 a.accordionmenu:hover {
  color: #235789; }

/* line 570, src/assets/scss/modules/_m13.scss */
.m13 .panel-group:last-child {
  margin-bottom: 110px; }

/* line 576, src/assets/scss/modules/_m13.scss */
.m13 .panel-body {
  padding: 0; }

/* line 580, src/assets/scss/modules/_m13.scss */
.m13 .panel-group .panel + .panel {
  margin-top: 0;
  border-top: 0; }

/* line 585, src/assets/scss/modules/_m13.scss */
.m13 .panel-group .panel {
  border-radius: 0; }

/* line 589, src/assets/scss/modules/_m13.scss */
.m13 .panel-default > .panel-heading {
  color: #333;
  background-color: #fff;
  border-color: #e4e5e7;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 600, src/assets/scss/modules/_m13.scss */
.m13 .panel-default > .panel-heading a {
  display: block;
  padding: 10px 15px;
  text-decoration: none; }

/* line 606, src/assets/scss/modules/_m13.scss */
.m13 .panel-default > .panel-heading a:after {
  content: "";
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: transform .25s linear;
  -webkit-transition: -webkit-transform .25s linear; }

/* line 621, src/assets/scss/modules/_m13.scss */
.m13 .panel-default > .panel-heading a[aria-expanded="true"] {
  border-bottom: 1px solid #dddddd;
  border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 50px; }

/* line 628, src/assets/scss/modules/_m13.scss */
.m13 .panel-default > .panel-heading a[aria-expanded="true"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 30px;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
  transform: rotate(225deg);
  top: 20px; }

/* line 640, src/assets/scss/modules/_m13.scss */
.m13 .panel-default > .panel-heading a[aria-expanded="false"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 30px;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
  transform: rotate(45deg);
  top: 19px; }

/* line 652, src/assets/scss/modules/_m13.scss */
.m13 .panel-group {
  border: 1px solid #dddddd;
  margin-bottom: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative; }

/* line 665, src/assets/scss/modules/_m13.scss */
.m13 h4.panel-title {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000000; }

/* line 678, src/assets/scss/modules/_m13.scss */
.m13 input#searchid {
  width: 100%;
  background-repeat: no-repeat;
  text-indent: 20px;
  background-position: right 30px center;
  padding: 0;
  border: none;
  background-color: #f4f4f4;
  line-height: 44px; }

/* line 689, src/assets/scss/modules/_m13.scss */
.m13 input[type=text]:focus {
  background-image: none; }

/* line 692, src/assets/scss/modules/_m13.scss */
.m13 input[type=text]:active {
  background-image: none; }

/* line 695, src/assets/scss/modules/_m13.scss */
.m13 input[type=text]:hover {
  background-image: none; }

/* line 698, src/assets/scss/modules/_m13.scss */
.m13 .accordion {
  overflow-y: scroll;
  height: 100px;
  margin-left: 21px; }

/* line 704, src/assets/scss/modules/_m13.scss */
.m13 ::-webkit-scrollbar {
  width: 12px;
  background-color: #f4f4f4; }

/* line 709, src/assets/scss/modules/_m13.scss */
.m13 ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset red; }

/* line 713, src/assets/scss/modules/_m13.scss */
.m13 ::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #235789; }

/* line 717, src/assets/scss/modules/_m13.scss */
.m13 .regular-checkbox {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative; }

/* line 727, src/assets/scss/modules/_m13.scss */
.m13 input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-top: 15px;
  text-align: center;
  border: 5px solid blue;
  color: blue;
  background-color: white; }

/* line 737, src/assets/scss/modules/_m13.scss */
.m13 .checkbox-custom {
  opacity: 1;
  background-color: #fafafa; }

/* line 742, src/assets/scss/modules/_m13.scss */
.m13 .rectanglemission {
  width: 194px;
  height: 165px;
  border-radius: 3px;
  background-color: #ededed; }

/* line 748, src/assets/scss/modules/_m13.scss */
.m13 ul.no_bullet {
  list-style-type: none;
  padding: 0;
  margin: 0; }

/* line 753, src/assets/scss/modules/_m13.scss */
.m13 li.leaf {
  background: url("/assets/img/icons/arrow_back.svg") no-repeat left center;
  height: 30px;
  padding-left: 50px;
  padding-top: 3px;
  padding-right: 10px; }

/* line 761, src/assets/scss/modules/_m13.scss */
.m13 .rectanglemod {
  padding: 10px;
  max-width: 350px;
  border: 1px solid;
  border-color: #dddddd;
  position: relative;
  border-top: 3px solid black;
  margin-top: 15px;
  margin-bottom: 15px; }
  @media screen and (max-width: 992px) {
    /* line 761, src/assets/scss/modules/_m13.scss */
    .m13 .rectanglemod {
      max-width: 100%;
      padding: 0;
      margin: 0;
      border: none;
      border-bottom: 1px solid;
      border-color: #dddddd; } }

/* line 779, src/assets/scss/modules/_m13.scss */
.m13 .rectanglerwo {
  width: 24px;
  height: 24px;
  position: absolute;
  border: none;
  z-index: 999;
  right: -1px;
  bottom: -1px; }
  @media screen and (max-width: 992px) {
    /* line 779, src/assets/scss/modules/_m13.scss */
    .m13 .rectanglerwo {
      display: none;
      visibility: hidden; } }

/* line 794, src/assets/scss/modules/_m13.scss */
.m13 .digonalline {
  width: 24px; }

/* line 798, src/assets/scss/modules/_m13.scss */
.m13 .digonalline {
  background: linear-gradient(-45deg, #ffffff 49%, #dddddd 52%, #ffffff 51%); }

/* line 802, src/assets/scss/modules/_m13.scss */
.m13 .coldiv {
  width: 320px; }
  /* line 805, src/assets/scss/modules/_m13.scss */
  .m13 .coldiv .div-title {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    min-height: 95px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 805, src/assets/scss/modules/_m13.scss */
      .m13 .coldiv .div-title {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        min-height: 60px;
        margin-top: 6px; } }
  /* line 823, src/assets/scss/modules/_m13.scss */
  .m13 .coldiv .div-text {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    /*text-overflow: ellipsis;
                overflow: hidden; 
                width: 240px; 
                height: 45px;
                white-space: nowrap;*/ }
    @media screen and (max-width: 992px) {
      /* line 823, src/assets/scss/modules/_m13.scss */
      .m13 .coldiv .div-text {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 20px;
        margin-top: 10px; } }
  /* line 845, src/assets/scss/modules/_m13.scss */
  .m13 .coldiv .div-link {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 845, src/assets/scss/modules/_m13.scss */
      .m13 .coldiv .div-link {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
        margin-top: 2px; } }
  /* line 861, src/assets/scss/modules/_m13.scss */
  .m13 .coldiv .div-titlet {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
    height: 95px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 861, src/assets/scss/modules/_m13.scss */
      .m13 .coldiv .div-titlet {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 60px;
        margin-top: 6px; } }
  /* line 879, src/assets/scss/modules/_m13.scss */
  .m13 .coldiv .div-textt {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 5px;
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 879, src/assets/scss/modules/_m13.scss */
      .m13 .coldiv .div-textt {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 20px; } }
  /* line 895, src/assets/scss/modules/_m13.scss */
  .m13 .coldiv .div-linkt {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 895, src/assets/scss/modules/_m13.scss */
      .m13 .coldiv .div-linkt {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
        margin-top: 2px; } }
  @media screen and (max-width: 992px) {
    /* line 802, src/assets/scss/modules/_m13.scss */
    .m13 .coldiv {
      width: 100%; } }

/* line 917, src/assets/scss/modules/_m13.scss */
.m13 h6.firstclass {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333; }

/* line 928, src/assets/scss/modules/_m13.scss */
.m13 h6.secondclass {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333; }

/* line 940, src/assets/scss/modules/_m13.scss */
.m13 .classborder {
  border-right: 1px solid  #dddddd; }
  @media screen and (max-width: 992px) {
    /* line 940, src/assets/scss/modules/_m13.scss */
    .m13 .classborder {
      border-right: 1px solid  #dddddd; } }

/* line 946, src/assets/scss/modules/_m13.scss */
.m13 .classborderwot {
  border-right: 1px solid  #dddddd; }
  @media screen and (max-width: 992px) {
    /* line 946, src/assets/scss/modules/_m13.scss */
    .m13 .classborderwot {
      border-right: none;
      min-height: 0px; } }
  @media screen and (max-width: 576px) {
    /* line 946, src/assets/scss/modules/_m13.scss */
    .m13 .classborderwot {
      border-right: none;
      min-height: 0; } }

/* line 959, src/assets/scss/modules/_m13.scss */
.m13 .classborderwo {
  border-right: none; }
  @media screen and (max-width: 992px) {
    /* line 959, src/assets/scss/modules/_m13.scss */
    .m13 .classborderwo {
      padding-bottom: 9px;
      padding-right: 1px;
      margin-right: 14px;
      padding-left: 1px;
      margin-left: 14px;
      border-bottom: 1px solid  #dddddd;
      margin-bottom: 0px;
      border-right: none;
      min-height: 0px; } }
  @media screen and (max-width: 576px) {
    /* line 959, src/assets/scss/modules/_m13.scss */
    .m13 .classborderwo {
      border-right: none;
      min-height: 0; } }

/* line 981, src/assets/scss/modules/_m13.scss */
.m13 .coldivposition .positiondiv {
  width: 100%;
  height: 20px;
  margin-bottom: 16px; }

/* line 988, src/assets/scss/modules/_m13.scss */
.m13 .coldivposition .titlediv {
  width: 100%;
  min-height: 95px;
  margin-bottom: 13px; }

/* line 995, src/assets/scss/modules/_m13.scss */
.m13 .coldivposition .descriptiondiv {
  width: 100%;
  min-height: 65px;
  margin-bottom: 15px; }

/* line 1002, src/assets/scss/modules/_m13.scss */
.m13 .coldivposition .infodiv {
  width: 100%;
  min-height: 35px;
  margin-bottom: 16px; }

/* line 1009, src/assets/scss/modules/_m13.scss */
.m13 .coldivposition .buttonbiv {
  width: 100%;
  min-height: 45px;
  margin-bottom: 0px; }

/* line 1016, src/assets/scss/modules/_m13.scss */
.m13 .col-md-4.coldivposition {
  border-right: 1px solid #dddddd; }
  /* line 1018, src/assets/scss/modules/_m13.scss */
  .m13 .col-md-4.coldivposition:last-child {
    border-right: none; }

/* line 1023, src/assets/scss/modules/_m13.scss */
.m13 .col-md-12.r {
  margin-bottom: 100px; }

/* line 1027, src/assets/scss/modules/_m13.scss */
.m13 .col-md-12.borderbc {
  border-bottom: 1px solid; }

/* line 2, src/assets/scss/modules/_m14x.scss */
.m14x h2 {
  margin-bottom: 33px; }

/* line 5, src/assets/scss/modules/_m14x.scss */
.m14x ul {
  padding-right: 15px; }

/* line 9, src/assets/scss/modules/_m14x.scss */
.m14x ol li {
  list-style-type: decimal-leading-zero;
  color: orangered; }

/* line 14, src/assets/scss/modules/_m14x.scss */
.m14x a.fontstyle {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 25, src/assets/scss/modules/_m14x.scss */
.m14x a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 45px;
  padding: 0; }

/* line 32, src/assets/scss/modules/_m14x.scss */
.m14x a.linkfont {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000; }

/* line 42, src/assets/scss/modules/_m14x.scss */
.m14x a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 51, src/assets/scss/modules/_m14x.scss */
.m14x a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 60, src/assets/scss/modules/_m14x.scss */
  .m14x a.accordionmenu:first-child {
    color: #235789; }

/* line 64, src/assets/scss/modules/_m14x.scss */
.m14x a.arcodionlink {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 74, src/assets/scss/modules/_m14x.scss */
.m14x a.arcodionlinkchb {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }
  @media screen and (max-width: 992px) {
    /* line 74, src/assets/scss/modules/_m14x.scss */
    .m14x a.arcodionlinkchb {
      margin-left: 15px; } }

/* line 87, src/assets/scss/modules/_m14x.scss */
.m14x a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 100, src/assets/scss/modules/_m14x.scss */
.m14x a:hover {
  outline: none;
  text-decoration: none; }

/* line 104, src/assets/scss/modules/_m14x.scss */
.m14x a:focus {
  outline: none;
  text-decoration: none; }

/* line 108, src/assets/scss/modules/_m14x.scss */
.m14x img {
  width: 100%;
  height: auto; }

/* line 112, src/assets/scss/modules/_m14x.scss */
.m14x .col-md-3.d-flex.justify-content-end {
  padding: 0; }

/* line 116, src/assets/scss/modules/_m14x.scss */
.m14x ul li.nav-item {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 121, src/assets/scss/modules/_m14x.scss */
.m14x ul li.nav-item:active {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 126, src/assets/scss/modules/_m14x.scss */
.m14x ul li.lit {
  padding-left: 12px; }

/* line 130, src/assets/scss/modules/_m14x.scss */
.m14x li.dropdown:hover > .dropdown-menu {
  display: block; }

/* line 133, src/assets/scss/modules/_m14x.scss */
.m14x a.nav-link.linkfont2 {
  margin: 0; }

/* line 137, src/assets/scss/modules/_m14x.scss */
.m14x a.nav-link.linkfont2:hover {
  font-weight: 600; }

/* line 141, src/assets/scss/modules/_m14x.scss */
.m14x ul .collapse.list-unstyled {
  padding: 0;
  border-top: 1px solid #dddddd; }

/* line 146, src/assets/scss/modules/_m14x.scss */
.m14x .triangle {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(315deg);
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px; }

/* line 158, src/assets/scss/modules/_m14x.scss */
.m14x .divaccordionspace {
  margin-top: 30px;
  margin-bottom: 19px; }

/* line 162, src/assets/scss/modules/_m14x.scss */
.m14x .divborder {
  border-top: 1px solid #dddddd;
  margin: 0px;
  padding: 0px; }

/* line 167, src/assets/scss/modules/_m14x.scss */
.m14x .divborderbottom {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px; }

/* line 171, src/assets/scss/modules/_m14x.scss */
.m14x .divacc {
  padding-top: 40px; }

/* line 174, src/assets/scss/modules/_m14x.scss */
.m14x ul.nav.flex-column {
  padding-right: 0; }

/* line 177, src/assets/scss/modules/_m14x.scss */
.m14x .backtp {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101010;
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  text-align: center; }

/* line 190, src/assets/scss/modules/_m14x.scss */
.m14x .arrow {
  content: "";
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 10px;
  height: 10px;
  border-right: 2px solid #222222;
  border-bottom: 2px solid #222222;
  transform: rotate(225deg);
  cursor: pointer;
  margin-bottom: 55px; }

/* line 207, src/assets/scss/modules/_m14x.scss */
.m14x .nav-link.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 62px; }

/* line 221, src/assets/scss/modules/_m14x.scss */
.m14x .nav-link.expand[aria-expanded="true"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
  transform: rotate(225deg);
  top: 69px; }

/* line 238, src/assets/scss/modules/_m14x.scss */
.m14x .nav-item.show .nav-link.linkfont.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 2px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 62px; }

/* line 253, src/assets/scss/modules/_m14x.scss */
.m14x ul.nav.flex-column.divborder {
  margin-right: 0px; }

/* line 256, src/assets/scss/modules/_m14x.scss */
.m14x a.accordionmenu:hover {
  color: #235789; }

/* line 260, src/assets/scss/modules/_m14x.scss */
.m14x .panel-body {
  padding: 29px 0 37px 54px; }
  @media screen and (max-width: 992px) {
    /* line 260, src/assets/scss/modules/_m14x.scss */
    .m14x .panel-body {
      padding: 20px 0px; } }
  /* line 265, src/assets/scss/modules/_m14x.scss */
  .m14x .panel-body p {
    margin-bottom: 11px; }

/* line 270, src/assets/scss/modules/_m14x.scss */
.m14x .panel-group .panel + .panel {
  margin-top: 0;
  border-top: 0; }

/* line 275, src/assets/scss/modules/_m14x.scss */
.m14x .panel-group .panel {
  border-radius: 0; }

/* line 279, src/assets/scss/modules/_m14x.scss */
.m14x .panel-default > .panel-heading {
  color: #333333;
  background-color: #fff;
  border-color: #e4e5e7;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 290, src/assets/scss/modules/_m14x.scss */
.m14x .panel-default > .panel-heading a {
  display: block;
  padding: 10px 15px;
  text-decoration: none; }
  @media screen and (max-width: 992px) {
    /* line 290, src/assets/scss/modules/_m14x.scss */
    .m14x .panel-default > .panel-heading a {
      padding-left: 0px; } }

/* line 299, src/assets/scss/modules/_m14x.scss */
.m14x .panel-default > .panel-heading a:after {
  content: "";
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: transform .25s linear;
  -webkit-transition: -webkit-transform .25s linear; }

/* line 314, src/assets/scss/modules/_m14x.scss */
.m14x .panel-default > .panel-heading a[aria-expanded="true"] {
  border-bottom: 1px solid #dddddd;
  border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 50px; }

/* line 321, src/assets/scss/modules/_m14x.scss */
.m14x .panel-default > .panel-heading a[aria-expanded="true"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 30px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 20px; }
  @media screen and (max-width: 992px) {
    /* line 321, src/assets/scss/modules/_m14x.scss */
    .m14x .panel-default > .panel-heading a[aria-expanded="true"]:after {
      right: 10px; } }

/* line 336, src/assets/scss/modules/_m14x.scss */
.m14x .panel-default > .panel-heading a[aria-expanded="false"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 30px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 19px; }
  @media screen and (max-width: 992px) {
    /* line 336, src/assets/scss/modules/_m14x.scss */
    .m14x .panel-default > .panel-heading a[aria-expanded="false"]:after {
      right: 10px; } }

/* line 351, src/assets/scss/modules/_m14x.scss */
.m14x .panel-group {
  border: 1px solid #dddddd;
  margin-bottom: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative; }
  @media screen and (max-width: 992px) {
    /* line 351, src/assets/scss/modules/_m14x.scss */
    .m14x .panel-group {
      border: none;
      border-radius: 0;
      border-top: 1px solid #dddddd;
      margin-bottom: 0; }
      /* line 365, src/assets/scss/modules/_m14x.scss */
      .m14x .panel-group:first-child {
        margin-top: 30px; }
      /* line 368, src/assets/scss/modules/_m14x.scss */
      .m14x .panel-group:last-child {
        border-bottom: 1px solid #dddddd; } }

@media screen and (max-width: 992px) {
  /* line 373, src/assets/scss/modules/_m14x.scss */
  .m14x .col-md-12.accdiv {
    padding: 0; } }

/* line 380, src/assets/scss/modules/_m14x.scss */
.m14x h4.panel-title {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 392, src/assets/scss/modules/_m14x.scss */
.m14x input#searchid {
  width: 100%;
  background-repeat: no-repeat;
  text-indent: 20px;
  background-position: right 30px center;
  padding: 0;
  border: none;
  background-color: #d2d2d2;
  line-height: 44px; }

/* line 403, src/assets/scss/modules/_m14x.scss */
.m14x input[type=text]:focus {
  background-image: none; }

/* line 406, src/assets/scss/modules/_m14x.scss */
.m14x input[type=text]:active {
  background-image: none; }

/* line 409, src/assets/scss/modules/_m14x.scss */
.m14x input[type=text]:hover {
  background-image: none; }

/* line 412, src/assets/scss/modules/_m14x.scss */
.m14x .accordion {
  overflow-y: scroll;
  height: 100px;
  margin-left: 21px; }

/* line 417, src/assets/scss/modules/_m14x.scss */
.m14x ::-webkit-scrollbar {
  width: 12px;
  background-color: #d2d2d2; }

/* line 422, src/assets/scss/modules/_m14x.scss */
.m14x ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset red; }

/* line 426, src/assets/scss/modules/_m14x.scss */
.m14x ::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #235789; }

/* line 430, src/assets/scss/modules/_m14x.scss */
.m14x .regular-checkbox {
  -webkit-appearance: none;
  background-color: #d2d2d2;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative; }

/* line 440, src/assets/scss/modules/_m14x.scss */
.m14x input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-top: 15px;
  text-align: center;
  border: 5px solid blue;
  color: blue;
  background-color: white; }

/* line 450, src/assets/scss/modules/_m14x.scss */
.m14x .checkbox-custom {
  opacity: 1;
  background-color: #d2d2d2; }

/* line 455, src/assets/scss/modules/_m14x.scss */
.m14x .rectanglemission {
  margin-top: 50px;
  width: 194px;
  border-radius: 3px;
  background-color: #e4e5e7;
  padding-bottom: 20px; }
  @media screen and (max-width: 992px) {
    /* line 455, src/assets/scss/modules/_m14x.scss */
    .m14x .rectanglemission {
      margin-top: 50px;
      width: 100%;
      margin-bottom: 50px; } }

/* line 467, src/assets/scss/modules/_m14x.scss */
.m14x ul.no_bullet {
  list-style-type: none;
  padding: 0;
  margin: 0; }

/* line 472, src/assets/scss/modules/_m14x.scss */
.m14x li.leaf {
  background: url("/assets/img/icons/arrow_back.svg") no-repeat left top;
  min-height: 30px;
  padding-left: 50px;
  padding-top: 3px;
  padding-right: 10px; }

/* line 479, src/assets/scss/modules/_m14x.scss */
.m14x .col-md-12.d-flex.justify-content-end {
  padding: 0;
  margin-bottom: 50px; }

/* line 483, src/assets/scss/modules/_m14x.scss */
.m14x .panel-body.checkbox {
  padding: 0; }

@media screen and (max-width: 992px) {
  /* line 487, src/assets/scss/modules/_m14x.scss */
  .m14x .panel-group.checkbox {
    border: 1px solid #dddddd;
    margin-bottom: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; } }

@media screen and (max-width: 992px) {
  /* line 498, src/assets/scss/modules/_m14x.scss */
  .m14x .firstmenu {
    display: none !important; } }

/* line 503, src/assets/scss/modules/_m14x.scss */
.m14x a {
  color: #333333;
  text-decoration: underline;
  margin-bottom: 10px; }
  /* line 507, src/assets/scss/modules/_m14x.scss */
  .m14x a:hover {
    color: #333333; }

@media screen and (max-width: 767px) {
  /* line 512, src/assets/scss/modules/_m14x.scss */
  .m14x .mobile-showall {
    border: 0; }
    /* line 514, src/assets/scss/modules/_m14x.scss */
    .m14x .mobile-showall .panel-collapse {
      display: block !important; }
    /* line 517, src/assets/scss/modules/_m14x.scss */
    .m14x .mobile-showall .arcodionlink {
      border: 0 !important; }
      /* line 519, src/assets/scss/modules/_m14x.scss */
      .m14x .mobile-showall .arcodionlink:after {
        display: none !important; } }

/* line 529, src/assets/scss/modules/_m14x.scss */
.m14x .dropdown-filter-search .noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 538, src/assets/scss/modules/_m14x.scss */
.m14x .dropdown-filter-search .dropdown-container, .m14x .dropdown-filter-search .instructions {
  width: 200px;
  margin: 20px auto 0;
  font-size: 14px;
  font-family: sans-serif;
  overflow: auto; }

/* line 546, src/assets/scss/modules/_m14x.scss */
.m14x .dropdown-filter-search .instructions {
  width: 100%;
  text-align: center; }

/* line 551, src/assets/scss/modules/_m14x.scss */
.m14x .dropdown-filter-search .dropdown-button {
  float: left;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 5px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  line-height: 50px;
  padding: 0 20px;
  position: relative;
  max-width: 326px; }
  /* line 563, src/assets/scss/modules/_m14x.scss */
  .m14x .dropdown-filter-search .dropdown-button:after {
    content: "";
    display: inline-block;
    font-family: Glyphicons Halflings;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: transform .25s linear;
    -webkit-transition: -webkit-transform .25s linear;
    width: 10px;
    height: 10px;
    position: absolute;
    right: 30px;
    border-right: 1px solid #222;
    border-bottom: 1px solid #222;
    transform: rotate(45deg);
    top: 19px; }
  /* line 584, src/assets/scss/modules/_m14x.scss */
  .m14x .dropdown-filter-search .dropdown-button .dropdown-label, .m14x .dropdown-filter-search .dropdown-button .dropdown-quantity {
    float: left; }
  /* line 588, src/assets/scss/modules/_m14x.scss */
  .m14x .dropdown-filter-search .dropdown-button .dropdown-quantity {
    margin-left: 4px; }
  /* line 592, src/assets/scss/modules/_m14x.scss */
  .m14x .dropdown-filter-search .dropdown-button .fa-filter {
    float: right; }

/* line 597, src/assets/scss/modules/_m14x.scss */
.m14x .dropdown-filter-search .dropdown-list {
  float: left;
  border: 1px solid lightgray;
  border-top: none;
  box-sizing: border-box;
  position: absolute;
  z-index: 999999999;
  background: #fff;
  top: 93px;
  max-width: 326px;
  width: -webkit-calc(100% - 30px);
  width: -moz-calc(100% - 30px);
  width: calc(100% - 30px); }
  /* line 610, src/assets/scss/modules/_m14x.scss */
  .m14x .dropdown-filter-search .dropdown-list input[type="search"] {
    line-height: 40px;
    width: 100%;
    border: 0;
    background-color: #f4f4f4; }
  /* line 617, src/assets/scss/modules/_m14x.scss */
  .m14x .dropdown-filter-search .dropdown-list ul {
    list-style-type: none !important;
    margin: 10px 0;
    max-height: 138px;
    overflow-y: auto;
    padding: 10px 14px 0px 20px; }
    /* line 623, src/assets/scss/modules/_m14x.scss */
    .m14x .dropdown-filter-search .dropdown-list ul input[type="checkbox"] {
      position: relative;
      top: 4px;
      right: 4px; }
    /* line 628, src/assets/scss/modules/_m14x.scss */
    .m14x .dropdown-filter-search .dropdown-list ul label {
      font-family: "Work Sans", "sans-serif";
      font-size: 13px;
      line-height: 1.38;
      color: #222222; }

/* line 636, src/assets/scss/modules/_m14x.scss */
.m14x .dropdown-filter-search input[type="checkbox"] {
  margin-top: 0; }

/* line 640, src/assets/scss/modules/_m14x.scss */
.m14x input[type="search"]::placeholder {
  color: #999999;
  font-family: "Work Sans", "sans-serif";
  font-size: 12px;
  padding-left: 20px; }

/* line 3, src/assets/scss/modules/_m15.scss */
.m15 h2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }
  /* line 14, src/assets/scss/modules/_m15.scss */
  .m15 h2.h22 {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #222222;
    margin-left: 15px; }
    @media screen and (max-width: 992px) {
      /* line 14, src/assets/scss/modules/_m15.scss */
      .m15 h2.h22 {
        font-size: 20px;
        margin-left: 15px;
        padding: 0;
        width: 50%; } }
  @media screen and (max-width: 992px) {
    /* line 3, src/assets/scss/modules/_m15.scss */
    .m15 h2 {
      font-size: 20px;
      margin-bottom: 30px; } }

/* line 41, src/assets/scss/modules/_m15.scss */
.m15 h6.firstclass {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-left: 15px; }
  @media screen and (max-width: 992px) {
    /* line 41, src/assets/scss/modules/_m15.scss */
    .m15 h6.firstclass {
      margin: 0;
      position: absolute;
      right: 15px;
      top: 20px; } }

/* line 59, src/assets/scss/modules/_m15.scss */
.m15 h6.secondclass {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333; }

/* line 72, src/assets/scss/modules/_m15.scss */
.m15 a.arrowpg {
  color: #235789;
  font-size: 18px;
  padding-top: 0;
  padding-bottom: 0; }

/* line 79, src/assets/scss/modules/_m15.scss */
.m15 a.h44 {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #235789;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 79, src/assets/scss/modules/_m15.scss */
    .m15 a.h44 {
      padding: 0;
      margin-top: 1px;
      margin-bottom: 30px;
      margin-left: 0px;
      margin-right: 0px; } }

/* line 97, src/assets/scss/modules/_m15.scss */
.m15 a.paragraph {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: inline;
  color: #9b9b9b; }
  @media screen and (max-width: 992px) {
    /* line 97, src/assets/scss/modules/_m15.scss */
    .m15 a.paragraph {
      font-size: 15px;
      margin-left: 0px;
      margin-right: 0px; } }

/* line 113, src/assets/scss/modules/_m15.scss */
.m15 a.withimagea {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 113, src/assets/scss/modules/_m15.scss */
    .m15 a.withimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px; } }

/* line 133, src/assets/scss/modules/_m15.scss */
.m15 a.withoutimagea {
  margin-left: 15px;
  margin-right: 15px;
  height: 95px;
  font-family: "Work Sans", "sans-serif";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  display: flex;
  margin-bottom: 40px; }
  @media screen and (max-width: 992px) {
    /* line 133, src/assets/scss/modules/_m15.scss */
    .m15 a.withoutimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px; } }

/* line 156, src/assets/scss/modules/_m15.scss */
.m15 a.fontstyle {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 168, src/assets/scss/modules/_m15.scss */
.m15 a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 45px;
  padding: 0; }

/* line 176, src/assets/scss/modules/_m15.scss */
.m15 a.linkfont {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000; }

/* line 186, src/assets/scss/modules/_m15.scss */
.m15 a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 196, src/assets/scss/modules/_m15.scss */
.m15 a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 206, src/assets/scss/modules/_m15.scss */
  .m15 a.accordionmenu:first-child {
    color: #235789; }

/* line 211, src/assets/scss/modules/_m15.scss */
.m15 a.arcodionlink {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 221, src/assets/scss/modules/_m15.scss */
.m15 a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 233, src/assets/scss/modules/_m15.scss */
.m15 a.linksubmenu {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline;
  border-bottom: 1px solid #333333; }

/* line 246, src/assets/scss/modules/_m15.scss */
.m15 a:hover {
  outline: none;
  text-decoration: none; }

/* line 251, src/assets/scss/modules/_m15.scss */
.m15 a:focus {
  outline: none;
  text-decoration: none; }

/* line 257, src/assets/scss/modules/_m15.scss */
.m15 .rectanglemod {
  padding: 10px;
  max-width: 350px;
  border: 1px solid;
  border-color: #dddddd;
  position: relative;
  border-top: 3px solid black;
  margin-top: 15px;
  margin-bottom: 15px; }
  @media screen and (max-width: 992px) {
    /* line 257, src/assets/scss/modules/_m15.scss */
    .m15 .rectanglemod {
      max-width: 100%;
      padding: 15px;
      margin: 0;
      border: none;
      border: 1px solid #dddddd;
      border-top: 3px solid black;
      margin-bottom: 20px; } }

/* line 276, src/assets/scss/modules/_m15.scss */
.m15 .rectanglerwo {
  width: 24px;
  height: 24px;
  position: absolute;
  border: none;
  z-index: 999;
  right: -1px;
  bottom: -1px; }

/* line 289, src/assets/scss/modules/_m15.scss */
.m15 .digonalline {
  width: 24px; }

/* line 293, src/assets/scss/modules/_m15.scss */
.m15 .digonalline {
  background: linear-gradient(-45deg, #fff 49%, #dddddd 52%, #fff 51%); }

/* line 297, src/assets/scss/modules/_m15.scss */
.m15 .coldiv {
  width: 320px; }
  /* line 300, src/assets/scss/modules/_m15.scss */
  .m15 .coldiv .div-title {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    min-height: 95px;
    max-height: 95px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 300, src/assets/scss/modules/_m15.scss */
      .m15 .coldiv .div-title {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        min-height: 60px;
        margin-top: 6px;
        padding: 0; } }
  /* line 320, src/assets/scss/modules/_m15.scss */
  .m15 .coldiv .div-text {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    /*text-overflow: ellipsis;
            overflow: hidden; 
            width: 240px; 
             height: 45px;
            white-space: nowrap;*/ }
    @media screen and (max-width: 992px) {
      /* line 320, src/assets/scss/modules/_m15.scss */
      .m15 .coldiv .div-text {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 20px;
        margin-top: 10px;
        padding: 0; } }
  /* line 343, src/assets/scss/modules/_m15.scss */
  .m15 .coldiv .div-link {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 343, src/assets/scss/modules/_m15.scss */
      .m15 .coldiv .div-link {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-top: 2px;
        padding: 0; } }
  /* line 360, src/assets/scss/modules/_m15.scss */
  .m15 .coldiv .div-titlet {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
    height: 95px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 360, src/assets/scss/modules/_m15.scss */
      .m15 .coldiv .div-titlet {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 60px;
        margin-top: 6px; } }
  /* line 378, src/assets/scss/modules/_m15.scss */
  .m15 .coldiv .div-textt {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 5px;
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 378, src/assets/scss/modules/_m15.scss */
      .m15 .coldiv .div-textt {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 20px; } }
  /* line 394, src/assets/scss/modules/_m15.scss */
  .m15 .coldiv .div-linkt {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 394, src/assets/scss/modules/_m15.scss */
      .m15 .coldiv .div-linkt {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
        margin-top: 2px; } }
  @media screen and (max-width: 992px) {
    /* line 297, src/assets/scss/modules/_m15.scss */
    .m15 .coldiv {
      width: 100%; } }

/* line 415, src/assets/scss/modules/_m15.scss */
.m15 .imageclass {
  max-width: 230px;
  height: 117px;
  background-size: cover;
  margin-top: 19px;
  margin-bottom: 15.6px;
  margin-left: 0px;
  margin-right: 0px; }
  @media screen and (max-width: 992px) {
    /* line 415, src/assets/scss/modules/_m15.scss */
    .m15 .imageclass {
      max-width: 313px;
      max-height: 140px;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      background-size: cover; } }

/* line 432, src/assets/scss/modules/_m15.scss */
.m15 img {
  width: 100%;
  height: auto; }

/* line 437, src/assets/scss/modules/_m15.scss */
.m15 .publications_slider {
  padding-bottom: 55px;
  padding-top: 20px;
  border-bottom: 1px solid;
  border-color: #dddddd;
  width: 100%;
  margin-bottom: 50px; }
  @media screen and (max-width: 992px) {
    /* line 437, src/assets/scss/modules/_m15.scss */
    .m15 .publications_slider {
      padding-top: 0;
      padding-bottom: 0px;
      border-bottom: 1px solid #dddddd; } }

/* line 3, src/assets/scss/modules/_m16.scss */
.m16 h2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  margin-bottom: 34px; }
  /* line 14, src/assets/scss/modules/_m16.scss */
  .m16 h2.h22 {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #222222; }
    @media screen and (max-width: 992px) {
      /* line 14, src/assets/scss/modules/_m16.scss */
      .m16 h2.h22 {
        font-size: 20px;
        font-weight: 500; } }
  @media screen and (max-width: 992px) {
    /* line 3, src/assets/scss/modules/_m16.scss */
    .m16 h2 {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      margin: 0;
      padding: 0; } }

/* line 40, src/assets/scss/modules/_m16.scss */
.m16 a.buttonlinkw {
  width: 200px;
  height: 45px;
  line-height: 44px;
  border-radius: 22.5px;
  background-color: #fff;
  border: 1px solid #235789;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #235789;
  text-align: center;
  position: absolute; }
  @media screen and (max-width: 992px) {
    /* line 40, src/assets/scss/modules/_m16.scss */
    .m16 a.buttonlinkw {
      position: relative;
      padding: 15px 50px; } }

/* line 62, src/assets/scss/modules/_m16.scss */
.m16 a.adescriptiondiv {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #222222;
  display: table-row; }
  @media screen and (max-width: 992px) {
    /* line 62, src/assets/scss/modules/_m16.scss */
    .m16 a.adescriptiondiv {
      font-size: 13px;
      text-align: center; } }

/* line 79, src/assets/scss/modules/_m16.scss */
.m16 a.ainfodiv {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #9b9b9b;
  display: table-row; }
  @media screen and (max-width: 992px) {
    /* line 79, src/assets/scss/modules/_m16.scss */
    .m16 a.ainfodiv {
      font-size: 14px;
      text-align: center; } }

/* line 95, src/assets/scss/modules/_m16.scss */
.m16 a.pclass2 {
  min-height: 93px;
  margin-right: 0px;
  margin-left: 0%;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.40;
  letter-spacing: normal;
  color: #222222;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 95, src/assets/scss/modules/_m16.scss */
    .m16 a.pclass2 {
      display: none; } }

/* line 112, src/assets/scss/modules/_m16.scss */
.m16 a.h22 {
  font-family: "Work Sans", "sans-serif";
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #222222;
  margin-right: 0px;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 112, src/assets/scss/modules/_m16.scss */
    .m16 a.h22 {
      min-height: 126px;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3; } }

/* line 132, src/assets/scss/modules/_m16.scss */
.m16 a.h44 {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #235789;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 132, src/assets/scss/modules/_m16.scss */
    .m16 a.h44 {
      padding: 0;
      margin-top: 1px;
      margin-bottom: 30px;
      margin-left: 0px;
      margin-right: 0px; } }

/* line 150, src/assets/scss/modules/_m16.scss */
.m16 a.paragraph {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: inline;
  color: #9b9b9b; }
  @media screen and (max-width: 992px) {
    /* line 150, src/assets/scss/modules/_m16.scss */
    .m16 a.paragraph {
      font-size: 15px;
      margin-left: 0px;
      margin-right: 0px; } }

/* line 166, src/assets/scss/modules/_m16.scss */
.m16 a.withimagea {
  font-family: "Work Sans", "sans-serif";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 166, src/assets/scss/modules/_m16.scss */
    .m16 a.withimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px; } }

/* line 186, src/assets/scss/modules/_m16.scss */
.m16 a.withoutimagea {
  margin-left: 15px;
  margin-right: 15px;
  height: 95px;
  font-family: "Work Sans", "sans-serif";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  display: flex;
  margin-bottom: 40px; }
  @media screen and (max-width: 992px) {
    /* line 186, src/assets/scss/modules/_m16.scss */
    .m16 a.withoutimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px; } }

/* line 209, src/assets/scss/modules/_m16.scss */
.m16 a.fontstyle {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 221, src/assets/scss/modules/_m16.scss */
.m16 a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 75px;
  padding: 0; }

/* line 229, src/assets/scss/modules/_m16.scss */
.m16 a.linkfont {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000; }

/* line 239, src/assets/scss/modules/_m16.scss */
.m16 a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 249, src/assets/scss/modules/_m16.scss */
.m16 a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 259, src/assets/scss/modules/_m16.scss */
  .m16 a.accordionmenu:first-child {
    color: #235789; }

/* line 264, src/assets/scss/modules/_m16.scss */
.m16 a.arcodionlink {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 274, src/assets/scss/modules/_m16.scss */
.m16 a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 286, src/assets/scss/modules/_m16.scss */
.m16 a.linksubmenu {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline;
  border-bottom: 1px solid #333333; }

/* line 299, src/assets/scss/modules/_m16.scss */
.m16 a:hover {
  outline: none;
  text-decoration: none; }

/* line 304, src/assets/scss/modules/_m16.scss */
.m16 a:focus {
  outline: none;
  text-decoration: none; }

/* line 312, src/assets/scss/modules/_m16.scss */
.m16 .coldivposition .positiondiv {
  width: 100%;
  height: 20px;
  margin-bottom: 16px; }
  @media screen and (max-width: 992px) {
    /* line 312, src/assets/scss/modules/_m16.scss */
    .m16 .coldivposition .positiondiv {
      display: none; } }

/* line 322, src/assets/scss/modules/_m16.scss */
.m16 .coldivposition .titlediv {
  width: 100%;
  min-height: 95px;
  margin-bottom: 13px; }
  @media screen and (max-width: 992px) {
    /* line 322, src/assets/scss/modules/_m16.scss */
    .m16 .coldivposition .titlediv {
      width: 255px;
      max-height: 90px;
      margin-left: auto;
      margin-right: auto;
      text-align: center; } }

/* line 336, src/assets/scss/modules/_m16.scss */
.m16 .coldivposition .descriptiondiv {
  width: 100%;
  min-height: 65px;
  margin-bottom: 15px; }
  @media screen and (max-width: 992px) {
    /* line 336, src/assets/scss/modules/_m16.scss */
    .m16 .coldivposition .descriptiondiv {
      width: 255px;
      max-height: 65px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-bottom: 5px; } }

/* line 351, src/assets/scss/modules/_m16.scss */
.m16 .coldivposition .infodiv {
  width: 100%;
  min-height: 35px;
  margin-bottom: 16px; }
  @media screen and (max-width: 992px) {
    /* line 351, src/assets/scss/modules/_m16.scss */
    .m16 .coldivposition .infodiv {
      width: 255px;
      height: 40px;
      margin-left: auto;
      margin-right: auto;
      text-align: center; } }

/* line 365, src/assets/scss/modules/_m16.scss */
.m16 .coldivposition .buttonbiv {
  width: 100%;
  min-height: 45px;
  margin-bottom: 0px; }
  @media screen and (max-width: 992px) {
    /* line 365, src/assets/scss/modules/_m16.scss */
    .m16 .coldivposition .buttonbiv {
      text-align: center;
      margin-bottom: 10px; } }

/* line 376, src/assets/scss/modules/_m16.scss */
.m16 .col-md-4.coldivposition {
  border-right: 1px solid #dddddd; }
  /* line 378, src/assets/scss/modules/_m16.scss */
  .m16 .col-md-4.coldivposition:last-child {
    border-right: none; }
    @media screen and (max-width: 992px) {
      /* line 378, src/assets/scss/modules/_m16.scss */
      .m16 .col-md-4.coldivposition:last-child {
        border: none; } }
  @media screen and (max-width: 992px) {
    /* line 376, src/assets/scss/modules/_m16.scss */
    .m16 .col-md-4.coldivposition {
      border-right: none; } }

/* line 389, src/assets/scss/modules/_m16.scss */
.m16 .publications_slider {
  margin-top: 15px;
  margin-bottom: 100px; }
  @media screen and (max-width: 992px) {
    /* line 389, src/assets/scss/modules/_m16.scss */
    .m16 .publications_slider {
      margin-top: 40px;
      padding-bottom: 45px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 40px; } }

/* line 400, src/assets/scss/modules/_m16.scss */
.m16 .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 30px 0;
  list-style-type: none; }
  /* line 409, src/assets/scss/modules/_m16.scss */
  .m16 .slick-dots li {
    margin: 0 0.25rem; }
  /* line 413, src/assets/scss/modules/_m16.scss */
  .m16 .slick-dots button {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #dddddd;
    text-indent: -9999px; }
  /* line 425, src/assets/scss/modules/_m16.scss */
  .m16 .slick-dots li.slick-active button {
    background-color: #192830; }

/* line 3, src/assets/scss/modules/_m17.scss */
.m17 h2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  margin-bottom: 34px; }
  @media screen and (max-width: 992px) {
    /* line 3, src/assets/scss/modules/_m17.scss */
    .m17 h2 {
      font-size: 20px;
      font-weight: 500; } }

/* line 21, src/assets/scss/modules/_m17.scss */
.m17 a.arcodionlink {
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 35, src/assets/scss/modules/_m17.scss */
.m17 p.parccodion {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #333333;
  margin-left: 55px;
  padding: 0;
  margin-bottom: 18px; }
  /* line 47, src/assets/scss/modules/_m17.scss */
  .m17 p.parccodion:first-child {
    padding-left: 55px;
    padding-top: 28px; }
  /* line 52, src/assets/scss/modules/_m17.scss */
  .m17 p.parccodion:last-child {
    padding-bottom: 30px; }

/* line 57, src/assets/scss/modules/_m17.scss */
.m17 p.pacrtext {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #333333; }

/* line 68, src/assets/scss/modules/_m17.scss */
.m17 p.pacrtextb {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #333333;
  font-weight: bold; }

/* line 86, src/assets/scss/modules/_m17.scss */
.m17 .panel-group:last-child {
  margin-bottom: 110px; }

/* line 92, src/assets/scss/modules/_m17.scss */
.m17 .panel-body {
  padding: 40px 54px; }

/* line 96, src/assets/scss/modules/_m17.scss */
.m17 .panel-group .panel + .panel {
  margin-top: 0;
  border-top: 0; }

/* line 101, src/assets/scss/modules/_m17.scss */
.m17 .panel-group .panel {
  border-radius: 0; }

/* line 105, src/assets/scss/modules/_m17.scss */
.m17 .panel-default > .panel-heading {
  color: #333333;
  background-color: #fff;
  border-color: #8c8c8c;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 116, src/assets/scss/modules/_m17.scss */
.m17 .panel-default > .panel-heading a {
  display: block;
  padding: 10px 15px;
  text-decoration: none; }
  @media screen and (max-width: 992px) {
    /* line 116, src/assets/scss/modules/_m17.scss */
    .m17 .panel-default > .panel-heading a {
      padding-left: 0px; } }

/* line 125, src/assets/scss/modules/_m17.scss */
.m17 .panel-default > .panel-heading a:after {
  content: "";
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: transform .25s linear;
  -webkit-transition: -webkit-transform .25s linear; }

/* line 140, src/assets/scss/modules/_m17.scss */
.m17 .panel-default > .panel-heading a[aria-expanded="true"] {
  border-bottom: 1px solid #dddddd;
  border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 50px; }

/* line 147, src/assets/scss/modules/_m17.scss */
.m17 .panel-default > .panel-heading a[aria-expanded="true"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 30px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 20px; }
  @media screen and (max-width: 992px) {
    /* line 147, src/assets/scss/modules/_m17.scss */
    .m17 .panel-default > .panel-heading a[aria-expanded="true"]:after {
      right: 5px; } }

/* line 162, src/assets/scss/modules/_m17.scss */
.m17 .panel-default > .panel-heading a[aria-expanded="false"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 30px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 19px; }
  @media screen and (max-width: 992px) {
    /* line 162, src/assets/scss/modules/_m17.scss */
    .m17 .panel-default > .panel-heading a[aria-expanded="false"]:after {
      right: 5px; } }

/* line 177, src/assets/scss/modules/_m17.scss */
.m17 .panel-group {
  border: 1px solid #dddddd;
  margin-bottom: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative; }
  @media screen and (max-width: 992px) {
    /* line 177, src/assets/scss/modules/_m17.scss */
    .m17 .panel-group {
      border: none;
      border-radius: 0;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 0; }
      /* line 192, src/assets/scss/modules/_m17.scss */
      .m17 .panel-group:first-child {
        margin-top: 30px; } }

@media screen and (max-width: 992px) {
  /* line 197, src/assets/scss/modules/_m17.scss */
  .m17 .col-md-12.accdiv {
    padding: 0; } }

/* line 1, src/assets/scss/modules/_m18x.scss */
.m18 {
  border-top: 1px solid #dddddd; }
  /* line 4, src/assets/scss/modules/_m18x.scss */
  .m18 a.buttonlinkk2 {
    min-width: 120px;
    height: 30px;
    background-color: #235789;
    border: #235789;
    padding-top: 6px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 19px;
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #fff;
    margin-left: 15px;
    margin-right: 5px;
    margin-bottom: 20px;
    text-align: center;
    border-radius: 3px; }
    @media screen and (max-width: 992px) {
      /* line 4, src/assets/scss/modules/_m18x.scss */
      .m18 a.buttonlinkk2 {
        margin-bottom: 20px;
        margin-left: 0px;
        margin-right: 20px; } }
  /* line 34, src/assets/scss/modules/_m18x.scss */
  .m18 .linkmenu {
    margin-top: 15px;
    width: 100%; }
    @media screen and (max-width: 992px) {
      /* line 34, src/assets/scss/modules/_m18x.scss */
      .m18 .linkmenu {
        margin-bottom: 0px; } }

/* line 4, src/assets/scss/modules/_m19.scss */
.m19 input#searchid {
  width: 100%;
  background-repeat: no-repeat;
  text-indent: 20px;
  background-position: right 30px center;
  padding: 0;
  border: none;
  background-color: #d2d2d2;
  line-height: 44px; }

/* line 15, src/assets/scss/modules/_m19.scss */
.m19 input[type=text]:focus {
  background-image: none; }

/* line 18, src/assets/scss/modules/_m19.scss */
.m19 input[type=text]:active {
  background-image: none; }

/* line 21, src/assets/scss/modules/_m19.scss */
.m19 input[type=text]:hover {
  background-image: none; }

/* line 24, src/assets/scss/modules/_m19.scss */
.m19 .accordion {
  overflow-y: scroll;
  height: 100px;
  margin-left: 21px; }

/* line 30, src/assets/scss/modules/_m19.scss */
.m19 ::-webkit-scrollbar {
  width: 12px;
  background-color: #d2d2d2; }

/* line 35, src/assets/scss/modules/_m19.scss */
.m19 ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset red; }

/* line 39, src/assets/scss/modules/_m19.scss */
.m19 ::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #235789; }

/* line 43, src/assets/scss/modules/_m19.scss */
.m19 .regular-checkbox {
  -webkit-appearance: none;
  background-color: #d2d2d2;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative; }

/* line 53, src/assets/scss/modules/_m19.scss */
.m19 input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-top: 15px;
  text-align: center;
  border: 5px solid blue;
  color: blue;
  background-color: white; }

/* line 63, src/assets/scss/modules/_m19.scss */
.m19 .checkbox-custom {
  opacity: 1;
  background-color: #d2d2d2; }

/* line 70, src/assets/scss/modules/_m19.scss */
.m19 .panel-group:last-child {
  margin-bottom: 110px; }

/* line 76, src/assets/scss/modules/_m19.scss */
.m19 .panel-body {
  padding: 40px 54px; }

/* line 80, src/assets/scss/modules/_m19.scss */
.m19 .panel-group .panel + .panel {
  margin-top: 0;
  border-top: 0; }

/* line 85, src/assets/scss/modules/_m19.scss */
.m19 .panel-group .panel {
  border-radius: 0; }

/* line 89, src/assets/scss/modules/_m19.scss */
.m19 .panel-default > .panel-heading {
  color: #333333;
  background-color: #fff;
  border-color: #e4e5e7;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 100, src/assets/scss/modules/_m19.scss */
.m19 .panel-default > .panel-heading a {
  display: block;
  padding: 10px 15px;
  text-decoration: none; }
  @media screen and (max-width: 992px) {
    /* line 100, src/assets/scss/modules/_m19.scss */
    .m19 .panel-default > .panel-heading a {
      padding-left: 0px; } }

/* line 109, src/assets/scss/modules/_m19.scss */
.m19 .panel-default > .panel-heading a:after {
  content: "";
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: transform .25s linear;
  -webkit-transition: -webkit-transform .25s linear; }

/* line 124, src/assets/scss/modules/_m19.scss */
.m19 .panel-default > .panel-heading a[aria-expanded="true"] {
  border-bottom: 1px solid #dddddd;
  border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 50px; }

/* line 131, src/assets/scss/modules/_m19.scss */
.m19 .panel-default > .panel-heading a[aria-expanded="true"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 30px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 20px; }
  @media screen and (max-width: 992px) {
    /* line 131, src/assets/scss/modules/_m19.scss */
    .m19 .panel-default > .panel-heading a[aria-expanded="true"]:after {
      right: 5px; } }

/* line 146, src/assets/scss/modules/_m19.scss */
.m19 .panel-default > .panel-heading a[aria-expanded="false"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 30px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 19px; }
  @media screen and (max-width: 992px) {
    /* line 146, src/assets/scss/modules/_m19.scss */
    .m19 .panel-default > .panel-heading a[aria-expanded="false"]:after {
      right: 5px; } }

/* line 161, src/assets/scss/modules/_m19.scss */
.m19 .panel-group {
  border: 1px solid #dddddd;
  margin-bottom: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative; }
  @media screen and (max-width: 992px) {
    /* line 161, src/assets/scss/modules/_m19.scss */
    .m19 .panel-group {
      border: none;
      border-radius: 0;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 0; }
      /* line 176, src/assets/scss/modules/_m19.scss */
      .m19 .panel-group:first-child {
        margin-top: 30px; } }

@media screen and (max-width: 992px) {
  /* line 181, src/assets/scss/modules/_m19.scss */
  .m19 .col-md-12.accdiv {
    padding: 0; } }

/* line 2, src/assets/scss/modules/_m20x.scss */
.m20x p {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #333333;
  padding-bottom: 51px;
  margin-bottom: 0px; }
  /* line 13, src/assets/scss/modules/_m20x.scss */
  .m20x p:first-child {
    margin-bottom: 30px;
    padding-bottom: 0px; }
  /* line 17, src/assets/scss/modules/_m20x.scss */
  .m20x p.ptext {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d2114; }
  /* line 27, src/assets/scss/modules/_m20x.scss */
  .m20x p.ptext12 {
    font-family: "Work Sans", "sans-serif";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #222222; }
  /* line 37, src/assets/scss/modules/_m20x.scss */
  .m20x p.parccodion {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    margin-left: 55px;
    padding: 0;
    margin-bottom: 18px; }
    /* line 48, src/assets/scss/modules/_m20x.scss */
    .m20x p.parccodion:first-child {
      margin-left: 55px;
      margin-top: 28px; }
    /* line 52, src/assets/scss/modules/_m20x.scss */
    .m20x p.parccodion:last-child {
      margin-bottom: 30px; }
  /* line 56, src/assets/scss/modules/_m20x.scss */
  .m20x p.psubmenu {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    text-align: center;
    border-bottom: 1px solid #dddddd; }
  /* line 72, src/assets/scss/modules/_m20x.scss */
  .m20x p.pdepartment {
    max-width: 164px;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    margin: 0;
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #235789; }
  /* line 87, src/assets/scss/modules/_m20x.scss */
  .m20x p.pname {
    max-width: 220px;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    margin: 0;
    font-family: "TiemposHeadline", sans-serif;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #383838; }
  /* line 102, src/assets/scss/modules/_m20x.scss */
  .m20x p.pgroup {
    max-width: 191px;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    margin: 0;
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9b9b9b; }
  /* line 117, src/assets/scss/modules/_m20x.scss */
  .m20x p.pspecialization {
    max-width: 220px;
    height: 100%;
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #383838;
    margin-bottom: auto; }
  /* line 131, src/assets/scss/modules/_m20x.scss */
  .m20x p.pinfocontact {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin-bottom: 0;
    text-align: center;
    line-height: 22px; }

/* line 147, src/assets/scss/modules/_m20x.scss */
.m20x .department {
  width: 100%;
  min-height: 37px;
  display: flex;
  justify-content: center;
  top: 0px;
  text-align: center;
  vertical-align: middle;
  line-height: 90px; }
  @media screen and (max-width: 992px) {
    /* line 147, src/assets/scss/modules/_m20x.scss */
    .m20x .department {
      display: none; } }

/* line 161, src/assets/scss/modules/_m20x.scss */
.m20x .profilepicture {
  width: 100%;
  min-height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 57px; }
  @media screen and (max-width: 992px) {
    /* line 161, src/assets/scss/modules/_m20x.scss */
    .m20x .profilepicture {
      margin-top: 20px; } }

/* line 173, src/assets/scss/modules/_m20x.scss */
.m20x .name {
  width: 100%;
  min-height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 199px;
  margin-top: 20px;
  margin-bottom: 20px; }
  @media screen and (max-width: 992px) {
    /* line 173, src/assets/scss/modules/_m20x.scss */
    .m20x .name {
      margin-top: 17px;
      margin-bottom: 12px; } }

/* line 188, src/assets/scss/modules/_m20x.scss */
.m20x .specialization {
  width: 100%;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 260px;
  margin-bottom: 5px; }
  @media screen and (max-width: 992px) {
    /* line 188, src/assets/scss/modules/_m20x.scss */
    .m20x .specialization {
      margin-bottom: 30px; } }

/* line 201, src/assets/scss/modules/_m20x.scss */
.m20x a:hover {
  text-decoration: none; }

/* line 205, src/assets/scss/modules/_m20x.scss */
.m20x .role {
  width: 100%;
  min-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 235px;
  margin-top: 8px;
  margin-bottom: 20px; }

/* line 216, src/assets/scss/modules/_m20x.scss */
.m20x img.imageround {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover; }

@media screen and (max-width: 992px) {
  /* line 223, src/assets/scss/modules/_m20x.scss */
  .m20x .borderclass {
    border-right: none; } }

/* line 229, src/assets/scss/modules/_m20x.scss */
.m20x .col-md-4.person-box {
  border-left: 1px solid #fff;
  border-right: 1px solid #dddddd; }
  /* line 232, src/assets/scss/modules/_m20x.scss */
  .m20x .col-md-4.person-box:nth-child(3) {
    border-right: 0; }
  /* line 235, src/assets/scss/modules/_m20x.scss */
  .m20x .col-md-4.person-box:first-child {
    padding-left: 0; }
  @media screen and (max-width: 992px) {
    /* line 229, src/assets/scss/modules/_m20x.scss */
    .m20x .col-md-4.person-box {
      border-right: none;
      border-bottom: 1px solid #dddddd; } }
  /* line 242, src/assets/scss/modules/_m20x.scss */
  .m20x .col-md-4.person-box.last-person {
    border-bottom: 0; }

/* line 247, src/assets/scss/modules/_m20x.scss */
.m20x .row.divspaces {
  padding-bottom: 22px;
  padding-top: 30px;
  border-bottom: 1px solid #dddddd; }
  /* line 251, src/assets/scss/modules/_m20x.scss */
  .m20x .row.divspaces:last-child {
    border-bottom: none; }
  @media screen and (max-width: 992px) {
    /* line 247, src/assets/scss/modules/_m20x.scss */
    .m20x .row.divspaces {
      border-bottom: none; } }

/* line 260, src/assets/scss/modules/_m20x.scss */
.m20x .col-md-4.person-box.withoutborder {
  border-right: 1px solid #fff; }
  @media screen and (max-width: 992px) {
    /* line 260, src/assets/scss/modules/_m20x.scss */
    .m20x .col-md-4.person-box.withoutborder {
      border-right: none; } }

/* line 266, src/assets/scss/modules/_m20x.scss */
.m20x .publications_slider {
  padding-top: 40px; }

@media screen and (max-width: 992px) {
  /* line 269, src/assets/scss/modules/_m20x.scss */
  .m20x .row.divaccordionspace {
    display: none; } }

@media screen and (max-width: 992px) {
  /* line 274, src/assets/scss/modules/_m20x.scss */
  .m20x .row.divspaces {
    padding: 0; } }

@media screen and (max-width: 992px) {
  /* line 279, src/assets/scss/modules/_m20x.scss */
  .m20x .d-flex {
    display: block !important; } }

@media screen and (max-width: 992px) {
  /* line 284, src/assets/scss/modules/_m20x.scss */
  .m20x hr {
    display: none; } }

/* line 3, src/assets/scss/modules/_m21x.scss */
.m21x .slider-for img {
  width: 100%;
  padding-right: 2px; }

/* line 9, src/assets/scss/modules/_m21x.scss */
.m21x .slider-nav img {
  padding-right: 2px;
  margin-top: 2px;
  -webkit-filter: brightness(50%); }
  /* line 13, src/assets/scss/modules/_m21x.scss */
  .m21x .slider-nav img:hover {
    cursor: pointer; }

/* line 18, src/assets/scss/modules/_m21x.scss */
.m21x .slider-nav .slick-current img {
  -webkit-filter: brightness(100%); }

/* line 22, src/assets/scss/modules/_m21x.scss */
.m21x .slider-nav button {
  display: none !important; }

/* line 26, src/assets/scss/modules/_m21x.scss */
.m21x .prev {
  position: absolute;
  top: 217px;
  bottom: 29px;
  left: 15px;
  transform: rotate(90deg);
  width: 80px;
  height: 80px;
  cursor: pointer; }
  /* line 35, src/assets/scss/modules/_m21x.scss */
  .m21x .prev img {
    height: 80px;
    width: 80px; }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    /* line 26, src/assets/scss/modules/_m21x.scss */
    .m21x .prev {
      top: 170px !important; } }
  @media screen and (max-width: 767px) {
    /* line 26, src/assets/scss/modules/_m21x.scss */
    .m21x .prev {
      display: none !important; } }

/* line 46, src/assets/scss/modules/_m21x.scss */
.m21x .next {
  position: absolute;
  top: 217px;
  right: 15px;
  transform: rotate(270deg);
  width: 80px;
  height: 80px;
  cursor: pointer; }
  /* line 54, src/assets/scss/modules/_m21x.scss */
  .m21x .next img {
    height: 80px;
    width: 80px; }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    /* line 46, src/assets/scss/modules/_m21x.scss */
    .m21x .next {
      top: 170px !important; } }
  @media screen and (max-width: 767px) {
    /* line 46, src/assets/scss/modules/_m21x.scss */
    .m21x .next {
      display: none !important; } }

/* line 2, src/assets/scss/modules/_m22x.scss */
.m22x .iframe {
  width: 100%;
  height: 242px;
  margin-bottom: 50px; }

/* line 7, src/assets/scss/modules/_m22x.scss */
.m22x .linklist {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #222222;
  display: inline-block; }

/* line 19, src/assets/scss/modules/_m22x.scss */
.m22x .buttonlinkk2 {
  min-width: 120px;
  height: 30px;
  background-color: #235789;
  border: #235789;
  border-radius: 3px;
  font-family: "Work Sans", "sans-serif";
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #fff;
  margin-right: 20px;
  text-align: center;
  font-size: 14px;
  line-height: 30px; }
  @media screen and (max-width: 992px) {
    /* line 19, src/assets/scss/modules/_m22x.scss */
    .m22x .buttonlinkk2 {
      margin-bottom: 20px; } }

/* line 39, src/assets/scss/modules/_m22x.scss */
.m22x .links-bottom {
  border-top: 1px solid #ddd;
  padding: 20px 0; }

/* line 2, src/assets/scss/modules/_m23x.scss */
.m23x .table {
  padding: 0; }

/* line 6, src/assets/scss/modules/_m23x.scss */
.m23x table {
  padding: 0; }

/* line 10, src/assets/scss/modules/_m23x.scss */
.m23x .table td, .m23x .table th {
  padding-left: 0; }

/* line 13, src/assets/scss/modules/_m23x.scss */
.m23x .thr {
  font-family: "Roboto Slab", serif;
  border-bottom: 2px solid #1d2114;
  border-top: none; }

/* line 18, src/assets/scss/modules/_m23x.scss */
.m23x .tspace {
  padding: 0 !important;
  margin: 0 !important;
  vertical-align: middle; }
  @media screen and (max-width: 992px) {
    /* line 18, src/assets/scss/modules/_m23x.scss */
    .m23x .tspace {
      width: 100%; } }
  /* line 25, src/assets/scss/modules/_m23x.scss */
  .m23x .tspace p {
    padding: 0;
    margin: 0; }

/* line 30, src/assets/scss/modules/_m23x.scss */
.m23x table.table {
  margin-bottom: 70px; }

/* line 33, src/assets/scss/modules/_m23x.scss */
.m23x .pdatetimetext {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  color: #999999; }

@media screen and (max-width: 992px) {
  /* line 39, src/assets/scss/modules/_m23x.scss */
  .m23x thead {
    display: none; } }

@media screen and (max-width: 992px) {
  /* line 43, src/assets/scss/modules/_m23x.scss */
  .m23x table {
    border-top: 2px solid #1d2114; } }

@media screen and (max-width: 992px) {
  /* line 50, src/assets/scss/modules/_m23x.scss */
  .m23x tbody tr {
    border-bottom: 1px solid #ddd; }
    /* line 52, src/assets/scss/modules/_m23x.scss */
    .m23x tbody tr th {
      padding-bottom: 0; }
    /* line 56, src/assets/scss/modules/_m23x.scss */
    .m23x tbody tr td:nth-child(2) {
      padding-top: 0; }
    /* line 59, src/assets/scss/modules/_m23x.scss */
    .m23x tbody tr td:nth-child(3) {
      padding-bottom: 0; }
    /* line 62, src/assets/scss/modules/_m23x.scss */
    .m23x tbody tr td:nth-child(4) {
      padding-top: 0; }
    /* line 66, src/assets/scss/modules/_m23x.scss */
    .m23x tbody tr th, .m23x tbody tr td {
      width: 100%;
      display: block;
      border: 0; } }

/* line 2, src/assets/scss/modules/_m24x.scss */
.m24x .inputcass {
  width: 100%;
  height: 50px;
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #dddddd; }

/* line 13, src/assets/scss/modules/_m24x.scss */
.m24x input[type=text]:focus {
  outline: none; }

/* line 16, src/assets/scss/modules/_m24x.scss */
.m24x .divbotton {
  margin-top: 55px;
  margin-bottom: 66px;
  text-align: right; }
  @media screen and (max-width: 992px) {
    /* line 16, src/assets/scss/modules/_m24x.scss */
    .m24x .divbotton {
      text-align: center; } }

/* line 25, src/assets/scss/modules/_m24x.scss */
.m24x .bottomdiv {
  border-bottom: 1px solid #dddddd;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0px;
  margin-right: 0px; }

/* line 32, src/assets/scss/modules/_m24x.scss */
.m24x .buttonlinkk {
  background-color: #ff6b35;
  border: #ff6b35;
  border-radius: 22.5px;
  font-family: Work Sans,"sans-serif";
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding: 15px 20px; }
  @media screen and (max-width: 992px) {
    /* line 32, src/assets/scss/modules/_m24x.scss */
    .m24x .buttonlinkk {
      left: 0;
      margin-left: auto;
      margin-right: auto; } }

/* line 48, src/assets/scss/modules/_m24x.scss */
.m24x form p {
  margin: 16px 0; }

/* line 2, src/assets/scss/modules/_m25x.scss */
.m25x h1 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #333333;
  margin-bottom: 30px; }

/* line 14, src/assets/scss/modules/_m25x.scss */
.m25x h2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  margin-bottom: 34px; }

/* line 25, src/assets/scss/modules/_m25x.scss */
.m25x p {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #333333;
  padding-bottom: 51px;
  margin-bottom: 0px; }
  /* line 36, src/assets/scss/modules/_m25x.scss */
  .m25x p:first-child {
    margin-bottom: 30px;
    padding-bottom: 0px; }
  /* line 40, src/assets/scss/modules/_m25x.scss */
  .m25x p.ptext {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d2114; }
  /* line 50, src/assets/scss/modules/_m25x.scss */
  .m25x p.ptext12 {
    font-family: "Work Sans", "sans-serif";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #222222; }
  /* line 60, src/assets/scss/modules/_m25x.scss */
  .m25x p.parccodion {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    margin-left: 55px;
    padding: 0;
    margin-bottom: 18px; }
    /* line 71, src/assets/scss/modules/_m25x.scss */
    .m25x p.parccodion:first-child {
      margin-left: 55px;
      margin-top: 28px; }
    /* line 75, src/assets/scss/modules/_m25x.scss */
    .m25x p.parccodion:last-child {
      margin-bottom: 30px; }
  /* line 79, src/assets/scss/modules/_m25x.scss */
  .m25x p.psubmenu {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    text-align: center;
    border-bottom: 1px solid #dddddd; }
  /* line 95, src/assets/scss/modules/_m25x.scss */
  .m25x p.placeholder {
    margin-left: 20px; }

/* line 99, src/assets/scss/modules/_m25x.scss */
.m25x ul {
  padding-right: 15px; }

/* line 103, src/assets/scss/modules/_m25x.scss */
.m25x ol li {
  list-style-type: decimal-leading-zero;
  color: orangered; }

/* line 108, src/assets/scss/modules/_m25x.scss */
.m25x a.fontstyle {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 119, src/assets/scss/modules/_m25x.scss */
.m25x a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 45px;
  padding: 0; }

/* line 126, src/assets/scss/modules/_m25x.scss */
.m25x a.linkfont {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000; }

/* line 136, src/assets/scss/modules/_m25x.scss */
.m25x a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 145, src/assets/scss/modules/_m25x.scss */
.m25x a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 154, src/assets/scss/modules/_m25x.scss */
  .m25x a.accordionmenu:first-child {
    color: #235789; }

/* line 158, src/assets/scss/modules/_m25x.scss */
.m25x a.arcodionlink {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 167, src/assets/scss/modules/_m25x.scss */
.m25x a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 178, src/assets/scss/modules/_m25x.scss */
.m25x a.linksubmenu {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline;
  border-bottom: 1px solid #333333; }

/* line 190, src/assets/scss/modules/_m25x.scss */
.m25x a.h44:first-of-type {
  font-family: "Work Sans", "sans-serif";
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #235789;
  margin-right: 0%;
  margin-left: 0%;
  display: inline; }

/* line 208, src/assets/scss/modules/_m25x.scss */
.m25x a.pclass2 {
  min-height: 93px;
  margin-right: 0px;
  margin-left: 0%;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.40;
  letter-spacing: normal;
  color: #222222;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 208, src/assets/scss/modules/_m25x.scss */
    .m25x a.pclass2 {
      display: none; } }

/* line 225, src/assets/scss/modules/_m25x.scss */
.m25x a.h22 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #222222;
  margin-right: 0px;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 225, src/assets/scss/modules/_m25x.scss */
    .m25x a.h22 {
      min-height: 126px;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3; } }

/* line 246, src/assets/scss/modules/_m25x.scss */
.m25x a:hover {
  outline: none;
  text-decoration: none; }

/* line 250, src/assets/scss/modules/_m25x.scss */
.m25x a:focus {
  outline: none;
  text-decoration: none; }

/* line 254, src/assets/scss/modules/_m25x.scss */
.m25x img {
  width: 100%;
  height: 117px; }
  /* line 257, src/assets/scss/modules/_m25x.scss */
  .m25x img.classimg {
    vertical-align: middle; }

/* line 261, src/assets/scss/modules/_m25x.scss */
.m25x .col-md-3.d-flex.justify-content-end {
  padding: 0; }

/* line 265, src/assets/scss/modules/_m25x.scss */
.m25x ul li.nav-item {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 270, src/assets/scss/modules/_m25x.scss */
.m25x ul li.nav-item:active {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 275, src/assets/scss/modules/_m25x.scss */
.m25x ul li.lit {
  padding-left: 12px; }

/* line 279, src/assets/scss/modules/_m25x.scss */
.m25x li.dropdown:hover > .dropdown-menu {
  display: block; }

/* line 282, src/assets/scss/modules/_m25x.scss */
.m25x a.nav-link.linkfont2 {
  margin: 0; }

/* line 286, src/assets/scss/modules/_m25x.scss */
.m25x a.nav-link.linkfont2:hover {
  font-weight: 600; }

/* line 290, src/assets/scss/modules/_m25x.scss */
.m25x ul .collapse.list-unstyled {
  padding: 0;
  border-top: 1px solid #dddddd; }

/* line 295, src/assets/scss/modules/_m25x.scss */
.m25x .triangle {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(315deg);
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px; }

/* line 307, src/assets/scss/modules/_m25x.scss */
.m25x .divaccordionspace {
  margin-top: 30px;
  margin-bottom: 19px; }

/* line 311, src/assets/scss/modules/_m25x.scss */
.m25x .divborder {
  border-top: 1px solid #dddddd;
  margin: 0px;
  padding: 0px; }

/* line 316, src/assets/scss/modules/_m25x.scss */
.m25x .divborderbottom {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px; }

/* line 320, src/assets/scss/modules/_m25x.scss */
.m25x .divacc {
  padding-top: 40px; }

/* line 323, src/assets/scss/modules/_m25x.scss */
.m25x ul.nav.flex-column {
  padding-right: 0; }

/* line 327, src/assets/scss/modules/_m25x.scss */
.m25x .backtp {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d2114;
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  text-align: center; }

/* line 340, src/assets/scss/modules/_m25x.scss */
.m25x .arrow {
  content: "";
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 10px;
  height: 10px;
  border-right: 2px solid #222222;
  border-bottom: 2px solid #222222;
  transform: rotate(225deg);
  cursor: pointer;
  margin-bottom: 55px; }

/* line 357, src/assets/scss/modules/_m25x.scss */
.m25x .nav-link.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 16px; }

/* line 371, src/assets/scss/modules/_m25x.scss */
.m25x .nav-link.expand[aria-expanded="true"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
  transform: rotate(225deg);
  top: 20px; }

/* line 388, src/assets/scss/modules/_m25x.scss */
.m25x .nav-item.show .nav-link.linkfont.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 2px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 16px; }

/* line 403, src/assets/scss/modules/_m25x.scss */
.m25x ul.nav.flex-column.divborder {
  margin-right: 15px; }

/* line 406, src/assets/scss/modules/_m25x.scss */
.m25x a.accordionmenu:hover {
  color: #235789; }

/* line 411, src/assets/scss/modules/_m25x.scss */
.m25x .coldiv {
  width: 255px; }
  /* line 413, src/assets/scss/modules/_m25x.scss */
  .m25x .coldiv .div-title {
    margin-top: 16px;
    margin-bottom: 20px;
    height: 93px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%; }
    @media screen and (max-width: 992px) {
      /* line 413, src/assets/scss/modules/_m25x.scss */
      .m25x .coldiv .div-title {
        height: 126px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; } }
  /* line 429, src/assets/scss/modules/_m25x.scss */
  .m25x .coldiv .div-text {
    height: 117px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 25px; }
    @media screen and (max-width: 992px) {
      /* line 429, src/assets/scss/modules/_m25x.scss */
      .m25x .coldiv .div-text {
        display: none; } }
  /* line 441, src/assets/scss/modules/_m25x.scss */
  .m25x .coldiv .div-link {
    margin-top: 28px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 441, src/assets/scss/modules/_m25x.scss */
      .m25x .coldiv .div-link {
        bottom: 0;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-top: 50px; } }
  /* line 455, src/assets/scss/modules/_m25x.scss */
  .m25x .coldiv .div-link2 {
    margin-top: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 455, src/assets/scss/modules/_m25x.scss */
      .m25x .coldiv .div-link2 {
        bottom: 0;
        margin-top: 7px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; } }
  @media screen and (max-width: 992px) {
    /* line 411, src/assets/scss/modules/_m25x.scss */
    .m25x .coldiv {
      width: 100%; } }

/* line 474, src/assets/scss/modules/_m25x.scss */
.m25x .classborder {
  border-right: 1px solid #dddddd; }
  @media screen and (max-width: 992px) {
    /* line 474, src/assets/scss/modules/_m25x.scss */
    .m25x .classborder {
      border-right: 1px solid #dddddd; } }

/* line 480, src/assets/scss/modules/_m25x.scss */
.m25x .classborderwot {
  border-right: 1px solid #dddddd; }
  @media screen and (max-width: 992px) {
    /* line 480, src/assets/scss/modules/_m25x.scss */
    .m25x .classborderwot {
      border-right: none;
      min-height: 0px; } }
  @media screen and (max-width: 576px) {
    /* line 480, src/assets/scss/modules/_m25x.scss */
    .m25x .classborderwot {
      border-right: none;
      min-height: 0; } }

/* line 493, src/assets/scss/modules/_m25x.scss */
.m25x .classborderwo {
  border-right: none; }
  @media screen and (max-width: 992px) {
    /* line 493, src/assets/scss/modules/_m25x.scss */
    .m25x .classborderwo {
      padding-bottom: 9px;
      padding-right: 1px;
      margin-right: 14px;
      padding-left: 1px;
      margin-left: 14px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 0px;
      border-right: none;
      min-height: 0px; } }
  @media screen and (max-width: 576px) {
    /* line 493, src/assets/scss/modules/_m25x.scss */
    .m25x .classborderwo {
      border-right: none;
      min-height: 0; } }

/* line 513, src/assets/scss/modules/_m25x.scss */
.m25x .imagediv {
  width: 100%;
  height: 117px; }

/* line 517, src/assets/scss/modules/_m25x.scss */
.m25x .col-lg-12.order-lg-1.coldiv {
  padding-right: 0; }

/* line 520, src/assets/scss/modules/_m25x.scss */
.m25x .col-lg-12.order-lg-2 {
  padding-right: 0; }

/* line 523, src/assets/scss/modules/_m25x.scss */
.m25x .divspaces {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 15px;
  height: 1px;
  width: 100%;
  background-color: #dddddd; }
  @media screen and (max-width: 992px) {
    /* line 523, src/assets/scss/modules/_m25x.scss */
    .m25x .divspaces {
      display: none; } }

/* line 534, src/assets/scss/modules/_m25x.scss */
.m25x .divspaces2 {
  display: none; }
  @media screen and (max-width: 992px) {
    /* line 534, src/assets/scss/modules/_m25x.scss */
    .m25x .divspaces2 {
      margin-top: 24px;
      margin-bottom: 24px;
      margin-left: 15px;
      height: 1px;
      width: 100%;
      background-color: #dddddd;
      display: block; } }

/* line 547, src/assets/scss/modules/_m25x.scss */
.m25x h4 {
  margin: 0; }

/* line 550, src/assets/scss/modules/_m25x.scss */
.m25x .publications_slider {
  padding-top: 30px;
  padding-bottom: 40px;
  margin-bottom: 20px; }
  @media screen and (max-width: 992px) {
    /* line 550, src/assets/scss/modules/_m25x.scss */
    .m25x .publications_slider {
      padding-bottom: 30px; } }

/* line 559, src/assets/scss/modules/_m25x.scss */
.m25x .pagination {
  display: inline-block;
  border: 1px solid #dddddd;
  position: absolute;
  right: 0; }

/* line 567, src/assets/scss/modules/_m25x.scss */
.m25x .pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none; }

/* line 575, src/assets/scss/modules/_m25x.scss */
.m25x .pagination a.active {
  background-color: #dddddd;
  border: 1px solid #dddddd;
  cursor: default; }

/* line 581, src/assets/scss/modules/_m25x.scss */
.m25x .pagination a:hover:not(.active) {
  background-color: #fff; }

/* line 585, src/assets/scss/modules/_m25x.scss */
.m25x .pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

/* line 590, src/assets/scss/modules/_m25x.scss */
.m25x .pagination a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

/* line 594, src/assets/scss/modules/_m25x.scss */
.m25x .divpagenation {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 50px;
  margin-bottom: 100px;
  position: relative; }
  @media screen and (max-width: 992px) {
    /* line 594, src/assets/scss/modules/_m25x.scss */
    .m25x .divpagenation {
      display: none; } }

/* line 607, src/assets/scss/modules/_m25x.scss */
.m25x a.arrowpg {
  color: #235789;
  font-size: 28px;
  padding-top: 0;
  padding-bottom: 0; }

/* line 614, src/assets/scss/modules/_m25x.scss */
.m25x .searchnews {
  width: 100%;
  height: 50px;
  border: 1px solid #dddddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-position: right 30px center;
  background-repeat: no-repeat;
  padding-left: 20px;
  padding-right: 40px; }
  @media screen and (max-width: 767px) {
    /* line 614, src/assets/scss/modules/_m25x.scss */
    .m25x .searchnews {
      background-position: right 10px center; } }

/* line 630, src/assets/scss/modules/_m25x.scss */
.m25x .dates {
  margin-top: 30px;
  width: 100%;
  height: 50px;
  border: 1px solid #dddddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-position: left 16px center;
  background-repeat: no-repeat;
  color: #dddddd;
  padding-left: 70px;
  color: #333333; }
  /* line 644, src/assets/scss/modules/_m25x.scss */
  .m25x .dates:focus {
    border-color: #235789; }
  @media screen and (max-width: 992px) {
    /* line 630, src/assets/scss/modules/_m25x.scss */
    .m25x .dates {
      margin-top: 0; } }

/* line 652, src/assets/scss/modules/_m25x.scss */
.m25x .form-control::-webkit-input-placeholder {
  margin-left: 20px;
  padding-left: 20px; }
  @media screen and (max-width: 992px) {
    /* line 652, src/assets/scss/modules/_m25x.scss */
    .m25x .form-control::-webkit-input-placeholder {
      color: #fff; } }

/* line 659, src/assets/scss/modules/_m25x.scss */
.m25x .checkboxclass {
  width: 30px;
  height: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  border: 1px solid #235789; }

/* line 670, src/assets/scss/modules/_m25x.scss */
.m25x .styled-checkbox {
  position: absolute;
  opacity: 0;
  border: 1px solid #235789; }
  /* line 675, src/assets/scss/modules/_m25x.scss */
  .m25x .styled-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin-right: 30px; }
    @media screen and (max-width: 767px) {
      /* line 675, src/assets/scss/modules/_m25x.scss */
      .m25x .styled-checkbox + label {
        margin-right: 15px !important; } }
  /* line 686, src/assets/scss/modules/_m25x.scss */
  .m25x .styled-checkbox + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 30px;
    height: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: white;
    border: 1px solid #235789;
    margin-top: -5px; }
    @media screen and (max-width: 767px) {
      /* line 686, src/assets/scss/modules/_m25x.scss */
      .m25x .styled-checkbox + label:before {
        width: 20px;
        height: 20px;
        margin-top: 0; } }
  /* line 708, src/assets/scss/modules/_m25x.scss */
  .m25x .styled-checkbox:checked + label:before {
    background: #fff; }
  /* line 713, src/assets/scss/modules/_m25x.scss */
  .m25x .styled-checkbox:disabled + label {
    color: #fff;
    cursor: auto; }
  /* line 719, src/assets/scss/modules/_m25x.scss */
  .m25x .styled-checkbox:disabled + label:before {
    box-shadow: none;
    background: #dddddd; }
  /* line 725, src/assets/scss/modules/_m25x.scss */
  .m25x .styled-checkbox:checked + label:after {
    content: '\2713';
    position: absolute;
    left: 10px;
    top: 2px;
    color: #235789; }

/* line 733, src/assets/scss/modules/_m25x.scss */
.m25x label {
  margin-top: 40px;
  margin-bottom: 17px; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    /* line 733, src/assets/scss/modules/_m25x.scss */
    .m25x label {
      margin-top: 25px;
      margin-bottom: 25px; } }
  @media screen and (max-width: 767px) {
    /* line 733, src/assets/scss/modules/_m25x.scss */
    .m25x label {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 15px; } }
  @media screen and (max-width: 767px) {
    /* line 745, src/assets/scss/modules/_m25x.scss */
    .m25x label:first-of-type {
      margin-top: 20px; } }
  @media screen and (max-width: 767px) {
    /* line 750, src/assets/scss/modules/_m25x.scss */
    .m25x label:last-of-type {
      margin-bottom: 20px; } }

/* line 757, src/assets/scss/modules/_m25x.scss */
.m25x .smalldatediv {
  height: 40px;
  width: 40px;
  background-color: red; }

/* line 762, src/assets/scss/modules/_m25x.scss */
.m25x .bigdatediv {
  height: 40px;
  width: 100%;
  background-color: aqua; }

@media screen and (max-width: 992px) {
  /* line 767, src/assets/scss/modules/_m25x.scss */
  .m25x .row.divaccordionspace {
    display: none; } }

/* line 772, src/assets/scss/modules/_m25x.scss */
.m25x .styled-checkbox:after {
  content: "\2713";
  position: absolute;
  left: 9px;
  top: 0px;
  color: #235789;
  font-size: 20px; }

/* line 780, src/assets/scss/modules/_m25x.scss */
.m25x .styled-checkbox:checked + label:after {
  position: absolute;
  left: 7px;
  top: 5px;
  content: " ";
  background-image: url(/assets/img/svg/icon-tick.svg);
  background-size: 17px 17px;
  height: 17px;
  width: 17px; }
  @media screen and (max-width: 767px) {
    /* line 780, src/assets/scss/modules/_m25x.scss */
    .m25x .styled-checkbox:checked + label:after {
      left: 5px;
      top: 7px;
      background-size: 11px 11px;
      height: 11px;
      width: 11px; } }

/* line 798, src/assets/scss/modules/_m25x.scss */
.m25x .divbotton {
  margin-top: 30px;
  margin-bottom: 12px; }
  /* line 801, src/assets/scss/modules/_m25x.scss */
  .m25x .divbotton .buttonlinkk {
    background-color: #ff6b35;
    border: #ff6b35;
    border-radius: 22.5px;
    font-family: Work Sans,"sans-serif";
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    padding: 15px 20px; }
    @media screen and (max-width: 992px) {
      /* line 801, src/assets/scss/modules/_m25x.scss */
      .m25x .divbotton .buttonlinkk {
        left: 0;
        margin-left: auto;
        margin-right: auto; } }
    @media screen and (max-width: 385px) {
      /* line 801, src/assets/scss/modules/_m25x.scss */
      .m25x .divbotton .buttonlinkk {
        padding-left: 10px;
        padding-right: 10px; } }
  @media screen and (max-width: 385px) {
    /* line 820, src/assets/scss/modules/_m25x.scss */
    .m25x .divbotton .floatbuttonleft {
      float: left; } }
  @media screen and (max-width: 385px) {
    /* line 825, src/assets/scss/modules/_m25x.scss */
    .m25x .divbotton .floatbuttonright {
      float: right; } }

/* line 833, src/assets/scss/modules/_m25x.scss */
.drp-selected {
  display: none !important; }

/* line 837, src/assets/scss/modules/_m25x.scss */
.drp-buttons .applyBtn {
  border-radius: 22.5px !important;
  background-color: #fff !important;
  border: 1px solid #235789 !important;
  font-family: Work Sans, "sans-serif" !important;
  font-size: 14px !important;
  color: #235789 !important;
  line-height: 35px !important;
  padding: 0 35px !important; }

/* line 848, src/assets/scss/modules/_m25x.scss */
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #235789 !important;
  border-radius: 50% !important; }

/* line 2, src/assets/scss/modules/_m26x.scss */
.m26x h1 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #333333;
  margin-bottom: 30px; }

/* line 14, src/assets/scss/modules/_m26x.scss */
.m26x h2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  margin-bottom: 34px; }

/* line 25, src/assets/scss/modules/_m26x.scss */
.m26x p {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #333333;
  padding-bottom: 51px;
  margin-bottom: 0px; }
  /* line 36, src/assets/scss/modules/_m26x.scss */
  .m26x p:first-child {
    margin-bottom: 30px;
    padding-bottom: 0px; }
  /* line 40, src/assets/scss/modules/_m26x.scss */
  .m26x p.ptext {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d2114; }
  /* line 50, src/assets/scss/modules/_m26x.scss */
  .m26x p.ptext12 {
    font-family: "Work Sans", "sans-serif";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #222222; }
  /* line 60, src/assets/scss/modules/_m26x.scss */
  .m26x p.parccodion {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    margin-left: 55px;
    padding: 0;
    margin-bottom: 18px; }
    /* line 71, src/assets/scss/modules/_m26x.scss */
    .m26x p.parccodion:first-child {
      margin-left: 55px;
      margin-top: 28px; }
    /* line 75, src/assets/scss/modules/_m26x.scss */
    .m26x p.parccodion:last-child {
      margin-bottom: 30px; }
  /* line 79, src/assets/scss/modules/_m26x.scss */
  .m26x p.psubmenu {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    text-align: center;
    border-bottom: 1px solid #dddddd; }
  /* line 95, src/assets/scss/modules/_m26x.scss */
  .m26x p.placeholder {
    margin-left: 20px; }

/* line 99, src/assets/scss/modules/_m26x.scss */
.m26x ul {
  padding-right: 15px; }

/* line 103, src/assets/scss/modules/_m26x.scss */
.m26x ol li {
  list-style-type: decimal-leading-zero;
  color: orangered; }

/* line 108, src/assets/scss/modules/_m26x.scss */
.m26x a.fontstyle {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 119, src/assets/scss/modules/_m26x.scss */
.m26x a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 45px;
  padding: 0; }

/* line 126, src/assets/scss/modules/_m26x.scss */
.m26x a.linkfont {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000; }

/* line 136, src/assets/scss/modules/_m26x.scss */
.m26x a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 145, src/assets/scss/modules/_m26x.scss */
.m26x a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 154, src/assets/scss/modules/_m26x.scss */
  .m26x a.accordionmenu:first-child {
    color: #235789; }

/* line 158, src/assets/scss/modules/_m26x.scss */
.m26x a.arcodionlink {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 167, src/assets/scss/modules/_m26x.scss */
.m26x a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 178, src/assets/scss/modules/_m26x.scss */
.m26x a.linksubmenu {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline;
  border-bottom: 1px solid #333333; }

/* line 190, src/assets/scss/modules/_m26x.scss */
.m26x a.h44:first-of-type {
  font-family: "Work Sans", "sans-serif";
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #235789;
  margin-right: 0%;
  margin-left: 0%;
  display: inline; }

/* line 208, src/assets/scss/modules/_m26x.scss */
.m26x a.pclass2 {
  min-height: 93px;
  margin-right: 0px;
  margin-left: 0%;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.40;
  letter-spacing: normal;
  color: #222222;
  display: inline; }

/* line 224, src/assets/scss/modules/_m26x.scss */
.m26x a.h22 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #222222;
  margin-right: 0px;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 224, src/assets/scss/modules/_m26x.scss */
    .m26x a.h22 {
      min-height: 126px;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3; } }

/* line 245, src/assets/scss/modules/_m26x.scss */
.m26x a:hover {
  outline: none;
  text-decoration: none; }

/* line 249, src/assets/scss/modules/_m26x.scss */
.m26x a:focus {
  outline: none;
  text-decoration: none; }

/* line 253, src/assets/scss/modules/_m26x.scss */
.m26x img {
  max-width: 100%;
  height: auto; }
  /* line 256, src/assets/scss/modules/_m26x.scss */
  .m26x img.classimg {
    vertical-align: middle; }

/* line 260, src/assets/scss/modules/_m26x.scss */
.m26x .col-md-3.d-flex.justify-content-end {
  padding: 0; }

/* line 264, src/assets/scss/modules/_m26x.scss */
.m26x ul li.nav-item {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 269, src/assets/scss/modules/_m26x.scss */
.m26x ul li.nav-item:active {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 274, src/assets/scss/modules/_m26x.scss */
.m26x ul li.lit {
  padding-left: 12px; }

/* line 278, src/assets/scss/modules/_m26x.scss */
.m26x li.dropdown:hover > .dropdown-menu {
  display: block; }

/* line 281, src/assets/scss/modules/_m26x.scss */
.m26x a.nav-link.linkfont2 {
  margin: 0; }

/* line 285, src/assets/scss/modules/_m26x.scss */
.m26x a.nav-link.linkfont2:hover {
  font-weight: 600; }

/* line 289, src/assets/scss/modules/_m26x.scss */
.m26x ul .collapse.list-unstyled {
  padding: 0;
  border-top: 1px solid #dddddd; }

/* line 294, src/assets/scss/modules/_m26x.scss */
.m26x .triangle {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(315deg);
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px; }

/* line 306, src/assets/scss/modules/_m26x.scss */
.m26x .divaccordionspace {
  margin-top: 30px;
  margin-bottom: 19px; }

/* line 310, src/assets/scss/modules/_m26x.scss */
.m26x .divborder {
  border-top: 1px solid #dddddd;
  margin: 0px;
  padding: 0px; }

/* line 315, src/assets/scss/modules/_m26x.scss */
.m26x .divborderbottom {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px; }

/* line 319, src/assets/scss/modules/_m26x.scss */
.m26x .divacc {
  padding-top: 40px; }

/* line 322, src/assets/scss/modules/_m26x.scss */
.m26x ul.nav.flex-column {
  padding-right: 0; }

/* line 326, src/assets/scss/modules/_m26x.scss */
.m26x .backtp {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d2114;
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  text-align: center; }

/* line 339, src/assets/scss/modules/_m26x.scss */
.m26x .arrow {
  content: "";
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 10px;
  height: 10px;
  border-right: 2px solid #222222;
  border-bottom: 2px solid #222222;
  transform: rotate(225deg);
  cursor: pointer;
  margin-bottom: 55px; }

/* line 356, src/assets/scss/modules/_m26x.scss */
.m26x .nav-link.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 16px; }

/* line 370, src/assets/scss/modules/_m26x.scss */
.m26x .nav-link.expand[aria-expanded="true"]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
  transform: rotate(225deg);
  top: 20px; }

/* line 387, src/assets/scss/modules/_m26x.scss */
.m26x .nav-item.show .nav-link.linkfont.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 2px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 16px; }

/* line 402, src/assets/scss/modules/_m26x.scss */
.m26x ul.nav.flex-column.divborder {
  margin-right: 15px; }

/* line 405, src/assets/scss/modules/_m26x.scss */
.m26x a.accordionmenu:hover {
  color: #235789; }

/* line 410, src/assets/scss/modules/_m26x.scss */
.m26x .coldiv {
  width: 255px; }
  /* line 412, src/assets/scss/modules/_m26x.scss */
  .m26x .coldiv .div-title {
    margin-top: 16px;
    margin-bottom: 20px;
    height: 90px;
    width: 100%; }
    @media screen and (max-width: 992px) {
      /* line 412, src/assets/scss/modules/_m26x.scss */
      .m26x .coldiv .div-title {
        height: 80px; } }
  /* line 421, src/assets/scss/modules/_m26x.scss */
  .m26x .coldiv .div-text {
    height: 118px;
    overflow: hidden;
    margin-bottom: 25px; }
  /* line 426, src/assets/scss/modules/_m26x.scss */
  .m26x .coldiv .div-link {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 426, src/assets/scss/modules/_m26x.scss */
      .m26x .coldiv .div-link {
        bottom: -18px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-top: 0; } }
  /* line 439, src/assets/scss/modules/_m26x.scss */
  .m26x .coldiv .div-link2 {
    margin-top: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 439, src/assets/scss/modules/_m26x.scss */
      .m26x .coldiv .div-link2 {
        bottom: 0;
        margin-top: 7px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; } }
  @media screen and (max-width: 992px) {
    /* line 410, src/assets/scss/modules/_m26x.scss */
    .m26x .coldiv {
      width: 100%; } }

/* line 458, src/assets/scss/modules/_m26x.scss */
.m26x .classborder {
  padding-bottom: 15px;
  border-right: 1px solid #dddddd; }
  @media screen and (max-width: 992px) {
    /* line 458, src/assets/scss/modules/_m26x.scss */
    .m26x .classborder {
      border-right: 1px solid #dddddd; }
      /* line 463, src/assets/scss/modules/_m26x.scss */
      .m26x .classborder:nth-child(2), .m26x .classborder:nth-child(6), .m26x .classborder:nth-child(9), .m26x .classborder:nth-child(13) {
        border-right: 0; } }
  @media screen and (min-width: 993px) {
    /* line 471, src/assets/scss/modules/_m26x.scss */
    .m26x .classborder:nth-child(4), .m26x .classborder:nth-child(9), .m26x .classborder:nth-child(15) {
      border-right: 0; } }

/* line 478, src/assets/scss/modules/_m26x.scss */
.m26x .classborderwot {
  border-right: 1px solid #dddddd; }
  @media screen and (max-width: 992px) {
    /* line 478, src/assets/scss/modules/_m26x.scss */
    .m26x .classborderwot {
      border-right: none;
      min-height: 0px; } }
  @media screen and (max-width: 576px) {
    /* line 478, src/assets/scss/modules/_m26x.scss */
    .m26x .classborderwot {
      border-right: none;
      min-height: 0; } }

/* line 491, src/assets/scss/modules/_m26x.scss */
.m26x .classborderwo {
  border-right: none; }
  @media screen and (max-width: 992px) {
    /* line 491, src/assets/scss/modules/_m26x.scss */
    .m26x .classborderwo {
      padding-bottom: 9px;
      padding-right: 1px;
      margin-right: 14px;
      padding-left: 1px;
      margin-left: 14px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 0px;
      border-right: none;
      min-height: 0px; } }
  @media screen and (max-width: 576px) {
    /* line 491, src/assets/scss/modules/_m26x.scss */
    .m26x .classborderwo {
      border-right: none;
      min-height: 0; } }

/* line 511, src/assets/scss/modules/_m26x.scss */
.m26x .imagediv {
  width: 100%;
  margin-bottom: 25px;
  text-align: center; }
  @media screen and (max-width: 992px) {
    /* line 511, src/assets/scss/modules/_m26x.scss */
    .m26x .imagediv {
      margin-bottom: 20px; } }

/* line 519, src/assets/scss/modules/_m26x.scss */
.m26x .col-lg-12.order-lg-1.coldiv {
  padding-right: 0; }

/* line 522, src/assets/scss/modules/_m26x.scss */
.m26x .col-lg-12.order-lg-2 {
  padding-right: 0; }

/* line 525, src/assets/scss/modules/_m26x.scss */
.m26x .divspaces {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 15px;
  height: 1px;
  width: 100%;
  background-color: #dddddd; }
  @media screen and (max-width: 992px) {
    /* line 525, src/assets/scss/modules/_m26x.scss */
    .m26x .divspaces {
      display: none; } }

/* line 536, src/assets/scss/modules/_m26x.scss */
.m26x .divspaces2 {
  display: none; }
  @media screen and (max-width: 992px) {
    /* line 536, src/assets/scss/modules/_m26x.scss */
    .m26x .divspaces2 {
      margin-top: 24px;
      margin-bottom: 24px;
      margin-left: 15px;
      height: 1px;
      width: 100%;
      background-color: #dddddd;
      display: block; } }

/* line 549, src/assets/scss/modules/_m26x.scss */
.m26x h4 {
  margin: 0;
  font-weight: 500; }

/* line 553, src/assets/scss/modules/_m26x.scss */
.m26x .publications_slider {
  padding-top: 30px; }
  @media screen and (max-width: 992px) {
    /* line 553, src/assets/scss/modules/_m26x.scss */
    .m26x .publications_slider {
      padding-bottom: 30px; } }

/* line 560, src/assets/scss/modules/_m26x.scss */
.m26x .pagination {
  display: inline-block;
  border: 1px solid #dddddd;
  position: absolute;
  right: 0; }

/* line 568, src/assets/scss/modules/_m26x.scss */
.m26x .pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none; }

/* line 576, src/assets/scss/modules/_m26x.scss */
.m26x .pagination a.active {
  background-color: #dddddd;
  border: 1px solid #dddddd;
  cursor: default; }

/* line 582, src/assets/scss/modules/_m26x.scss */
.m26x .pagination a:hover:not(.active) {
  background-color: #fff; }

/* line 586, src/assets/scss/modules/_m26x.scss */
.m26x .pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

/* line 591, src/assets/scss/modules/_m26x.scss */
.m26x .pagination a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

/* line 595, src/assets/scss/modules/_m26x.scss */
.m26x .divpagenation {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 50px;
  margin-bottom: 100px;
  position: relative; }
  @media screen and (max-width: 992px) {
    /* line 595, src/assets/scss/modules/_m26x.scss */
    .m26x .divpagenation {
      display: none; } }

/* line 608, src/assets/scss/modules/_m26x.scss */
.m26x a.arrowpg {
  color: #235789;
  font-size: 28px;
  padding-top: 0;
  padding-bottom: 0; }

/* line 615, src/assets/scss/modules/_m26x.scss */
.m26x .searchnews {
  width: 100%;
  height: 50px;
  border: 1px solid #dddddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-position: right 30px center;
  background-repeat: no-repeat;
  color: #dddddd;
  padding-left: 20px; }

/* line 628, src/assets/scss/modules/_m26x.scss */
.m26x .daterange {
  margin-top: 30px;
  width: 100%;
  height: 50px;
  border: 1px solid #dddddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-position: left 16px center;
  background-repeat: no-repeat;
  color: #dddddd;
  padding-left: 70px; }

/* line 643, src/assets/scss/modules/_m26x.scss */
.m26x .form-control::-webkit-input-placeholder {
  margin-left: 20px;
  padding-left: 20px; }
  @media screen and (max-width: 992px) {
    /* line 643, src/assets/scss/modules/_m26x.scss */
    .m26x .form-control::-webkit-input-placeholder {
      color: #fff; } }

/* line 650, src/assets/scss/modules/_m26x.scss */
.m26x .checkboxclass {
  width: 30px;
  height: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  border: 1px solid #235789; }

/* line 661, src/assets/scss/modules/_m26x.scss */
.m26x .styled-checkbox {
  position: absolute;
  opacity: 0;
  border: 1px solid #235789; }
  /* line 666, src/assets/scss/modules/_m26x.scss */
  .m26x .styled-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0; }
  /* line 673, src/assets/scss/modules/_m26x.scss */
  .m26x .styled-checkbox + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 30px;
    height: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: white;
    border: 1px solid #235789; }
  /* line 689, src/assets/scss/modules/_m26x.scss */
  .m26x .styled-checkbox:checked + label:before {
    background: #fff; }
  /* line 694, src/assets/scss/modules/_m26x.scss */
  .m26x .styled-checkbox:disabled + label {
    color: #fff;
    cursor: auto; }
  /* line 700, src/assets/scss/modules/_m26x.scss */
  .m26x .styled-checkbox:disabled + label:before {
    box-shadow: none;
    background: #dddddd; }
  /* line 706, src/assets/scss/modules/_m26x.scss */
  .m26x .styled-checkbox:checked + label:after {
    content: '\2713';
    position: absolute;
    left: 10px;
    top: 2px;
    color: #235789; }

/* line 714, src/assets/scss/modules/_m26x.scss */
.m26x label {
  margin-top: 40px;
  margin-bottom: 17px; }

/* line 719, src/assets/scss/modules/_m26x.scss */
.m26x .smalldatediv {
  height: 40px;
  width: 40px;
  background-color: red; }

/* line 724, src/assets/scss/modules/_m26x.scss */
.m26x .bigdatediv {
  height: 40px;
  width: 100%;
  background-color: aqua; }

@media screen and (max-width: 992px) {
  /* line 729, src/assets/scss/modules/_m26x.scss */
  .m26x .row.divaccordionspace {
    display: none; } }

/* line 734, src/assets/scss/modules/_m26x.scss */
.m26x .styled-checkbox:after {
  content: "\2713";
  position: absolute;
  left: 9px;
  top: 0px;
  color: #235789;
  font-size: 20px; }

/* line 742, src/assets/scss/modules/_m26x.scss */
.m26x .styled-checkbox:checked + label:after {
  content: "\2713";
  position: absolute;
  left: 9px;
  top: 0px;
  color: #235789;
  font-size: 20px; }

/* line 751, src/assets/scss/modules/_m26x.scss */
.m26x .search-news-slider .slick-dots {
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 40px; }
  /* line 756, src/assets/scss/modules/_m26x.scss */
  .m26x .search-news-slider .slick-dots li {
    display: inline-block;
    margin: 0 .25rem; }
    /* line 759, src/assets/scss/modules/_m26x.scss */
    .m26x .search-news-slider .slick-dots li:focus {
      outline: 0; }
    /* line 762, src/assets/scss/modules/_m26x.scss */
    .m26x .search-news-slider .slick-dots li button {
      display: block;
      width: .5rem;
      height: .5rem;
      padding: 0;
      border: none;
      border-radius: 100%;
      background-color: #ddd;
      text-indent: -9999px; }
  /* line 774, src/assets/scss/modules/_m26x.scss */
  .m26x .search-news-slider .slick-dots .slick-active button {
    background-color: #192830; }

/* line 781, src/assets/scss/modules/_m26x.scss */
.m26x hr.every-third-row {
  width: 100%;
  margin: 15px; }
  @media screen and (max-width: 992px) {
    /* line 781, src/assets/scss/modules/_m26x.scss */
    .m26x hr.every-third-row {
      display: none; } }

/* line 788, src/assets/scss/modules/_m26x.scss */
.m26x hr.every-second-row {
  width: 100%;
  margin: 15px; }
  @media screen and (min-width: 993px) {
    /* line 788, src/assets/scss/modules/_m26x.scss */
    .m26x hr.every-second-row {
      display: none; } }

/* line 796, src/assets/scss/modules/_m26x.scss */
.m26x .more-than-8-news {
  border-right: none; }
  @media screen and (max-width: 992px) {
    /* line 796, src/assets/scss/modules/_m26x.scss */
    .m26x .more-than-8-news {
      display: none; } }

/* line 802, src/assets/scss/modules/_m26x.scss */
.m26x .div-link {
  position: absolute;
  bottom: 0;
  left: 17px; }

/* line 2, src/assets/scss/modules/_m27x.scss */
.m27x h1 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #333333;
  margin-bottom: 9px; }
  @media screen and (max-width: 992px) {
    /* line 2, src/assets/scss/modules/_m27x.scss */
    .m27x h1 {
      text-align: left;
      margin: 0;
      font-size: 30px; } }

/* line 19, src/assets/scss/modules/_m27x.scss */
.m27x h2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }
  /* line 29, src/assets/scss/modules/_m27x.scss */
  .m27x h2.h22 {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #222222;
    margin-left: 15px; }
    @media screen and (max-width: 992px) {
      /* line 29, src/assets/scss/modules/_m27x.scss */
      .m27x h2.h22 {
        font-size: 20px;
        margin-left: 15px;
        padding: 0;
        width: 50%; } }
  @media screen and (max-width: 992px) {
    /* line 19, src/assets/scss/modules/_m27x.scss */
    .m27x h2 {
      font-size: 20px;
      margin-bottom: 30px; } }

/* line 54, src/assets/scss/modules/_m27x.scss */
.m27x h6.firstclass {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-left: 15px; }
  @media screen and (max-width: 992px) {
    /* line 54, src/assets/scss/modules/_m27x.scss */
    .m27x h6.firstclass {
      margin: 0;
      position: absolute;
      right: 15px;
      top: 20px; } }

/* line 71, src/assets/scss/modules/_m27x.scss */
.m27x h6.secondclass {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333; }

/* line 82, src/assets/scss/modules/_m27x.scss */
.m27x p {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #333333;
  padding-bottom: 51px;
  margin-bottom: 0px; }
  /* line 93, src/assets/scss/modules/_m27x.scss */
  .m27x p:first-child {
    margin-bottom: 30px;
    padding-bottom: 0px; }
  /* line 97, src/assets/scss/modules/_m27x.scss */
  .m27x p.ptext {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d2114; }
  /* line 107, src/assets/scss/modules/_m27x.scss */
  .m27x p.ptext12 {
    font-family: "Work Sans", "sans-serif";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #222222; }
  /* line 117, src/assets/scss/modules/_m27x.scss */
  .m27x p.parccodion {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    margin-left: 55px;
    padding: 0;
    margin-bottom: 18px; }
    /* line 128, src/assets/scss/modules/_m27x.scss */
    .m27x p.parccodion:first-child {
      padding-left: 55px;
      padding-top: 28px; }
    /* line 132, src/assets/scss/modules/_m27x.scss */
    .m27x p.parccodion:last-child {
      padding-bottom: 30px; }
  /* line 136, src/assets/scss/modules/_m27x.scss */
  .m27x p.psubmenu {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    text-align: center;
    border-bottom: 1px solid #dddddd; }

/* line 153, src/assets/scss/modules/_m27x.scss */
.m27x ul {
  padding-right: 15px; }

/* line 157, src/assets/scss/modules/_m27x.scss */
.m27x ol li {
  list-style-type: decimal-leading-zero;
  color: orangered; }

/* line 162, src/assets/scss/modules/_m27x.scss */
.m27x a.arrowpg {
  color: #235789;
  font-size: 18px;
  padding-top: 0;
  padding-bottom: 0; }

/* line 168, src/assets/scss/modules/_m27x.scss */
.m27x a.h44 {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #235789;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 168, src/assets/scss/modules/_m27x.scss */
    .m27x a.h44 {
      padding: 0;
      margin-top: 1px;
      margin-bottom: 30px;
      margin-left: 0px;
      margin-right: 0px; } }

/* line 186, src/assets/scss/modules/_m27x.scss */
.m27x a.paragraph {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: inline;
  color: #9b9b9b; }
  @media screen and (max-width: 992px) {
    /* line 186, src/assets/scss/modules/_m27x.scss */
    .m27x a.paragraph {
      font-size: 15px;
      margin-left: 0px;
      margin-right: 0px; } }

/* line 202, src/assets/scss/modules/_m27x.scss */
.m27x a.withimagea {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 202, src/assets/scss/modules/_m27x.scss */
    .m27x a.withimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px; } }

/* line 221, src/assets/scss/modules/_m27x.scss */
.m27x a.withoutimagea {
  margin-left: 15px;
  margin-right: 15px;
  height: 95px;
  font-family: "Work Sans", "sans-serif";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  display: flex;
  margin-bottom: 40px; }
  @media screen and (max-width: 992px) {
    /* line 221, src/assets/scss/modules/_m27x.scss */
    .m27x a.withoutimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px; } }

/* line 244, src/assets/scss/modules/_m27x.scss */
.m27x a.fontstyle {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 255, src/assets/scss/modules/_m27x.scss */
.m27x a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 45px;
  padding: 0; }

/* line 262, src/assets/scss/modules/_m27x.scss */
.m27x a.linkfont {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000; }

/* line 272, src/assets/scss/modules/_m27x.scss */
.m27x a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 281, src/assets/scss/modules/_m27x.scss */
.m27x a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 290, src/assets/scss/modules/_m27x.scss */
  .m27x a.accordionmenu:first-child {
    color: #235789; }

/* line 294, src/assets/scss/modules/_m27x.scss */
.m27x a.arcodionlink {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 303, src/assets/scss/modules/_m27x.scss */
.m27x a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 314, src/assets/scss/modules/_m27x.scss */
.m27x a.linksubmenu {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline;
  border-bottom: 1px solid #333333; }

/* line 326, src/assets/scss/modules/_m27x.scss */
.m27x a:hover {
  outline: none;
  text-decoration: none; }

/* line 330, src/assets/scss/modules/_m27x.scss */
.m27x a:focus {
  outline: none;
  text-decoration: none; }

/* line 334, src/assets/scss/modules/_m27x.scss */
.m27x .col-md-3.d-flex.justify-content-end {
  padding: 0; }

/* line 338, src/assets/scss/modules/_m27x.scss */
.m27x ul li.nav-item {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 343, src/assets/scss/modules/_m27x.scss */
.m27x ul li.nav-item:active {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 348, src/assets/scss/modules/_m27x.scss */
.m27x ul li.lit {
  padding-left: 12px; }

/* line 352, src/assets/scss/modules/_m27x.scss */
.m27x li.dropdown:hover > .dropdown-menu {
  display: block; }

/* line 355, src/assets/scss/modules/_m27x.scss */
.m27x a.nav-link.linkfont2 {
  margin: 0; }

/* line 359, src/assets/scss/modules/_m27x.scss */
.m27x a.nav-link.linkfont2:hover {
  font-weight: 600; }

/* line 363, src/assets/scss/modules/_m27x.scss */
.m27x ul .collapse.list-unstyled {
  padding: 0;
  border-top: 1px solid #dddddd; }

/* line 368, src/assets/scss/modules/_m27x.scss */
.m27x .triangle {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(315deg);
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px; }

/* line 380, src/assets/scss/modules/_m27x.scss */
.m27x .divaccordionspace {
  margin-top: 30px;
  margin-bottom: 19px; }

/* line 384, src/assets/scss/modules/_m27x.scss */
.m27x .divborder {
  border-top: 1px solid #dddddd;
  margin: 0px;
  padding: 0px; }

/* line 389, src/assets/scss/modules/_m27x.scss */
.m27x .divborderbottom {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px; }

/* line 393, src/assets/scss/modules/_m27x.scss */
.m27x ul.nav.flex-column {
  padding-right: 0; }

/* line 396, src/assets/scss/modules/_m27x.scss */
.m27x .backtp {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d2114;
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  text-align: center; }

/* line 409, src/assets/scss/modules/_m27x.scss */
.m27x .arrow {
  content: "";
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 10px;
  height: 10px;
  border-right: 2px solid #222222;
  border-bottom: 2px solid #222222;
  transform: rotate(225deg);
  cursor: pointer;
  margin-bottom: 55px; }

/* line 426, src/assets/scss/modules/_m27x.scss */
.m27x .nav-link.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 16px; }

/* line 440, src/assets/scss/modules/_m27x.scss */
.m27x .nav-link.expand[aria-expanded=true]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 22px; }

/* line 457, src/assets/scss/modules/_m27x.scss */
.m27x .nav-item.show .nav-link.linkfont.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 2px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 16px; }

/* line 472, src/assets/scss/modules/_m27x.scss */
.m27x ul.nav.flex-column.divborder {
  margin-right: 15px; }

/* line 475, src/assets/scss/modules/_m27x.scss */
.m27x a.accordionmenu:hover {
  color: #235789; }

/* line 480, src/assets/scss/modules/_m27x.scss */
.m27x .rectanglemod {
  padding: 10px;
  max-width: 350px;
  border: 1px solid;
  border-color: #dddddd;
  position: relative;
  border-top: 3px solid black;
  margin-top: 15px;
  margin-bottom: 15px; }
  @media screen and (max-width: 992px) {
    /* line 480, src/assets/scss/modules/_m27x.scss */
    .m27x .rectanglemod {
      max-width: 100%;
      padding: 15px;
      margin: 0;
      border: none;
      border: 1px solid #dddddd;
      border-top: 3px solid black;
      margin-bottom: 20px; } }

/* line 499, src/assets/scss/modules/_m27x.scss */
.m27x .rectanglerwo {
  width: 24px;
  height: 24px;
  position: absolute;
  border: none;
  z-index: 999;
  right: -1px;
  bottom: -1px; }

/* line 511, src/assets/scss/modules/_m27x.scss */
.m27x .digonalline {
  width: 24px; }

/* line 514, src/assets/scss/modules/_m27x.scss */
.m27x .digonalline {
  background: linear-gradient(-45deg, #fff 49%, #dddddd 52%, #fff 51%); }

/* line 517, src/assets/scss/modules/_m27x.scss */
.m27x .publications_slider {
  padding-top: 20px;
  border-bottom: 1px solid;
  border-color: #dddddd;
  width: 100%;
  margin-bottom: 50px; }
  @media screen and (max-width: 992px) {
    /* line 517, src/assets/scss/modules/_m27x.scss */
    .m27x .publications_slider {
      padding-top: 0;
      padding-bottom: 0px;
      border-bottom: 1px solid #dddddd; } }

/* line 529, src/assets/scss/modules/_m27x.scss */
.m27x .imageclass {
  max-width: 230px;
  height: 150px;
  background-size: cover;
  margin-bottom: 15.6px;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (min-width: 993px) and (max-width: 1199px) {
    /* line 529, src/assets/scss/modules/_m27x.scss */
    .m27x .imageclass {
      height: 124px; } }
  @media screen and (max-width: 992px) {
    /* line 529, src/assets/scss/modules/_m27x.scss */
    .m27x .imageclass {
      max-width: 313px;
      padding: 0;
      background-size: cover;
      max-height: initial;
      height: initial; } }

/* line 547, src/assets/scss/modules/_m27x.scss */
.m27x img {
  width: 100%;
  height: auto; }

/* line 551, src/assets/scss/modules/_m27x.scss */
.m27x .coldiv {
  width: 320px; }
  /* line 553, src/assets/scss/modules/_m27x.scss */
  .m27x .coldiv .div-title {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 35px;
    height: 95px;
    overflow: hidden; }
    @media screen and (max-width: 992px) {
      /* line 553, src/assets/scss/modules/_m27x.scss */
      .m27x .coldiv .div-title {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        min-height: 60px;
        margin-top: 6px;
        padding: 0; } }
  /* line 568, src/assets/scss/modules/_m27x.scss */
  .m27x .coldiv .div-text {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    /*text-overflow: ellipsis;
      overflow: hidden;
      width: 240px;
       height: 45px;
      white-space: nowrap;*/ }
    @media screen and (max-width: 992px) {
      /* line 568, src/assets/scss/modules/_m27x.scss */
      .m27x .coldiv .div-text {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 20px;
        margin-top: 10px;
        padding: 0; } }
  /* line 591, src/assets/scss/modules/_m27x.scss */
  .m27x .coldiv .div-link {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 591, src/assets/scss/modules/_m27x.scss */
      .m27x .coldiv .div-link {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-top: 2px;
        padding: 0; } }
  /* line 607, src/assets/scss/modules/_m27x.scss */
  .m27x .coldiv .div-titlet {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
    height: 95px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 607, src/assets/scss/modules/_m27x.scss */
      .m27x .coldiv .div-titlet {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 60px;
        margin-top: 6px; } }
  /* line 624, src/assets/scss/modules/_m27x.scss */
  .m27x .coldiv .div-textt {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 5px;
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 624, src/assets/scss/modules/_m27x.scss */
      .m27x .coldiv .div-textt {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 20px; } }
  /* line 640, src/assets/scss/modules/_m27x.scss */
  .m27x .coldiv .div-linkt {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 640, src/assets/scss/modules/_m27x.scss */
      .m27x .coldiv .div-linkt {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
        margin-top: 2px; } }
  @media screen and (max-width: 992px) {
    /* line 551, src/assets/scss/modules/_m27x.scss */
    .m27x .coldiv {
      width: 100%; } }

/* line 659, src/assets/scss/modules/_m27x.scss */
.m27x .type2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  position: absolute;
  right: 15px;
  top: 0px;
  background-size: cover;
  transform: rotate(180deg); }
  @media screen and (max-width: 992px) {
    /* line 659, src/assets/scss/modules/_m27x.scss */
    .m27x .type2 {
      display: none; } }

/* line 674, src/assets/scss/modules/_m27x.scss */
.m27x .type3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  position: absolute;
  right: 83px;
  top: 0px;
  background-size: cover; }
  @media screen and (max-width: 992px) {
    /* line 674, src/assets/scss/modules/_m27x.scss */
    .m27x .type3 {
      display: none; } }

/* line 691, src/assets/scss/modules/_m27x.scss */
.m27x .type2 img:hover {
  cursor: pointer;
  opacity: 0.4; }

/* line 699, src/assets/scss/modules/_m27x.scss */
.m27x .type3 img:hover {
  cursor: pointer;
  opacity: 0.4; }

/* line 706, src/assets/scss/modules/_m27x.scss */
.m27x .typegen:hover {
  cursor: pointer; }

/* line 710, src/assets/scss/modules/_m27x.scss */
.m27x .typegen .slick-disabled {
  opacity: 0.4 !important; }

/* line 715, src/assets/scss/modules/_m27x.scss */
.m27x img.imagearrow {
  width: 38px;
  height: 38px;
  border-radius: 50%; }

/* line 722, src/assets/scss/modules/_m27x.scss */
.m27x .pagination {
  display: inline-block;
  border: 1px solid #dddddd;
  position: absolute;
  right: 0;
  bottom: 0; }

/* line 730, src/assets/scss/modules/_m27x.scss */
.m27x .pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none; }

/* line 738, src/assets/scss/modules/_m27x.scss */
.m27x .pagination a.active {
  background-color: #dddddd;
  border: 1px solid #dddddd;
  cursor: default; }

/* line 744, src/assets/scss/modules/_m27x.scss */
.m27x .pagination a:hover:not(.active) {
  background-color: #fff; }

/* line 748, src/assets/scss/modules/_m27x.scss */
.m27x .pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

/* line 753, src/assets/scss/modules/_m27x.scss */
.m27x .pagination a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

/* line 757, src/assets/scss/modules/_m27x.scss */
.m27x .divpagenation {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 50px;
  position: relative; }
  @media screen and (max-width: 992px) {
    /* line 757, src/assets/scss/modules/_m27x.scss */
    .m27x .divpagenation {
      display: none; } }

/* line 767, src/assets/scss/modules/_m27x.scss */
.m27x .publications_slider2 {
  padding-top: 30px;
  padding-bottom: 40px;
  margin-bottom: 20px; }
  @media screen and (max-width: 992px) {
    /* line 767, src/assets/scss/modules/_m27x.scss */
    .m27x .publications_slider2 {
      padding-bottom: 30px; } }

/* line 776, src/assets/scss/modules/_m27x.scss */
.m27x .searchnews {
  width: 100%;
  height: 50px;
  border: 1px solid #dddddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-position: right 30px center;
  background-repeat: no-repeat;
  color: #dddddd;
  padding-left: 20px;
  margin-top: 30px;
  margin-bottom: 30px; }

/* line 791, src/assets/scss/modules/_m27x.scss */
.m27x .daterange {
  margin-top: 30px;
  width: 100%;
  height: 50px;
  border: 1px solid #dddddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-position: left 16px center;
  background-repeat: no-repeat;
  color: #dddddd;
  padding-left: 70px; }

/* line 806, src/assets/scss/modules/_m27x.scss */
.m27x .form-control::-webkit-input-placeholder {
  margin-left: 20px;
  padding-left: 20px; }
  @media screen and (max-width: 992px) {
    /* line 806, src/assets/scss/modules/_m27x.scss */
    .m27x .form-control::-webkit-input-placeholder {
      color: #fff; } }

/* line 813, src/assets/scss/modules/_m27x.scss */
.m27x .checkboxclass {
  width: 30px;
  height: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  border: 1px solid #235789; }

/* line 824, src/assets/scss/modules/_m27x.scss */
.m27x .styled-checkbox {
  position: absolute;
  opacity: 0;
  border: 1px solid #235789; }
  /* line 829, src/assets/scss/modules/_m27x.scss */
  .m27x .styled-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0; }
  /* line 836, src/assets/scss/modules/_m27x.scss */
  .m27x .styled-checkbox + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 30px;
    height: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: white;
    border: 1px solid #235789; }
  /* line 852, src/assets/scss/modules/_m27x.scss */
  .m27x .styled-checkbox:checked + label:before {
    background: #fff; }
  /* line 857, src/assets/scss/modules/_m27x.scss */
  .m27x .styled-checkbox:disabled + label {
    color: #b8b8b8;
    cursor: auto; }
  /* line 863, src/assets/scss/modules/_m27x.scss */
  .m27x .styled-checkbox:disabled + label:before {
    box-shadow: none;
    background: #dddddd; }
  /* line 869, src/assets/scss/modules/_m27x.scss */
  .m27x .styled-checkbox:checked + label:after {
    content: '\2713';
    position: absolute;
    left: 10px;
    top: 2px;
    color: #235789; }

/* line 877, src/assets/scss/modules/_m27x.scss */
.m27x label {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  color: #333333; }

@media screen and (max-width: 992px) {
  /* line 882, src/assets/scss/modules/_m27x.scss */
  .m27x .d-flex {
    padding: 15px;
    display: block !important; } }

/* line 888, src/assets/scss/modules/_m27x.scss */
.m27x .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 30px 0;
  list-style-type: none; }
  /* line 897, src/assets/scss/modules/_m27x.scss */
  .m27x .slick-dots li {
    margin: 0 0.25rem; }
  /* line 901, src/assets/scss/modules/_m27x.scss */
  .m27x .slick-dots button {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    padding: 0;
    cursor: pointer;
    border: none;
    border-radius: 100%;
    background-color: #dddddd;
    text-indent: -9999px; }
  /* line 913, src/assets/scss/modules/_m27x.scss */
  .m27x .slick-dots li.slick-active button {
    background-color: #192830; }

/* line 918, src/assets/scss/modules/_m27x.scss */
.m27x .styled-checkbox:checked + label:after {
  content: "\2713";
  position: absolute;
  left: 9px;
  top: 3px;
  color: #235789;
  font-size: 20px; }

@media screen and (max-width: 992px) {
  /* line 926, src/assets/scss/modules/_m27x.scss */
  .m27x ul.slick-dots {
    padding-top: 0; } }

/* line 931, src/assets/scss/modules/_m27x.scss */
.m27x label {
  margin-right: 15px; }

@media screen and (max-width: 992px) {
  /* line 934, src/assets/scss/modules/_m27x.scss */
  .m27x .row.divaccordionspace {
    display: none; } }

/* line 2, src/assets/scss/modules/_m28x.scss */
.m28x h1 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #333333;
  margin-bottom: 9px; }
  @media screen and (max-width: 992px) {
    /* line 2, src/assets/scss/modules/_m28x.scss */
    .m28x h1 {
      text-align: left;
      margin: 0;
      font-size: 30px; } }

/* line 19, src/assets/scss/modules/_m28x.scss */
.m28x h2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }
  /* line 29, src/assets/scss/modules/_m28x.scss */
  .m28x h2.h22 {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #222222; }
    @media screen and (max-width: 767px) {
      /* line 29, src/assets/scss/modules/_m28x.scss */
      .m28x h2.h22 {
        font-size: 20px;
        margin-left: 15px;
        padding: 0;
        width: 50%; } }
  @media screen and (max-width: 992px) {
    /* line 19, src/assets/scss/modules/_m28x.scss */
    .m28x h2 {
      font-size: 20px;
      margin-bottom: 30px; } }

/* line 53, src/assets/scss/modules/_m28x.scss */
.m28x h6.firstclass {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin: 0; }
  @media screen and (min-width: 768px) {
    /* line 53, src/assets/scss/modules/_m28x.scss */
    .m28x h6.firstclass {
      width: 100%; } }
  @media screen and (max-width: 767px) {
    /* line 53, src/assets/scss/modules/_m28x.scss */
    .m28x h6.firstclass {
      margin: 0;
      position: absolute;
      right: 15px;
      top: 20px; } }

/* line 74, src/assets/scss/modules/_m28x.scss */
.m28x h6.secondclass {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333; }

/* line 85, src/assets/scss/modules/_m28x.scss */
.m28x p {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #333333;
  padding-bottom: 51px;
  margin-bottom: 0; }
  /* line 96, src/assets/scss/modules/_m28x.scss */
  .m28x p:first-child {
    margin-bottom: 30px;
    padding-bottom: 0; }
  /* line 100, src/assets/scss/modules/_m28x.scss */
  .m28x p.ptext {
    font-family: "Work Sans", "sans-serif";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d2114; }
  /* line 110, src/assets/scss/modules/_m28x.scss */
  .m28x p.ptext12 {
    font-family: "Work Sans", "sans-serif";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #222222; }
  /* line 120, src/assets/scss/modules/_m28x.scss */
  .m28x p.parccodion {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    margin-left: 55px;
    padding: 0;
    margin-bottom: 18px; }
    /* line 131, src/assets/scss/modules/_m28x.scss */
    .m28x p.parccodion:first-child {
      padding-left: 55px;
      padding-top: 28px; }
    /* line 135, src/assets/scss/modules/_m28x.scss */
    .m28x p.parccodion:last-child {
      padding-bottom: 30px; }
  /* line 139, src/assets/scss/modules/_m28x.scss */
  .m28x p.psubmenu {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    text-align: center;
    border-bottom: 1px solid #dddddd; }

/* line 156, src/assets/scss/modules/_m28x.scss */
.m28x ul {
  padding-right: 15px; }

/* line 160, src/assets/scss/modules/_m28x.scss */
.m28x ol li {
  list-style-type: decimal-leading-zero;
  color: orangered; }

/* line 165, src/assets/scss/modules/_m28x.scss */
.m28x a.arrowpg {
  color: #235789;
  font-size: 18px;
  padding-top: 0;
  padding-bottom: 0; }

/* line 171, src/assets/scss/modules/_m28x.scss */
.m28x a.h44 {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #235789;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 171, src/assets/scss/modules/_m28x.scss */
    .m28x a.h44 {
      padding: 0;
      margin-top: 1px;
      margin-bottom: 30px;
      margin-left: 0;
      margin-right: 0; } }

/* line 189, src/assets/scss/modules/_m28x.scss */
.m28x a.paragraph {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: inline;
  color: #9b9b9b; }
  @media screen and (max-width: 992px) {
    /* line 189, src/assets/scss/modules/_m28x.scss */
    .m28x a.paragraph {
      font-size: 15px;
      margin-left: 0;
      margin-right: 0; } }

/* line 205, src/assets/scss/modules/_m28x.scss */
.m28x a.withimagea {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  line-height: 1.24;
  color: #222222;
  display: inline;
  overflow: hidden; }
  @media screen and (max-width: 992px) {
    /* line 205, src/assets/scss/modules/_m28x.scss */
    .m28x a.withimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0; } }

/* line 221, src/assets/scss/modules/_m28x.scss */
.m28x a.withoutimagea {
  margin-left: 15px;
  margin-right: 15px;
  height: 95px;
  font-family: "Work Sans", "sans-serif";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  display: flex;
  margin-bottom: 40px; }
  @media screen and (max-width: 992px) {
    /* line 221, src/assets/scss/modules/_m28x.scss */
    .m28x a.withoutimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0; } }

/* line 244, src/assets/scss/modules/_m28x.scss */
.m28x a.fontstyle {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 255, src/assets/scss/modules/_m28x.scss */
.m28x a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 45px;
  padding: 0; }

/* line 262, src/assets/scss/modules/_m28x.scss */
.m28x a.linkfont {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000; }

/* line 272, src/assets/scss/modules/_m28x.scss */
.m28x a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 281, src/assets/scss/modules/_m28x.scss */
.m28x a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 290, src/assets/scss/modules/_m28x.scss */
  .m28x a.accordionmenu:first-child {
    color: #235789; }

/* line 294, src/assets/scss/modules/_m28x.scss */
.m28x a.arcodionlink {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 303, src/assets/scss/modules/_m28x.scss */
.m28x a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 314, src/assets/scss/modules/_m28x.scss */
.m28x a.linksubmenu {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline;
  border-bottom: 1px solid #333333; }

/* line 326, src/assets/scss/modules/_m28x.scss */
.m28x a:hover {
  outline: none;
  text-decoration: none; }

/* line 330, src/assets/scss/modules/_m28x.scss */
.m28x a:focus {
  outline: none;
  text-decoration: none; }

/* line 334, src/assets/scss/modules/_m28x.scss */
.m28x .col-md-3.d-flex.justify-content-end {
  padding: 0; }

/* line 338, src/assets/scss/modules/_m28x.scss */
.m28x ul li.nav-item {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 343, src/assets/scss/modules/_m28x.scss */
.m28x ul li.nav-item:active {
  line-height: 44px;
  text-align: left;
  border-bottom: 1px solid #dddddd; }

/* line 348, src/assets/scss/modules/_m28x.scss */
.m28x ul li.lit {
  padding-left: 12px; }

/* line 352, src/assets/scss/modules/_m28x.scss */
.m28x li.dropdown:hover > .dropdown-menu {
  display: block; }

/* line 355, src/assets/scss/modules/_m28x.scss */
.m28x a.nav-link.linkfont2 {
  margin: 0; }

/* line 359, src/assets/scss/modules/_m28x.scss */
.m28x a.nav-link.linkfont2:hover {
  font-weight: 600; }

/* line 363, src/assets/scss/modules/_m28x.scss */
.m28x ul .collapse.list-unstyled {
  padding: 0;
  border-top: 1px solid #dddddd; }

/* line 368, src/assets/scss/modules/_m28x.scss */
.m28x .triangle {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(315deg);
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px; }

/* line 380, src/assets/scss/modules/_m28x.scss */
.m28x .divaccordionspace {
  margin-top: 30px;
  margin-bottom: 19px; }

/* line 384, src/assets/scss/modules/_m28x.scss */
.m28x .divborder {
  border-top: 1px solid #dddddd;
  margin: 0;
  padding: 0; }

/* line 389, src/assets/scss/modules/_m28x.scss */
.m28x .divborderbottom {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px; }

/* line 393, src/assets/scss/modules/_m28x.scss */
.m28x .divacc {
  padding-top: 40px; }

/* line 396, src/assets/scss/modules/_m28x.scss */
.m28x ul.nav.flex-column {
  padding-right: 0; }

/* line 399, src/assets/scss/modules/_m28x.scss */
.m28x .backtp {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d2114;
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  text-align: center; }

/* line 412, src/assets/scss/modules/_m28x.scss */
.m28x .arrow {
  content: "";
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 10px;
  height: 10px;
  border-right: 2px solid #222222;
  border-bottom: 2px solid #222222;
  transform: rotate(225deg);
  cursor: pointer;
  margin-bottom: 55px; }

/* line 429, src/assets/scss/modules/_m28x.scss */
.m28x .nav-link.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(45deg);
  top: 16px; }

/* line 443, src/assets/scss/modules/_m28x.scss */
.m28x .nav-link.expand[aria-expanded=true]:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 22px; }

/* line 460, src/assets/scss/modules/_m28x.scss */
.m28x .nav-item.show .nav-link.linkfont.expand:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 2px;
  border-right: 1px solid #222222;
  border-bottom: 1px solid #222222;
  transform: rotate(225deg);
  top: 16px; }

/* line 475, src/assets/scss/modules/_m28x.scss */
.m28x ul.nav.flex-column.divborder {
  margin-right: 15px; }

/* line 478, src/assets/scss/modules/_m28x.scss */
.m28x a.accordionmenu:hover {
  color: #235789; }

/* line 483, src/assets/scss/modules/_m28x.scss */
.m28x .rectanglemod {
  padding: 10px;
  max-width: 350px;
  border: 1px solid;
  border-color: #dddddd;
  position: relative;
  border-top: 3px solid black;
  margin-top: 15px;
  margin-bottom: 15px; }
  @media screen and (max-width: 992px) {
    /* line 483, src/assets/scss/modules/_m28x.scss */
    .m28x .rectanglemod {
      max-width: 100%;
      padding: 15px;
      border: 1px solid #dddddd;
      border-top: 3px solid black;
      margin-bottom: 20px; } }

/* line 500, src/assets/scss/modules/_m28x.scss */
.m28x .rectanglerwo {
  width: 24px;
  height: 24px;
  position: absolute;
  border: none;
  z-index: 999;
  right: -1px;
  bottom: -1px; }

/* line 512, src/assets/scss/modules/_m28x.scss */
.m28x .digonalline {
  width: 24px; }

/* line 515, src/assets/scss/modules/_m28x.scss */
.m28x .digonalline {
  background: linear-gradient(-45deg, #fff 49%, #dddddd 52%, #fff 51%); }

/* line 518, src/assets/scss/modules/_m28x.scss */
.m28x .publications_slider {
  padding-bottom: 55px;
  padding-top: 20px;
  border-bottom: 1px solid;
  border-color: #dddddd;
  width: 100%;
  margin-bottom: 50px; }
  @media screen and (max-width: 992px) {
    /* line 518, src/assets/scss/modules/_m28x.scss */
    .m28x .publications_slider {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: 1px solid #dddddd; } }

/* line 531, src/assets/scss/modules/_m28x.scss */
.m28x .imageclass {
  max-width: 230px;
  height: 117px;
  background-size: cover;
  margin-top: 19px;
  margin-bottom: 15.6px;
  margin-left: 0;
  margin-right: 0; }
  @media screen and (max-width: 992px) {
    /* line 531, src/assets/scss/modules/_m28x.scss */
    .m28x .imageclass {
      max-width: 313px;
      max-height: 140px;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      background-size: cover; } }

/* line 548, src/assets/scss/modules/_m28x.scss */
.m28x img {
  width: 100%;
  height: auto; }

/* line 552, src/assets/scss/modules/_m28x.scss */
.m28x .coldiv {
  width: 320px; }
  /* line 554, src/assets/scss/modules/_m28x.scss */
  .m28x .coldiv .div-title {
    margin-bottom: 5px;
    height: 120px;
    overflow: hidden; }
    @media screen and (max-width: 992px) {
      /* line 554, src/assets/scss/modules/_m28x.scss */
      .m28x .coldiv .div-title {
        margin-left: 0;
        margin-right: 0;
        min-height: 60px;
        margin-top: 6px;
        padding: 0;
        height: 100px;
        margin-bottom: 20px; } }
  /* line 568, src/assets/scss/modules/_m28x.scss */
  .m28x .coldiv .div-text {
    margin-bottom: 5px;
    width: 100%;
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    /*text-overflow: ellipsis;
      overflow: hidden;
      width: 240px;
       height: 45px;
      white-space: nowrap;*/ }
    @media screen and (max-width: 992px) {
      /* line 568, src/assets/scss/modules/_m28x.scss */
      .m28x .coldiv .div-text {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        height: 20px;
        margin-top: 10px;
        padding: 0; } }
  /* line 590, src/assets/scss/modules/_m28x.scss */
  .m28x .coldiv .div-link {
    margin-top: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 590, src/assets/scss/modules/_m28x.scss */
      .m28x .coldiv .div-link {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        margin-top: 2px;
        padding: 0; } }
  /* line 604, src/assets/scss/modules/_m28x.scss */
  .m28x .coldiv .div-titlet {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0;
    height: 95px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 604, src/assets/scss/modules/_m28x.scss */
      .m28x .coldiv .div-titlet {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        height: 60px;
        margin-top: 6px; } }
  /* line 621, src/assets/scss/modules/_m28x.scss */
  .m28x .coldiv .div-textt {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 5px;
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 621, src/assets/scss/modules/_m28x.scss */
      .m28x .coldiv .div-textt {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        height: 20px; } }
  /* line 637, src/assets/scss/modules/_m28x.scss */
  .m28x .coldiv .div-linkt {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 637, src/assets/scss/modules/_m28x.scss */
      .m28x .coldiv .div-linkt {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        margin-top: 2px; } }
  @media screen and (max-width: 992px) {
    /* line 552, src/assets/scss/modules/_m28x.scss */
    .m28x .coldiv {
      width: 100%; } }

/* line 656, src/assets/scss/modules/_m28x.scss */
.m28x .type2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  position: absolute;
  right: 15px;
  top: 0;
  background-size: cover;
  transform: rotate(180deg); }
  @media screen and (max-width: 992px) {
    /* line 656, src/assets/scss/modules/_m28x.scss */
    .m28x .type2 {
      display: none; } }

/* line 671, src/assets/scss/modules/_m28x.scss */
.m28x .type3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  position: absolute;
  right: 83px;
  top: 0;
  background-size: cover; }
  @media screen and (max-width: 992px) {
    /* line 671, src/assets/scss/modules/_m28x.scss */
    .m28x .type3 {
      display: none; } }

/* line 688, src/assets/scss/modules/_m28x.scss */
.m28x .type2 img:hover {
  cursor: pointer;
  opacity: 0.4; }

/* line 696, src/assets/scss/modules/_m28x.scss */
.m28x .type3 img:hover {
  cursor: pointer;
  opacity: 0.4; }

/* line 703, src/assets/scss/modules/_m28x.scss */
.m28x .typegen:hover {
  cursor: pointer; }

/* line 707, src/assets/scss/modules/_m28x.scss */
.m28x .typegen .slick-disabled {
  opacity: 0.4 !important; }

/* line 712, src/assets/scss/modules/_m28x.scss */
.m28x img.imagearrow {
  width: 38px;
  height: 38px;
  border-radius: 50%; }

/* line 719, src/assets/scss/modules/_m28x.scss */
.m28x .pagination {
  display: inline-block;
  border: 1px solid #dddddd;
  position: absolute;
  right: 0;
  bottom: 0; }

/* line 727, src/assets/scss/modules/_m28x.scss */
.m28x .pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none; }

/* line 735, src/assets/scss/modules/_m28x.scss */
.m28x .pagination a.active {
  background-color: #dddddd;
  border: 1px solid #dddddd;
  cursor: default; }

/* line 741, src/assets/scss/modules/_m28x.scss */
.m28x .pagination a:hover:not(.active) {
  background-color: #fff; }

/* line 745, src/assets/scss/modules/_m28x.scss */
.m28x .pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

/* line 750, src/assets/scss/modules/_m28x.scss */
.m28x .pagination a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

/* line 754, src/assets/scss/modules/_m28x.scss */
.m28x .divpagenation {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 50px;
  position: relative; }
  @media screen and (max-width: 992px) {
    /* line 754, src/assets/scss/modules/_m28x.scss */
    .m28x .divpagenation {
      display: none; } }

/* line 764, src/assets/scss/modules/_m28x.scss */
.m28x .publications_slider2 {
  padding-top: 30px;
  padding-bottom: 40px;
  margin-bottom: 20px; }
  @media screen and (max-width: 992px) {
    /* line 764, src/assets/scss/modules/_m28x.scss */
    .m28x .publications_slider2 {
      padding-bottom: 30px; } }

/* line 773, src/assets/scss/modules/_m28x.scss */
.m28x .searchnews {
  width: 100%;
  height: 50px;
  border: 1px solid #dddddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-position: right 30px center;
  background-repeat: no-repeat;
  color: #dddddd;
  padding-left: 20px;
  margin-top: 30px;
  margin-bottom: 30px; }

/* line 788, src/assets/scss/modules/_m28x.scss */
.m28x .daterange {
  margin-top: 30px;
  width: 100%;
  height: 50px;
  border: 1px solid #dddddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-position: left 16px center;
  background-repeat: no-repeat;
  color: #dddddd;
  padding-left: 70px; }

/* line 803, src/assets/scss/modules/_m28x.scss */
.m28x .form-control::-webkit-input-placeholder {
  margin-left: 20px;
  padding-left: 20px; }
  @media screen and (max-width: 992px) {
    /* line 803, src/assets/scss/modules/_m28x.scss */
    .m28x .form-control::-webkit-input-placeholder {
      color: #fff; } }

/* line 810, src/assets/scss/modules/_m28x.scss */
.m28x .checkboxclass {
  width: 30px;
  height: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  border: 1px solid #235789; }

/* line 821, src/assets/scss/modules/_m28x.scss */
.m28x .styled-checkbox {
  position: absolute;
  opacity: 0;
  border: 1px solid #235789; }
  /* line 826, src/assets/scss/modules/_m28x.scss */
  .m28x .styled-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0; }
  /* line 833, src/assets/scss/modules/_m28x.scss */
  .m28x .styled-checkbox + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 30px;
    height: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: white;
    border: 1px solid #235789; }
  /* line 849, src/assets/scss/modules/_m28x.scss */
  .m28x .styled-checkbox:checked + label:before {
    background: #fff; }
  /* line 854, src/assets/scss/modules/_m28x.scss */
  .m28x .styled-checkbox:disabled + label {
    color: #b8b8b8;
    cursor: auto; }
  /* line 860, src/assets/scss/modules/_m28x.scss */
  .m28x .styled-checkbox:disabled + label:before {
    box-shadow: none;
    background: #dddddd; }
  /* line 866, src/assets/scss/modules/_m28x.scss */
  .m28x .styled-checkbox:checked + label:after {
    content: '\2713';
    position: absolute;
    left: 10px;
    top: 2px;
    color: #235789; }

/* line 874, src/assets/scss/modules/_m28x.scss */
.m28x label {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  color: #333333; }

@media screen and (max-width: 992px) {
  /* line 879, src/assets/scss/modules/_m28x.scss */
  .m28x .d-flex {
    padding: 15px;
    display: block !important; } }

/* line 885, src/assets/scss/modules/_m28x.scss */
.m28x .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 30px 0;
  list-style-type: none; }
  /* line 894, src/assets/scss/modules/_m28x.scss */
  .m28x .slick-dots li {
    margin: 0 0.25rem; }
  /* line 898, src/assets/scss/modules/_m28x.scss */
  .m28x .slick-dots button {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #dddddd;
    text-indent: -9999px; }
  /* line 910, src/assets/scss/modules/_m28x.scss */
  .m28x .slick-dots li.slick-active button {
    background-color: #192830; }

/* line 915, src/assets/scss/modules/_m28x.scss */
.m28x .styled-checkbox:checked + label:after {
  content: "\2713";
  position: absolute;
  left: 9px;
  top: 3px;
  color: #235789;
  font-size: 20px; }

@media screen and (max-width: 992px) {
  /* line 923, src/assets/scss/modules/_m28x.scss */
  .m28x ul.slick-dots {
    padding-top: 0; } }

/* line 928, src/assets/scss/modules/_m28x.scss */
.m28x label {
  margin-right: 15px; }

@media screen and (max-width: 992px) {
  /* line 931, src/assets/scss/modules/_m28x.scss */
  .m28x .row.divaccordionspace {
    display: none; } }

/* line 2, src/assets/scss/modules/_m29x.scss */
.m29x h2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }
  /* line 12, src/assets/scss/modules/_m29x.scss */
  .m29x h2.h22 {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #222222; }
    @media screen and (max-width: 992px) {
      /* line 12, src/assets/scss/modules/_m29x.scss */
      .m29x h2.h22 {
        font-size: 20px;
        margin-left: 15px;
        padding: 0;
        width: 50%; } }
  @media screen and (max-width: 992px) {
    /* line 2, src/assets/scss/modules/_m29x.scss */
    .m29x h2 {
      font-size: 20px;
      margin-bottom: 30px; } }

/* line 36, src/assets/scss/modules/_m29x.scss */
.m29x h6.firstclass {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333; }
  @media screen and (max-width: 992px) {
    /* line 36, src/assets/scss/modules/_m29x.scss */
    .m29x h6.firstclass {
      margin: 0;
      position: absolute;
      right: 15px;
      top: 20px; } }

/* line 52, src/assets/scss/modules/_m29x.scss */
.m29x h6.secondclass {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333; }

/* line 64, src/assets/scss/modules/_m29x.scss */
.m29x a.arrowpg {
  color: #235789;
  font-size: 18px;
  padding-top: 0;
  padding-bottom: 0; }

/* line 70, src/assets/scss/modules/_m29x.scss */
.m29x a.h44 {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #235789;
  display: inline;
  text-decoration: none; }
  @media screen and (max-width: 992px) {
    /* line 70, src/assets/scss/modules/_m29x.scss */
    .m29x a.h44 {
      padding: 0;
      margin-top: 1px;
      margin-bottom: 30px;
      margin-left: 0px;
      margin-right: 0px; } }

/* line 89, src/assets/scss/modules/_m29x.scss */
.m29x a.paragraph {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: inline;
  color: #9b9b9b;
  text-decoration: none; }
  @media screen and (max-width: 992px) {
    /* line 89, src/assets/scss/modules/_m29x.scss */
    .m29x a.paragraph {
      font-size: 15px;
      margin-left: 0px;
      margin-right: 0px; } }

/* line 106, src/assets/scss/modules/_m29x.scss */
.m29x a.withimagea {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  display: inline;
  text-decoration: none; }
  @media screen and (max-width: 992px) {
    /* line 106, src/assets/scss/modules/_m29x.scss */
    .m29x a.withimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px; } }

/* line 126, src/assets/scss/modules/_m29x.scss */
.m29x a.withoutimagea {
  margin-left: 15px;
  margin-right: 15px;
  height: 95px;
  font-family: "Work Sans", "sans-serif";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  display: flex;
  margin-bottom: 40px; }
  @media screen and (max-width: 992px) {
    /* line 126, src/assets/scss/modules/_m29x.scss */
    .m29x a.withoutimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px; } }

/* line 149, src/assets/scss/modules/_m29x.scss */
.m29x a.fontstyle {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 160, src/assets/scss/modules/_m29x.scss */
.m29x a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 45px;
  padding: 0; }

/* line 167, src/assets/scss/modules/_m29x.scss */
.m29x a.linkfont {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000; }

/* line 176, src/assets/scss/modules/_m29x.scss */
.m29x a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 185, src/assets/scss/modules/_m29x.scss */
.m29x a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 194, src/assets/scss/modules/_m29x.scss */
  .m29x a.accordionmenu:first-child {
    color: #235789; }

/* line 198, src/assets/scss/modules/_m29x.scss */
.m29x a.arcodionlink {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 207, src/assets/scss/modules/_m29x.scss */
.m29x a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 218, src/assets/scss/modules/_m29x.scss */
.m29x a.linksubmenu {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline;
  border-bottom: 1px solid #333333; }

/* line 230, src/assets/scss/modules/_m29x.scss */
.m29x a:hover {
  outline: none;
  text-decoration: none; }

/* line 234, src/assets/scss/modules/_m29x.scss */
.m29x a:focus {
  outline: none;
  text-decoration: none; }

/* line 239, src/assets/scss/modules/_m29x.scss */
.m29x .rectanglemod {
  padding: 10px;
  max-width: 350px;
  border: 1px solid;
  border-color: #dddddd;
  position: relative;
  border-top: 3px solid black;
  margin-top: 15px;
  margin-bottom: 15px; }
  @media screen and (max-width: 992px) {
    /* line 239, src/assets/scss/modules/_m29x.scss */
    .m29x .rectanglemod {
      max-width: 100%;
      padding: 15px;
      margin: 0;
      border: none;
      border: 1px solid #dddddd;
      border-top: 3px solid black;
      margin-bottom: 20px; } }

/* line 258, src/assets/scss/modules/_m29x.scss */
.m29x .rectanglerwo {
  width: 24px;
  height: 24px;
  position: absolute;
  border: none;
  z-index: 999;
  right: -1px;
  bottom: -1px; }

/* line 270, src/assets/scss/modules/_m29x.scss */
.m29x .digonalline {
  width: 24px; }

/* line 273, src/assets/scss/modules/_m29x.scss */
.m29x .digonalline {
  background: linear-gradient(-45deg, #fff 49%, #dddddd 52%, #fff 51%); }

/* line 276, src/assets/scss/modules/_m29x.scss */
.m29x .coldiv {
  width: 320px; }
  /* line 278, src/assets/scss/modules/_m29x.scss */
  .m29x .coldiv .div-title {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    min-height: 95px;
    max-height: 95px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 278, src/assets/scss/modules/_m29x.scss */
      .m29x .coldiv .div-title {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        min-height: 60px;
        margin-top: 6px;
        padding: 0; } }
  /* line 297, src/assets/scss/modules/_m29x.scss */
  .m29x .coldiv .div-text {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    /*text-overflow: ellipsis;
      overflow: hidden;
      width: 240px;
       height: 45px;
      white-space: nowrap;*/ }
    @media screen and (max-width: 992px) {
      /* line 297, src/assets/scss/modules/_m29x.scss */
      .m29x .coldiv .div-text {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 20px;
        margin-top: 10px;
        padding: 0; } }
  /* line 320, src/assets/scss/modules/_m29x.scss */
  .m29x .coldiv .div-link {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 320, src/assets/scss/modules/_m29x.scss */
      .m29x .coldiv .div-link {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-top: 2px;
        padding: 0; } }
  /* line 336, src/assets/scss/modules/_m29x.scss */
  .m29x .coldiv .div-titlet {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
    height: 95px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 336, src/assets/scss/modules/_m29x.scss */
      .m29x .coldiv .div-titlet {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 60px;
        margin-top: 6px; } }
  /* line 353, src/assets/scss/modules/_m29x.scss */
  .m29x .coldiv .div-textt {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 5px;
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 353, src/assets/scss/modules/_m29x.scss */
      .m29x .coldiv .div-textt {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 20px; } }
  /* line 369, src/assets/scss/modules/_m29x.scss */
  .m29x .coldiv .div-linkt {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 992px) {
      /* line 369, src/assets/scss/modules/_m29x.scss */
      .m29x .coldiv .div-linkt {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
        margin-top: 2px; } }
  @media screen and (max-width: 992px) {
    /* line 276, src/assets/scss/modules/_m29x.scss */
    .m29x .coldiv {
      width: 100%; } }

/* line 389, src/assets/scss/modules/_m29x.scss */
.m29x .imageclass {
  max-width: 230px;
  height: 117px;
  background-size: cover;
  margin-top: 19px;
  margin-bottom: 15.6px;
  margin-left: 0px;
  margin-right: 0px; }
  @media screen and (max-width: 992px) {
    /* line 389, src/assets/scss/modules/_m29x.scss */
    .m29x .imageclass {
      max-width: 313px;
      max-height: 140px;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      background-size: cover; } }

/* line 406, src/assets/scss/modules/_m29x.scss */
.m29x img {
  width: 100%;
  height: auto; }

/* line 410, src/assets/scss/modules/_m29x.scss */
.m29x .publications_slider {
  padding-bottom: 55px;
  padding-top: 20px;
  border-bottom: 1px solid;
  border-color: #dddddd;
  width: 100%;
  margin-bottom: 50px; }
  @media screen and (max-width: 992px) {
    /* line 410, src/assets/scss/modules/_m29x.scss */
    .m29x .publications_slider {
      padding-top: 0;
      padding-bottom: 0px;
      border-bottom: 1px solid #dddddd; } }

/* line 2, src/assets/scss/modules/_m30x.scss */
.m30x h2 {
  font-family: "TiemposHeadline", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  margin-bottom: 15px; }
  /* line 12, src/assets/scss/modules/_m30x.scss */
  .m30x h2.h22 {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #222222; }
    @media screen and (max-width: 992px) {
      /* line 12, src/assets/scss/modules/_m30x.scss */
      .m30x h2.h22 {
        font-size: 20px;
        font-weight: 500; } }
  @media screen and (max-width: 992px) {
    /* line 2, src/assets/scss/modules/_m30x.scss */
    .m30x h2 {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      margin: 0;
      padding: 0; } }

/* line 37, src/assets/scss/modules/_m30x.scss */
.m30x a.buttonlinkw {
  width: 200px;
  height: 45px;
  line-height: 44px;
  border-radius: 22.5px;
  background-color: #fff;
  border: 1px solid #235789;
  text-decoration: none;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #235789;
  text-align: center;
  position: absolute; }
  @media screen and (max-width: 992px) {
    /* line 37, src/assets/scss/modules/_m30x.scss */
    .m30x a.buttonlinkw {
      position: relative;
      padding: 15px 50px; } }

/* line 59, src/assets/scss/modules/_m30x.scss */
.m30x a.adescriptiondiv {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #222222;
  display: table-row;
  text-decoration: none; }
  @media screen and (max-width: 992px) {
    /* line 59, src/assets/scss/modules/_m30x.scss */
    .m30x a.adescriptiondiv {
      font-size: 13px;
      text-align: center; } }

/* line 77, src/assets/scss/modules/_m30x.scss */
.m30x a.ainfodiv {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #9b9b9b;
  display: table-row;
  text-decoration: none; }
  @media screen and (max-width: 992px) {
    /* line 77, src/assets/scss/modules/_m30x.scss */
    .m30x a.ainfodiv {
      font-size: 14px;
      text-align: center; } }

/* line 94, src/assets/scss/modules/_m30x.scss */
.m30x a.pclass2 {
  min-height: 93px;
  margin-right: 0px;
  margin-left: 0%;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.40;
  letter-spacing: normal;
  color: #222222;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 94, src/assets/scss/modules/_m30x.scss */
    .m30x a.pclass2 {
      display: none; } }

/* line 111, src/assets/scss/modules/_m30x.scss */
.m30x a.h22 {
  font-family: "Work Sans", "sans-serif";
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #222222;
  margin-right: 0px;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 111, src/assets/scss/modules/_m30x.scss */
    .m30x a.h22 {
      min-height: 126px;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3; } }

/* line 131, src/assets/scss/modules/_m30x.scss */
.m30x a.h44 {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #235789;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 131, src/assets/scss/modules/_m30x.scss */
    .m30x a.h44 {
      padding: 0;
      margin-top: 1px;
      margin-bottom: 30px;
      margin-left: 0px;
      margin-right: 0px; } }

/* line 149, src/assets/scss/modules/_m30x.scss */
.m30x a.paragraph {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: inline;
  color: #9b9b9b; }
  @media screen and (max-width: 992px) {
    /* line 149, src/assets/scss/modules/_m30x.scss */
    .m30x a.paragraph {
      font-size: 15px;
      margin-left: 0px;
      margin-right: 0px; } }

/* line 165, src/assets/scss/modules/_m30x.scss */
.m30x a.withimagea {
  font-family: "Work Sans", "sans-serif";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  display: inline; }
  @media screen and (max-width: 992px) {
    /* line 165, src/assets/scss/modules/_m30x.scss */
    .m30x a.withimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px; } }

/* line 184, src/assets/scss/modules/_m30x.scss */
.m30x a.withoutimagea {
  margin-left: 15px;
  margin-right: 15px;
  height: 95px;
  font-family: "Work Sans", "sans-serif";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  display: flex;
  margin-bottom: 40px; }
  @media screen and (max-width: 992px) {
    /* line 184, src/assets/scss/modules/_m30x.scss */
    .m30x a.withoutimagea {
      min-height: 60px;
      max-height: 60px;
      font-size: 20px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px; } }

/* line 207, src/assets/scss/modules/_m30x.scss */
.m30x a.fontstyle {
  font-family: "Work Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered; }

/* line 218, src/assets/scss/modules/_m30x.scss */
.m30x a.nav-link {
  padding-left: 0;
  line-height: 44px;
  display: inline;
  margin-right: 75px;
  padding: 0; }

/* line 225, src/assets/scss/modules/_m30x.scss */
.m30x a.linkfont {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000; }

/* line 234, src/assets/scss/modules/_m30x.scss */
.m30x a.linkfont2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222; }

/* line 243, src/assets/scss/modules/_m30x.scss */
.m30x a.accordionmenu {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 252, src/assets/scss/modules/_m30x.scss */
  .m30x a.accordionmenu:first-child {
    color: #235789; }

/* line 256, src/assets/scss/modules/_m30x.scss */
.m30x a.arcodionlink {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000; }

/* line 265, src/assets/scss/modules/_m30x.scss */
.m30x a.linkinsideacc {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #235789;
  margin-left: 6px;
  cursor: pointer; }

/* line 276, src/assets/scss/modules/_m30x.scss */
.m30x a.linksubmenu {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline;
  border-bottom: 1px solid #333333; }

/* line 288, src/assets/scss/modules/_m30x.scss */
.m30x a:hover {
  outline: none;
  text-decoration: none; }

/* line 292, src/assets/scss/modules/_m30x.scss */
.m30x a:focus {
  outline: none;
  text-decoration: none; }

/* line 298, src/assets/scss/modules/_m30x.scss */
.m30x .coldivposition .positiondiv {
  width: 100%;
  height: 20px;
  margin-bottom: 16px; }
  @media screen and (max-width: 992px) {
    /* line 298, src/assets/scss/modules/_m30x.scss */
    .m30x .coldivposition .positiondiv {
      display: none; } }

/* line 307, src/assets/scss/modules/_m30x.scss */
.m30x .coldivposition .titlediv {
  width: 100%;
  min-height: 95px;
  margin-bottom: 13px; }
  @media screen and (max-width: 992px) {
    /* line 307, src/assets/scss/modules/_m30x.scss */
    .m30x .coldivposition .titlediv {
      width: 255px;
      max-height: 90px;
      margin-left: auto;
      margin-right: auto;
      text-align: center; } }

/* line 320, src/assets/scss/modules/_m30x.scss */
.m30x .coldivposition .descriptiondiv {
  width: 100%;
  min-height: 65px;
  margin-bottom: 15px; }
  @media screen and (max-width: 992px) {
    /* line 320, src/assets/scss/modules/_m30x.scss */
    .m30x .coldivposition .descriptiondiv {
      width: 255px;
      max-height: 65px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-bottom: 5px; } }

/* line 334, src/assets/scss/modules/_m30x.scss */
.m30x .coldivposition .infodiv {
  width: 100%;
  min-height: 35px;
  margin-bottom: 16px; }
  @media screen and (max-width: 992px) {
    /* line 334, src/assets/scss/modules/_m30x.scss */
    .m30x .coldivposition .infodiv {
      width: 255px;
      height: 40px;
      margin-left: auto;
      margin-right: auto;
      text-align: center; } }

/* line 347, src/assets/scss/modules/_m30x.scss */
.m30x .coldivposition .buttonbiv {
  width: 100%;
  min-height: 45px;
  margin-bottom: 0px; }
  @media screen and (max-width: 992px) {
    /* line 347, src/assets/scss/modules/_m30x.scss */
    .m30x .coldivposition .buttonbiv {
      text-align: center;
      margin-bottom: 10px; } }

/* line 357, src/assets/scss/modules/_m30x.scss */
.m30x .col-md-4.coldivposition {
  border-right: 1px solid #dddddd; }
  /* line 359, src/assets/scss/modules/_m30x.scss */
  .m30x .col-md-4.coldivposition:last-child {
    border-right: none; }
    @media screen and (max-width: 992px) {
      /* line 359, src/assets/scss/modules/_m30x.scss */
      .m30x .col-md-4.coldivposition:last-child {
        border: none; } }
  @media screen and (max-width: 992px) {
    /* line 357, src/assets/scss/modules/_m30x.scss */
    .m30x .col-md-4.coldivposition {
      border-right: none; } }

/* line 369, src/assets/scss/modules/_m30x.scss */
.m30x .publications_slider {
  margin-top: 15px;
  margin-bottom: 100px; }
  @media screen and (max-width: 992px) {
    /* line 369, src/assets/scss/modules/_m30x.scss */
    .m30x .publications_slider {
      margin-top: 40px;
      padding-bottom: 45px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 40px; } }

/* line 379, src/assets/scss/modules/_m30x.scss */
.m30x .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 30px 0;
  list-style-type: none; }
  /* line 388, src/assets/scss/modules/_m30x.scss */
  .m30x .slick-dots li {
    margin: 0 0.25rem; }
  /* line 392, src/assets/scss/modules/_m30x.scss */
  .m30x .slick-dots button {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #dddddd;
    text-indent: -9999px; }
  /* line 404, src/assets/scss/modules/_m30x.scss */
  .m30x .slick-dots li.slick-active button {
    background-color: #192830; }

/* line 3, src/assets/scss/modules/_m31.scss */
.m31 h1 {
  margin-bottom: 18px; }

/* line 6, src/assets/scss/modules/_m31.scss */
.m31 h2 {
  margin-bottom: 35px; }

/* line 9, src/assets/scss/modules/_m31.scss */
.m31 h3 {
  margin-bottom: 20px; }

/* line 12, src/assets/scss/modules/_m31.scss */
.m31 h4 {
  color: #1d2114; }

/* line 15, src/assets/scss/modules/_m31.scss */
.m31 .fontstyle {
  font-family: "TiemposText", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #333333;
  border-bottom: 1px solid orangered;
  text-decoration: none; }

/* line 27, src/assets/scss/modules/_m31.scss */
.m31 ul {
  /* https://www.w3schools.com/howto/howto_css_bullet_color.asp */
  list-style: none;
  /* Remove default bullets */
  padding-left: 20px; }
  /* line 31, src/assets/scss/modules/_m31.scss */
  .m31 ul li::before {
    content: "\2022";
    /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #ff6b35;
    /* Change the color */
    font-weight: bold;
    /* If you want it to be bold */
    display: inline-block;
    /* Needed to add space between the bullet and the text */
    width: 1em;
    /* Also needed for space (tweak if needed) */
    margin-left: -1em;
    /* Also needed for space (tweak if needed) */
    margin-top: 10px; }

/* line 41, src/assets/scss/modules/_m31.scss */
.m31 .div-button {
  width: 182px;
  /* margin-left: 40px; */
  z-index: 999;
  display: flex;
  margin-bottom: 30px; }

/* line 48, src/assets/scss/modules/_m31.scss */
.m31 .orange-button {
  width: 180px;
  height: 45px;
  line-height: 44px;
  border-radius: 22.5px;
  background-color: #ff6b35;
  /* border: #ff6b35; */
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #fff !important;
  text-align: center;
  text-decoration: none !important; }
  /* line 64, src/assets/scss/modules/_m31.scss */
  .m31 .orange-button:hover {
    opacity: 0.9;
    text-decoration: underline !important; }

/* line 69, src/assets/scss/modules/_m31.scss */
.m31 .white-button {
  width: 180px;
  height: 45px;
  line-height: 44px;
  border-radius: 22.5px;
  background-color: #fff;
  border-style: solid;
  /* border: #ff6b35; */
  border-width: thin;
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #ff6b35 !important;
  text-align: center;
  text-decoration: none !important; }
  /* line 87, src/assets/scss/modules/_m31.scss */
  .m31 .white-button:hover {
    opacity: 0.9;
    text-decoration: underline !important; }

/* line 92, src/assets/scss/modules/_m31.scss */
.m31 .overpay {
  font-size: 12px; }

/* line 95, src/assets/scss/modules/_m31.scss */
.m31 .benefit {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 0px;
  padding-right: 0px; }
  /* line 100, src/assets/scss/modules/_m31.scss */
  .m31 .benefit img {
    width: 45px;
    margin-right: 15px; }

/* line 105, src/assets/scss/modules/_m31.scss */
.m31 .modal_background {
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-x: hidden; }

/* line 116, src/assets/scss/modules/_m31.scss */
.m31 .modal_box {
  background-color: #fff;
  margin: 10% auto;
  width: 30%;
  padding-top: 15px;
  padding-bottom: 25px;
  border-radius: 5px; }

/* line 124, src/assets/scss/modules/_m31.scss */
.m31 .close_button {
  color: #ccc;
  float: right;
  font-size: 30px;
  margin-right: 10px; }

/* line 130, src/assets/scss/modules/_m31.scss */
.m31 .close_button:hover, .m31 .close_button:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer; }

/* line 135, src/assets/scss/modules/_m31.scss */
.m31 .share_icon_row {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-around; }
  /* line 140, src/assets/scss/modules/_m31.scss */
  .m31 .share_icon_row img {
    height: 40px; }

/* line 144, src/assets/scss/modules/_m31.scss */
.m31 .share_header {
  margin-left: 20px;
  padding-top: 8px; }

@media screen and (max-width: 992px) {
  /* line 149, src/assets/scss/modules/_m31.scss */
  .m31 .modal_box {
    width: 82%;
    margin: 40% auto; }
  /* line 153, src/assets/scss/modules/_m31.scss */
  .m31 .button-col {
    display: flex;
    justify-content: center; } }

@media screen and (max-width: 992px) {
  /* line 160, src/assets/scss/modules/_m31.scss */
  .main-content {
    padding-bottom: 0px; }
  /* line 163, src/assets/scss/modules/_m31.scss */
  .share-symbols {
    display: flex;
    justify-content: space-around; } }

@media screen and (max-width: 992px) {
  /* line 1, src/assets/scss/modules/_pag.scss */
  .pag {
    display: none; } }

/* line 5, src/assets/scss/modules/_pag.scss */
.pag .pagination {
  height: 40px;
  display: inline-block;
  border: 1px solid #dddddd;
  float: right; }
  /* line 10, src/assets/scss/modules/_pag.scss */
  .pag .pagination a {
    font-family: "Work Sans", "sans-serif";
    color: black;
    float: left;
    text-decoration: none;
    width: 34px;
    line-height: 38px;
    border: 0 !important;
    text-align: center; }
    /* line 19, src/assets/scss/modules/_pag.scss */
    .pag .pagination a.active {
      background-color: #dddddd;
      border: 1px solid #dddddd;
      cursor: default; }
    /* line 24, src/assets/scss/modules/_pag.scss */
    .pag .pagination a:hover:not(.active) {
      background-color: #fff; }
    /* line 27, src/assets/scss/modules/_pag.scss */
    .pag .pagination a:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px; }
    /* line 31, src/assets/scss/modules/_pag.scss */
    .pag .pagination a:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; }

/* line 37, src/assets/scss/modules/_pag.scss */
.pag .divpagenation {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 50px;
  position: relative; }

/* line 45, src/assets/scss/modules/_pag.scss */
.pag a.arrowpg {
  color: #235789;
  font-size: 28px;
  width: 40px;
  line-height: 35px; }

/* line 53, src/assets/scss/modules/_pag.scss */
.show-more-button {
  text-align: center; }
  @media screen and (min-width: 993px) {
    /* line 53, src/assets/scss/modules/_pag.scss */
    .show-more-button {
      display: none; } }

/* line 2, src/assets/scss/modules/_breadcrumbs.scss */
.breadcrumbs .border-bottom {
  padding-top: 30px;
  padding-bottom: 19px; }

/* line 6, src/assets/scss/modules/_breadcrumbs.scss */
.breadcrumbs a {
  font-family: "Work Sans", "sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a; }
  /* line 15, src/assets/scss/modules/_breadcrumbs.scss */
  .breadcrumbs a:first-child {
    color: #235789; }

/* line 19, src/assets/scss/modules/_breadcrumbs.scss */
.breadcrumbs .triangle {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
  transform: rotate(315deg);
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block; }

/* line 32, src/assets/scss/modules/_breadcrumbs.scss */
.breadcrumbs a.accordionmenu:hover {
  color: #235789; }

/* line 35, src/assets/scss/modules/_breadcrumbs.scss */
.breadcrumbs .divaccordionspace {
  padding-left: 0; }

@media screen and (max-width: 992px) {
  /* line 1, src/assets/scss/modules/_breadcrumbs.scss */
  .breadcrumbs {
    display: none; } }

/* line 1, src/assets/scss/modules/_backtotop.scss */
.back-to-top {
  position: fixed;
  bottom: 2em;
  right: -10px;
  text-decoration: none;
  color: #000000;
  font-size: 12px;
  display: none;
  width: 174px;
  height: 71px;
  border-radius: 8px;
  padding-right: 55px;
  text-align: center;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 99999999; }
  @media screen and (max-width: 767px) {
    /* line 1, src/assets/scss/modules/_backtotop.scss */
    .back-to-top {
      display: none;
      width: 50px;
      height: 40px; } }
  /* line 22, src/assets/scss/modules/_backtotop.scss */
  .back-to-top .backtp {
    font-family: "TiemposText", sans-serif;
    font-size: 16px;
    color: #1d2114;
    cursor: pointer;
    text-align: center;
    margin-top: -10px; }
    @media screen and (max-width: 767px) {
      /* line 22, src/assets/scss/modules/_backtotop.scss */
      .back-to-top .backtp {
        display: none; } }
  /* line 33, src/assets/scss/modules/_backtotop.scss */
  .back-to-top img {
    cursor: pointer; }

/* line 1, src/assets/scss/modules/_sidebar01x.scss */
.sidebar01x {
  max-width: 210px;
  margin-left: auto;
  border-top: 1px solid #ddd; }
  @media screen and (max-width: 992px) {
    /* line 1, src/assets/scss/modules/_sidebar01x.scss */
    .sidebar01x {
      display: none; } }
  /* line 9, src/assets/scss/modules/_sidebar01x.scss */
  .sidebar01x ul li {
    padding: 20px 0;
    border-bottom: 1px solid #dddddd;
    position: relative; }
    /* line 13, src/assets/scss/modules/_sidebar01x.scss */
    .sidebar01x ul li a {
      font-family: "TiemposText", sans-serif;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: normal;
      color: #000000;
      padding: 0; }
      /* line 20, src/assets/scss/modules/_sidebar01x.scss */
      .sidebar01x ul li a:hover {
        font-weight: 600; }
      /* line 23, src/assets/scss/modules/_sidebar01x.scss */
      .sidebar01x ul li a.active {
        font-weight: 600; }
      /* line 27, src/assets/scss/modules/_sidebar01x.scss */
      .sidebar01x ul li a.expand:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 17px;
        top: 24px;
        border-right: 1px solid #222222;
        border-bottom: 1px solid #222222;
        transform: rotate(45deg); }
      /* line 40, src/assets/scss/modules/_sidebar01x.scss */
      .sidebar01x ul li a.expand[aria-expanded="true"]:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 17px;
        border-right: 1px solid #222;
        border-bottom: 1px solid #222;
        transform: rotate(225deg);
        top: 27px; }
    /* line 53, src/assets/scss/modules/_sidebar01x.scss */
    .sidebar01x ul li ul {
      border-top: 1px solid #ddd; }
      /* line 55, src/assets/scss/modules/_sidebar01x.scss */
      .sidebar01x ul li ul li {
        border: 0;
        padding: 7px 0; }
        /* line 58, src/assets/scss/modules/_sidebar01x.scss */
        .sidebar01x ul li ul li a {
          font-family: "Work Sans", "sans-serif";
          font-size: 14px;
          font-weight: 400;
          line-height: 1.29;
          color: #222222;
          margin-left: 10px; }
        /* line 67, src/assets/scss/modules/_sidebar01x.scss */
        .sidebar01x ul li ul li:first-of-type a {
          margin-top: 20px; }
        /* line 72, src/assets/scss/modules/_sidebar01x.scss */
        .sidebar01x ul li ul li:last-of-type a {
          margin-bottom: 20px; }
  /* line 80, src/assets/scss/modules/_sidebar01x.scss */
  .sidebar01x a:hover {
    outline: none;
    text-decoration: none; }
  /* line 84, src/assets/scss/modules/_sidebar01x.scss */
  .sidebar01x a:focus {
    outline: none;
    text-decoration: none; }

/* line 90, src/assets/scss/modules/_sidebar01x.scss */
.sidebar01x-dropdown {
  margin-bottom: 30px;
  border-top: 1px solid #ddd;
  position: relative; }
  @media screen and (min-width: 768px) {
    /* line 90, src/assets/scss/modules/_sidebar01x.scss */
    .sidebar01x-dropdown {
      display: none; } }
  /* line 98, src/assets/scss/modules/_sidebar01x.scss */
  .sidebar01x-dropdown ul li {
    line-height: 60px;
    border-bottom: 1px solid #dddddd;
    position: relative; }
    /* line 102, src/assets/scss/modules/_sidebar01x.scss */
    .sidebar01x-dropdown ul li a {
      font-family: "TiemposText", sans-serif;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: normal;
      color: #000000;
      padding: 0; }
      /* line 109, src/assets/scss/modules/_sidebar01x.scss */
      .sidebar01x-dropdown ul li a:hover {
        font-weight: 600; }
      /* line 112, src/assets/scss/modules/_sidebar01x.scss */
      .sidebar01x-dropdown ul li a.active {
        font-weight: 600; }
      /* line 116, src/assets/scss/modules/_sidebar01x.scss */
      .sidebar01x-dropdown ul li a.expand:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 17px;
        top: 24px;
        border-right: 1px solid #222222;
        border-bottom: 1px solid #222222;
        transform: rotate(45deg); }
      /* line 129, src/assets/scss/modules/_sidebar01x.scss */
      .sidebar01x-dropdown ul li a.expand[aria-expanded="true"]:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 17px;
        border-right: 1px solid #222;
        border-bottom: 1px solid #222;
        transform: rotate(225deg);
        top: 27px; }
    /* line 142, src/assets/scss/modules/_sidebar01x.scss */
    .sidebar01x-dropdown ul li ul {
      border-top: 1px solid #ddd; }
      /* line 144, src/assets/scss/modules/_sidebar01x.scss */
      .sidebar01x-dropdown ul li ul li {
        border: 0;
        padding: 7px 0; }
        /* line 147, src/assets/scss/modules/_sidebar01x.scss */
        .sidebar01x-dropdown ul li ul li a {
          font-family: "Work Sans", "sans-serif";
          font-size: 14px;
          font-weight: 400;
          line-height: 1.29;
          color: #222222;
          margin-left: 10px; }
        /* line 156, src/assets/scss/modules/_sidebar01x.scss */
        .sidebar01x-dropdown ul li ul li:first-of-type a {
          margin-top: 20px; }
        /* line 161, src/assets/scss/modules/_sidebar01x.scss */
        .sidebar01x-dropdown ul li ul li:last-of-type a {
          margin-bottom: 20px; }
  /* line 169, src/assets/scss/modules/_sidebar01x.scss */
  .sidebar01x-dropdown a:hover {
    outline: none;
    text-decoration: none; }
  /* line 173, src/assets/scss/modules/_sidebar01x.scss */
  .sidebar01x-dropdown a:focus {
    outline: none;
    text-decoration: none; }

/* line 1, src/assets/scss/modules/_sidebar02x.scss */
.sidebar02x {
  max-width: 210px;
  margin-left: auto;
  margin-top: 50px;
  border-radius: 3px;
  background-color: #e4e5e7;
  padding: 16px 18px; }
  @media screen and (max-width: 992px) {
    /* line 1, src/assets/scss/modules/_sidebar02x.scss */
    .sidebar02x {
      margin-top: -80px;
      width: 100%;
      margin-bottom: 100px;
      max-width: 100%;
      margin-left: 0; } }
  /* line 15, src/assets/scss/modules/_sidebar02x.scss */
  .sidebar02x p {
    font-family: "TiemposHeadline", sans-serif;
    line-height: 1.25;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #333333;
    padding-bottom: 15px; }
  /* line 28, src/assets/scss/modules/_sidebar02x.scss */
  .sidebar02x ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    /* line 32, src/assets/scss/modules/_sidebar02x.scss */
    .sidebar02x ul li {
      min-height: 30px;
      padding-left: 44px;
      position: relative; }
      @media screen and (max-width: 992px) {
        /* line 32, src/assets/scss/modules/_sidebar02x.scss */
        .sidebar02x ul li {
          padding-left: 30px; } }
      /* line 39, src/assets/scss/modules/_sidebar02x.scss */
      .sidebar02x ul li:before {
        content: " ";
        background-image: url(/assets/img/icons/arrow_back.svg);
        background-size: 30px 30px;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 10px;
        transform: rotate(180deg); }
        @media screen and (max-width: 992px) {
          /* line 39, src/assets/scss/modules/_sidebar02x.scss */
          .sidebar02x ul li:before {
            left: 0; } }
      /* line 52, src/assets/scss/modules/_sidebar02x.scss */
      .sidebar02x ul li a {
        font-size: 14px;
        color: #333333;
        display: inline;
        border-bottom: 1px solid #333333; }
        /* line 57, src/assets/scss/modules/_sidebar02x.scss */
        .sidebar02x ul li a:hover {
          text-decoration: none; }

/* line 1, src/assets/scss/modules/_sidebar02x1.scss */
.sidebar02x1 {
  margin-left: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 3px;
  background-color: #e4e5e7;
  padding: 16px 18px; }
  @media screen and (max-width: 992px) {
    /* line 1, src/assets/scss/modules/_sidebar02x1.scss */
    .sidebar02x1 {
      width: 100%;
      max-width: 100%;
      margin-left: 0; } }
  /* line 13, src/assets/scss/modules/_sidebar02x1.scss */
  .sidebar02x1 p {
    font-family: "TiemposHeadline", sans-serif;
    line-height: 1.25;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #333333;
    padding-bottom: 15px; }
  /* line 26, src/assets/scss/modules/_sidebar02x1.scss */
  .sidebar02x1 ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    /* line 30, src/assets/scss/modules/_sidebar02x1.scss */
    .sidebar02x1 ul li {
      min-height: 30px;
      padding-left: 44px;
      position: relative; }
      @media screen and (max-width: 992px) {
        /* line 30, src/assets/scss/modules/_sidebar02x1.scss */
        .sidebar02x1 ul li {
          padding-left: 30px; } }
      /* line 37, src/assets/scss/modules/_sidebar02x1.scss */
      .sidebar02x1 ul li:before {
        content: " ";
        background-image: url(/assets/img/icons/arrow_back.svg);
        background-size: 30px 30px;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 10px;
        transform: rotate(180deg); }
        @media screen and (max-width: 992px) {
          /* line 37, src/assets/scss/modules/_sidebar02x1.scss */
          .sidebar02x1 ul li:before {
            left: 0; } }
      /* line 50, src/assets/scss/modules/_sidebar02x1.scss */
      .sidebar02x1 ul li a {
        font-size: 14px;
        color: #333333;
        display: inline;
        border-bottom: 1px solid #333333; }
        /* line 55, src/assets/scss/modules/_sidebar02x1.scss */
        .sidebar02x1 ul li a:hover {
          text-decoration: none; }

/* line 1, src/assets/scss/modules/_sidebar03x.scss */
.sidebar03x {
  max-width: 210px;
  margin-left: auto;
  margin-top: 50px;
  border-radius: 3px;
  background-color: #e4e5e7;
  padding: 16px 18px; }
  @media screen and (max-width: 992px) {
    /* line 1, src/assets/scss/modules/_sidebar03x.scss */
    .sidebar03x {
      margin-top: -80px;
      width: 100%;
      margin-bottom: 0;
      max-width: 100%;
      margin-left: 0; } }
  /* line 15, src/assets/scss/modules/_sidebar03x.scss */
  .sidebar03x p {
    font-size: 14px;
    line-height: 1.29;
    color: #333333; }
    /* line 19, src/assets/scss/modules/_sidebar03x.scss */
    .sidebar03x p.mainp {
      font-family: "TiemposHeadline", sans-serif;
      line-height: 1.25;
      text-align: left;
      border-bottom: 1px solid #ddd;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #333333;
      padding-bottom: 15px; }

/* line 1, src/assets/scss/modules/_sidebar04x.scss */
.sidebar04x {
  max-width: 210px;
  margin-left: auto;
  margin-top: 50px;
  border-radius: 3px;
  border: 1px solid #e4e5e7;
  padding: 16px 18px; }
  @media screen and (max-width: 992px) {
    /* line 1, src/assets/scss/modules/_sidebar04x.scss */
    .sidebar04x {
      margin-top: 20px;
      width: 100%;
      margin-bottom: 0;
      max-width: 100%;
      margin-left: 0; } }
  /* line 15, src/assets/scss/modules/_sidebar04x.scss */
  .sidebar04x p {
    font-size: 14px;
    line-height: 1.29;
    color: #333333;
    word-break: break-word; }
    /* line 20, src/assets/scss/modules/_sidebar04x.scss */
    .sidebar04x p.mainp {
      font-family: "TiemposHeadline", sans-serif;
      line-height: 1.25;
      text-align: left;
      border-bottom: 1px solid #ddd;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #333333;
      padding-bottom: 15px; }

/* line 1, src/assets/scss/modules/_sidebar05x.scss */
.sidebar05x {
  max-width: 210px;
  margin-left: auto;
  margin-top: 50px;
  border-radius: 3px;
  border: 1px solid #e4e5e7;
  padding: 16px 18px; }
  @media screen and (max-width: 992px) {
    /* line 1, src/assets/scss/modules/_sidebar05x.scss */
    .sidebar05x {
      margin-top: 20px;
      width: 100%;
      margin-bottom: 0;
      max-width: 100%;
      margin-left: 0; } }
  /* line 15, src/assets/scss/modules/_sidebar05x.scss */
  .sidebar05x p {
    font-family: "TiemposHeadline", sans-serif;
    line-height: 1.25;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #333333;
    padding-bottom: 15px; }
  /* line 28, src/assets/scss/modules/_sidebar05x.scss */
  .sidebar05x ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    /* line 32, src/assets/scss/modules/_sidebar05x.scss */
    .sidebar05x ul li {
      min-height: 30px;
      padding-left: 44px;
      position: relative; }
      /* line 36, src/assets/scss/modules/_sidebar05x.scss */
      .sidebar05x ul li:before {
        content: " ";
        background-image: url(/assets/img/icons/arrow_back.svg);
        background-size: 30px 30px;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 10px;
        transform: rotate(180deg); }
      /* line 46, src/assets/scss/modules/_sidebar05x.scss */
      .sidebar05x ul li a {
        font-size: 14px;
        color: #333333;
        display: inline;
        border-bottom: 1px solid #333333; }
        /* line 51, src/assets/scss/modules/_sidebar05x.scss */
        .sidebar05x ul li a:hover {
          text-decoration: none; }

/* line 1, src/assets/scss/modules/_sidebar06x.scss */
.sidebar06x {
  max-width: 210px;
  margin-left: auto;
  margin-top: 50px;
  border-radius: 3px;
  padding: 16px 18px; }
  @media screen and (max-width: 992px) {
    /* line 1, src/assets/scss/modules/_sidebar06x.scss */
    .sidebar06x {
      margin-top: 20px;
      width: 100%;
      margin-bottom: 100px;
      max-width: 100%;
      margin-left: 0; } }
  /* line 14, src/assets/scss/modules/_sidebar06x.scss */
  .sidebar06x p {
    font-family: "TiemposHeadline", sans-serif;
    line-height: 1.25;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #333333;
    padding-bottom: 15px;
    margin-bottom: 30px; }
  /* line 28, src/assets/scss/modules/_sidebar06x.scss */
  .sidebar06x .divimg {
    width: 20px;
    height: 28px;
    margin: 0 8px;
    display: inline-block; }
    @media screen and (max-width: 992px) {
      /* line 28, src/assets/scss/modules/_sidebar06x.scss */
      .sidebar06x .divimg {
        width: 20px;
        height: 20px;
        margin: 0 8px;
        display: inline-block; } }

/* line 1, src/assets/scss/modules/_overlay.scss */
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden; }
  /* line 10, src/assets/scss/modules/_overlay.scss */
  .overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s; }
  /* line 18, src/assets/scss/modules/_overlay.scss */
  .overlay a:hover, .overlay a:focus {
    color: #f1f1f1; }
  /* line 21, src/assets/scss/modules/_overlay.scss */
  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px; }
    @media screen and (max-width: 767px) {
      /* line 21, src/assets/scss/modules/_overlay.scss */
      .overlay .closebtn {
        top: 5px;
        right: 20px;
        font-weight: 300;
        font-size: 29px;
        color: #333333;
        padding: 0;
        z-index: 99999999; } }

@media screen and (max-height: 450px) {
  /* line 39, src/assets/scss/modules/_overlay.scss */
  .overlay a {
    font-size: 20px; }
  /* line 42, src/assets/scss/modules/_overlay.scss */
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px; } }

/* line 50, src/assets/scss/modules/_overlay.scss */
.m-overlay h1 {
  margin-top: 100px;
  margin-bottom: 56px;
  font-size: 36px;
  font-family: "TiemposHeadline", sans-serif; }

/* line 56, src/assets/scss/modules/_overlay.scss */
.m-overlay h2 {
  font-family: "TiemposText", sans-serif; }

/* line 59, src/assets/scss/modules/_overlay.scss */
.m-overlay .searchnews {
  margin-bottom: 34px;
  width: 100%;
  height: 50px;
  border: 1px solid #dddddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-position: right 30px center;
  background-repeat: no-repeat;
  padding-left: 20px;
  padding-right: 40px; }
  @media screen and (max-width: 767px) {
    /* line 59, src/assets/scss/modules/_overlay.scss */
    .m-overlay .searchnews {
      background-position: right 10px center; } }

@media screen and (max-width: 767px) {
  /* line 78, src/assets/scss/modules/_overlay.scss */
  .m-overlay .m26x .classborder:nth-child(even) {
    border-right: 0; } }

@media screen and (max-width: 992px) {
  /* line 83, src/assets/scss/modules/_overlay.scss */
  .m-overlay .m26x .coldiv .div-text {
    display: -webkit-box;
    margin: 0 !important; }
  /* line 87, src/assets/scss/modules/_overlay.scss */
  .m-overlay .m26x a.pclass2 {
    display: block; }
  /* line 90, src/assets/scss/modules/_overlay.scss */
  .m-overlay .m26x .coldiv .div-title {
    margin-top: 15px;
    margin-bottom: 5px;
    height: 140px; }
  /* line 95, src/assets/scss/modules/_overlay.scss */
  .m-overlay .m26x .coldiv .div-link {
    margin-top: 10px; } }

/* line 100, src/assets/scss/modules/_overlay.scss */
.m-overlay .person-box {
  border-right: 1px solid #dddddd; }
  @media screen and (max-width: 767px) {
    /* line 100, src/assets/scss/modules/_overlay.scss */
    .m-overlay .person-box {
      border-right: 0;
      border-bottom: 1px solid #ddd; } }
  /* line 106, src/assets/scss/modules/_overlay.scss */
  .m-overlay .person-box:last-child {
    border: none; }

/* line 110, src/assets/scss/modules/_overlay.scss */
.m-overlay .show-all {
  border-radius: 22.5px;
  border: solid 1px #235789;
  font-size: 14px;
  font-weight: 600;
  color: #235789;
  padding: 15px 62px;
  margin: 0 auto;
  max-width: 200px; }
  /* line 119, src/assets/scss/modules/_overlay.scss */
  .m-overlay .show-all:hover {
    text-decoration: none; }

@media screen and (max-width: 767px) {
  /* line 123, src/assets/scss/modules/_overlay.scss */
  .m-overlay .desktop-only {
    display: none; } }

/* line 129, src/assets/scss/modules/_overlay.scss */
.m-overlay .pop-search h2 {
  margin-top: 120px;
  margin-bottom: 65px; }
  @media screen and (max-width: 767px) {
    /* line 129, src/assets/scss/modules/_overlay.scss */
    .m-overlay .pop-search h2 {
      margin-top: 50px; } }

/* line 136, src/assets/scss/modules/_overlay.scss */
.m-overlay .pop-search a {
  color: #999999;
  display: block;
  margin-bottom: 35px; }
  /* line 140, src/assets/scss/modules/_overlay.scss */
  .m-overlay .pop-search a:hover {
    text-decoration: none; }

/* line 3, src/assets/scss/_gutenberg_overrides.scss */
.wp-block-file__button {
  background: #e67800 !important; }

/* line 9, src/assets/scss/_gutenberg_overrides.scss */
.wp-block-image img {
  height: auto; }
