.sidebar03x {
  max-width: 210px;
  margin-left: auto;
  margin-top: 50px;
  border-radius: 3px;
  background-color: $othercol;
  padding: 16px 18px;
  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: -80px;
    width: 100%;
    margin-bottom: 0;
    max-width: 100%;
    margin-left: 0;
  }
  p {
    font-size: 14px;
    line-height: 1.29;
    color: #333333;
    &.mainp {
      font-family: $tiempos-headline-medium;
      line-height: 1.25;
      text-align: left;
      border-bottom: 1px solid #ddd;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #333333;
      padding-bottom: 15px;
    }
  }
}