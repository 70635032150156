.m27x {
  h1 {
    font-family: $tiempos-headline-medium;
    font-size: 36px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: justify;
    color: $onyx;
    margin-bottom: 9px;
    @media screen and (max-width: $mobile-breakpoint) {
      text-align: left;
      margin: 0;
      font-size: 30px;
    }
  }
  h2 {
    font-family: $tiempos-headline-medium;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: $fbgdark;

    &.h22 {
      width: 100%;
      margin: 0;
      padding: 0;
      font-size: 24px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $fbgdark;
      margin-left: 15px;
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 20px;
        margin-left: 15px;
        padding: 0;
        width: 50%;
      }
    }
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }
  h6 {
    &.firstclass {
      font-family: $WorkSans;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $onyx;
      margin-left: 15px;
      @media screen and (max-width: $mobile-breakpoint) {
        margin: 0;
        position: absolute;
        right: 15px;
        top: 20px;
      }
    }
    &.secondclass {
      font-family: $WorkSans;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $onyx;
    }
  }
  p {
    font-family: $WorkSans;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: $onyx;
    padding-bottom: 51px;
    margin-bottom: 0px;
    &:first-child {
      margin-bottom: 30px;
      padding-bottom: 0px;
    }
    &.ptext {
      font-family: $WorkSans;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $istblack;
    }
    &.ptext12 {
      font-family: $WorkSans;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $fbgdark;
    }
    &.parccodion {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: $onyx;
      margin-left: 55px;
      padding: 0;
      margin-bottom: 18px;
      &:first-child {
        padding-left: 55px;
        padding-top: 28px;
      }
      &:last-child {
        padding-bottom: 30px;
      }
    }
    &.psubmenu {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $black;
      padding-bottom: 20px;
      margin-bottom: 0;
      margin-left: 20px;
      margin-top: 20px;
      margin-right: 20px;
      text-align: center;
      border-bottom: 1px solid $brdgrey;
    }
  }
  ul {
    padding-right: 15px;
  }

  ol li {
    list-style-type: decimal-leading-zero;
    color: orangered;
  }
  a {
    &.arrowpg {
      color: $dark-blue;
      font-size: 18px;
      padding-top: 0;
      padding-bottom: 0;
    }
    &.h44 {
      font-family: $WorkSans;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $dark-blue;
      display: inline;
      @media screen and (max-width: $mobile-breakpoint) {
        padding: 0;
        margin-top: 1px;
        margin-bottom: 30px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    &.paragraph {
      font-family: $WorkSans;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      display: inline;
      color: $grey;
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 15px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    &.withimagea {
      font-family: $tiempos-headline-medium;
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $fbgdark;
      display: inline;
      @media screen and (max-width: $mobile-breakpoint) {
        min-height: 60px;
        max-height: 60px;
        font-size: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
      }
    }
    &.withoutimagea {
      margin-left: 15px;
      margin-right: 15px;
      height: 95px;
      font-family: $WorkSans;
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $fbgdark;
      display: flex;
      margin-bottom: 40px;
      @media screen and (max-width: $mobile-breakpoint) {
        min-height: 60px;
        max-height: 60px;
        font-size: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
      }
    }
    &.fontstyle {
      font-family: $WorkSans;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.5;
      letter-spacing: normal;
      color: $onyx;
      border-bottom: 1px solid orangered;
    }
    &.nav-link {
      padding-left: 0;
      line-height: 44px;
      display: inline;
      margin-right: 45px;
      padding: 0;
    }
    &.linkfont {
      font-family: $TiemposText;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $black;
    }
    &.linkfont2 {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $fbgdark;
    }
    &.accordionmenu {
      font-family: $WorkSans;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $greyb;
      &:first-child {
        color: $dark-blue;
      }
    }
    &.arcodionlink {
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.11;
      letter-spacing: normal;
      color: $black;
    }
    &.linkinsideacc {
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: $dark-blue;
      margin-left: 6px;
      cursor: pointer;
    }
    &.linksubmenu {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $onyx;
      display: inline;
      border-bottom: 1px solid $onyx;
    }
  }
  a:hover {
    outline: none;
    text-decoration: none;
  }
  a:focus {
    outline: none;
    text-decoration: none;
  }
  .col-md-3.d-flex.justify-content-end {
    padding: 0;
  }
  ul li {
    &.nav-item {
      line-height: 44px;
      text-align: left;
      border-bottom: 1px solid $brdgrey;
    }
    &.nav-item:active {
      line-height: 44px;
      text-align: left;
      border-bottom: 1px solid $brdgrey;
    }
    &.lit {
      padding-left: 12px;
    }
  }
  li.dropdown:hover > .dropdown-menu {
    display: block;
  }
  a.nav-link.linkfont2 {
    //padding-left: 15px;
    margin: 0;
  }
  a.nav-link.linkfont2:hover {
    font-weight: 600;
  }
  ul {
    .collapse.list-unstyled {
      padding: 0;
      border-top: 1px solid $brdgrey;
    }
  }
  .triangle {
    content: "";
    width: 5px;
    height: 5px;
    border-right: 1px solid $fbgdark;
    border-bottom: 1px solid $fbgdark;
    transform: rotate(315deg);
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
  .divaccordionspace {
    margin-top: 30px;
    margin-bottom: 19px
  }
  .divborder {
    border-top: 1px solid $brdgrey;
    margin: 0px;
    padding: 0px;
  }
  .divborderbottom {
    border-bottom: 1px solid $brdgrey;
    margin-bottom: 10px;
  }
  ul.nav.flex-column {
    padding-right: 0;
  }
  .backtp {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $istblack;
    margin: 0 auto;
    width: 100%;
    cursor: pointer;
    text-align: center;
  }
  .arrow {
    content: "";
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 10px;
    height: 10px;
    border-right: 2px solid $fbgdark;
    border-bottom: 2px solid $fbgdark;
    transform: rotate(225deg);
    cursor: pointer;
    margin-bottom: 55px;
  }

  .nav-link {

    &.expand {
      &:after {

        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 17px;
        border-right: 1px solid $fbgdark;
        border-bottom: 1px solid $fbgdark;
        transform: rotate(45deg);
        top: 16px;
      }
    }
    &.expand[aria-expanded=true] {
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 17px;
        border-right: 1px solid $fbgdark;
        border-bottom: 1px solid $fbgdark;
        transform: rotate(225deg);
        top: 22px;
      }
    }
  }

  .nav-item {
    &.show {
      .nav-link.linkfont.expand {
        &:after {

          content: "";
          width: 10px;
          height: 10px;
          position: absolute;
          right: 2px;
          border-right: 1px solid $fbgdark;
          border-bottom: 1px solid $fbgdark;
          transform: rotate(225deg);
          top: 16px;
        }
      }
    }
  }
  ul.nav.flex-column.divborder {
    margin-right: 15px;
  }
  a.accordionmenu:hover {
    color: $dark-blue;
  }
  ////////////////////////////

  .rectanglemod {
    padding: 10px;
    max-width: 350px;
    border: 1px solid;
    border-color: $brdgrey;
    position: relative;
    border-top: 3px solid black;
    margin-top: 15px;
    margin-bottom: 15px;
    @media screen and (max-width: $mobile-breakpoint) {
      max-width: 100%;
      padding: 15px;
      margin: 0;
      border: none;
      border: 1px solid $brdgrey;
      border-top: 3px solid black;
      margin-bottom: 20px;
    }
  }
  .rectanglerwo {
    width: 24px;
    height: 24px;
    position: absolute;
    border: none;

    z-index: 999;
    right: -1px;
    bottom: -1px;
    @media screen and (max-width: $mobile-breakpoint) {
    }
  }
  .digonalline {
    width: 24px
  }
  .digonalline {
    background: linear-gradient(-45deg, $white 49%, $brdgrey 52%, $white 51%);
  }
  .publications_slider {
    padding-top: 20px;
    border-bottom: 1px solid;
    border-color: $brdgrey;
    width: 100%;
    margin-bottom: 50px;
    @media screen and (max-width: $mobile-breakpoint) {
      padding-top: 0;
      padding-bottom: 0px;
      border-bottom: 1px solid $brdgrey;
    }
  }
  .imageclass {
    max-width: 230px;
    height: 150px;
    background-size: cover;
    margin-bottom: 15.6px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: $mobile-breakpoint+1) and (max-width: 1199px) {
      height: 124px;
    }
    @media screen and (max-width: $mobile-breakpoint) {
      max-width: 313px;
      padding: 0;
      background-size: cover;
      max-height: initial;
      height: initial;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
  .coldiv {
    width: 320px;
    .div-title {
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 35px;
      height: 95px;
      overflow: hidden;
      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        min-height: 60px;
        margin-top: 6px;
        padding: 0;
      }
    }
    .div-text {
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 5px;
      height: 45px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      /*text-overflow: ellipsis;
      overflow: hidden;
      width: 240px;
       height: 45px;
      white-space: nowrap;*/
      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 20px;
        margin-top: 10px;
        padding: 0;
      }
    }
    .div-link {
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 0px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-top: 2px;
        padding: 0;
      }
    }
    .div-titlet {
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 0px;
      height: 95px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 60px;
        margin-top: 6px;
      }
    }
    .div-textt {
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 5px;
      height: 45px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        height: 20px;
      }
    }
    .div-linkt {
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 10px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
        margin-top: 2px;
      }
    }
    @media screen and (max-width: $mobile-breakpoint) {
      width: 100%;
    }
  }
  .type2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    position: absolute;
    right: 15px;
    top: 0px;
    background-size: cover;
    transform: rotate(180deg);
    @media screen and (max-width: $mobile-breakpoint) {
      display: none;
    }
  }
  .type3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    position: absolute;
    right: 83px;
    top: 0px;
    background-size: cover;
    @media screen and (max-width: $mobile-breakpoint) {
      display: none;
    }
  }

  .type2 {
    img {
      &:hover {
        cursor: pointer;
        opacity: 0.4;
      }
    }
  }
  .type3 {
    img {
      &:hover {
        cursor: pointer;
        opacity: 0.4;
      }
    }
  }
  .typegen {
    &:hover {
      cursor: pointer;
    }

    .slick-disabled {
      opacity: 0.4 !important;
    }
  }
  img {
    &.imagearrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
  }
  ///////////
  .pagination {
    display: inline-block;
    border: 1px solid $brdgrey;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;

  }

  .pagination a.active {
    background-color: $brdgrey;
    border: 1px solid $brdgrey;
    cursor: default;
  }

  .pagination a:hover:not(.active) {
    background-color: $white;
  }

  .pagination a:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .pagination a:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .divpagenation {
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 50px;
    position: relative;
    @media screen and (max-width: $mobile-breakpoint) {
      display: none;
    }
  }
  .publications_slider2 {
    padding-top: 30px;
    padding-bottom: 40px;
    margin-bottom: 20px;
    @media screen and (max-width: $mobile-breakpoint) {
      padding-bottom: 30px;
    }

  }
  .searchnews {
    width: 100%;
    height: 50px;
    border: 1px solid $brdgrey;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-position: right 30px center;
    background-repeat: no-repeat;
    color: $brdgrey;
    padding-left: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .daterange {
    margin-top: 30px;
    width: 100%;
    height: 50px;
    border: 1px solid $brdgrey;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-position: left 16px center;
    background-repeat: no-repeat;
    color: $brdgrey;
    padding-left: 70px;
  }

  .form-control::-webkit-input-placeholder {
    margin-left: 20px;
    padding-left: 20px;
    @media screen and (max-width: $mobile-breakpoint) {
      color: $white;
    }
  }
  .checkboxclass {
    width: 30px;
    height: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: $white;
    border: 1px solid $dark-blue;
  }
  //
  .styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
    border: 1px solid $dark-blue;

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }

    // Box.
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 30px;
      height: 30px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      background: white;
      border: 1px solid $dark-blue;
    }

    // Box checked
    &:checked + label:before {
      background: $white;
    }

    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: $brdgrey;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: '\2713';;
      position: absolute;
      left: 10px;
      top: 2px;
      color: $dark-blue;
    }
  }
  label {
    font-family: $WorkSans;
    font-size: 14px;
    color: $onyx;
  }
  .d-flex {
    @media screen and (max-width: $mobile-breakpoint) {
      padding: 15px;
      display: block !important;
    }
  }
  .slick-dots {
    display: flex;
    justify-content: center;

    margin: 0;
    padding: 30px 0;

    list-style-type: none;

    li {
      margin: 0 0.25rem;
    }

    button {
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      padding: 0;
      cursor: pointer;
      border: none;
      border-radius: 100%;
      background-color: $brdgrey;
      text-indent: -9999px;
    }

    li.slick-active button {
      background-color: $bluedark;
    }

  }
  .styled-checkbox:checked + label:after {
    content: "\2713";
    position: absolute;
    left: 9px;
    top: 3px;
    color: $dark-blue;
    font-size: 20px
  }
  ul.slick-dots {
    @media screen and (max-width: $mobile-breakpoint) {
      padding-top: 0;
    }
  }
  label {
    margin-right: 15px;
  }
  .row.divaccordionspace {
    @media screen and (max-width: $mobile-breakpoint) {
      display: none;
    }
  }
}
