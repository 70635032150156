.m11{
    h1 
    {
        font-family: $tiempos-headline-medium;
        font-size: 36px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: justify;
        color: $onyx;
        margin-bottom: 9px;
        white-space: pre-wrap;
    }
    h2 
    {
        font-family: $tiempos-headline-medium;
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: $fbgdark;
        margin-bottom: 34px;
    }
    p {
        font-family: $WorkSans;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.75;
        letter-spacing: normal;
        color: $onyx;
        padding-bottom: 51px;
        margin-bottom: 0px;
        &:first-child
        {
            margin-bottom: 30px;
            padding-bottom: 0px;
        }
        &.ptext
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $istblack;
        }
        &.ptext12
        {
            font-family: $WorkSans;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: $fbgdark;
        }
        &.parccodion
        {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            color: $onyx;
            margin-left: 55px;
            padding: 0;
            margin-bottom: 18px;
            &:first-child
            {
                margin-left: 55px;
                margin-top: 28px;
            }
            &:last-child
            {
                margin-bottom: 30px;
            }
        }
        &.psubmenu
        {
            font-family: $TiemposText;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: $black;
            padding-bottom: 20px;
            margin-bottom: 0;
            margin-left: 20px;
            margin-right: 20px;
            text-align: left;
            border-bottom: 1px solid $brdgrey;
        }
        &.pdescription
        {
            font-family: $TiemposText;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.88;
            letter-spacing: normal;
            text-align: center;
            color: $onyx;
            @media screen and (max-width: $mobile-breakpoint) {
                font-size: 16px;
                margin: 0;
            }
        }
        &.pauthor
        {
            font-family: $tiempos-headline-medium;
            font-size: 24px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $black;
            @media screen and (max-width: $mobile-breakpoint) {
                font-size: 22px;
                margin: 0;
            }
        }
        &.prole
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $grey;
        }
        &.pdatetimetext
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $grey;
            margin: 0;
            padding: 0;
        }
    }
    ul 
    {
        padding-right: 15px;
    }

    ol li 
    {
        list-style-type:decimal-leading-zero;
        color: orangered;
    }
    a {
        &.buttonlinkk2{
            min-width: 120px;
            height: 30px;
            background-color: $dark-blue;
            border: $dark-blue;
            padding-top: 6px;
            padding-bottom: 8px;
            padding-left: 15px;
            padding-right: 19px;

            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: $white;
            margin-left: 15px;
            margin-right: 5px;
            text-align: center;
            @media screen and (max-width: $mobile-breakpoint) {
                margin-bottom: 20px;
            }
        }
        &.linklist
        {
            font-family: $WorkSans;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.44;
            letter-spacing: normal;
            color: $fbgdark;
            display: inline-block;
        }
        &.linklist.active
        {
            font-family: $WorkSans;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.44;
            letter-spacing: normal;
            color: $dark-blue;
             display: inline-block;
        }
        &.fontstyle
        {
            font-family: $WorkSans;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.5;
            letter-spacing: normal;
            color: $onyx;
            border-bottom: 1px solid orangered;
        }
        &.nav-link
        {
            padding-left: 0;
            line-height: 44px;
            display: inline;
            margin-right: 45px;
            padding: 0;
        }
        &.linkfont
        {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: $black;
        }
        &.linkfont2
        {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: $fbgdark;
        }
        &.accordionmenu
        {
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $greyb;
            &:first-child
            {
                color: $dark-blue;
            }
        }
        &.arcodionlink
        {
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.11;
            letter-spacing: normal;
            color: $black;
        }
        &.linkinsideacc
        {
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            color: $dark-blue;
            margin-left: 6px;
            cursor: pointer;
        }
        &.linksubmenu
        {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $onyx;
            display: inline;
            border-bottom:  1px solid $onyx;
        }
    }
    a:hover 
    {
        outline: none;
        text-decoration: none;
    }
    a:focus
    {
        outline: none;
        text-decoration: none;
    }
    img {
        width: 100%;
        height: auto;
    }
    .col-md-3.d-flex.justify-content-end
    {
        padding: 0;
    }
    ul li {
        &.nav-item
        {
            line-height: 44px;
            text-align: left;
            border-bottom: 1px solid $brdgrey; 
        }
        &.nav-item:active
        {
            line-height: 44px;
            text-align: left;
            border-bottom: 1px solid $brdgrey; 
        }
        &.lit
        {
            padding-left: 12px;
        }
    }
    li.dropdown:hover > .dropdown-menu {
        display: block;
    }
    a.nav-link.linkfont2 {
        //padding-left: 15px;
        margin: 0;
    }
    a.nav-link.linkfont2:hover {
        font-weight: 600;
    }
    ul 
    {
        .collapse.list-unstyled
        {
            padding: 0;
            border-top: 1px solid $brdgrey;
        }
    }
    .triangle
    {
        content: "";
        width: 5px;
        height: 5px;
        border-right: 1px solid $fbgdark;
        border-bottom: 1px solid $fbgdark;
        transform: rotate(315deg);
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 10px;
        margin-right: 10px;
    }
    .divaccordionspace
    {
        margin-top: 30px;
        margin-bottom: 19px
    }
    .divborder
    {
        border-top: 1px solid $brdgrey;
        margin: 0px;
        padding: 0px;
    }
    .divborderbottom
    {
        border-bottom: 1px solid $brdgrey;
        margin-bottom: 10px;
    }
    .divacc
    {
        padding-top: 45px;
    }
    ul.nav.flex-column {
        padding-right: 0;
    }
    .backtp{
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $istblack;
        margin: 0 auto;
        width: 100%;
        cursor: pointer;
        text-align: center;
    }
    .arrow 
    {
        content: "";
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        width: 10px;
        height: 10px;
        border-right: 2px solid $fbgdark;
        border-bottom: 2px solid $fbgdark;
        transform: rotate(225deg);
        cursor: pointer;
        margin-bottom: 55px;
    }

    .nav-link {

        &.expand {
        &:after {
            
                    content: "";
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    right: 17px;
                    border-right: 1px solid $fbgdark;
                    border-bottom: 1px solid $fbgdark;
                    transform: rotate(45deg);
                    top: 62px;
            }
        }
    }

    .nav-item {
        &.show {
        .nav-link.linkfont.expand {
            &:after {
                
                        content: "";
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        right: 2px;
                        border-right: 1px solid $fbgdark;
                        border-bottom: 1px solid $fbgdark;
                        transform: rotate(225deg);
                        top: 62px;
            }
        }
        }
    }
    ul.nav.flex-column.divborder {
        margin-right: 15px;
    }
    a.accordionmenu:hover
    {
        color: $dark-blue;
    }
    ////////////////////////////// 
    .rectanglemission {
        width: 194px;
        min-height: 165px;
        border-radius: 3px;
        background-color: $whtn;
        padding-bottom: 22px;
        margin-top: 0px;
        padding-top: 14px;
        @media screen and (max-width: $mobile-breakpoint) {
            margin-top: 0px;
            width: 100%;
        }
      }
      ul.no_bullet {
        list-style-type: none;
        padding: 0;
        margin: 0;
        }
      li.leaf {
        background: url('/assets/img/icons/arrow_back.svg') no-repeat left top ;
        min-height: 30px;
        padding-left: 50px;
        padding-top: 3px;
        padding-right: 10px;
        }
        .col-md-12.d-flex.justify-content-end {
            padding: 0;
            margin-bottom: 50px;
        }
    .description
    {
        width: 635px;
        height: 144px;
        margin-left: auto;
        margin-right: auto;
        @media screen and (max-width: $mobile-breakpoint) {
            width: 100%;
            min-height: 210px;
            }
    }
    .author
    {
        width: 220px;
        height: 24px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 27px;
        margin-bottom: 12px;
    }
    .role
    {
        width: 190px;
        height: 16px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 54px;
    }
    .col-md-12.divborderbottom
    {
        width: 100%;
        height: 30px;
    }
    .linkmenu
    {
        margin-top: 15px;
        margin-bottom: 10px;
        width: 100%;
        @media screen and (max-width: $mobile-breakpoint) {
            margin-bottom: 20px;
          }
    }
    .row.divaccordionspace {
        @media screen and (max-width: $mobile-breakpoint) {
            display: none;
          }
    }
    .d-flex {
        @media screen and (max-width: $mobile-breakpoint) {
        display: block!important;
        }
    }
    .slider-nav
    {
        @media screen and (max-width: $mobile-breakpoint) {
            display: none;
          }
    }
    .iframe
    {
        width:100%; 
        height:242px;
        margin-bottom: 50px;
        @media screen and (max-width: $mobile-breakpoint) {
            height:152px;
            margin-bottom: 50px;
          }
    }
}