header {
  .change-language {
    background-color: rgb(237, 237, 237);
    vertical-align: middle;
    p {
      margin: 0;
      line-height: 50px;
      font-size: 14px;
      padding-left: 14px;
      a {
        color: #333;
        font-weight: 700;

      }
    }
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
  a.dropdown-item {

    border-bottom: 1px solid #dddddd!important;
    font-family: $tiempos-text;
    @media screen and (min-width: 768px) {
      min-width: 400px;
      line-height: 50px!important;
    }
  }
  .divlogo {
    width: 300px;
    height: 150px;
    background-repeat: no-repeat;
    z-index: 999;
    position: absolute;
    /* @media screen and (max-width: $screen-xl-min) {
         width: 250px;
         height: 100px;
     }*/
  }
  .divspace {
    width: 100%;
    height: 120px;
    background-color: $white;
  }
  .divlogondy {
    width: 100px;
    height: 66px;
    background-repeat: no-repeat;
    z-index: 999;
  }
  .navcolor {
    background-color: $whtn;
    border-top: 2px solid #ffffff;
  }
  a {
    padding: 0px;
  }
  nav.navbar.navbar-expand-md.navbar-light.sticky.top {
    padding: 0px;
  }

  .listtwo {
    padding: 0;
    margin-top: 9px;
    margin-bottom: 5px;
    font-family: $WorkSans;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $onyx;
    margin-left: 14px;
    margin-right: 14px;
    margin-bottom: 5px;
    margin-top: 9px;
  }

  li.nav-item.border-right.border-left {
    line-height: 30px;
  }
  li.nav-item {
    line-height: 30px;
  }
  p {
    &.ptest {
      @media screen and (max-width: $mobile-breakpoint) {
        font-family: $WorkSans;
        font-size: 15px;
        font-weight: 500;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 0px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $white;
        :first-of-type {
          border-bottom: 1px solid $othcol4;
        }
        :last-of-type {
          border: none;
        }
      }
    }
    &.p13 {
      line-height: 44px;
      font-family: $WorkSans;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $onyx;

      @media screen and (max-width: $mobile-breakpoint) {
        font-family: $WorkSans;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $othcol4;
        margin-bottom: 19px;
        margin-top: 19px;
        margin-left: 0px;
        margin-right: 0px;
        width: 80%;
        :first-of-type {

          margin-top: 15px;
          margin-bottom: 19px;
        }
        :last-of-type {

          margin-top: 19px;
          margin-bottom: 25px;
        }
      }
    }
  }
  a {
    &.puppermenu {
      font-family: $WorkSans;
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $onyx;
      margin-left: 12px;
      margin-right: 12px;
      margin-bottom: 5px;
      margin-top: 9px;
      text-transform: uppercase;
    }
  }

  a:hover {
    color: #B3B6B7;
    text-decoration: none;
  }

  a.nav-link.testlink {
    padding: 0px;
  }
  a.nav-link.bottom {
    padding: 0px;
  }
  .searchdiv {
    width: 25px;
    height: 25px;
    z-index: 999;
    right: 65px;
    top: 20px;
    position: absolute;
  }
  .triangle {
    width: 12px;
    height: 12px;
    background-color: red;
    position: absolute;
    right: 15px;
    top: 0px;
  }
  .iconmenu {
    position: relative;
  }
  .bottom2 {
    @media screen and (max-width: $mobile-breakpoint) {
      margin-left: 15px;
    }
  }
  nav .p1 {
    margin: 0;
    line-height: 44px;
    @media screen and (max-width: $mobile-breakpoint) {
      margin-top: 12px;
      margin-bottom: 12px;
      line-height: normal;
    }
  }
  a.nav-link.bottom.bottom2 {
    padding: 0;
    font-family: $WorkSans;
    border-bottom: 2px solid white;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: $onyx;
    line-height: 42px;
    @media screen and (max-width: $mobile-breakpoint) {
      margin-left: 15px;
      margin-right: 15px;
      border-bottom: 1px solid white;
    }
  }
  a.nav-link.darkline.p1 {
    padding: 0;
    border-bottom: 2px solid white;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 42px;
    font-family: $WorkSans;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: $onyx;
    text-transform: uppercase;
    @media screen and (max-width: $screen-md-min) {
      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 15px;
        margin-right: 15px;
        border-bottom: 2px solid $othcol4;
      }
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  .dropdown-menu {
    padding: 0px;
    margin: 0px;
    margin-top: 0;
    border: none;
    box-shadow: 0px 3px 8px rgb(100, 100, 100);
    border-radius: 0;
    border-top: 1px solid #dee2e6!important;
    @media screen and (max-width: 767px) {
      box-shadow: none;
      border-radius: none;
    }
    a {
      font-family: $tiempos-headline-regular;
    }
  }
  a.dropdown-item:hover {
    background-color: $orangeo;
    color: $white;
    border: none;
    border-radius: 0;
  }
  li.dropdown-item {
    padding: 0;
  }
  li.dropdown-item :hover {
    background-color: $orangeo;
    padding: 0;
    margin: 0;
    border-radius: none;
    border: none;
  }
  ul.dropdown-menu.show {
    text-decoration: underline;
    text-decoration-color: $orangeo;
    border: none;
  }
  a.nav-link.darkline.p1:hover {
    border-bottom: 2px solid $orangeo;
    border-radius: 0;
    @media screen and (max-width: $mobile-breakpoint) {
      @media screen and (max-width: $screen-md-min) {
        color: $othcol4;
        border-bottom: 2px solid $othcol4;
      }
      border-bottom: 2px solid $orangeo;
    }
  }
  a.nav-link.bottom.bottom2:hover {
    border-bottom: 2px solid $orangeo;
    border-radius: 0;
    @media screen and (max-width: $mobile-breakpoint) {
      color: $othcol4;
      border-bottom: 2px solid $orangeo;
    }
    @media screen and (max-width: $screen-sm-min) {
      color: $othcol4;
      border-bottom: 2px solid $fbgdark;
    }
  }

  .primary-navigation ul li:hover a {
    background: #f39cbd;
    border-radius: 0;
  }
  a.nav-link.darkline.p1:hover a {
    background: #f39cbd;
    border-radius: 0;
  }

  .navbar-expand-md.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }

  a.nav-link.testlink.ptest {
    @media screen and (max-width: $mobile-breakpoint) {
      color: white;
      font-family: $WorkSans;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      line-height: 44px;
      color: $white;
      margin-left: 15px;
    }
  }
  a.nav-link.testlink.ptest:hover {
    @media screen and (max-width: $mobile-breakpoint) {
      color: white;
      border-bottom: 1px solid $white;
    }
  }
  a.nav-link.testlink.ptest::after {
    color: $white;
  }
  button.navbar-toggler {
    @media screen and (max-width: $mobile-breakpoint) {
      border: none;
    }
  }
  button.navbar-toggler:hover {
    border-radius: 0;
    @media screen and (max-width: $mobile-breakpoint) {
      border: none;
      border-radius: 0;
    }
  }
  .navbar-toggle.collapsed {
    background-color: $black !important;
  }

  .divinline {
    @media screen and (max-width: $mobile-breakpoint) {
      width: 200px;
      text-align: left;
      margin-bottom: 15px;
      margin-top: 16px;
    }
  }
  .singlecharacter {
    @media screen and (max-width: $mobile-breakpoint) {
      display: inline-block;
      width: 10px;
      height: 33px;
      background-color: #485d30;
      color: white;
      text-align: center;
      vertical-align: middle;
      line-height: 31px;
      font-size: 16px;
    }
  }
  .multiplesinglecharacter {
    @media screen and (max-width: $mobile-breakpoint) {
      display: inline-block;
      width: 57px;
      height: 33px;
      background-color: #485d30;
      color: white;
      text-align: center;
      vertical-align: middle;
      line-height: 31px;
      font-size: 16px;
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .navbar-toggler {
      border: none;
      outline: none;
    }
  }
  .nav-link {

    @media screen and (max-width: $mobile-breakpoint) {
      position: relative;
    }

    &.expand {
      &:after {
        @media screen and (max-width: $screen-md-min) {
          @media screen and (max-width: $mobile-breakpoint) {
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            right: 2px;
            border-right: 1px solid $fbgdark;
            border-bottom: 1px solid $fbgdark;
            transform: rotate(45deg);
            top: 17px;
          }

        }
      }
    }

    &.testlink {
      padding: 0px;
    }
  }

  .nav-item {
    &.show {
      .nav-link.linkfont.expand {
        &:after {
          @media screen and (max-width: $screen-md-min) {
            @media screen and (max-width: $mobile-breakpoint) {
              content: "";
              width: 10px;
              height: 10px;
              position: absolute;
              right: 2px;
              border-right: 1px solid $fbgdark;
              border-bottom: 1px solid $fbgdark;
              transform: rotate(225deg);
              top: 23px;
            }
          }
        }
      }
    }
  }
  nav.navbar.navbar-expand-md.navbar-light.sticky.top {
    border-bottom: 1px solid #dee2e6 !important;
    border-top: 1px solid #dee2e6 !important;
    @media screen and (max-width: $mobile-breakpoint) {
      border-bottom: 1px solid #dee2e6 !important;
    }
    //border: none;
  }
  img {
    &.mainlogo {
        margin-top: 10px;
        max-width: 190px;
        background: #fff;
      @media screen and (min-width: 768px) and (max-width: $mobile-breakpoint) {
        width: auto;
        height: 99px;
      }
      @media screen and (max-width: 767px)  {
        margin-top: 0px;
        width: auto;
        height: 100%;
      }
    }
  }
  @media screen and (min-width: 768px) {
    li.dropdown:hover > .dropdown-menu {
      display: block;
    }

  }
  @media screen and (max-width: 767px) {
    li.dropdown > .dropdown-menu {
      padding: 0 30px;
      a.dropdown-item {
        padding: 0;
        line-height: 50px;
        &:last-child {
          border-bottom: 0!important;
        }
      }
    }
  }
  .dropdown::after .dropdown-menu {
    display: none;
  }

  .navbar-light .navbar-nav .nav-link:hover {
    @media screen and (max-width: $screen-sm-min) {
      color: white;
    }
  }
  nav.navbar.navbar-expand-md.navbar-light.sticky.top {
    @media screen and (max-width: $mobile-breakpoint) {
      z-index: 9999;
    }
  }

  nav.navbar.navbar-expand-md.navbar-light.sticky.top > .container {
    position: relative;
  }



  li {
    @media screen and (min-width: $screen-sm-min) {
      &.nav-item {

        &:hover {
          .nav-link {
            border-bottom: 2px solid $orangeo;
            @media screen and (max-width: $screen-sm-min) {
              border-bottom: 2px solid $fbgdark;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: $screen-sm-min) {
    .dropdown:hover .dropdown-menu {
      display: block;
    }
  }

  .nav-item:link, .nav-link:link {
    display: block;
  }
  .nav-item:link, .nav-link:active {
    display: block;
  }
}
