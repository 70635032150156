.m19
{
    /////////////////
    input#searchid {
        //url('../assets/img/svg/icon-search.svg');
        width: 100%;
        background-repeat: no-repeat;
        text-indent: 20px;
        background-position: right 30px center;
        padding: 0;
        border: none;
        background-color: $grey-light;
        line-height: 44px;
    }
    input[type=text]:focus{
        background-image: none;
      }
      input[type=text]:active{
        background-image: none;
      }
      input[type=text]:hover{
        background-image: none;
      }
      .accordion
      {
        overflow-y:scroll; 
        height: 100px;
        margin-left: 21px;
      }
      ::-webkit-scrollbar {
        width: 12px;
        background-color: $grey-light;
    }
     
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset red;
    }
     
    ::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: $dark-blue; 
    }
    .regular-checkbox {
        -webkit-appearance: none;
        background-color: $grey-light;
        border: 1px solid $greyvo;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
        padding: 9px;
        border-radius: 3px;
        display: inline-block;
        position: relative;
    }
    input[type="checkbox"] {
        width: 20px;
        height: 20px;
        margin-top: 15px;
        text-align: center;
        border: 5px solid blue;
        color: blue;
        background-color: white;
    }
    //
    .checkbox-custom {
        opacity: 1;
        background-color: $grey-light;   
    }
    ///////////////
    .panel-group
    {
        &:last-child
        {
            margin-bottom: 110px;
        }
    }
      
      .panel-body {
        padding: 40px 54px;
      }
      
      .panel-group .panel+.panel {
        margin-top: 0;
        border-top: 0;
      }
      
      .panel-group .panel {
        border-radius: 0;
      }
      
      .panel-default>.panel-heading {
        color: $onyx;
        background-color: $white;
        border-color: $othercol;
        padding: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      .panel-default>.panel-heading a {
        display: block;
        padding: 10px 15px;
        text-decoration: none;
        @media screen and (max-width: $mobile-breakpoint) {
            padding-left: 0px;
        }
      }
      
      .panel-default>.panel-heading a:after {
        content: "";
        position: relative;
        top: 1px;
        display: inline-block;
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: transform .25s linear;
        -webkit-transition: -webkit-transform .25s linear;
      }
      
      .panel-default>.panel-heading a[aria-expanded="true"] {
        border-bottom: 1px solid $brdgrey;
        border-bottom-right-radius: 0px;
        border-bottom-right-radius: 0px;
        height: 50px;
      }
      
      .panel-default>.panel-heading a[aria-expanded="true"]:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 30px;
        border-right: 1px solid $fbgdark;
        border-bottom: 1px solid $fbgdark;
        transform: rotate(225deg);
        top: 20px;
        @media screen and (max-width: $mobile-breakpoint) {
            right: 5px;
        }
      }
      
      .panel-default>.panel-heading a[aria-expanded="false"]:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 30px;
        border-right: 1px solid $fbgdark;
        border-bottom: 1px solid $fbgdark;
        transform: rotate(45deg);
        top: 19px;
        @media screen and (max-width: $mobile-breakpoint) {
            right: 5px;
        }
      }

      .panel-group
      {
          border: 1px solid $brdgrey;
          margin-bottom: 10px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          position: relative;
          @media screen and (max-width: $mobile-breakpoint) {
                border:none;
                border-radius: 0;
                border-top: 1px solid $brdgrey;
                border-bottom: 1px solid $brdgrey;
                margin-bottom: 0;
                &:first-child {
                    margin-top: 30px;
                }
            }
      }
    .col-md-12.accdiv
    {
        @media screen and (max-width: $mobile-breakpoint) {
            padding: 0;
        }
    }
}