.m21x {
  .slider-for {
    img {
      width: 100%;
      padding-right: 2px;
    }
  }
  .slider-nav {
    img {
      padding-right: 2px;
      margin-top: 2px;
      -webkit-filter: brightness(50%);
      &:hover {
        cursor: pointer;
      }
    }
    .slick-current {
      img {
        -webkit-filter: brightness(100%);
      }
    }
    button {
      display: none !important;
    }
  }
  .prev {
    position: absolute;
    top: 217px;
    bottom: 29px;
    left: 15px;
    transform: rotate(90deg);
    width: 80px;
    height: 80px;
    cursor: pointer;
    img {
      height: 80px;
      width: 80px;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      top: 170px!important;
    }
    @media screen and (max-width: 767px) {
      display: none!important;
    }
  }
  .next {
    position: absolute;
    top: 217px;
    right: 15px;
    transform: rotate(270deg);
    width: 80px;
    height: 80px;
    cursor: pointer;
    img {
      height: 80px;
      width: 80px;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      top: 170px!important;
    }
    @media screen and (max-width: 767px) {
      display: none!important;
    }
  }
}