.sidebar05x {
  max-width: 210px;
  margin-left: auto;
  margin-top: 50px;
  border-radius: 3px;
  border:1px solid $othercol;
  padding: 16px 18px;
  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: 20px;
    width: 100%;
    margin-bottom: 0;
    max-width: 100%;
    margin-left: 0;
  }
  p {
    font-family: $tiempos-headline-medium;
    line-height: 1.25;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #333333;
    padding-bottom: 15px;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      min-height: 30px;
      padding-left: 44px;
      position: relative;
      &:before {
        content: " ";
        background-image: url(/assets/img/icons/arrow_back.svg);
        background-size: 30px 30px;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 10px;
        transform: rotate(180deg);
      }
      a {
        font-size: 14px;
        color: $onyx;
        display: inline;
        border-bottom:  1px solid $onyx;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}