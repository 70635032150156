.m18 {
  border-top: 1px solid $brdgrey;
  a {
    &.buttonlinkk2 {
      min-width: 120px;
      height: 30px;
      background-color: $dark-blue;
      border: $dark-blue;
      padding-top: 6px;
      padding-bottom: 8px;
      padding-left: 15px;
      padding-right: 19px;

      font-family: $WorkSans;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: $white;
      margin-left: 15px;
      margin-right: 5px;
      margin-bottom: 20px;
      text-align: center;
      border-radius: 3px;
      @media screen and (max-width: $mobile-breakpoint) {
        margin-bottom: 20px;
        margin-left: 0px;
        margin-right: 20px;
      }
    }
  }
  ///
  .linkmenu {
    margin-top: 15px;
    width: 100%;
    @media screen and (max-width: $mobile-breakpoint) {
      margin-bottom: 0px;
    }
  }
}