.m07 {
  .classcenter {
    @media screen and (max-width: $mobile-breakpoint) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .rectangle {
    min-height: 248px;
    position: relative;
    padding: 20px;
    border: 1px solid;
    border-color: $brdgrey;
    @media screen and (max-width: $mobile-breakpoint) {
      min-height: 313px;
      max-width: 255px;
    }
  }

  .rectangle2 {
    width: 24px;
    height: 24px;
    position: absolute;
    border: 1px solid;

    z-index: 999;
    right: -1px;
    top: -1px;
    border-color: white white $brdgrey $brdgrey
  }

  .publications_slider {
    padding-bottom: 0px;
    padding-top: 30px;
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-color: $brdgrey;
  }


  .one {
    width: 24px
  }

  .one {
    background: linear-gradient(45deg, $white 49%, $brdgrey 50%, $white 51%);
  }


  .bottomLine {
    border: 1px solid $grey;
    background-color: $grey;
  }

  .topLine {
    border: 1px solid $black;
    background-color: $black;
  }

  p {
    &.link {
      font-family: $WorkSans;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: $it-orange;
      right: 0%;
      position: relative;
      margin-top: 20px;
    }
  }

  .linkdiv {
    width: 100%;
    background-color: white;
    text-align: right;
    margin-left: 15px;
    margin-right: 15px;
    height: 120px;
    @media screen and (max-width: $mobile-breakpoint) {
      //display: flex;
      display: grid;
      align-items: center;
      justify-content: center;
    }
  }

  .upertext {
    position: relative;
    width: 100%;
    background-color: white;
    text-align: left;
    margin-left: 15px;
    margin-right: 15px;
    height: 75px;
    border-top: 2px solid black;
  }

  h1 {
    font-family: $tiempos-headline-medium;
    margin-bottom: 0px;
    margin-top: 18px;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: justify;
    color: $onyx;
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 24px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $fbgdark;
    }
  }

  p {
    margin-bottom: 18px;

    &.p2 {
      margin-bottom: 20px;

      &:first-of-type {
        color: $dark-blue;
        font-weight: 500;
      }

      &:last-of-type {
        margin: 0;
      }
    }

    &.backtotop {
      font-family: $WorkSans;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $istblack;
      text-align: center;
      @media screen and (max-width: $mobile-breakpoint) {
        text-align: center;
        bottom: 5px;
      }
    }

  }

  h2 {
    margin-bottom: 22px;
  }

  .divsliderarrows {
    position: absolute;
    right: 0;
    top: 18px;
    background-color: red;
    @media screen and (max-width: $mobile-breakpoint) {
      display: none;
    }
  }

  .divsliderarrows img {
    margin: 5px 5px 0px;
    width: 35px;
    height: 35px;
    opacity: 0.4;
    filter: alpha(opacity=40); /* For IE8 and earlier */
  }

  .divsliderarrows img:hover {
    opacity: 0.7;
    filter: alpha(opacity=70); /* For IE8 and earlier */
  }

  a:hover {
    text-decoration: none;
  }

  img {
    &.imagearrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
  }

  .slick-dots {
    display: flex;
    justify-content: center;

    margin: 0;
    padding: 30px 0;

    list-style-type: none;

    li {
      margin: 0 0.25rem;
    }

    button {
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      padding: 0;

      border: none;
      border-radius: 100%;
      background-color: $brdgrey;
      text-indent: -9999px;
    }

    li.slick-active button {
      background-color: $bluedark;
    }

  }


  .type2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0px;
    top: 18px;
    background-size: cover;
    transform: rotate(180deg);
    @media screen and (max-width: $mobile-breakpoint) {
      display: none;
    }
  }

  .type3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 67px;
    top: 18px;
    background-size: cover;
    @media screen and (max-width: $mobile-breakpoint) {
      display: none;
    }
  }

  .type2 {
    img {
      &:hover {
        cursor: pointer;
        opacity: 0.4;
      }
    }
  }

  .type3 {
    img {
      &:hover {
        cursor: pointer;
        opacity: 0.4;
      }
    }
  }

  .typegen {
    &:hover {
      cursor: pointer;
    }

    .slick-disabled {
      opacity: 0.4 !important;
    }
  }


  .toptriangle {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    right: 2px;
    border-right: 1px solid $fbgdark;
    border-bottom: 1px solid $fbgdark;
    transform: rotate(45deg);
    top: 17px;
    text-align: center;
  }

  .col-md-6.classcenter {
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 15px;
    margin-right: 15px;
    @media screen and (max-width: $mobile-breakpoint) {
      border-bottom: 1px solid $brdgrey;
    }
  }

  .backtp {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $istblack;
    margin: 0 auto;
    width: 100px;
    cursor: pointer;
    text-align: center;
  }

  .arrow {
    content: "";
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 10px;
    height: 10px;
    border-right: 2px solid $fbgdark;
    border-bottom: 2px solid $fbgdark;
    transform: rotate(225deg);
    cursor: pointer;
  }

  .slick-slider {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  .slick-list.draggable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  a {
    &.linkh2 {
      font-family: $tiempos-headline-medium;
      font-size: 24px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $fbgdark;
      margin-bottom: 22px;
      font-weight: 500;
      /*
      @media screen and (min-width: $mobile-breakpoint+1) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        */
        /*! autoprefixer: off */
      /*
        -webkit-box-orient: vertical;
        height: 65px;
      }
    */
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.3;
        letter-spacing: normal;
        color: $fbgdark;
        /*
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        */
        /*! autoprefixer: off */
        /*
        -webkit-box-orient: vertical;
        height: 82px;
        */
      }

    }
  }

  a:hover {
    outline: none;
    text-decoration: none;
    border: none
  }

  a:focus {
    outline: none;
    text-decoration: none;
    border: none
  }

  p {
    &.paragraph {
      @media screen and (min-width: $mobile-breakpoint) {
        margin-top: 22px;
      }
      @media screen and (max-width: $mobile-breakpoint) {
        margin-top: 12px;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $fbgdark;
      }
    }
  }
}

