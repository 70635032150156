.m05
{
    ////
    p
    {
        &.link
        {
            font-family: $WorkSans;
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: $orangeo;
            right: 0%;
            position: relative;
            margin-top: 20px;
        }

        &.pdepartment {
            max-width: 164px;
            height: 100%;
            text-align: center;
            vertical-align: middle;
            margin: 0;
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $dark-blue;
        }
        &.pname {
            max-width: 220px;
            height: 100%;
            text-align: center;
            vertical-align: middle;
            margin: 0;
            font-family: $tiempos-headline-medium;
            font-size: 24px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $othcol;
        }
        &.pgroup {
            max-width: 191px;
            height: 100%;
            text-align: center;
            vertical-align: middle;
            margin: 0;
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color:$othcol ;
        }
        &.pspecialization {
            max-width: 220px;
            height: 100%;
            font-family: $WorkSans;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $othcol;
            margin-bottom: auto;
        }
    }
    h1 {
        &.headerone {
            font-family: $tiempos-headline-medium;
            margin-top: 19px;
            margin-bottom: 19px;
        }
        @media screen and (max-width: $mobile-breakpoint) {
            font-size: 24px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $fbgdark;
        }
    }
    
    .publications_slider {
        padding: 50px 0;
        border-bottom: 1px solid;
        border-top: 1px solid;
        border-color: $brdgrey;
        @media screen and (max-width: $mobile-breakpoint) {
            padding: 15px 0;
            }
    }

    .divsliderarrows {
        position: absolute;
        right: 0;
        top:18px;
        background-color: red;
        @media screen and (max-width: $mobile-breakpoint) {
            display: none;
        }
    }
    
    .divsliderarrows img {
        margin: 5px 5px 0px;
        width: 35px;
        height: 35px;
        opacity: 0.4;
        filter: alpha(opacity=40); /* For IE8 and earlier */
    }
    
    .divsliderarrows img:hover {
        opacity: 0.7;
        filter: alpha(opacity=70); /* For IE8 and earlier */
    }
    .linkdiv
    {
        width: 100%;
        background-color: white;
        text-align: right;
        margin-left: 15px;
        margin-right: 15px;
        height: 100px;
        @media screen and (max-width: $mobile-breakpoint) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .upertext
    {
        position: relative;
        width: 100%;
        background-color: white;
        text-align: left;
        margin-right: 15px;
        height: 76px;
        border-top: 2px solid black;
        @media screen and (max-width: $mobile-breakpoint) {
            
        }
    }
    /////


    .profile {
        width: 100%;
        min-height: 316px;
        position: relative;
        @media screen and (max-width: $mobile-breakpoint) {
            border-bottom: 1px solid;
            //border-top: 1px solid;
            border-color: $brdgrey;
            margin-bottom: 15px;
        }
    }
    .department{
        width: 100%;
        min-height: 37px;
        display: flex;
        justify-content: center;
        top:0px;
        text-align: center;
        vertical-align: middle;
        line-height: 90px;
    }

    .profilepicture {
        width: 100%;
        min-height: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 57px;
    }

    .name{
        width: 100%;
        min-height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 199px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .specialization {
        width: 100%;
        min-height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 260px;
        margin-bottom: 5px;
    }
    
    a:hover {
        text-decoration: none;
     }

    .role {
        width: 100%;
        min-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 235px;
        margin-top: 8px;
        margin-bottom: 20px;
    }
    img {
        &.imageround{
            width: 125px;
            height: 125px;
            border-radius: 50%;
            object-fit: cover;
        }
        &.imagearrow {
            width: 38px;
            height: 38px;
            border-radius: 50%;
        }
      }
    .borderclass {
            //border-left: 1px solid  $brdgrey;
        @media screen and (max-width: $mobile-breakpoint) {
            border-right: none;
        }
    }
    
    .type2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0px;
        top:18px;
        background-size:cover;
        transform: rotate(180deg);
        @media screen and (max-width: $mobile-breakpoint) {
            display: none;
        }
    }
    .type3 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 67px;
        top:18px;
        background-size:cover;
        @media screen and (max-width: $mobile-breakpoint) {
            display: none;
        }
    }

    .type2 {
        img {
            &:hover {
                cursor: pointer;
                opacity: 0.4;
            }
        }
    }
    .type3 {
        img {
            &:hover {
                cursor: pointer;
                opacity: 0.4;
            }
        }
    } 
    .typegen {
        &:hover {
            cursor: pointer;
        }

        .slick-disabled {
            opacity: 0.4 !important;
        }
    }
    .slick-dots {
        display: flex;
        justify-content: center;
        
        margin: 0;
        padding: 1rem 0;
        
        list-style-type: none;
        
            li {
                margin: 0 0.25rem;
            }
        
            button {
                display: block;
                width: 0.5rem;
                height: 0.5rem;
                padding: 0;
                
                border: none;
                border-radius: 100%;
                background-color: $brdgrey;
                text-indent: -9999px;
            }
        
            li.slick-active button {
                background-color: $bluedark;
            }
        
    }
    .slick-slider {
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
      }
      .slick-list.draggable {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .col-md-3.slick-slider
      {
          border-right: 1px solid  $white;
          border-left: 1px solid  $brdgrey;
      }
      .col-md-3.slick-slider.slick-slide.slick-current.slick-active
      {
        border-left: 1px solid  $white;
      }
}